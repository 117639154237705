/* eslint-disable max-len */
import React, { Fragment } from 'react';
import { Link } from 'react-router';
import { ROUTES } from 'constants';

export const TITLE = 'The solution for user experience, risk management, and data protection in one';

export const CONTENT = [
  {
    id: 1,
    title: 'Protect Data and Prevent Fraud',
    text: 'Reduce losses to fraud with accurate and fast biometric comparison technology. Trust Stamp Verify provides a low-friction way to enhance risk management and data protection measures with secure, spoof-resistant authentication systems, all while protecting sensitive information from would-be criminals',
    slug: 'protect-data',
  },
  {
    id: 2,
    title: 'Increase Reach and Retention',
    text: 'Leverage the positive friction and reputational boost of biometric technology while reaching more users, reducing drop-out rates, and improving customer satisfaction with accessible digital tools',
    slug: 'woman-microphone',
  },
  {
    id: 3,
    title: 'Enhance User Experience',
    text: 'Empower your users to quickly prove their identity anytime and anywhere, eliminating barriers to conversion so they can access your services and systems the way you intended',
    slug: 'girl-phone',
  },
  {
    id: 4,
    title: 'Seamlessly Integrate',
    text: 'Easily add advanced biometric capabilities to your user flows for account access, recovery, and more. Our systems are designed for rapid deployment across devices and platforms for a consistent user experience',
    slug: 'integrate',
  },
  {
    id: 5,
    title: 'Adjust for Needs',
    text: 'Adjustable matching thresholds allow your organisation to set criteria based on risk-acceptance needs and make on-the-fly changes to balance good friction with security requirements',
    slug: 'adjust',
  },
  {
    id: 6,
    title: 'Leverage Unmatched Efficiency',
    text: 'Trust Stamp’s transformation and comparison technologies significantly minimise data needed to accurately match identifiers. With an added layer of automated verification reducing the need for manual review, you can free up resources and reduce system strain',
    slug: 'unmatched-efficiency',
  },
  {
    id: 7,
    title: 'Build for Longevity',
    text: 'Set the foundation for a robust identity infrastructure with technology that prioritises privacy and data protection, in a rapidly evolving global compliance landscape',
    slug: 'globe-longevity',
  },
];

export const TITLE2 = 'Biometric login in seconds';

export const HOWITWORKS = [
  {
    id: 1,
    title: 'Accurate and efficient biometric analysis',
    text: <Fragment><Link to={ ROUTES.CAPTURE }>Trust Stamp Capture</Link> leverages advanced biometric analysis with presentation attack detection, including proof-of-life and anti-spoofing tests that determine whether captured data belongs to a real person</Fragment>,
    slug: 'pol',
  },
  {
    id: 2,
    title: 'Data security layered throughout',
    text: <Fragment>With <Link to={ ROUTES.PROTECT }>Trust Stamp Protect</Link>, transformation algorithms minimise and obfuscate raw information, turning PII into pseudonymised data that serves as an accurate identifier within your systems, but has no external value if ever compromised</Fragment>,
    slug: 'multiple-checks',
  },
  {
    id: 3,
    title: 'Instant verification with zero compromises',
    text: 'Trust Stamp Verify performs a rapid probabilistic 1:1 comparison between the Irreversibly Transformed Identity Token generated on enrolment and that created during authentication for frictionless, protected proofing in seconds ',
    slug: 'authenticate',
  },
];
