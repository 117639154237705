/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router';
import BasePage from 'pages/BasePage';
import { ROUTES } from 'constants';
import Banner from 'components/Banner';

export default class BlogBeyondPandemic extends BasePage {
  className = 'ts-BlogBeyondPandemic';
  static title = 'Blog Beyond Pandemic';

  renderContent() {
    return (
      <section>
        <div className="ts-container">
          <h2>Digital Identity Tools to Power Financial Institutions Through and Beyond the Pandemic</h2>
          <div className={ this.cn`__info` }>
            via <Link
              href="https://www.venturecenter.co/"
              target="_blank"
              rel="noopener noreferrer"
            >
              The Venture Center
            </Link>
          </div>
          <p>In a short period of time, in-person interactions have gone remote and virtual, creating increased reliance on digital channels for normal identity functions, including <Link to={ ROUTES.IDENTIFY }>new customer onboarding</Link>, transaction approval, and <Link to={ ROUTES.VERIFY }>account recovery</Link>. Because every digital interaction starts with establishing the customer’s identity, it is a crucial part of a successful digital transformation. However, in 2019 alone, over 7,000 reported security breaches exposed <Link
            href="https://www.riskbasedsecurity.com/2020/02/10/number-of-records-exposed-in-2019-hits-15-1-billion/"
            target="_blank"
            rel="noopener noreferrer"
          >15B
          </Link> personal records, revealing a vast array of personally identifiable information (PII) ranging from social security numbers to knowledge-based authentication (KBA) answers. These breaches fuel identity fraud, which reached <Link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          href="https://escalent.co/blog/managing-fraud-and-trust-in-the-covid-19-environment/"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          target="_blank"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          rel="noopener noreferrer"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        >$16.9B
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              </Link> in losses in 2019.
          </p>
          <p>Successful identity innovation helps financial institutions seamlessly add and retain new customers while reducing fraud. When combined with robust <Link to={ ROUTES.PROTECT }>data security and compliance</Link>, <Link to={ ROUTES.CAPTURE }>biometric tools</Link> offer a powerful approach to keeping fraudsters out while letting in more genuine customers. At a time when financial institutions and consumers alike are under an increasing financial strain, these improvements to security and useability can efficiently boost growth alongside customer loyalty.</p>
          <Link
            href="https://www.venturecenter.co/blog/truststamp/"
            className="ts-button--secondary"
            target="_blank"
            rel="noopener noreferrer"
          >
            READ THE FULL ARTICLE
          </Link>
        </div>
      </section>
    );
  }

  render() {
    return (
      <div className={ this.rootcn() }>

        { /* banner */ }
        <Banner theme="selfie" size="small">
          <h1>Digital Identity Blog</h1>
        </Banner>

        { this.renderContent() }

      </div>
    );
  }
}
