import atom from 'atom-js';
import is from 'next-is';
import store from 'sf/helpers/store';

const isSamsungDeviceWithBrightnessProblem = () => {
/*
	When utilizing ImageCapture API,
	Samsung Galaxy A70 and A72 produce images that are very bright.
	As a result, for those two devices, we shouldn't use that API.
*/
	const isSamsung = is.samsungBrowser() || is.samsung();
	if (!is.browser() || !isSamsung) return false;

	const ua = navigator.userAgent;

	return !!ua.match(/SM-A705/i) || !!ua.match(/SM-A725/i);
};

const modelFactory = (Webcam) => {
	const videoParams = {
		// We assume bitrate 2300000 is OK for 640x480.
		// 640 * 480 * 7.5 ~= 2300000
		// 1280 * 720 * 7.5 ~= 7000000
		// fps should be also counted, but we don't have control over it.
		video_bitrate_per_pixel: 7.5,
		video_length: 2 * 60 * 1000, // 2 minutes to save some bandwith.
		video_recording_time: 10, // seconds
	};

	const userProvidedParams = {
		cameraDetectionMode: null, // Labels are more reliable than webrtc, but doesn't always work
		capture_mode: Webcam.constants.CAPTURE_MODE_PHOTO,
		dest_height: 1600,        // these default to width/height
		dest_width: 1600,         // size of captured image.
		enable_file_fallback: true,
		flash_light_node: 'Enable Flash Light', // React Node required
		flip_horiz: false,     // flip image horiz (mirror mode)
		flip_horiz_back: undefined,     // flip image horizontally on both: front and back camera
		force_file: false,     // force file upload mode
		force_fresh_photo: false, // When true, it will disallow to Select Photo from Library on iOS
		output_format: 'canvas', // 'base64', 'canvas', 'blob'
		image_format: 'jpeg',  // image format (may be jpeg or png)
		jpeg_quality: 90,      // jpeg image quality from 0 (worst) to 100 (best)
		no_interface_found_text: 'No supported webcam interface found.',
		switch_camera_node: 'Switch camera',   // Contents of "Switch Camera" button. React Node required
		use_ImageCapture: true, // use experimental ImageCapture API if available
		use_ImageCapture_flashlight: false, // use flashlight, part of ImageCapture API if available
		use_ImageCapture_takePhoto: true, // use takePhoto, part of ImageCapture API if available
		use_ImageCapture_grabFrame: false, // use grabFrame, part of ImageCapture API (fallback to takePhoto)
		verbose: false,              // Set to false to hide logs.
		webcam_path: './',            // URI to CSS files (defaults to the js location)
		cssPrefix: 'webcamjs', // prefix for all css classes
		controls: true,   // false to take a control over "flashlight" and "switch camera" buttons
		audio: false,     // enable microphone support
		/**
		 * To capture successfully barcode it's better to
		 * zoom camera, so the barcode stays in focus.
		 */
		zoomLevel: 1,
	};

	/* persistedState = state that won't be cleared on webcam.reset() */
	const persistedState = {
		camera: Webcam.constants.CAM_BACK, // "CAM_FRONT" or "CAM_BACK". Use `webcam.switchCamera(cam)` to control
		cameraInfs: [], // list of Inf objects for atteched camera devices
		cameraMode: null,
		height: 0,
		userMedia: true, // true when getUserMedia is supported natively;
		width: 0,
		isFullyLoaded: false,
		lastCameraId: null,
		webcamAccessGranted: false,
		cameraId: undefined,
		originalImageSize: '', // Information about the original size of the image before compression
		compressedImageSize: '', // Information about the size of the image after compression
	};

	const params = {
		...userProvidedParams,
		...videoParams,
		...persistedState,
	};

	/* state is cleared after reset (rotate, reattach etc) */
	const state = {
		live: false, // true when webcam is initialized and ready to snap
		load: false, // true when webcam movie finishes loading
		loadedVideoDimensions: {},
		photoCapabilities: {}, // https://developer.mozilla.org/en-US/docs/Web/API/PhotoCapabilities
		videoRecording: false,
		captureInProgress: false,	// set it to TRUE in async tasks so camera is not reset on
									// rotation change.
		cameraVideoElement: null,
		virtualCameraFound: false,
	};

	const defaults = {
		...params,
		...state
	};

	let initialisation = true;
	const model = atom.setup({
		onChange: (field, value) => {
			if (!(field in defaults)) {
				if (process.env.NODE_ENV !== 'production' && model.get('verbose')) {
					// eslint-disable-next-line no-console
					console.log(`[WARN] Webcamjs: "${field}" is not declared in params model.`);
				}
			}
			if (!initialisation) {
				Webcam.dispatch(field, value);
			}
		}
	})(defaults);

	model.resetState = () => {
		// clear data marked as state, keep params.
		Object
			.keys(model.get())
			.filter((key) => !Object.hasOwn(params, key)) // select everything that is not a param
			.forEach((key) => {
				const isDefaultState = Object.hasOwn(state, key);
				model.set(key, isDefaultState ? state[key] : undefined);
			});
	};

	const imageCaptureAvailabilityChecker = (value) => {
		if ((value && typeof ImageCapture === 'undefined') || isSamsungDeviceWithBrightnessProblem()) {
			if (is.browser()) {
				// eslint-disable-next-line no-console, max-len
				console.warn('ImageCapture is not available. `use_ImageCapture` passed to webcamjs reset to `false`.');
			}
			model.set('use_ImageCapture', false);
		}
	};

	model.on('use_ImageCapture', imageCaptureAvailabilityChecker);

	imageCaptureAvailabilityChecker(model.get('use_ImageCapture'));

	initialisation = false;
	return model;
};

module.exports = modelFactory;
