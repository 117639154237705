import * as types from 'components/UserPanel/UserPanel.types';

export const FAQ = () => ({
  type: types.FAQ,
});

export const contactUs = () => ({
  type: types.CONTACT_US,
});

export const logIn = () => ({
  type: types.LOG_IN,
});

export const register = () => ({
  type: types.REGISTER,
});
