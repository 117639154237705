/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import React, { Fragment } from 'react';

export const CONTENT = [
  {
    id: 0,
    cookie: 'Google Analytics',
    name: '_gid',
    purpose: <Fragment>
      <p>This cookie is a Google Analytics persistent cookie used to distinguish between unique users.</p>
    </Fragment>,
    duration: '24 hours',
    requirement: 'Analytics',
  },
  {
    id: 1,
    cookie: 'Google Analytics',
    name: '_gat_gtag_[Analytic_Key]',
    purpose: <Fragment>
      <p>This cookie is used to throttle the request rate. These are third-party cookies placed on your device to allow us to use the Google Analytics service. These cookies are used to collect information about how visitors use our website. We use this information to compile reports and to help us improve the website.</p>
    </Fragment>,
    duration: '1 minute',
    requirement: 'Analytics',
  },
  {
    id: 2,
    cookie: 'Google Analytics',
    name: '_ga',
    purpose: <Fragment>
      <p>This cookie is used to store information on how visitors use a website and helps in creating an analytics report of how the website is doing. The data collected including the number of visitors, the source from where they have come, and the pages visited in an anonymous form.</p>
    </Fragment>,
    duration: '2 years',
    requirement: 'Analytics',
  },
  {
    id: 3,
    cookie: 'Google Analytics',
    name: '1P_JAR',
    purpose: <Fragment>
      <p>These cookies are set via embedded youtube-videos. They register anonymous statistical data on, for example, how many times the video is displayed and what settings are used for playback.</p>
      <p>No sensitive data is collected unless you log in to your google account; in that case, your choices are linked with your account, for example, if you click “like” on a video.</p>
    </Fragment>,
    duration: '1 month',
    requirement: 'Analytics',
  },
];
