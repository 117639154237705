import React, { PureComponent, useEffect } from 'react';
import isPlainObject from 'lodash/isPlainObject';
import is from 'next-is';
import noop from 'no-op';
import { findDOMNode } from 'react-dom';
import { createRoot } from 'react-dom/client';
import {
  advancedPick,
  getRefValues,
  mediator,
  navigate,
  sentryLog,
} from 'sf/helpers';
import {
  addEventListener,
  removeEventListener,
  scrollTo,
} from 'sf/helpers/domHelper';

function syncOnModelChange(that, model, key, callback) {
  const args = [
    key,
    (value) => {
      that.setState({ [key]: value }, () => {
        callback(key, value);
      });
    },
  ];
  model.on(...args);
  that.__listeners.push(() => {
    model.off(...args);
  });
}

const roots = [];
let componentId = 0;

export default class BaseComponent extends PureComponent {
  static renderTo(_container, renderProps) {
    return new Promise((resolve) => {
      // just for a widget
      const Component = this;
      const WrappedComponent = () => {
        useEffect(() => {
          resolve();
        });
        return <Component { ...renderProps } />;
      };
      const container = typeof _container === 'string'
        ? document.querySelector(_container)
        : _container;

      if (!container) {
        return;
      }

      // make sure that node is not controlled by other React component
      roots.forEach((oldRoot, index) => {
        if (oldRoot._internalRoot && oldRoot._internalRoot.containerInfo === container) {
          oldRoot.unmount();
          roots.splice(index, 1); // remove from the array
        }
      });

      const root = createRoot(container);
      root.render(<WrappedComponent />);
      roots.push(root);

      const reset = () => {
        try {
          root.unmount();
        } catch (e) { /* noop */ }
        mediator.remove('RESET', reset);
      };

      mediator.subscribe('RESET', reset);
    });
  }

  // eslint-disable-next-line react/sort-comp
  constructorBrowser() { /* override me */ }

  constructor(props) {
    super(props);
    this.__listeners = [];
    this.__refCreators = {};
    this.componentId = ++componentId;

    const __is_browser = is.browser();
    if (__is_browser) {
      this.constructorBrowser(props);
    }
    this.state = Object.assign(this.state || {}, {
      /*
        the only pourpose of __is_browser state is making sure that component rerenders
        after first reuse of server-side rendered markup.
      */
      __is_browser,
    });

    const _oldRender = this.render;
    this.render = () => {
      return _oldRender.call(this, this.props, this.state);
    };
  }

  componentDidCatch(error) {
    sentryLog(error, 'ERROR HANDLED IN componentDidCatch');
  }

  componentWillUnmount() {
    this.__listeners.forEach((remove) => remove());
    this.__listeners.length = 0;
    this.isDestroyed = true;
  }

  /**
   * React's setTimeout implementation with few benefits:
   * - timeout function is not called when component is unmounted
   * - timeouts can be chained
   *
   * @param {Function} fn
   * @param {Number}   time
   * @param {mixed} ...args
   */
  setTimeout(fn, time = 0, ...args) {
    const promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        if (this.isDestroyed || promise.cancelled) {
          return reject();
        }
        resolve(fn(...args));
      }, time);
    })
      .catch(noop);

    return promise;
  }

  /**
   * React's setInterval implementation with a benefit of
   * timeout function not calling when component is unmounted
   *
   * @param {Function} fn
   * @param {Number}   time
   * @param {mixed} ...args
   */
  setInterval(fn, time = 0, ...args) {
    const intervalID = setInterval(() => {
      if (this.isDestroyed) {
        clearInterval(intervalID);
        return;
      }
      fn(...args);
    }, time);

    return intervalID;
  }

  asyncSetState(data, callback) {
    return new Promise((resolve) => {
      this.setState(data, (...args) => {
        if (callback) {
          callback(...args);
        }
        resolve();
      });
    });
  }

  /**
   * Join class names of any type to string
   *
   * cn(['a', 'b', 'c']) // => 'a b c'
   * cn({
   *   a: true,
   *   b: false,
   *   c: true
   * })                                  // => 'a c'
   *
   *
   * for this.className = ts-Xyz:
   *
   * - '--some-modifier' // "ts-Xyz ts-Xyz--some-modifier"
   * - '__element'       // "ts-Xyz ts-Xyz__element"
   * - { '__element': true,
   *     '--modifier': true,
   *     'bootstrap-class': true
   *   }                 // "ts-Xyz ts-Xyz__element ts-Xyz--modifier bootstrap-class"
   */
  cn(...inputClassNames) {
    if (inputClassNames.length === 0) {
      return this.cn.bind(this);
    }
    if (is.string.isTemplateTagArgs(inputClassNames)) {
      return this.cn(String.raw(...inputClassNames));
    }
    if (inputClassNames.length > 1) {
      return inputClassNames.map((cn) => this.cn(cn)).join(' ');
    }
    let className = inputClassNames[0];
    if (isPlainObject(className)) {
      className = Object.keys(className).filter((el) => className[el] ? true : false);
    }
    className = Array.isArray(className) ? className.join(' ') : className || '';
    if (this.className) {
      className = className.replace(/(^| )(__|--)/g, `$1${this.className}$2`);
    }
    return className;
  }

  /**
   * Computes classNames based on passed function params, this.className and
   * className from props passed to the component.
   *
   * @return {string}
   */
  rootcn(...classNames) {
    if (is.string.isTemplateTagArgs(classNames)) {
      return this.rootcn(String.raw(...classNames));
    }
    /* eslint-disable react/prop-types */
    return this.cn(this.props.className, this.className, ...classNames).trim();
    /* eslint-enable */
  }

  /**
   * Continously copies data from the model into state.
   *
   * @param  {Object}   model             atom model object
   * @param  {Array}    fieldsToInclude   Use it to sync just selected fields
   * @param  {Function} callback          function called on each value update
   * @return {Promise}
   */
  syncStateWithModelInitial(model, fieldsToInclude = [], callback = noop) {
    return fieldsToInclude.reduce((acc, key) => {
      acc[key] = model.get(key);
      // treat this.state as defaults, in case model is empty
      acc[key] = acc[key] === undefined ? this.state[key] : acc[key];
      syncOnModelChange(this, model, key, callback);

      return acc;
    }, {});
  }

  /**
   * Copy data from the model into state and update it when model is updated.
   * If `fieldsToInclude` is not provided only fields initialised in the component
   * state will be synced.
   * This function needs to be called in componentDidMount
   *
   * @param  {Object}   model             atom model object
   * @param  {Array}    fieldsToInclude   Use it to sync just selected fields
   * @param  {Function} callback          function called on each value update
   * @return {Promise}
   */
  syncStateWithModel(model, fieldsToInclude = [], callback = noop) {
    if (ENV === 'local') {
      if (!this.state) {
        console.warn('[DEBUG] Provide state object to your component', this); // eslint-disable-line
      }
      if (!model) {
        // eslint-disable-next-line
        console.error('[ERROR] Provide valid model to syncStateWithModel', this, fieldsToInclude);
        return;
      }
    }

    if (!fieldsToInclude.length) {
      return this.syncStateWithModel(model, Object.keys(this.state), callback);
    }

    const modelState = this.syncStateWithModelInitial(model, fieldsToInclude, callback);

    const prevState = this.state;
    return this.asyncSetState(modelState, () => {
      // initial values
      Object.keys(modelState).forEach((key) => {
        if (prevState[key] !== modelState[key]) {
          callback(key, modelState[key]);
        }
      });
    });
  }

  /**
   * This function performs `set` on a model and validates whole form based
   * on refs from a component.
   * Additionally page is scrolled to first error occurrence.
   *
   * NOTE: We are operating on DOM elements, intead of state, because we can't
   * trust, if change event is fired (google: onChange handler not triggered by Safari's auto fill)
   *
   * Sample usage:
      // Component:
      render = () => (
        <div>
          <ValidationInput ref="form.foo" value="test value" />
          <ValidationInput ref="form.bar" value="different value" />

          <Button onClick={() => {
            // { foo: 'test value', bar: 'different value' } is saved to the model.
            // if model validation fails: proper fields are highlighted red
            // if data saves correctly: `then` is called.
            this.formValidation(
              model, 'form.'
            ).then(() => {
              console.log('success');
            });
          }} >Send</Button>
        </div>
      )
   *
   * @param  {model instance or a Promise} model
   * @param  {String} refPrefix
   * @param  {Bool} activeScroll
   * @return {Promise}
   */
  formValidation(model, refPrefix = '', activeScroll = true) {
    const done = () => {
      Object.keys(this.refs).forEach((key) => {
        if (!this.refs[key]) {
          console.warn('WARN/done:', `there is no ${key} in the form.`); // eslint-disable-line
          return;
        }

        if (this.refs[key].setValid) {
          this.refs[key].setValid(true);
        }
      });
    };
    const fail = (errors) => {
      const formDOMElements = [];
      Object.keys(errors).forEach((key) => {
        if (errors[key] && !this.refs[refPrefix + key]) {
          console.warn('WARN/fail:', `there is no ${refPrefix + key} in the form.`); // eslint-disable-line
          return;
        }
        if (errors[key] && this.refs[refPrefix + key].setValid) {
          const setInputInvalid = (message) => {
            this.refs[refPrefix + key].setValid(false, message);
          };

          if (errors[key] instanceof Promise) {
            errors[key].catch(setInputInvalid);
          } else {
            setInputInvalid(errors[key])
          }

          formDOMElements.push(this.refs[refPrefix + key]);
        }
      });

      if (activeScroll && formDOMElements.length) {
        // Scroll to first form field with error and move focus on it.
        scrollTo(findDOMNode(formDOMElements[0]));
        formDOMElements[0].focus();
      }
    };

    const promise = is.isPromise(model)
      ? model
      : model.set(getRefValues(this, refPrefix));
    promise.then(done, fail);

    return promise;
  }

  /**
   * Shorthand for addEventListener + removeEventListener at constructor
   */
  addEventListener(...args) {
    const unsubscribe = () => removeEventListener(...args);
    this.__listeners.push(unsubscribe);
    unsubscribe();
    return addEventListener(...args);
  }

  /**
   * Shorthand for mediator.subscribe and mediator.remove
   * See: mediator documentation:
   * http://thejacklawson.com/Mediator.js/
   *
   * When first argument is an atom model, then model is subscription target.
   */
  subscribe(...args) {
    let unsubscribe;
    let subscribe;

    if (typeof args[0] === 'string') {
      unsubscribe = () => mediator.remove(...args);
      subscribe = () => mediator.subscribe(...args);
    } else if (Array.isArray(args[1])) {
      return args[1].forEach((field) => {
        this.subscribe(args[0], field, args[2]);
      });
    } else {
      unsubscribe = () => args[0].off(args[1], args[2]);
      subscribe = () => args[0].on(args[1], args[2]);
    }

    this.__listeners.push(unsubscribe);
    unsubscribe();
    subscribe();
  }

  /**
   * Shorthand for mediator.publish
   */
  publish(...args) {
    mediator.publish(...args);
  }

  /**
   * Shorthand for browserHistory.replace
   */
  navigate(url) {
    return navigate(url);
  }

  /**
   * Unified ref method for html nodes and react components
   * Use as react ref attribute callback:
   * <div ref={ this.createRef('myRefNameNode') }/>
   * and created reference will be stored as this.myRefNameNode in
   * your component
   */
  createRef(refName) {
    if (!this.__refCreators[refName]) {
      this.__refCreators[refName] = (element) => {
        if (ENV === 'local' && !refName.endsWith('Node')) {
          /* eslint-disable max-len */
          throw new Error(`createRef: refName "${refName}" should end with "Node" suffix. Maybe "${refName}Node" will be a good choice?`);
          /* eslint-enable max-len */
        } else {
          this[refName] = findDOMNode(element);
        }
      };

      this.__listeners.push(() => {
        delete this.__refCreators[refName];
        delete this[refName];
      });
    }

    return this.__refCreators[refName];
  }

  pickProps(...propNames) {
    const defaultProps = {
      // make sure, that every component has unique ID
      id: this.className ? `${this.className}-${this.componentId}` : null,
    };

    return advancedPick(Object.assign(defaultProps, this.props), [
      /^data-/,
      /^aria-/,
      'id',
      'style',
      ...propNames
    ]);
  }
}
