import React from 'react';
import { Link } from 'react-router';
import BasePage from 'pages/BasePage';
import { ROUTES } from 'constants';
import Banner from 'components/Banner';

export default class BlogGlobalProgress extends BasePage {
  className = 'ts-BlogGlobalProgress';
  static title = 'Blog Global Progress';

  renderContent() {
    return (
      <section>
        <div className="ts-container">
          <h2>Identity to Empower Global Progress</h2>
          <div className={ this.cn`__info` }>
            via <Link
              href="https://whoswho.mt/en/home/"
              target="_blank"
              rel="noopener noreferrer"
            >
              WhosWho.mt
            </Link>
          </div>
          <p>Billions of people are currently financially and socially excluded from participating in the global market because they are unable to assert their identity. Beyond empowering individual prosperity, enabling inclusion of this massive population with secure, trusted, and accessible identity tools has the potential to leave a positive impact in many areas, from political discourse to technical innovation.</p>
          <p>Trust Stamp creates and delivers <Link to={ ROUTES.IDENTITY_TOOLS }>artificial intelligence-powered identity solutions</Link> to empower <Link to={ ROUTES.MISSION }>global financial and societal inclusion</Link>, with applications ranging across industries and sectors. We work to expand access to life-changing services, which can in turn create lasting value for emerging economies and beyond. By bringing together advanced technology that prioritizes privacy and security with societal and cultural context, we can deliver the most effective and targeted services that work even in the absence of infrastructure for identity or digital finance.<br />
            When we can recognise and address needs on an individual, human level, then society can deliver the most effective
          </p>
          <Link
            href="https://whoswho.mt/en/trust-stamp-asserts-commitment-to-establishing-identity-to-empower-global-progress"
            className="ts-button--secondary"
            target="_blank"
            rel="noopener noreferrer"
          >
            READ THE FULL ARTICLE
          </Link>
        </div>
      </section>
    );
  }

  render() {
    return (
      <div className={ this.rootcn() }>

        { /* banner */ }
        <Banner theme="fingers" size="small" colored={ true }>
          <h1>Digital Identity Blog</h1>
        </Banner>

        { this.renderContent() }

      </div>
    );
  }
}
