require('console-polyfill'); // console in IE
require('./hasOwn'); // Object.hasOwn
require('./domRemove'); // node.remove() for IE11
require('./resizeObserver'); // ResizeObserver micro polyfill
require('date-polyfill'); // date.format()
require('raf/polyfill'); // requestAnimationFrame

global.URL = global.URL || global.webkitURL || global.mozURL || global.msURL;

[global.OffscreenCanvas, global.HTMLCanvasElement]
  .forEach((CanvasClass) => {
    if (!CanvasClass) return;

    // Canvas elements not garbage collected on iOS 12:
    // https://github.com/openlayers/openlayers/issues/9291
    // https://bugs.webkit.org/show_bug.cgi?id=195325
    const oldRemove = CanvasClass.prototype.remove;
    CanvasClass.prototype.remove = function (...args) {
      // NOTE: arrow function will break this
      this.width = 0;
      this.height = 0;
      return oldRemove.apply(this, args);
    };
  });

if (ENV === 'local') {
  // OL-1705: Prevent loading Workers as a URL. Some origins doesnt accept such workers
  const _Worker = global.Worker || function () {};

  global.Worker = class Worker extends _Worker {
    constructor(url) {
      if (!url.startsWith('blob:')) {
        // eslint-disable-next-line max-len
        throw new Error(`"${url}" is a valid worker URL but needs to be loaded with cross-origin-worker`);
      }

      super(url);
    }
  }
}
