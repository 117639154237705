/* eslint-disable no-unused-vars */
import user from 'models/user';
import { configureAuthorization } from 'sf/helpers/request';

module.exports = () => {
  configureAuthorization({
    UNIVERSAL: {
      url: (method, url) => {
        switch (true) {
          case user.get('isSignedIn'):
          default:
            return url;
          case location.pathname.toLowerCase().includes('signup'):
            return `${url}?registration_token=${user.get('token')}`;
          case location.pathname.toLowerCase().includes('login'):
            return `${url}?login_token=${user.get('token')}`;
        }
      },
      req: (method, url, req) => {
        if (user.get('isSignedIn')) {
          req.use(interceptRequestEnd)
            .set('Authorization', `Token ${user.get('token')}`);
        }
      }
    },
    SIGNUP: {
      url: (method, url) => {
        return `${url}?registration_token=${user.get('token')}`;
      },
      req: (method, url, req) => {
        req.use(interceptTokenNotFound);
      }
    },
    LOGIN: {
      url: (method, url) => {
        return `${url}?login_token=${user.get('token')}`;
      },
      req: (method, url, req) => {
        // req.set('Authorization', `JWT ${store.get('JWT_TOKEN') || 'unauthorized'}`);
      }
    },
  });
};

/**
 * Plugin for superagent .use(), intercepts original .end() method and checks
 * for token_expiration_time for every request, to store it in user model.
 *
 * @param req superagent Request object
 */
function interceptRequestEnd(req) {
  const reqEnd = req.end;
  req.end = function (callback, options = {}) {
    reqEnd.call(req, (err, res) => {
      if (res && res.body && res.body.hasOwnProperty('token_expiration_time')) {
        const sessionModel = require('models/session'); // eslint-disable-line
        sessionModel.setTokenExpiration(res.body.token_expiration_time);
      }
      callback(err, res);
    }, options);
    return this;
  };
}

/**
 * Plugin for superagent .use(), intercepts original .end() method and checks
 * for 404 error: RegistrationTokenNotFound. If detected: resets client token.
 *
 * @param req superagent Request object
 */
function interceptTokenNotFound(req) {
  'use strict';

  const reqEnd = req.end;
  req.end = function (callback) {
    reqEnd.call(req, (err, res) => {
      const hasBodyData = res.body.data != null;
      if (
        err
        && res.statusCode === 404
        && hasBodyData
        && res.body.data.appErrorCode === 'RegistrationTokenNotFound'
      ) {
        // eslint-disable-next-line no-console
        console.info(`NOTE: RegistrationTokenNotFound special case is called.
In case of unexpected behaviour see: request.js for more info.`);

        user.logOut({ redirect: true, reason: 'auto' }); // eslint-disable-line global-require, max-len
        // omit callback to prevent from throwing error further, we reload anyway.
      } else {
        callback(err, res);
      }
    });
    return this;
  };
}
