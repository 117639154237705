import React from 'react';
import { asset } from 'helpers';
import BasePage from 'pages/BasePage';

export default class ErrorPage extends BasePage {
  className = 'ts-ErrorPage';
  static title = 'Error';

  render() {
    return (
      <div className={ this.rootcn`ts-container` }>
        <img
          alt=""
          className={ this.cn`__img` }
          src={ asset`img/404.png` }
        />
      </div>
    );
  }
}
