import atom from 'atom-js';

const model = atom('months', [
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
]);

model.SEC = 1000;
model.MIN = 60 * model.SEC;
model.HOUR = 60 * model.MIN;
model.DAY = 24 * model.HOUR;
model.WEEK = 7 * model.DAY;
model.YEAR = 365.25 * model.DAY;
model.MONTH = model.YEAR / 12;

export default model;
