/* eslint-disable max-len */
import React, { Fragment } from 'react';
import BaseComponent from 'components/BaseComponent';
import { asset } from 'sf/helpers';
import { Link } from 'react-router';
import { ROUTES } from 'constants';
import { CONTENT } from './constant';

export default class Process extends BaseComponent {
  className = 'ts-Process';

  render() {
    return (
      <section className={ this.rootcn() }>
        <div className={ this.cn`__container ts-container` }>
          <h6>HOW WE DO IT</h6>
          <h2>Our Differentiators</h2>
          <div className={ this.cn`__accordion` }>
            { CONTENT.map(({ id, title, slug, content }) => (
              <Fragment key={ id }>
                <input type="radio" name="accordion-tabs" id={ id } defaultChecked={ id === 1 ? true : '' } />
                <label htmlFor={ id }>
                  <div>
                    <img src={ asset`ai/${slug}.svg` } alt={ title } />
                  </div>
                  <h5>{ title }</h5>
                  <span className={ this.cn`__status` } />
                </label>
                <div className={ this.cn`__content __tab-${id}` }>
                  <div className={ this.cn`__text` }>
                    <h2>{ title }</h2>
                    <p>{ content.text }</p>
                    { content.href &&
                      <Link to={ content.href } target={ content.externalLink ? '_blank' : '_self' } rel="noopener noreferrer" className="ts-button--secondary">{ content.button }</Link>
                    }
                  </div>
                  <div className={ this.cn`__image-container` }>
                    <img src={ asset`ai/${content.slug}` } alt={ title } />
                  </div>
                </div>
              </Fragment>
            )) }
          </div>
          <Link to={ ROUTES.WHITEPAPER } className="ts-button--brand">Download your free whitepaper</Link>
        </div>
      </section>
    );
  }
}
