import React from 'react';
import atom from 'atom-js';
import _get from 'lodash/get';
import webcam from 'mighty-webcamjs';
import { browserHistory } from 'react-router';
import reduxStore from 'reduxStore';

import { ROLES, ROUTES } from 'constants';

import config from 'config';
import {
  authorizationAddon,
  del,
  errorHandler,
  get,
  plainGet,
  post,
  postMultipart,
  requestEnd,
} from 'sf/helpers/request';
import store from 'helpers/store';
import help from 'models/help';
import user from 'models/user';
import * as ProfileActions from 'pages/Profile/Profile.actions';
import {
  buildQuery,
  mediator,
} from 'sf/helpers';
import { dataURItoImage } from 'sf/helpers/canvas';

const REGISTRATION_DONE_STEP = Infinity;

const model = atom.setup({
  modelName: 'registrationModel',
  persistenceLib: store,
  validation: {
    phoneVerificationCode: {
      'Validation code is not valid': (phoneNumberVerificationCode) => {
        return new Promise((resolve, reject) => {
          post('backend/signup/phone/verify/', 'SIGNUP')
            .type('form')
            .send({
              code: phoneNumberVerificationCode,
            })
            .end((err, res) => {
              if (err) {
                errorHandler(err, res);
                reject();
              } else {
                resolve();
              }
            });
        });
      },
    },
  },
  methods: {
    deleteProgress(resolve, reject) {
      del('backend/signup/progress/', 'SIGNUP')
        .end((err, res) => {
          if (err) {
            reject(res.body && res.body.data.message);
          } else {
            resolve();
          }
        });
    },

    setRealtorNumber(resolve, reject) {
      const realtorNumber = user.get('realtor_number');
      post('backend/signup/realtor_document_number/', 'SIGNUP')
        .type('form')
        .send({
          realtor_document_number: realtorNumber,
        })
        .end((err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        });
    },

    getRegistrationStep(resolve) {
      let value = '/SignUpSelectSocialnetwork.html';
      if (user.get('isSignedIn')) {
        // User is signed in, skip registration
        value = REGISTRATION_DONE_STEP;
      } else if (model.get('registrationStep')) {
        value = model.get('registrationStep');
      }

      resolve(value);
    },

    signUpPhoneNumber(resolve, reject, phone) {
      post('backend/signup/phone/', 'SIGNUP')
        .type('form')
        .send({
          phone_number: phone,
        })
        .end((err, res) => {
          if (err) {
            reject(res.body && res.body.data.message);
            errorHandler(err, res);
          } else {
            resolve();
          }
        });
    },

    getSignUpStatus(resolve, reject) {
      // HACK: use plainGet instead of superagent.get to bypass
      //       interceptTokenNotFound.
      plainGet(`${BACKEND_URL}/signup/status/${authorizationAddon('SIGNUP')}`)
        .then(resolve, reject);
    },

    async uploadUserPhoto(resolve, reject) {
      const image = await dataURItoImage(user.get('photo'));

      postMultipart('backend/signup/photo/', 'SIGNUP')
        .attach('photo', image.data, `photo.${image.extension}`)
        .end(requestEnd(resolve, reject));
    },

    sendUserPolVideo(resolve, reject, videoDataOrUrl) {
      const endpointURL = 'backend/signup/pol/';
      if (videoDataOrUrl instanceof Blob) {
        const fileName = `video.${webcam.helpers.videoRecorder.videoFormatToExt(
          videoDataOrUrl.type
        )}`;

        postMultipart(endpointURL, 'SIGNUP')
          .attach('video_file', videoDataOrUrl, fileName)
          .end(requestEnd(resolve, reject));

        if (videoDataOrUrl.size < 1024) { // Video smaller than 1KB - super weird.
          throw new Error('blob passed to sendVideoSelfie is suspiciously small.');
        }
      } else {
        post(endpointURL, 'SIGNUP')
          .type('json')
          .send({ video_url: videoDataOrUrl })
          .end(requestEnd(resolve, reject));
      }
    },

    signUpComplete(resolve, reject) {
      // SAMPLE RESPONSE:
      // {"statusCode":400,
      //  "data":{
      //    "message":"Validation error",
      //    "errorCode":400,
      //    "validation":{
      //      "last_name":["This field may not be blank."],
      //      "phone":["This field is required."],
      //      "city":["Ensure this field has no more than 10 characters."]
      //    }
      //  },
      //  "status":"error"
      // }
      //
      // NOTE: We can not use Promise.all here - requests have to be sent
      // one-after-another.
      const done = () => {
        get('backend/signup/complete/', 'SIGNUP')
          .end((err, res) => {
            const data = res.body.data || {};
            if (err) {
              if (data.appErrorCode === 'UserAlreadyRegistered') {
                mediator.publish('warning', 'This account is already registered. Please Log In.');
                this.navigate('/LogIn.html');
                return;
              }
              if (data.price) {
                user.set('credit_price', data.price);
                // someone needs to pay for registration
                mediator.publish('setRegistrationStep', '/SignUpPayment.html');
                this.navigate('/SignUpPayment.html');
              }
              errorHandler(err, res);
              reject();
            } else {
              if (data.discount_code && data.discount_code.message) {
                reduxStore.dispatch(
                  ProfileActions.clearMPHDiscountCode()
                );
                if (data.discount_code.code === 200) {
                  help.addNotification({
                    type: 'success',
                    value: data.discount_code.message,
                    clearOnRouteChange: false,
                  });
                } else {
                  help.addNotification({
                    type: 'error',
                    value: data.discount_code.message,
                    clearOnRouteChange: false,
                  });
                }
              }
              user.set({
                'isSignedIn': true,
                'roles': [ROLES.SCORE_USER],
                'token': data.token,
              });
              resolve();
            }
          });
      };
      done();
    },

    signUpBasicInfoStepDone(resolve, reject) {
      const date_birth = user.getBirthDate();
      const MPHDiscountCode = _get(reduxStore.getState(), 'profile.mph_discount_code');
      post('backend/signup/basic/', 'SIGNUP')
        .type('form')
        .send({
          organization: model.get('organization'),
          legal_name: user.get('legal_name'),
          address: user.get('address'),
          zip_code: user.get('zip_code'),
          state: user.get('state'),
          city: user.get('city'),
          date_birth: date_birth,
          [MPHDiscountCode ? 'discount_code' : null]: MPHDiscountCode,
          email_auth_url: `${location.origin}/EmailConfirmation.html`,
          email: user.get('email'),
        })
        .end((err, res) => {
          if (err) {
            const didPublicDataFailed = res.body.data.appErrorCode === 'PublicdataFailedContactUs';

            if (didPublicDataFailed) {
              let publicDataErrorMessage;
              if (res.body.data.refreshable) {
                const clickNextStep = (e) => {
                  e.preventDefault();
                  mediator.publish('basicInfoContinue');
                };
                publicDataErrorMessage = (
                  <span>
                    Network error.

                    Please <a href="#" onClick={ clickNextStep }>retry</a> { }
                    or
                    <a href={ ROUTES.CONTACT_US } target="_blank" rel="noopener noreferrer">
                      contact us
                    </a>.
                  </span>
                );
              } else {
                publicDataErrorMessage = (
                  <span>
                    Publicdata verification failed.

                    Please
                    <a href={ ROUTES.CONTACT_US } target="_blank" rel="noopener noreferrer">
                      contact us
                    </a>.
                  </span>
                );
              }

              help.addNotification({
                type: 'error',
                value: publicDataErrorMessage,
              });
              help.open();
            } else {
              errorHandler(err, res);
            }
            reject(err);
          } else {
            resolve();
          }
        });
    },
    sendRealtorNumber(resolve, reject) {
      post('backend/signup/realtor_document_number/', 'SIGNUP')
        .type('form')
        .send({ realtor_document_number: user.get('realtor_number') })
        .end(requestEnd(resolve, reject));
    },
    sendPINCode(resolve, reject) {
      post('backend/signup/set_pin/', 'SIGNUP')
        .type('form')
        .send({
          pin_code: user.get('pinCode'),
        })
        .end((err, res) => {
          if (err) {
            reject();
            errorHandler(err, res);
          } else {
            resolve();
          }
        });
    },

    async uploadDrivingLicensePhoto(resolve, reject, dataURI, isFront) {
      const image = await dataURItoImage(dataURI);

      postMultipart('backend/signup/driver_license/', 'SIGNUP')
        .field('is_back', isFront ? '' : 'True')
        .attach('driver_license', image.data, `photo.${image.extension}`)
        .end((err, res) => {
          if (err) {
            errorHandler(err, res);
            reject();
          } else {
            resolve(res.body.data);
          }
        });
    },
    getOCRData: (resolve, reject) => {
      get('backend/signup/ocr_results/', 'SIGNUP')
        .end((err, res) => {
          if (err) {
            errorHandler(err, res);
            reject();
          } else {
            const { data } = res.body;
            const transformedData = {};
            Object.keys(data).forEach((key) => {
              transformedData[key] = data[key];
              if (Array.isArray(data[key])) {
                transformedData[`${key}_alts`] = transformedData[key].splice(1);
                transformedData[key] = transformedData[key][0];
              }
            });
            model.set({
              ...transformedData,
            });
            resolve(res);
          }
        });
    },
    getVerificationStatus: (resolve) => {
      get('backend/signup/verification_status/', 'SIGNUP')
        .end((err, res) => {
          if (err) {
            res.trimmedMessage = _get(res.body, 'data.message', '').trim();
            model.set({
              verification_complete: false,
              verification_in_progress: false,
            });
            resolve(res);
          } else {
            if (res.status === 202) {
              model.set({
                verification_in_progress: true,
              });
            } else {
              model.set({
                verification_complete: true,
                verification_in_progress: false,
              });
            }
            resolve(res);
          }
        });
    },
    async uploadDocument(
      resolve,
      reject,
      dataURI,
      documentType = 'driving_license',
      countryState = 'GA',
      isFront = true
    ) {
      const image = await dataURItoImage(dataURI);
      postMultipart('backend/signup/document/', 'SIGNUP')
        .field('is_back', isFront ? '' : 'True')
        .field('state', countryState || '')
        .field('document_type', documentType || '')
        .attach('document', image.data, `photo.${image.extension}`)
        .end((err, res) => {
          if (err) {
            errorHandler(err, res);
            reject();
          } else {
            resolve(res.body.data);
          }
        });
    },
  },
})(store.get('registrationModel') || {});

Object.assign(model, {
  signUpWithService(service_name) {
    const token = user.get('token');

    const callbackQueryParams = {
      ts_app_url: `${location.protocol}//${location.host}`,
      registration_token: token,
      ts_social_network: service_name,
    };
    if (user.get('invitation_key')) {
      callbackQueryParams.invitation_key = user.get('invitation_key');
    }
    const oneAllQueryParams = {
      callback_uri: `${BACKEND_URL}/signup/select_socialnetwork?${
        buildQuery(callbackQueryParams)
      }`,
      service: 'social_login',
    };
    window.location.href =
      `//${config.oneAllApp}.api.oneall.com/socialize/connect/direct/${service_name}/?${
        buildQuery(oneAllQueryParams)
      }`;
  },
});

if (!model.get('isRealtor')) {
  model.set('isRealtor', global.REALTOR);
}

mediator.subscribe('setRegistrationStep', (registrationStep) => {
  let targetPage = registrationStep;
  model.set('registrationStep', registrationStep);

  if (registrationStep === REGISTRATION_DONE_STEP) {
    targetPage = '/Profile.html';
  }
  if (targetPage && targetPage !== location.pathname) {
    // Don't append SignUp.html page to the browser history,
    // because it's only redirecting to more specific SignUp... components
    // TODO: This is a bit wrong approach to rely on path names,
    // but the logic is too tangled to be refactored.
    // Ideally we should move this part to Redux.
    if (location.pathname === '/SignUp.html') {
      browserHistory.replace(targetPage);
    } else {
      browserHistory.push(targetPage);
    }
  }
});

model.REGISTRATION_DONE_STEP = REGISTRATION_DONE_STEP;

export default model;
