/* eslint-disable max-len */
import React, { Fragment } from 'react';
import { ROUTES } from 'constants';

export const CONTENT = [
  {
    id: 1,
    subtitle: 'TRUST STAMP PROTECT',
    title: (
      <Fragment>
        Privacy and Data <span>Protect</span>ion
      </Fragment>
    ),
    content:
      'Maximise the security, privacy and functionality of your biometric identity systems',
    href: ROUTES.PROTECT,
    slug: 'protect.jpg',
    button: 'Learn More',
    className: 'protect'
  },
  {
    id: 2,
    subtitle: 'BIOMETRIC MFA',
    title: (
      <Fragment>
        Biometric <span>Multi-Factor Authentication</span>
      </Fragment>
    ),
    content:
      'Replace vulnerable one-time passcodes with secure biometric authentication',
    href: ROUTES.BIOMETRIC_MFA,
    slug: 'biometric-mfa.jpg',
    button: 'Learn More',
    className: 'biometric-mfa',
    blank: true
  },
  {
    id: 3,
    subtitle: 'TRUST STAMP IDENTIFY',
    title: (
      <Fragment>
        <span>Identity </span>Document Verification
      </Fragment>
    ),
    content:
      "Determine whether an identity document is authentic with Trust Stamp's automated, multi-layer approach to document verification",
    href: ROUTES.IDENTIFY,
    slug: 'products-validate.png',
    button: 'Learn More',
    className: 'identity'
  },
  {
    id: 4,
    subtitle: 'TRUST STAMP CAPTURE',
    title: (
      <Fragment>
        Biometric <span>Capture</span>
      </Fragment>
    ),
    content:
      'Consistent, accurate, and efficient biometric capture with advanced presentation attack detection, including proof-of-life and anti-spoofing technology layered in',
    href: ROUTES.CAPTURE,
    slug: 'palm.png',
    button: 'Learn More',
    className: 'capture'
  },
  {
    id: 5,
    subtitle: 'TRUST STAMP DETECT',
    title: (
      <Fragment>
        Duplicate <span>Detect</span>ion
      </Fragment>
    ),
    content:
      'High-performance deduplication that determines the uniqueness of one identity among many, without sharing or revealing sensitive information about any',
    href: ROUTES.DETECT,
    slug: 'angled-computer.png',
    button: 'Learn More',
    className: 'detect'
  },
  {
    id: 6,
    subtitle: 'TRUST STAMP VERIFY',
    title: (
      <Fragment>
        Identity <span>Verifi</span>cation
      </Fragment>
    ),
    content:
      'Know your customers are who they say they are with a biometric identity solution that supports compliance, protects data, and lowers barriers to conversion without compromising on efficiency',
    href: ROUTES.VERIFY,
    slug: 'products-identify.jpg',
    button: 'Learn More',
    className: 'verify'
  },
  {
    id: 7,
    subtitle: 'TRUST STAMP INNOVATE',
    title: (
      <Fragment>
        Custom <span>Innovat</span>ion
      </Fragment>
    ),
    content:
      'Bespoke biometric identity solutions built on proven and trusted artificial intelligence-powered technology',
    href: ROUTES.CONTACT_US,
    slug: 'products-custom.png',
    button: 'Get In Touch',
    className: 'innovate'
  }
];
