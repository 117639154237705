/* eslint-disable import/prefer-default-export */


/* eslint-disable max-len */
export const VIDEO_CONTENT = {
  id: 1,
  title: 'Identity Security with a Selfie',
  text: 'Know your customers are who they say they are with a biometric identity solution that supports compliance, protects data, and improves user satisfaction. Trust Stamp Verify layers security and privacy into any processes that require existing users to prove their identity, like logging into a system or recovering access to an account.',
  video: 'https://player.vimeo.com/video/948495652',
};
