// WARNING:
// Before writing your own helper, make sure if there is no available one on
// npm registry.

import { getConfig } from 'sf';
import get from 'lodash/get';
import { buildQuery } from 'sf/helpers';


export const asset = (...args) => {
  let { assetsURL } = getConfig();
  let result = typeof args[0] === 'string' && arguments.length === 1 ?
    args[0] :
    String.raw(...args);

  if (result[0] === '/') {
    result = result.substr(1);
  }

  if (assetsURL.substr(-1) !== '/') {
    assetsURL = `${assetsURL}/`;
  }

  return `${assetsURL}${result}`;
};

let loadFacebookSdkPromise;
export const loadFacebookSdk = () => {
  if (!loadFacebookSdkPromise) {
    loadFacebookSdkPromise = new Promise((resolve, reject) => {
      const js = document.createElement('script');
      js.id = 'facebook-jssdk';
      js.src = '//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.9';
      js.onload = resolve;
      js.onerror = reject;
      const fjs = document.getElementsByTagName('script')[0];
      fjs.parentNode.insertBefore(js, fjs);
    });
  }
  return loadFacebookSdkPromise;
};

export function goToRealtorApp(query) {
  const defaultQuery = {
    timestamp: Date.now(),
  };
  const url = `tsrealtor://Profile.html?${buildQuery({
    ...defaultQuery,
    ...query,
  })}`;

  // NOTE: Realtor link needs to be an `<a>` cause `window.open()` and
  // `location.href = ...` are not working on iOS9.
  const a = document.createElement('a');
  a.href = url;
  a.click();
}

export const getAllChildRoutesFromProps = (props) => {
  const indexRoute = get(props, 'routes', []).find((route) => route.indexRoute);
  return get(indexRoute, 'childRoutes', []);
};
