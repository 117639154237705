/* eslint-disable import/prefer-default-export */


/* eslint-disable max-len */
export const VIDEO_CONTENT = {
  id: 1,
  title: 'See it in action',
  text: 'In this example, Trust Stamp Capture, Identify, and Protect work together in a biometric enrolment user flow',
  video: 'https://player.vimeo.com/video/361351107',
};
