const React = require('react');
const PropTypes = require('prop-types');
const { findDOMNode } = require('react-dom');

const BaseComponent = require('sf/components/BaseComponent');
const FlashLightButton = require('./FlashLightButton');
const SwitchCameraButton = require('./SwitchCameraButton');
const WebRTCComponent = require('./WebRTCComponent');


module.exports = class WebcamContainer extends BaseComponent {
	static propTypes = {
		webcam: PropTypes.object,
		onPegRef: PropTypes.func,
	};

	static defaultProps = {};

	state = this.syncStateWithModelInitial(this.props.webcam.params, [
		'width',
		'height',
		'userMedia',
		'cssPrefix',
		'cameraMode'
	]);

	handlePegRef = (element) => {
		this.props.onPegRef(findDOMNode(element));
	};

	renderContainer() {
		const { cssPrefix, cameraMode } = this.state;
		const { webcam } = this.props;

		switch (cameraMode) {
			case webcam.constants.CAMERA_MODE_WEBRTC:
				return this.renderWebRTCContainer();
			case webcam.constants.CAMERA_MODE_FILE:
				return webcam.getUploadFallbackNode();
			case webcam.constants.CAMERA_MODE_NONE:
				return (
					<div className={ `${cssPrefix}__camera-not-detected` }>
						Could not load camera. Try different browser.
					</div>
				);
			case null:
				return cameraMode;
			default:
				console.warn('there is no camera mode', cameraMode);
		}
	}

	componentDidMount() {
		const { webcam } = this.props;
		const { params } = webcam;

		if (params.get('userMedia')) {
			params.set('cameraMode', webcam.constants.CAMERA_MODE_WEBRTC);
		} else if (params.get('enable_file_fallback')) {
			params.set({
				'cameraMode': webcam.constants.CAMERA_MODE_FILE,
				'load': true,
			});
		} else {
			webcam.dispatch('error', new webcam.errors.WebcamError(params.get('no_interface_found_text')));
			params.set('cameraMode', webcam.constants.CAMERA_MODE_NONE);
		}
	}

	renderWebRTCContainer() {
		const { webcam } = this.props;
		if (!webcam.params.get('userMedia')) return null;

		return <WebRTCComponent webcam={ webcam } />;
	}

	renderSwitchCameraButton() {
		const { webcam } = this.props;
		if (webcam.params.get('controls')) {
			return <SwitchCameraButton webcam={ webcam } model={ webcam.params } />;
		}
	}

	renderFlashLightButton() {
		const { webcam } = this.props;

		if (webcam.params.get('controls')) {
			return <FlashLightButton webcam={ webcam } model={ webcam.params } />;
		}
	}

	render(_, { cssPrefix, width, height }) {
		const style = {
			width: `${width}px`,
			height: `${height}px`,
		};
		return (
			<div style={ style }>
				<div className={ `${cssPrefix}__peg` } ref={ this.handlePegRef }></div>

				{ this.renderContainer() }

				{ this.renderSwitchCameraButton() }

				{ this.renderFlashLightButton() }
			</div>
		);
	}
};
