import atom from 'atom-js';
import { asset } from 'sf/helpers';
import { get } from 'sf/helpers/request';
import is from 'next-is';
import noop from 'no-op';

const noIcon = '<?xml version="1.0"?><svg width="40" height="40" viewBox="0 0 40 40"></svg>';
const requestCache = {};

const model = atom();

model.fetchIcon = async (iconName, iconSet, callback) => {
  const iconID = `${iconSet}/${iconName}`;
  const cachedIcon = model.get(iconID);
  if (cachedIcon) {
    return callback(cachedIcon);
  }

  model.once(iconID, callback);
  if (requestCache[iconID]) {
    return;
  }

  requestCache[iconID] = true; // prevent from multiple ajax calls

  let svgIconText;
  try {
    const { res } = await get(asset`icons/${iconID}.svg`, null, { disableMediator: true })
      .set('Accept', 'image/svg+xml');

    // We need to remove SVG ids in order fix the DOM parsing errors:
    svgIconText = res.text.replace(/id=["|'](.)*?["|']/g, '');
  } catch (err) {
    console.error('fetching icon failed', iconID, err); // eslint-disable-line no-console
  }

  model.set(iconID, svgIconText || noIcon);
};

/**
 * Cache/prefetch icons before it's used.
 *
 * example:
 *   prefetch(
 *     { set: 'ab', type: 'cde' },
 *     { set: 'fg', type: 'hij' },
 *     ...
 *   )
 */
model.prefetch = (...iconsToCache) => {
  if (!is.browser()) return;

  iconsToCache.forEach(({ set, type }) => {
    model.fetchIcon(type, set, noop);
  });
};

export default model;
