import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';

export default class Banner extends BaseComponent {
  className = 'ts-Banner';

  static propTypes = {
    children: PropTypes.node,
    // theme page e.g.: phone(homepage), globe(products), id-capture(identify), phone-photo(verify),
    // avatars(detect)
    theme: PropTypes.oneOf([
      'phone',
      'globe',
      'id-capture',
      'phone-photo',
      'avatars',
      'fingers',
      'finance',
      'binary',
      'selfie',
      'crypto',
      'numbers',
      'case',
      'mfa',
      'travel',
      'kyc',
      'banking',
      'age',
      'age-estimation',
    ]),
    size: PropTypes.oneOf([
      'small',
      'large',
    ]),
    colored: PropTypes.bool, // for text color
  }

  static defaultProps = {
    theme: 'phone',
    size: 'large',
    colored: false,
  }

  render() {
    const { theme, size, children, colored } = this.props;
    return (
      <section
        className={ this.rootcn`--theme-${theme} --size-${size} ${colored ? '--colored' : ''}` }
      >
        <div className={ this.cn`ts-container` }>
          { children }
        </div>
      </section>
    );
  }
}
