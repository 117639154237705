/* eslint-disable max-len */
import React, { Fragment } from 'react';

export const TITLE =
  'Tokenized Age with Trust Stamp’s Irreversibly Transformed Identity Token (IT2)';

export const CONTENT = [
  {
    id: 1,
    title: 'Unmatched Speed in KYC',
    text: (
      <Fragment>
        <ul>
          <li>Automated and AI-driven processes for instant verification.</li>
          <li>Scalable processing ensures rapid customer onboarding.</li>
        </ul>
      </Fragment>
    ),
    slug: '03_Automated_Reliable_Age_Checks'
  },
  {
    id: 2,
    title: 'Security Against Fraud and Other Threats',
    text: (
      <Fragment>
        <ul>
          <li>Proactive measures against velocity attacks, synthetic, and other identity fraud</li>
          <li>Advanced algorithms safeguard your institution and client’s data</li>
        </ul>
      </Fragment>
    ),
    slug: '04_Safeguard_Minors'
  },
  {
    id: 3,
    title: 'Superior Stability with Biometric MFA',
    text: (
      <Fragment>
        <ul>
          <li>Authenticate beyond passwords: stronger and more convenient</li>
          <li>Ensuring consistent, genuine account access with unique biometric data.</li>
        </ul>
      </Fragment>
    ),
    slug: 'pol'
  }
];
