/* global CAPTCHA_SITE_KEY */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import at from 'lodash/at';
import PropTypes from 'prop-types';
import BasePage from 'pages/BasePage';
import ValidationInput from 'sf/components/ValidationInput';
import contact from 'models/contact';
import ValidationTextArea from 'components/ValidationTextarea';
import Button from 'components/Button';
import { asset } from 'helpers';
import Captcha from 'sf/components/Captcha';
import captchaModel from 'sf/components/Captcha/model';

export default class ContactForm extends BasePage {
  className = 'ts-ContactForm';

  static propTypes = {
    onDone: PropTypes.func.isRequired,
    source: PropTypes.string,
    // Contact form is sent to [sales@truststamp.net, support@truststamp.net,
    // social@truststamp.net, privacy@truststamp.net]
    recipient: PropTypes.oneOf(['sales', 'support', 'social', 'privacy']),
  };

  // props required by contact model
  static defaultProps = {
    onDone: () => { },
    source: 'Truststamp.ai Callout Contact Form',
    recipient: 'sales',
  };

  state = {};

  componentDidMount() {
    this.syncStateWithModel(contact, [
      'first_name',
      'last_name',
      'email',
      'company_name',
      'message',
    ]);
  }

  handleCaptchaVerification = (response) => {
    this.setState({
      captchaResponse: response,
    });
  }

  resetCaptcha() {
    // reset captcha when validation fails to fix TRST-757
    this.handleCaptchaVerification(null);
    captchaModel.resetCaptcha();
  }

  renderCaptcha() {
    return (
      <div className={ this.cn`__captcha-container` }>
        <Captcha
          showLoading={ false }
          delayedStartTime={ 10000 }
          verificationCallback={ this.handleCaptchaVerification }
          sitekey={ CAPTCHA_SITE_KEY }
          language="en"
          onExpired={ () => {
            this.handleCaptchaVerification(null);
          } }
        />
      </div>
    );
  }

  // form submission
  handleSubmit = (e) => {
    e.preventDefault();
    Button.setProcessing(this.submitButton);
    const hideProcessing = () => {
      Button.setProcessing(this.submitButton, false);
    };

    this.formValidation(contact, 'form.')
      .then(() => {
        const done = () => {
          this.props.onDone();
          contact.clear();
        };
        const fail = (err = {}) => {
          if (Object.keys(err.validation || {}).length) {
            this.formValidation(Promise.reject(err.validation));
          }
          console.log(err); // eslint-disable-line no-console
        };

        contact
          .sendExtendedMessage(this.props.recipient, {
            includeDebugInfo: false,
            captchaResponse: this.state.captchaResponse,
            source: this.props.source,
          })
          .then(done, fail);
      })
      .finally(hideProcessing);
  };

  render() {
    // checking if all fields are enter so that the submit button is enabled
    const isFormValid = at(this.state, [
      'first_name',
      'last_name',
      'email',
      'message',
      'company_name',
      'captchaResponse',
    ])
      .every(Boolean);

    return (
      <form className={ this.rootcn() } onSubmit={ this.handleSubmit }>

        { /* last name field */ }
        <ValidationInput
          className={ this.cn`__input` }
          type="text"
          placeholder="First Name *"
          ref="form.first_name"
          stateLink={ [this, 'first_name'] }
        />

        { /* last name field */ }
        <ValidationInput
          className={ this.cn`__input` }
          type="text"
          placeholder="Last Name *"
          ref="form.last_name"
          stateLink={ [this, 'last_name'] }
        />

        { /* // email field */ }
        <ValidationInput
          className={ this.cn`__input` }
          type="text"
          placeholder="E-mail *"
          ref="form.email"
          stateLink={ [this, 'email'] }
        />

        { /* company name */ }
        <ValidationInput
          className={ this.cn`__input` }
          type="text"
          placeholder="Company Name *"
          ref="form.company_name"
          stateLink={ [this, 'company_name'] }
        />

        { /* // request details */ }
        <ValidationTextArea
          className={ this.cn`__textarea` }
          rows={ 3 }
          placeholder="How can we help you? *"
          ref="form.message"
          stateLink={ [this, 'message'] }
        />
        <div className="clearfix"></div>

        { this.renderCaptcha() }

        { /* send button */ }
        <Button
          className={ this.cn`__button` }
          disabled={ !isFormValid }
          type="submit"
        >
          <span>Send</span>
          <img
            alt="Send"
            className={ this.cn`__button-icon` }
            src={ asset`ai/arrow.svg` }
          />
        </Button>
      </form>

    );
  }
}
