import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const BREAKPOINTS = ['initial', 'xxsm', 'xsm', 'sm', 'md', 'lg', 'xlg', 'xxl'];
const CLASS_PREFIX = 'ts-';
const ROW_ALIGNMENTS = ['start', 'center', 'end', 'top', 'middle', 'bottom', 'between', 'around'];

/**
 * Filter provided object properties to only include breakpoints used in app
 * @param {object} propsObj - Object with properties to be filtered
 * @returns {object} New object
 */
const filterBreakpoints = (propsObj = {}) => {
  return Object.keys(propsObj)
    .filter((item) => BREAKPOINTS.includes(item))
    .reduce((accumulator, item) => {
      accumulator[item] = propsObj[item];
      return accumulator;
    }, {});
};

/**
 * Create object based on array
 * @param {array} arr - Array of items that should be converted to properties
 * @param {*} [defaultValue=null] - Default value for the property
 * @returns {object} New object
 */
const makeObj = (arr = [], defaultValue = null) => {
  return arr.reduce((accumulator, item) => {
    accumulator[item] = defaultValue;
    return accumulator;
  }, {});
};

/**
 * Create object with properties mapping css classes
 * @param {object} propsObj - All element props
 * @returns {object} New object
 */
const classNamesObj = (propsObj) => {
  return ROW_ALIGNMENTS.reduce((accumulator, item) => {
    accumulator[item] = filterBreakpoints(makeObj(propsObj[item]));
    return accumulator;
  }, {
    offset: filterBreakpoints(propsObj.offset),
    width: filterBreakpoints(propsObj),
  });
};

/**
 * Create string of css classes to  be applied to element
 * @param {object} propsObj - Object with properties mapping css classes
 * @param {string} [el='col'] - Grid element that these css classes should be applied to
 * @returns {string} String with css classes
 */
const classNamesString = (classNamesMap, el = 'col') => Object.keys(classNamesMap)
  .map((item) => {
    return Object.keys(classNamesMap[item])
      .map((key) => {
        if (el === 'row') {
          if (ROW_ALIGNMENTS.includes(item)) {
            return `${CLASS_PREFIX}${item}-${key}`;
          }
          return '';
        }
        if (item === 'width') {
          if (classNamesMap[item][key] === 'auto') {
            return `${CLASS_PREFIX}${el}-${key}`;
          }
          return `${CLASS_PREFIX}${el}-${key}-${classNamesMap[item][key]}`;
        }
        return `${CLASS_PREFIX}${el}-${key}-${item}-${classNamesMap[item][key]}`;
      });
  })
  .toString()
  .concat(`${CLASS_PREFIX}${el}`)
  .replace(/,+/g, ' ')
  .trim();

const Col = (props = {}) => {
  return (
    <div
      className={ classNames(
        classNamesString(classNamesObj(props)),
        props.className,
      ) }
    >
      { props.children }
    </div>
  );
};

const Row = (props = {}) => {
  return (
    <div
      className={ classNames(
        classNamesString(classNamesObj(props), 'row'),
        props.className,
      ) }
    >
      { props.children }
    </div>
  );
};

/* eslint-disable react/no-unused-prop-types */
Col.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  offset: PropTypes.object,
};

Col.defaultProps = {
  children: null,
  className: '',
  offset: {},
};

Row.propTypes = {
  around: PropTypes.array,
  between: PropTypes.array,
  bottom: PropTypes.array,
  center: PropTypes.array,
  children: PropTypes.node,
  className: PropTypes.string,
  end: PropTypes.array,
  middle: PropTypes.array,
  start: PropTypes.array,
  top: PropTypes.array,
};

Row.defaultProps = {
  around: [],
  between: [],
  bottom: [],
  center: [],
  children: null,
  className: '',
  end: [],
  middle: [],
  start: [],
  top: [],
};
/* eslint-enable react/no-unused-prop-types */

export { Col, Row };
