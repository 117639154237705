/* eslint-disable max-len */
export const TITLE = 'How Age Estimation Works';

export const CONTENT = [
  {
    id: 1,
    title: 'The user takes a secure selfie',
    text: 'Users simply take a quick selfie with their device\'s camera. Trust Stamp\'s real-time feedback ensures a smooth experience, guiding users if any issues arise during capture.',
    slug: 'Age_Estimation_5',
    slugType: 'png',
  },
  {
    id: 2,
    title: 'AI-powered age estimation',
    text: 'Our advanced AI algorithms analyze the selfie instantly. Within seconds, you receive an estimated age for the user.  This process also includes real-time liveness checks and presentation attack detection to ensure user authenticity.',
    slug: 'Age_Estimation_6',
    slugType: 'png',
  },
  {
    id: 3,
    title: 'Informed Decisions',
    text: 'Trust Stamp provides you with the estimated age, allowing you to confidently grant access, deny entry, or request further verification for age-restricted goods, content or services.',
    slug: 'Age_Estimation_7',
    slugType: 'png',
  }
];

export const CONTENT2 = [
  {
    id: 1,
    title: 'Frictionless age verification',
    text: 'State-of-the-art biometric AI algorithms estimate the user’s age with a quick and easy selfie.',
    slug: 'Age_Estimation_1',
  },
  {
    id: 2,
    title: 'Privacy-focused age checks',
    text: 'Enable users to confirm their age without sharing their identity documents.',
    slug: 'Age_Estimation_2',
  },
  {
    id: 3,
    title: 'Boost conversions & User experience',
    text: 'Smooth and instant age verification leads to happier users and more conversions on your platform.',
    slug: 'Age_Estimation_3',
  },
  {
    id: 4,
    title: 'Inclusive Age Verification',
    text: 'Allow age-appropriate users who may not have an approved or supported identity document to access to age-gated content, goods and services.',
    slug: 'Age_Estimation_4',
  }
];
