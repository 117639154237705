import React, { Fragment } from 'react';
import { compose } from 'recompose';
import contact from 'models/contact';
import BasePage from 'pages/BasePage';
import Button from 'sf/components/Button';
import Card from 'sf/components/Card';
import Dialog from 'sf/components/Dialog';
import Input from 'sf/components/Input';
import Link from 'sf/components/Link';
import { asset } from 'sf/helpers';
import withStateLink from 'sf/hoc/StateLink';
import withTooltip from 'sf/hoc/Tooltip';
import withValidation from 'sf/hoc/Validation';
import device from 'sf/models/device';

const ValidationInputWithStateLink = compose(
  withValidation,
  withStateLink,
)(Input);

const LinkWithTooltip = compose(
  withTooltip,
)(Link);

const CALENDLY_URL = 'https://calendly.com/truststamp';

/* eslint-disable max-len */
const ACTION_SUCCESS_MESSAGE = 'Thank you! You will receive an email from us shortly with the link to the demo site.';
/* eslint-enable */

const INITIAL_STATE = {
  email: undefined,
  name: undefined,
};

const CARDS = [
  /* eslint-disable max-len */
  'A one-way process converts biometric data into a hash that cannot be reconstructed into the original biometric; avoiding the security risks and legal complications of storing and transmitting PII data.',
  <Fragment>The IT<sup>2</sup> can also carry encrypted data including pivot points to external data stores.</Fragment>,
  'Hashes are stored in our (or the enterprise) "Identity Lake" and compared on a one-to-one or one-to-many basis solving problems like deduplication, synthetic identity fraud and KYC requirements.',
  /* eslint-enable */
];

export default class Money2020 extends BasePage {
  className = 'ts-Money2020';

  state = {
    ...INITIAL_STATE,
  };

  componentDidMount() {
    this.syncStateWithModel(device, ['smUp']);
  }

  displayNotification = (notification) => {
    this.setState({ notification });
    this.notificationDialog.toggle(true);
  };

  handleDialogClick = () => {
    this.notificationDialog.toggle(false);
  }

  handleFormSubmit = async (event) => {
    event.preventDefault();


    const isFormValid = await this.formValidation(
      contact.set(this.state)
    )
      .catch(() => false);

    if (!isFormValid) return;

    this.actionButton.setProcessing(true);

    try {
      await contact.sendMoneyDemoRequest();
      this.displayNotification(ACTION_SUCCESS_MESSAGE);
      this.setState(INITIAL_STATE);
    } catch ({ message = 'Oops! Something went wrong.' }) {
      this.displayNotification(message);
    }

    this.actionButton.setProcessing(false);
  };

  handleScheduleClick = () => {
    window.open(CALENDLY_URL, '_blank');
  }

  renderActionSection = () => (
    <section className={ this.cn`__action` }>
      <div className={ this.cn`__container __responsive-container` }>
        <div className={ this.cn`__action-video-wrapper` }>
          <h1 className={ this.cn`__title __action-title` }>
            See the process in action:
          </h1>
          <video
            className={ this.cn`__action-video` }
            controls="true"
            preload="metadata"
          >
            <source src={ asset`/ai/money2020-video.mp4` } />
            <track kind="captions" />
          </video>
        </div>
        <div className={ this.cn`__action-form-wrapper` }>
          <h1 className={ this.cn`__title` }>
            Try our account access product yourself!
          </h1>
          <p className={ this.cn`__action-p` }>
            { /* eslint-disable max-len */ }
            *Note: we will not capture or store your personal information or biometrics, this tool is for demonstration purposes only.
            { /* eslint-enable */ }
          </p>
          <form
            className={ this.cn`__action-form` }
            onSubmit={ this.handleFormSubmit }
          >
            <ValidationInputWithStateLink
              className={ this.cn`__action-input` }
              placeholder="Name"
              ref="name"
              stateLink={ [this, 'name'] }
            />
            <div className={ this.cn`__action-submit-wrapper` }>
              <ValidationInputWithStateLink
                className={ this.cn`__action-input` }
                placeholder="Email"
                ref="email"
                stateLink={ [this, 'email'] }
              />
              <Button
                className={ this.cn`__button __action-button` }
                ref={ (button) => this.actionButton = button }
              >
                Try our demo
              </Button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );

  renderCard = (copy, index) => (
    <Card
      className={ this.cn`__card` }
      key={ copy }
    >
      <img
        alt=""
        className={ this.cn`__card-image` }
        src={ `/ai/money_card_${index + 1}.svg` }
      />
      <p className={ this.cn`__card-copy` }>{ copy }</p>
    </Card>
  );

  renderCardSection = () => (
    <section className={ this.cn`__container __responsive-container` }>
      { CARDS.map(this.renderCard) }
    </section>
  );

  renderHeroSection = () => (
    <section className={ this.cn`__hero` }>
      <div className={ this.cn`__container` }>
        <div className={ this.cn`__hero-logo` } />
      </div>
    </section>
  );

  renderNotificationDialog = () => (
    <Dialog
      className={ this.cn`__notification-dialog` }
      closable={ false }
      ref={ (dialog) => this.notificationDialog = dialog }
    >
      <div className={ this.cn`__notification-dialog-content` }>
        { this.state.notification }
      </div>
      <Button
        className={ this.cn`__notification-dialog-button` }
        onClick={ this.handleDialogClick }
      >
        OK
      </Button>
    </Dialog>
  );

  renderScheduleSection = () => (
    <section className={ this.cn`__schedule` }>
      <div className={ this.cn`__container __schedule-container` }>
        <div className={ this.cn`__schedule-wrapper` }>
          <h1 className={ this.cn`__title` }>
            Schedule a meeting to learn more
          </h1>
          <div>
            <Link
              className={ this.cn`__button __schedule-button __schedule-button--calendly` }
              target="_blank"
              to={ CALENDLY_URL }
            >
              Schedule
            </Link>
            <LinkWithTooltip
              className={ this.cn`__button __schedule-button __schedule-button--download` }
              tooltipText="Download our 1-pager"
              tooltipPosition="right"
              to="/ai/money-2020-one-pager.pdf"
              download="money-2020-one-pager.pdf"
              target="_blank"
            >
              Download
            </LinkWithTooltip>
          </div>
          { this.state.smUp && <div className={ this.cn`__schedule-image` } /> }
        </div>
      </div>
      { !this.state.smUp && <div className={ this.cn`__schedule-image` } /> }
    </section>
  );

  renderTopSection = () => (
    <section className={ this.cn`__top` }>
      <div className={ this.cn`__container __top-container` }>
        <div className={ this.cn`__top-intro` }>
          <h1 className={ this.cn`__title` }>
            Thank You for Your Interest in Trust Stamp
          </h1>
          <p className={ this.cn`__top-intro-p` }>
            { /* eslint-disable max-len */ }
            We develop artificial intelligence and biometric hashing technology for identity verification and portability.  After a strategic investment, EmTech is deploying Trust Stamp’s proprietary AI-powered hashed biometric identity solution, enabling multi-factor and omni-channel identity verification and/or authentication, across its Emergent Payments<sup>&reg;</sup>, G-Coin™, and Responsible Gold™ businesses.
            { /* eslint-enable */ }
          </p>
        </div>
        <img
          className={ this.cn`__top-logos` }
          src={ asset`/ai/emergent_logos.png` }
          alt="logos"
        />
      </div>
    </section>
  );

  render() {
    return (
      <div className={ this.rootcn() }>
        { this.renderHeroSection() }
        { this.renderTopSection() }
        { this.renderCardSection() }
        { this.renderActionSection() }
        { this.renderScheduleSection() }
        { this.renderNotificationDialog() }
      </div>
    );
  }
}
