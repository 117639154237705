/* global YEAR_CONF, BRAND_NAME */
import React from 'react';
import BaseComponent from 'components/BaseComponent';
import IconButton from 'components/IconButton';
import Link from 'components/Link';
import PrivacyPolicy from 'components/PrivacyPolicyDialog';
import Render from 'components/Render';
import { BRANDINGS, ROUTES } from 'constants';
import { goToRealtorApp } from 'helpers';
import appState from 'models/appState';
import user from 'models/user';
import { asset } from 'sf/helpers';

export default class PageFooter extends BaseComponent {
  className = 'ts-PageFooter';

  state = {};

  handleContextMenu = (e) => {
    alert('Copying Prohibited by unifiedpatents.com.'); // eslint-disable-line
    e.preventDefault();
  }

  componentDidMount() {
    this.syncStateWithModel(appState, ['redirectionSource']);
  }

  handleGoToRealtorApp = () => {
    const query = {};
    if (user.get('isSignedIn')) {
      query.token = user.get('token');
      query.userRoles = JSON.stringify(user.get('roles'));
    }
    goToRealtorApp(query);
  }

  render() {
    const footerText = `Trust Stamp ${YEAR_CONF}. Various aspects of
Trust Stamp are protected by Patent, Trademark and Copyright.`;
    const footerClassName = {
      '--branding': this.props.showFooterLogo,
    };

    return (
      <footer className={ this.rootcn(footerClassName) }>
        <div className="ts-container">
          <Render when={ this.state.redirectionSource === 'realtorapp' }>
            <div className={ this.cn`__realtor-app-button` }>
              <IconButton
                theme="success"
                set="fa"
                type="external-link"
                iconSize={ 18 }
                onClick={ this.handleGoToRealtorApp }
              >
                Go to Realtor App
              </IconButton>
            </div>
          </Render>
          <div className={ this.cn`__navigation` }>
            {
              BRAND_NAME === BRANDINGS.CORPORATE_LANDING ?
                <span>
                  <Link
                    title="Team"
                    to="/TeamMembers.html"
                  >
                    Team
                  </Link>
                  <Link
                    title="Press and Media"
                    to="/PressAndMedia.html"
                  >
                    Press and Media
                  </Link>
                </span> :
                null
            }
            <Link
              title="Contact Us"
              to={ ROUTES.CONTACT_US }
            >
              Contact Us
            </Link>
            {
              BRAND_NAME === BRANDINGS.CORPORATE_LANDING
                ? null
                : <Link to="/TermsAndConditions.html" title="Terms and Conditions">
                  Terms and Conditions
                </Link>
            }
            <PrivacyPolicy />
            {
              BRAND_NAME === BRANDINGS.CORPORATE_LANDING
                ? null
                : <a href="https://truststamp.ai/SharedCCPAForm.html?source=trustStampForReality" target="_blank" rel="noopener noreferrer">California Residents CCPA Request</a>
            }
          </div>
          <a
            className={ this.cn`__patent-info` }
            href="http://unifiedpatents.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              onContextMenu={ this.handleContextMenu }
              alt={ `Unified addresses the risk and cost of NPE litigation by strategically protecting
                broad areas of technology, such as cloud storage or content delivery.` }
              src={ asset`img/secured-big.png` }
            />
          </a>
          <div className={ this.cn`__footer-text` }>
            &copy; { footerText }
          </div>
        </div>
      </footer>
    );
  }
}
