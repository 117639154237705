import { ROUTES } from 'constants';

export const LINKS = [
  {
    id: 0,
    title: 'Products',
    list: [
      {
        href: ROUTES.VERIFY,
        link: 'KYC Onboarding'
      },
      {
        href: ROUTES.IDENTIFY,
        link: 'Document Verification'
      },
      {
        href: ROUTES.BIOMETRIC_MFA,
        link: 'Facial Matching'
      },
      {
        href: ROUTES.CAPTURE,
        link: 'Liveness Detection'
      },
      {
        href: ROUTES.DETECT,
        link: 'Biometric Deduplication'
      },
      {
        href: ROUTES.AGE_ESTIMATION,
        link: 'Age Estimation'
      },
      {
        href: ROUTES.AGE_VERIFICATION,
        link: 'Age Verification'
      },
      {
        href: ROUTES.STABLE_IT2,
        link: 'Stable IT2'
      }
    ]
  },
  {
    id: 1,
    title: 'Solutions',
    list: [
      {
        href: ROUTES.PROTECT,
        link: 'Privacy and Data Protection'
      },
      {
        href: ROUTES.DETECT,
        link: 'Duplicate Identity Detection'
      },
      {
        href: ROUTES.GEOLOCATION,
        link: 'Geolocation'
      },
      {
        href: ROUTES.STABLE_IT2,
        link: 'Stable IT2'
      },
      {
        href: ROUTES.TRUSTED_MESSAGE,
        link: 'Message Encryption'
      },
      {
        href: ROUTES.WIRE_FRAUD_PREVENTION,
        link: 'Wire Fraud Prevention'
      }
    ]
  },
  {
    id: 2,
    title: 'Company',
    list: [
      {
        link: 'Mission',
        href: ROUTES.MISSION
      },
      {
        link: 'Team',
        href: ROUTES.TEAM
      },
      {
        link: 'News',
        href: ROUTES.NEWS
      }
    ]
  },
  {
    id: 3,
    title: 'Resources',
    list: [
      {
        href: ROUTES.INVESTORS,
        link: 'Investors',
      },
      {
        href: ROUTES.BLOGS,
        link: 'Blogs',
      },
      {
        href: ROUTES.B_RESOURCES,
        link: 'Resources',
      },
      {
        href: ROUTES.MISSION,
        link: 'Mission',
      },
      {
        href: ROUTES.TEAM,
        link: 'Team',
      },
      {
        href: ROUTES.CAREERS,
        link: 'Careers',
      },
      {
        href: ROUTES.DISCLOSURE,
        link: 'Disclosures',
      },
      {
        href: ROUTES.NEWS,
        link: 'News',
      }
    ]
  }
];
