/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router';
import BasePage from 'pages/BasePage';
import { ROUTES } from 'constants';
import Banner from 'components/Banner';

export default class BlogFinTech extends BasePage {
  className = 'ts-BlogFinTech';
  static title = 'FinTech Blog';

  renderContent() {
    return (
      <section>
        <div className="ts-container">
          <h2>Blue Tulip Awards’ experts of Finance & Prosperity: why COVID, regulations and digitisation boost innovation</h2>
          <div className={ this.cn`__info` }>
            via <Link
              href="https://siliconcanals.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              SiliconCanals
            </Link>
          </div>
          <p>Andrew Gowasack regrouped with the Blue Tulip Awards innovation ecosystem to discuss the newest theme in the competition, Finance and Prosperity. Digital transformation has jumped ahead in the innovation roadmap across organizations and industries as the world operates in an increasingly digital environment. As a result, solutions that prioritize privacy and security are forefront in serving the needs of regulated industries like finance and healthcare.</p>
          <p>From incorporating low-friction <Link to={ ROUTES.IDENTIFY }>document verification</Link> into account onboarding processes to using advanced <Link to={ ROUTES.DETECT }>deduplication technology</Link> to streamline KYC and prevent fraud, the opportunity for innovation is immense. Read the full article to learn more about the growing demand for <Link to={ ROUTES.IDENTITY_TOOLS }>secure, private, and inclusive solutions</Link> in the finance industry.</p>
          <Link
            href="https://siliconcanals.com/news/startups/finance-and-prosperity-theme-blue-tulip-awards/"
            className="ts-button--secondary"
            target="_blank"
            rel="noopener noreferrer"
          >
            READ THE FULL ARTICLE
          </Link>
        </div>
      </section>
    );
  }

  render() {
    return (
      <div className={ this.rootcn() }>

        { /* banner */ }
        <Banner theme="finance" size="small">
          <h1>FinTech Blog</h1>
        </Banner>

        { this.renderContent() }

      </div>
    );
  }
}
