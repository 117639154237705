/* global CREDIT_PRICE, TOKEN_LENGTH, GOOGLE_API_KEY, DEFAULT_PAGE_TITLE,
FACEBOOK_URL, TWITTER_URL, MAILCHIMP_URL, ONEALL_APP, VERIFICATION_COST,
REALTOR_LINK_IOS, REALTOR_LINK_ANDROID, CACHE_PARAM, REFRESH_COST,
MARKETPLACE_HEROES_DOCS, P2P_BLOG_URL, FEATURES, INVITATION_COST, DEFAULT_PAGE_DESCRIPTION
 */

// This file works as a proxy for main config file: src/config.js

export default {
  creditPrice: CREDIT_PRICE,
  refreshCost: REFRESH_COST,
  tokenLength: TOKEN_LENGTH,
  googleApiKey: GOOGLE_API_KEY,
  facebookURL: FACEBOOK_URL,
  twitterURL: TWITTER_URL,
  mailChimpURL: MAILCHIMP_URL,
  p2pBlogURL: P2P_BLOG_URL,
  marketplaceHeroesDocs: MARKETPLACE_HEROES_DOCS,
  oneAllApp: ONEALL_APP,
  invitationCost: INVITATION_COST,
  verificationCost: VERIFICATION_COST,
  realtorLinkiOS: REALTOR_LINK_IOS,
  realtorLinkAndroid: REALTOR_LINK_ANDROID,
  cacheParam: CACHE_PARAM,
  features: {
    realtor_registration: FEATURES.REALTOR_REGISTRATION,
    noScoreRegistration: FEATURES.NO_SCORE_REGISTRATION,
    indexRedirect: FEATURES.INDEX_REDIRECT,
  },
};
