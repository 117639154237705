import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps';
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel';
import { compose } from 'recompose';
import config from 'config';
import { ROUTES } from 'constants';
import BasePage from 'pages/BasePage';
import { scrollTo } from 'sf/helpers/domHelper';

import { OFFICES } from './constants';

const MALTA_OFFICE_LOCATION = { lat: 35.910644, lng: 14.506016 };

const MapWithAMarker = compose(
  withScriptjs,
  withGoogleMap,
)((props) => (
  <GoogleMap defaultZoom={ 1 } center={ MALTA_OFFICE_LOCATION }>
    { props.position.map(({ label, location }) => {
      return (
        <MarkerWithLabel
          /* eslint-disable no-undef */
          labelAnchor={ new google.maps.Point(-15, 28) }
          /* eslint-enable no-undef */
          position={ { lat: location.lat, lng: location.long } }
          labelStyle={ {
            fontSize: '10px',
            color: 'transparent',
            width: '200px',
          } }
          key={ label }
        >
          <div>{ label }</div>
        </MarkerWithLabel>
      );
    }) }
  </GoogleMap>
));

const USE_EMERGENT_IFRAME = false;

export default class ContactUs extends BasePage {
  className = 'ts-CorporateContactUsAi';
  static title = 'Unsubscribe';
  // eslint-disable-next-line max-len
  static description =
    'We research and leverage artificial intelligence and machine learning to deliver the most comprehensive facial biometric identity authentication solution.';

  static propTypes = {
    thankYou: PropTypes.bool,
  };

  static defaultProps = {
    thankYou: false,
  };

  state = {};

  // --------------- we might need this code in the near futur ------------------------
  // handlePositionChange = (event, location, label) => {
  //   event.preventDefault();

  //   scrollTo(this.map, 500, -100);
  // };

  performSuccessRedirect = () => {
    this.navigate(ROUTES.CONTACT_US_THANK_YOU);
  };

  performSuccessRedirectPardot = (event) => {
    if (event.data === 'pardot_form_success') {
      this.performSuccessRedirect();
    }
  };

  renderForm = () => {
    if (this.state.isMessageSent || this.props.thankYou) {
      return (
        <div>
          <h3
            className={ this.cn`__form__title` }
            ref={ (ref) => ref && scrollTo(ref, 250, 250) }
          >
            Thank you for contacting us.
          </h3>
          <p>
            Your message has been successfully sent. We will reply as soon as
            possible.
          </p>
        </div>
      );
    }

    let form;
    if (USE_EMERGENT_IFRAME) {
      form = (
        <div className={ this.cn`__iframe-holder` }>
          <iframe
            title="terms"
            src="https://go.pardot.com/l/643283/2019-02-01/qd6lx"
            className={ this.cn`__iframe` }
            type="text/html"
            frameBorder="0"
            allowTransparency="true"
            ref={ (ref) => {
              window[ref ? 'addEventListener' : 'removeEventListener'](
                'message',
                this.performSuccessRedirectPardot,
              );
            } }
          />
        </div>
      );
    } else {
      const UnsubscribeForm = require('./UnsubscribeForm');
      form = <UnsubscribeForm onDone={ this.performSuccessRedirect } />;
    }

    return (
      <div>
        <h1 className={ this.cn`__hero__title` }>
          Unsubscribe <br />
          Trust Stamp
        </h1>
        <h3 className={ this.cn`__form__title` }>
          Send us a message and someone on our staff will be quick to help.
        </h3>

        <div className={ this.cn`__form-container` }>{ form }</div>
      </div>
    );
  };

  renderFormSection() {
    // creating an array just with locations lat & long for map markers
    const LOCATIONS = [];
    const ARRLEN = OFFICES.length;
    for (let i = 0; i < ARRLEN; i++) {
      LOCATIONS.push({
        location: OFFICES[i].location,
        label: OFFICES[i].name,
      });
    }

    return (
      <section className={ this.cn`__form__section` }>
        <div className={ this.cn`__form__half-container` }>
          { this.renderForm() }
        </div>
        <div className={ this.cn`__form__map` } ref={ (map) => (this.map = map) }>
          <MapWithAMarker
            /* eslint-disable max-len */
            googleMapURL={ `https://maps.googleapis.com/maps/api/js?key=${config.googleApiKey}&v=3.exp&libraries=geometry,drawing,places` }
            /* eslint-enable max-len */
            loadingElement={ <div style={ { height: '100%' } } /> }
            containerElement={ <div style={ { height: '400px' } } /> }
            mapElement={ <div style={ { height: '100%' } } /> }
            position={ LOCATIONS }
          />
          { this.renderOfficesSection() }
        </div>
      </section>
    );
  }

  renderOfficesSection = () => (
    <section className={ this.cn`__offices` }>
      { OFFICES.map(({ address, name }) => (
        <div className={ this.cn`__office` } key={ name }>
          <h3 className={ this.cn`__office-name` }>{ name }:</h3>
          <p className={ this.cn`__office-address` }>{ address }</p>
          { /* --------------- we might need this code in the near futur ------------------------ */ }
          { /* <div className={ this.cn`__mark-container` }>
            <button
              className={ this.cn`__mark-button` }
              onClick={ event =>
                this.handlePositionChange(event, location, name)
              }
            >
              <img src={ asset`icons/ts/pin.svg` } alt="map pin" />
              <span className={ this.cn`__mark-text` }>Mark on the map</span>
            </button>
          </div> */ }
        </div>
      )) }
    </section>
  );

  render() {
    return (
      <div className={ this.rootcn() }>
        { this.renderFormSection() }
      </div>
    );
  }
}
