// libs
import React from 'react';
import PropTypes from 'prop-types';

// components
import BaseComponent from 'components/BaseComponent';
import GlobalLoader from 'components/GlobalLoader';
import SessionAlert from 'components/SessionAlert';
import MediatorAlert from 'sf/components/MediatorAlert';

// models
import router from 'models/router';


export default class App extends BaseComponent {
  static propTypes = {
    children: PropTypes.node,
    route: PropTypes.object,
    params: PropTypes.object,
  };

  componentDidMount() {
    document.body.parentElement.classList.add('ts-MinimalApp');
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== (router.get('location') || {}).pathname) {
      this.publish('locationChange');
    }
    router.set(nextProps);
  }

  /*
   * React router renders component inside root component (App), even if route is nested.
   * This method helps to create nested components tree.
   */
  getParentComponent(componentName = this.props.params.splat, parentRoute = this.props.route) {
    return parentRoute.childRoutes.find((route) => {
      if (route.childRoutes) {
        return this.getParentComponent(componentName, route);
      }

      return route.path === componentName;
    });
  }

  renderChildren() {
    const parentComponentRoute = this.getParentComponent();
    const ParentComponent = parentComponentRoute ? parentComponentRoute.component : null;
    if (ParentComponent) {
      return (
        <ParentComponent>
          { this.props.children }
        </ParentComponent>
      );
    }
    return this.props.children;
  }

  render() {
    return (
      <div>
        <SessionAlert />
        <div className="ts-container">
          {
            ['error', 'warning', 'info', 'success'].map((topic) => (
              <MediatorAlert
                key={ topic }
                listenTopic={ topic }
                closeTopic={ ['locationChange', 'photoTaken'] }
                theme={ topic }
              />
            ))
          }
        </div>
        <GlobalLoader />
        { this.renderChildren() }
      </div>
    );
  }
}
