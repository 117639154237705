/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import { asset } from 'sf/helpers';

export default class Benefits extends BaseComponent {
  className = 'ts-Benefits';

  static propTypes = {
    colored: PropTypes.bool, // background color
    content: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        text: PropTypes.node,
        slug: PropTypes.string,
        slugType: PropTypes.string
      })
    ),
    sectionTitle: PropTypes.string,
    showSubTitle: PropTypes.bool,
    subTitle: PropTypes.string,
    sectionSubText: PropTypes.string
  };

  static defaultProps = {
    colored: false,
    showSubTitle: true,
    subTitle: 'Benefits',
  };

  render() {
    const { colored, sectionTitle, content, sectionSubText, showSubTitle, subTitle } = this.props;
    return (
      <section className={ this.rootcn`${colored ? '__colored' : ''}` }>
        <div className="ts-container">
          { showSubTitle && <h6>{ subTitle }</h6> }
          <h2>{ sectionTitle }</h2>
          <p className={ this.cn`__subtext` }>
            { sectionSubText && sectionSubText }
          </p>

          { /* list of benefits */ }
          { content.map(
            ({ id, title, text, slug, href, buttonLabel, slugType }) => {
              return (
                <article key={ id }>
                  <div className={ this.cn`__column--text` }>
                    <h4>{ title }</h4>
                    <p>{ text }</p>
                    { href && (
                      <Link to={ href } className="ts-button--secondary">
                        { buttonLabel }
                      </Link>
                    ) }
                  </div>
                  <div
                    className={ this.cn({
                      '__column--image': true,
                      '__column--image-slug': slugType
                    }) }
                  >
                    { slugType ? (
                      <img
                        src={ asset`ai/${slug}.${slugType}` }
                        alt={ title }
                        width="500"
                      />
                    ) : (
                      <img
                        src={ asset`ai/${slug}.svg` }
                        alt={ title }
                        width="300"
                      />
                    ) }
                  </div>
                </article>
              );
            }
          ) }
        </div>
      </section>
    );
  }
}
