const React = require('react');
const PropTypes = require('prop-types');
const atom = require('atom-js');

const BaseComponent = require('sf/components/BaseComponent');
const Button = require('sf/components/Button');
const withTooltip = require('sf/hoc/Tooltip');

const { WEBCAM_SWITCH_CAMERA } = require('sf/l10n');

const IconWithTooltip = withTooltip(Button);

class SwitchCameraButton extends BaseComponent {
	className = 'ts-SwitchCameraButton';

	static propTypes = {
		cssPrefix: PropTypes.string.isRequired,
		live: PropTypes.bool.isRequired,
		model: PropTypes.object.isRequired,
		switch_camera_node: PropTypes.node.isRequired,
		webcam: PropTypes.object.isRequired,
	};

	handleClick = () => {
		const { webcam } = this.props;
		webcam.switchCamera().then(() => {
			webcam.reattach();
		});
	};

	render(props) {
		if (
			props.userMedia
			&& props.cameraInfs?.length > 1
		) {
			return (
				<IconWithTooltip
					disabled={ !props.live }
					className={ this.rootcn`${props.cssPrefix}__switch-camera-button` }
					onClick={ this.handleClick }
					aria-label={ WEBCAM_SWITCH_CAMERA }
					tooltipText={ WEBCAM_SWITCH_CAMERA }
				>
					{ props.switch_camera_node }
				</IconWithTooltip>
			);
		}
	}
}

module.exports = atom.reactConnect('model', [
	'live',
	'switch_camera_node',
	'cssPrefix',
	'userMedia',
	'cameraInfs',
])(SwitchCameraButton);
