/* eslint-disable max-len */
import React from 'react';
import BasePage from 'pages/BasePage';
import { Link } from 'react-router';
import { ROUTES } from 'constants';
import Banner from 'components/Banner';
import BankingAndFintechContent from './components/BankingAndFintechContent';
import help from 'models/help';

export default class BankingAndFintech extends BasePage {
  className = 'ts-BankingAndFintech';
  static title =
    'Empowering Financial Institutions with Enhanced Identity Verification and Fraud Protection';
  static description =
    "Discover advanced eKYC and Customer Due Diligence, Synthetic Identity Fraud Detection, and Biometric Multifactor Authentication solutions tailored for financial institutions. Secure customer accounts, increase customer acquisition, and reduce fraud with Trust Stamp's innovative technology.";

  componentDidMount() {
    help.set({ showDemoButton: true });
  }

  componentWillUnmount() {
    help.set({ showDemoButton: false });
    super.componentWillUnmount();
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        <Banner theme="banking">
          <h1>
            Empowering Financial Institutions with Enhanced Identity
            Verification and Fraud Protection
          </h1>
          <p>
            Discover advanced eKYC and Customer Due Diligence, Synthetic
            Identity Fraud Detection, and Biometric Multifactor Authentication
            solutions tailored for financial institutions. Secure customer
            accounts, increase customer acquisition, and reduce fraud with Trust
            Stamp's innovative technology.
          </p>
          <Link to={ ROUTES.CONTACT_US }>Get in Touch</Link>
        </Banner>

        <BankingAndFintechContent />
      </div>
    );
  }
}
