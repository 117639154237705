/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/click-events-have-key-events */

// TODO: eslint jsx-ally issue should be addressed because they are related to web accessibity.

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import { Link } from 'react-router';
import { ROUTES } from 'constants';
import Icon from 'sf/components/Icon';
import { asset } from 'sf/helpers';
import store from 'sf/helpers/store';

export default class CookiesConsent extends BaseComponent {
  className = 'ts-CookiesConsent';

  static propTypes = {
    children: PropTypes.node,
    cookieName: PropTypes.string, // name used for localstorage variable
    consentUrl: PropTypes.string,
  };

  static defaultProps = {
    children: '',
    cookieName: 'cookieConsent',
  };

  state = {
    checked: false, // consent switch
    closedModal: true, // modal
  };

  componentDidMount() {
    const { onConsentGranted, cookieName } = this.props;
    // check if user had given cookie consent previously
    const cookieConsent = store.get(cookieName);
    // false for null/undefined; true for anything else
    // eslint-disable-next-line eqeqeq
    const isCookieConsentDefined = cookieConsent != undefined;

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      closedModal: isCookieConsentDefined,
      checked: cookieConsent ? cookieConsent : false,
    });

    if (cookieConsent) {
      onConsentGranted();
    }

    document.body.style.overflowY = isCookieConsentDefined ? 'auto' : 'hidden';
  }

  // function triggered on the switch button
  handleCookieUpdate = function (event) {
    const { onConsentGranted, onConsentRejected } = this.props;
    // update the state
    this.setState({
      checked: event.target.checked,
    });

    // if checkbox is checked
    if (!this.state.checked) {
      onConsentGranted();
    } else if (this.state.checked) {
      // if checkbox is not checked
      onConsentRejected();
    }
  }

  // close cookie modal
  closeModal = function () {
    this.setState({
      closedModal: true,
    });
    document.body.style.overflowY = 'auto';
  }

  // open cookie modal
  openModal = function () {
    this.setState({
      closedModal: false,
    });
    document.body.style.overflowY = 'hidden';
  }

  // save functionality
  saveCookieConsent = function () {
    store.set(this.props.cookieName, this.state.checked);
    this.closeModal();
  }

  render() {
    const consentURL = this.props.consentURL || ROUTES.COOKIES;

    return (
      <Fragment>
        <div className={ this.rootcn({
          '--closed': this.state.closedModal,
        }) }
        >
          <div className={ this.cn`__overlay` } />
          <div className={ this.cn`__content` }>
            <h3>Our use of cookies</h3>
            <a
              className={ this.cn`__close` }
              role="button"
              tabIndex="0"
              onClick={ () => this.closeModal() }
            >
              <Icon set="io" size={ 16 } type="close-round" />
            </a>
            <p>
              We use necessary cookies to make our site work.
              We'd also like to set optional analytics cookies to help us improve it.
              We won't set optional cookies unless you enable them.
              Using this tool will set a cookie on your device to remember your preferences.
            </p>
            <p>For more detailed information about the cookies we use, see our <Link to={ consentURL } onClick={ () => this.closeModal() }>Cookies</Link> page
            </p>
            <hr />
            <h3>Necessary cookies</h3>
            <p>
              Necessary cookies enable core functionality such as security,
              network management, and accessibility.
              You may disable these by changing your browser settings,
              but this may affect how the website functions.
            </p>
            <hr />
            <div className={ this.cn`__switch-container` } >
              <h3>Analytics cookies</h3>
              <label className="switch" htmlFor="cookie-checkbox">
                <input
                  id="cookie-checkbox"
                  type="checkbox"
                  checked={ this.state.checked }
                  onChange={ (e) => { this.handleCookieUpdate(e); } }
                />
                <span className="slider" />
              </label>
            </div>
            <p>
              We'd like to set Google Analytics cookies to help us to improve our website
              by collecting and reporting information on how you use it.
              The cookies collect information in a way that does not directly identify anyone.
              For more information on how these cookies work, please see our <Link to={ consentURL } onClick={ () => this.closeModal() }>Cookies</Link> page.
            </p>
            <Link
              className={ this.cn`__button` }
              onClick={ () => this.saveCookieConsent() }
            >
              Save and close
            </Link>
          </div>
        </div>

        <a
          role="button"
          tabIndex="0"
          className={ this.cn({
            '__cookie': true,
            '__cookie--show': this.state.closedModal,
          }) }
          onClick={ () => { this.openModal(); } }
        >
          <img
            src={ asset`/img/cookie.svg` }
            alt="Cookie Consent"
            width="50"
          />
        </a>
      </Fragment>
    );
  }
}
