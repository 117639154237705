/* eslint-disable max-len */
import React from 'react';
import BasePage from 'pages/BasePage';
import { Link } from 'react-router';

export default class Careers extends BasePage {
  className = 'ts-CorporateCareers';
  static title = 'Careers';
  static description = 'Join Trust Stamp as we work to provide the world with secure societal and financial inclusion.';

  renderMainSection() {
    return (
      <section className={ this.cn`__hero` }>
        <div className={ this.cn`__hero-color` } />
        <div className={ this.cn`__hero__container` }>
          <h1 className={ this.cn`__hero__title` }>
            Careers
          </h1>
        </div>
      </section>
    );
  }

  renderCareerSection() {
    return (
      <section className={ this.cn`__careers` }>
        <div className="ts-container">
          <div className={ this.cn`__content` }>
            <div className={ this.cn`__content__photo` } />
            <div className={ this.cn`__content-left` }>
              <p>
                Trust Stamp is an artificial intelligence company focused on providing identity solutions at the intersection of biometrics, privacy, and cybersecurity. We are based out of the US, UK and EU, and have team members located across the world.
              </p>
              <p>
                We have a team of 70+ experienced developers, artificial intelligence and machine learning engineers, data scientists and business leaders that work to address pressing needs in the identity space, from enterprise data security to humanitarian aid distribution. Our solutions are used by S&P 500 bank clients and small businesses alike to identify fraud, improve customer onboarding and experience, and increase financial inclusion, all while protecting privacy. Trust Stamp solves real world problems with a passionate and dynamic team at our core.
              </p>
            </div>
          </div>
          <div className={ this.cn`__content--bottom` }>
            <p>
              Follow our LinkedIn page to learn about available job opportunities.
            </p>
            <Link href="https://www.linkedin.com/company/trust-stamp/jobs" rel="" target="_blank" className="ts-button--brand">Job Board</Link>
          </div>
        </div>
      </section>
    );
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        { this.renderMainSection() }
        { this.renderCareerSection() }
      </div>
    );
  }
}
