/* eslint-disable */
        module.exports = {
  "action-element-base": "#00ad68",
  "action-element-active": "#00ad68",
  "brand-x-light": "#f4f5fb",
  "brand-light": "#f1f2f5",
  "brand-base": "#717a8e",
  "brand-mid-dark": "#2c2e34",
  "brand-dark": "#121212",
  "brand-x-dark": "#293754",
  "fonts-slab": "\"Raleway\", Georgia, \"Times New Roman\", serif",
  "fonts-sans": "\"Noto Sans\", \"Arial\", sans-serif",
  "fonts-mono": "Monaco, Consolas, \"Andale Mono\", monospace",
  "brandName": "ai",
  "aboutUrl": null,
  "appName": "Truststamp",
  "companyName": "Truststamp",
  "policyUrl": null,
  "trustStampUrl": "https://prd.truststamp.net",
  "realtorUrl": "https://prd.truststamp.re",
  "brandSlug": "ai",
  "includeFonts": [
    "https://fonts.googleapis.com/css2?family=Noto+Sans&family=Raleway:wght@400;600&display=swap"
  ]
}
      /* eslint-enable */
      