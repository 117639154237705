/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router';
import BasePage from 'pages/BasePage';
import Banner from 'components/Banner';
import Benefits from 'components/Benefits';
import HowItWorks from 'components/HowItWorks';
import VideoAndContent from 'components/VideoAndContent';
import Resources from 'components/Resources';
import CaptureContent from './components/CaptureContent';
import { TITLE, CONTENT, TITLE2, HOWITWORKS, VIDEO_CONTENT } from './constants';
import { ROUTES } from 'constants';
import help from 'models/help';

export default class Capture extends BasePage {
  className = 'ts-Capture';
  static title = 'Spoof-Resistant Biometric Capture';
  static description = 'Trust Stamp Capture combines contactless biometric analysis with proof-of-life and anti-spoofing tests to determine whether captured data belongs to a real person.';

  componentDidMount() {
    help.set({ showDemoButton: true });
  }

  componentWillUnmount() {
    help.set({ showDemoButton: false });
    super.componentWillUnmount();
  }

  render() {
    return (
      <div className={ this.rootcn() }>

        <Banner theme="phone" size="small">
          <h1>Biometric Capture & Verification</h1>
        </Banner>

        <CaptureContent />

        <Benefits sectionTitle={ TITLE } content={ CONTENT } />

        <HowItWorks
          content={ HOWITWORKS }
          sectionTitle={ TITLE2 }
          columnCount={ 4 }
          colored={ true }
        />

        <VideoAndContent content={ VIDEO_CONTENT } />

        <Resources formLayout={ true }>
          <article>
            <h2>Talk to a specialist</h2>
            <p>Get in touch with a team member today to learn more about how Trust Stamp's biometric capture and analysis technology can seamlessly integrate into your existing systems.</p>
          </article>
          <aside>
            <Link className="ts-button--tertiary" to={ ROUTES.CONTACT_US }>Get in Touch</Link>
          </aside>
        </Resources>

      </div>
    );
  }
}
