import React from 'react';
import PropTypes from 'prop-types';
import { brandName } from 'brandingVariables';
import BaseComponent from 'components/BaseComponent';
import Logo from 'components/Logo';
import CustomLogo from 'components/CustomLogo';
import UserPanel from 'components/UserPanel';
import { BRANDINGS } from 'constants';
import user from 'models/user';
import layout from 'models/layout';

export default class PageHeader extends BaseComponent {
  className = 'ts-PageHeader';

  static propTypes = {
    size: PropTypes.string,
    theme: PropTypes.string,
  };

  state = {
    branding_logo_url: null,
  };

  componentDidMount() {
    this.syncStateWithModel(user, ['isSignedIn']);
    this.syncStateWithModel(layout, ['branding_logo_url']);
  }

  render() {
    const { active, brandingName, fixed, size, theme, visible } = this.props;
    const classNameModifiers = {
      '--big': size === 'big',
      '--light': theme === 'light' && brandName !== BRANDINGS.REALTOR,
      '--fixed': fixed,
      '--fixed-visible': fixed && visible,
      [`--branding-${brandName}`]: brandName,
    };
    return (
      <div className={ this.rootcn(classNameModifiers) }>
        <div className="ts-container">
          { this.state.branding_logo_url ? (
            <div className={ this.cn`__custom-logo-container` }>
              <CustomLogo
                logoSrc={ this.state.branding_logo_url }
              />
            </div>
          ) : (
            <Logo
              isSignedIn={ this.state.isSignedIn }
              theme={ theme }
              variant={ brandName }
            />
          ) }
          <div className={ this.cn`__user-panel` }>
            <UserPanel
              active={ active }
              brandingName={ brandingName }
            />
          </div>
        </div>
      </div>
    );
  }
}
