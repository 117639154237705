import React from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import InlineTimer from 'components/InlineTimer';
import { scrollTo } from 'sf/helpers/domHelper';
import session from 'models/session';
import user from 'models/user';
import Alert from 'sf/components/Alert';
import Icon from 'sf/components/Icon';

let messageId = 0;

export default class SessionAlert extends BaseComponent {
  className = 'ts-SessionAlert';

  state = {
    messageId: messageId,
  };

  static propTypes = {
    children: PropTypes.node,
    listenTopic: PropTypes.string,
    scrollToView: PropTypes.bool,
  };

  static defaultProps = {
    listenTopic: 'sessionTimeout',
    scrollToView: true,
  };

  updateTimeout = (timeoutDate) => {
    this.setState({
      messageId: ++messageId,
      timeoutDate,
    }, () => {
      if (this.props.scrollToView) {
        scrollTo(findDOMNode(this.refs.message));
      }
    });
  }

  componentDidMount() {
    this.subscribe(this.props.listenTopic, this.updateTimeout);
    user.on('isSignedIn', this.handleSignInChange);
    this.syncStateWithModel(user, ['isSignedIn']);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    user.off('isSignedIn', this.handleSignInChange);
  }

  handleExtendSession = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.refs.message) {
      this.refs.message.closeAlert();
    }
    session.extendSession();
  };

  handleSignInChange = (isSigned) => {
    if (!isSigned && this.refs.message) {
      this.refs.message.closeAlert();
    }
  };

  getTimer() {
    return (
      <InlineTimer
        date={ this.state.timeoutDate }
        finishCallback={ () => {
          if (this.refs.message) {
            this.refs.message.closeAlert();
          }
          user.logOut({ redirect: true, reason: 'session' });
        } }
      />
    );
  }

  getLink() {
    return (
      <Button
        theme="link"
        className={ this.cn`__link` }
        onClick={ this.handleExtendSession }
      >
        here
      </Button>
    );
  }

  render() {
    if (this.state.timeoutDate == null || !this.state.isSignedIn) return null;
    return (
      <Alert
        className={ this.rootcn() }
        key={ this.state.messageId }
        ref="message"
        theme="session"
        closeable={ false }
      >
        <div className="ts-container">
          <div className={ this.cn`__message` }>
            <Icon className={ this.cn`__icon` } set="io" type="clock" size={ 18 } />
            { /* eslint-disable */}
            Your session is about to expire in {}
            <span className={ this.cn`__message--important` }>{ this.getTimer() }</span>.
            Click { this.getLink() } to extend your session.
            {/* eslint-enable */ }
          </div>
        </div>
      </Alert>
    );
  }
}
