import React from 'react';
import { Link } from 'react-router';
import throttle from 'lodash/throttle';
import BasePage from 'pages/BasePage';
import Icon from 'sf/components/Icon';

const socialData = [
  {
    href: 'https://www.facebook.com/Trust-Stamp-1751960355032233/',
    type: 'social-facebook',
  },
  {
    href: 'https://www.linkedin.com/company/10373549?trk=tyah&trkInfo=clickedVertical:company,clickedEntityId:10373549,idx:3-1-3,tarId:1466175435680,tas:trust%20stamp',
    type: 'social-linkedin',
  },
  {
    href: 'https://twitter.com/truststamp',
    type: 'social-twitter',
  },
];

export default class SocialMediaPin extends BasePage {
  className = 'ts-SocialMediaPin';
  topOffset = 100;

  state = {
    afterHero: false,
  };

  componentDidMount() {
    this.addEventListener(window, 'scroll', this.handleScrollAfterHeroThrottled);
  }

  handleScrollAfterHero = () => {
    const afterHero = window.pageYOffset > this.topOffset;
    this.setState({ afterHero });
  };

  handleScrollAfterHeroThrottled = throttle(this.handleScrollAfterHero, 50);

  renderSocialMedia(social, index) {
    return (
      <div
        className="social-media-icon"
        key={ index }
      >
        <Link
          href={ social.href }
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon
            set="io"
            size={ 22 }
            type={ social.type }
          />
        </Link>
      </div>
    );
  }

  renderSocialMediaContainer() {
    return (
      <div className={ this.cn`__social-media-container` }>
        { socialData.map(this.renderSocialMedia) }
      </div>
    );
  }

  render() {
    const classNames = {
      '--hero': this.state.afterHero,
    };
    return (
      <div className={ this.rootcn(classNames) } >
        { this.renderSocialMediaContainer() }
      </div>
    );
  }
}
