import React from 'react';
import BasePage from 'pages/BasePage';
import SupportedList from './components/SupportedList';


export default class SupportedDocuments extends BasePage {
  static title = 'Trust Stamp’s Identity Document Database';
  static description = 'Trust Stamp’s identity document database contains 14,000 templates of IDs from 251 countries. Find out all types of documents available.';

  render() {
    return <SupportedList />
  }
}

