/* eslint-disable max-len */
import React from 'react';
import BaseComponent from 'components/BaseComponent';
import { asset } from 'sf/helpers';

export default class ProtectContent extends BaseComponent {
  className = 'ts-ProtectContent';

  render() {
    return (
      <div className={ this.rootcn() } >
        <section>
          <div className="ts-container">
            <div className={ this.cn`__column __column--left` }>
              <h6>TRUST STAMP PROTECT</h6>
              <h2>Privacy and data protection</h2>
              <p>Maximise the functionality of your identity systems by enabling secure storage, safe sharing and accurate querying without the need to expose sensitive data. Trust Stamp's deep neural networks turn any biometric data into unique, Irreversibly Transformed Identity Tokens, (IT)²s, that serve as privacy-preserving identifiers.</p>
            </div>
            <div className={ this.cn`__column __column--right` }>
              <img src={ asset`ai/it2.png` } alt="Privacy and data protection" width="217px" />
            </div>
          </div>
        </section>
        <section>
          <div className="ts-container">
            <h2>A competitive edge in digital services</h2>
            <p>Trust Stamp Protect gives unparalleled efficiency and security to any systems that rely on identity data. Lead the charge in digital transformation with streamlined onboarding, reauthentication and account recovery, alongside fraud-focused database deduplication that pushes the limits of what is computationally possible today.</p>
            <p>It all starts with Trust Stamp's privacy-preserving data transformation technology, which can be leveraged within existing risk management and data protection systems to enhance their functional integrity and leverage the benefits of biometric tools while minimising the risks.</p>
          </div>
        </section>
      </div>
    );
  }
}
