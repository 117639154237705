export const OFFICES = [
  {
    name: 'Atlanta Office (HQ)',
    address: `3017 Bolling Way NE,
Floor 2,
Atlanta, Georgia, 30305`,
    location: {
      lat: 33.837684,
      long: -84.380209,
    },
  },
  {
    name: 'Malta Office',
    address: `Level 1,
Tagliaferro Business Centre,
High Street, Sliema,
SLM 1551, Malta`,
    location: {
      lat: 35.910644,
      long: 14.506016,
    },
  },
  {
    name: 'The Philippines Office',
    address: `Sourcefit,
21st Floor, CyberOne Bldg,
Eastwood Cyberpark,
1110 Bagumbayan, Quezon City,
Metro Manila`,
    location: {
      lat: 14.607811,
      long: 121.080477,
    },
  },
  {
    name: 'Warsaw Office',
    address: `Finlandzka 10
03-903 Warszawa,
Poland`,
    location: {
      lat: 52.2370568,
      long: 21.0504272,
    },
  },
  {
    name: 'Rwanda Office',
    address: `Westerwelle Startup Haus Fl. 4,
Fairview Building,
KG622 Street, Rugando,
Kigali, Rwanda`,
    location: {
      lat: -1.9552914,
      long: 30.0965273,
    },
  },
  {
    name: 'Hillsborough Office',
    address: `128 North Churton Street
Hillsborough,
NC, 27278 USA`,
    location: {
      lat: 36.076302,
      long: -79.099110,
    },
  },
  {
    name: 'Silicon Valley Office',
    address: `Plug and Play,
440 N Wolfe Road Sunnyvale,
CA 94085 USA`,
    location: {
      lat: 37.383917,
      long: -122.012921,
    },
  },
  {
    name: 'Copenhagen Office',
    address: `c/o Copenhagen Fintech
Applebys Plads 7
1411 København K`,
    location: {
      lat: 55.67218,
      long: 12.57868,
    },
  },
  {
    name: 'UK Subsidiary',
    address: 'Biometric Innovations Ltd',
    location: {
      lat: 51.902676,
      long: -2.076349,
    },
  },
];

export const VIDEO_CONTENT = {
  id: 1,
  title: 'See it in action',
  text: 'In this example, Trust Stamp Capture, Identify, and Protect work together in a biometric enrolment user flow',
  video: 'https://player.vimeo.com/video/361351107',
};
