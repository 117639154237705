export const STEPS = {
  SELFIE: 'SELFIE',
  EMAIL: 'EMAIL',
  LINK_SENT: 'LINK_SENT',
  LINK_RECEIVED: 'LINK_RECEIVED',
  NEW_PASSWORD: 'NEW_PASSWORD',
};

/* eslint-disable max-len */
export const DATA = {
  [STEPS.SELFIE]: {
    breadcrumb: 'Selfie and email',
    postMethod: 'postSelfie',
    title: 'Take a selfie',
    subtitle: `
      <ul style="text-align: left; margin: 0 auto; max-width: 400px">
        <li>make sure that your face fits the light oval</li>
        <li>have optimal lighting -  consider turning on your lights or<br />
            moving closer to the window</li>
        <li>act natural!</li>
      </ul>`,
    nextUrl: '/business/ResetPassword/Email.html',
  },
  [STEPS.EMAIL]: {
    breadcrumb: 'Selfie and email',
    postMethod: 'postEmail',
    title: 'Enter the e-mail you used during the registration',
    subtitle: 'We will send you an email with a link to reset your password',
    nextUrl: '/business/ResetPassword/LinkSent.html',
  },
  [STEPS.LINK_SENT]: {
    breadcrumb: 'Reset with link from email',
    title: 'Reset link sent',
    subtitle: `You'll receive an email from TrustStamp that contains a link to set a new password.<br />
      If you don't receive this email within an hour or two, check your junk or spam folder.`,

  },
  [STEPS.LINK_RECEIVED]: {
    breadcrumb: 'Reset with link from email',
    postMethod: 'loginWithSelfie',
    title: 'Take a selfie',
    subtitle: `
      <ul style="text-align: left; margin: 0 auto; max-width: 400px">
        <li>make sure that your face fits the light oval</li>
        <li>have optimal lighting -  consider turning on your lights or<br />
            moving closer to the window</li>
        <li>act natural!</li>
      </ul>`,
    nextUrl: '/business/ResetPassword/NewPassword.html',
  },
  [STEPS.NEW_PASSWORD]: {
    breadcrumb: 'Set a new password',
    postMethod: 'setNewPassword',
    title: 'Set a new password',
    subtitle: 'Your password must be at least 8 characters long and contain: <br/>an uppercase letter, lowercase letter and a digit. Make sure your passwords match',
    nextUrl: '/business/LogInCredentials.html',
  },
};
/* eslint-enable max-len */
