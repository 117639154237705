import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import baseFunctionalComponent from 'components/BaseFunctionalComponent';
import Button from 'components/Button';
import NotificationPill from 'components/NotificationPill';
import Icon from 'sf/components/Icon';

export function ProfileDropdownItem({ legalName, notificationsCount, photo, onClick }) {
  return (
    <Button
      className={ this.cn`__profile` }
      theme="link-unstyled"
      onClick={ onClick }
    >
      { photo ?
        <img className={ this.cn`__avatar` } src={ photo } alt="" /> :
        <span className={ this.cn`__avatar` } />
      }
      { legalName }
      <NotificationPill>
        { notificationsCount }
      </NotificationPill>
      <Icon
        className={ this.cn`__caret` }
        set="io"
        size={ 14 }
        type="arrow-down-b"
      />
    </Button>
  );
}

ProfileDropdownItem.propTypes = {
  legalName: PropTypes.string.isRequired,
  notificationsCount: PropTypes.number,
  photo: PropTypes.string,
  onClick: PropTypes.func,
};

ProfileDropdownItem.defaultProps = {
  notificationsCount: null,
  photo: null,
  onClick: noop,
};

export default baseFunctionalComponent(ProfileDropdownItem, 'ProfileDropdownItem');
