/* eslint-disable max-len */

export const CONTENT = [
  {
    id: 1,
    title: '1. Keeping bad actors out',
    content: 'Banks are experiencing increasing rates and types of synthetic identity fraud and account takeover attacks due to greater numbers of consumers migrating to digital channels.',
    slug: 'fraustericon',
  },
  {
    id: 2,
    title: '2. Letting more good customers in',
    content: 'In an effort to reduce the incidence of fraud, banks may erroneously flag applications as high-risk, denying even legitimate transactions. Customers must prove their identities to clear these flags, but traditional tools can take over five business days to complete.',
    slug: 'goodcustomersicon',
  },
];
