import React from 'react';
import omit from 'lodash/omit';
import ValidationInput from 'sf/components/ValidationInput';

export default class ValidationTextarea extends ValidationInput {
  className = 'ts-ValidationTextarea';

  render() {
    const inputClassNames = {
      '__field': true,
      '__field--success': this.state.isValid,
      '__field--danger': this.state.isValid === false,
    };
    const { stateLink } = this.props;
    const value = stateLink ? stateLink[0].state[stateLink[1]] : undefined;

    return (
      <div className={ this.rootcn() }>
        { this.renderLabel() }
        <textarea
          ref="input"
          value={ value || '' }
          { ...omit(this.props, [
            'isValid', 'displayName', 'stateLink', 'maskOptions',
            'permanentPlaceholder', 'unmask', 'parser', 'mismatchInfo',
            'isValidatedByProp', 'isOptional', 'clearable',
          ]) }
          onChange={ this.handleChange }
          className={ this.cn(inputClassNames) }
        />
      </div>
    );
  }
}
