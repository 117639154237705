import userModel from 'models/user';
import * as TYPES from './Profile.types';

export function saveMPHDiscountCode(code) {
  return {
    type: TYPES.SAVE_MPH_DISCOUNT_CODE,
    data: { code },
  };
}

export function clearMPHDiscountCode() {
  return {
    type: TYPES.CLEAR_MPH_DISCOUNT_CODE,
  };
}

const requestProfileDataPending = () => ({
  type: TYPES.REQUEST_PROFILE_DATA_PENDING,
});

const requestProfileDataSuccess = (user) => ({
  type: TYPES.REQUEST_PROFILE_DATA_SUCCESS,
  data: { user },
});

const requestProfileDataFailure = (error) => ({
  type: TYPES.REQUEST_PROFILE_DATA_FAILURE,
  data: { error },
});

export const requestProfileData = () => {
  return (dispatch) => {
    dispatch(requestProfileDataPending());
    userModel.getProfileData()
      .then((data) => {
        dispatch(requestProfileDataSuccess(data));
      })
      .catch((error) => {
        dispatch(requestProfileDataFailure(error));
      });
  };
};

export const clearProfileData = () => ({
  type: TYPES.CLEAR_PROFILE_DATA,
});
