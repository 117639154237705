/* eslint-disable max-len */
import React from 'react';
import BaseComponent from 'components/BaseComponent';
import { ROUTES } from 'constants';
import { asset } from 'sf/helpers';
import { Link } from 'react-router';
import { CONTENT } from './constants';

export default class Work extends BaseComponent {
  className = 'ts-our-solution';

  render() {
    return (
      <section className={ this.rootcn() }>
        <div className={ this.cn`__container ts-container` }>
          <h6>HOW WE HELPED</h6>
          <h2>Our solution</h2>
          <p>
            Trust Stamp collaborated with the bank to implement{ ' ' }
            <Link
              href={ ROUTES.VERIFY }
              rel="noopener noreferrer"
              target="_blank"
            >
              biometric identity verification
            </Link>
            ,{ ' ' }
            <Link
              href={ ROUTES.DETECT }
              rel="noopener noreferrer"
              target="_blank"
            >
              document validation
            </Link>
            ,{ ' ' }
            <Link
              href="https://truststamp.ai/Detect.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              and duplicate detection
            </Link>{ ' ' }
            technologies throughout their identity architecture.
          </p>
          <p>
            The bank implemented this framework into their onboarding and
            account recovery processes to enhance security with robust privacy
            and fraud-prevention measures in the touchpoints that present the
            most common and potentially disastrous opportunities for successful
            fraud attempts: when a financial institution and an individual first
            enter a business relationship, and when a user is unable to provide
            their existing access credentials.
          </p>

          { /* what we do list */ }
          <div className={ this.cn`__features` }>
            { CONTENT.map(({ id, title, content, slug }) => (
              <div className={ this.cn`__column` } key={ id }>
                <h5>{ id }</h5>
                <img src={ asset`ai/${slug}.svg` } alt={ title } />
                <h3>{ title }</h3>
                <p>{ content }</p>
                <a href="">LEARN MORE</a>
              </div>
            )) }
          </div>

          <p>
            The program was deemed a success and has expanded to other customers
            and business lines within the bank. In a single year, the customer
            reported significant and substantial new sales revenue, hard fraud
            savings and fraud deterrence.
          </p>
        </div>
      </section>
    );
  }
}
