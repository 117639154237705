// WARNING:
// Before writing your own helper, make sure if there is no available one on
// npm registry.

import React from 'react';
import param from 'jquery-param';
import camelCase from 'lodash/camelCase';
import _get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import last from 'lodash/last';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import Mediator from 'mediator-js/lib/mediator';
import is from 'next-is';
import { browserHistory } from 'react-router';
import urlParse from 'url-parse';

import { createAndClickAnchor, getScript, loadCssFile as _loadCssFile } from 'sf/helpers/domHelper';
import { getConfig } from 'sf';

const { canvasToBlobURL } = require('sf/helpers/canvas');

// NOTE: use this file from domHelper!
export const loadCssFile = _loadCssFile;

/**
 * generateToken is a sub-function for generating UUID.
 * It can generate other random strings too.
 *
 * @param  {number} len                length of the token to generate. Separators doesn't count!
 * @param  {number} radix              Argument number.toString(radix). MIN: 2, MAX: 36.
 * @param  {string} separator          separator char for tokens
 * @param  {Array} separatorPositions position of separators
 * @return {string}                    token string
 */
export const generateToken = function (
  len = 32,
  radix = 36,
  separator = '',
  separatorPositions = [],
) {
  /* eslint-disable */
  let uuid = '';
  for (let i = 0; i < len; ++i) {
    let random = Math.random() * radix | 0;

    if (separator && separatorPositions.includes(i)) {
      uuid += separator;
      uuid += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random)).toString(radix);
    } else {
      uuid += random.toString(radix);
    }
  }
  /* eslint-enable */
  return uuid;
};

export const uuidv4 = function () {
  return generateToken(32, 16, '-', [8, 12, 16, 20]);
};

export const mediator = is.isFunction(Mediator) ?
  new Mediator() :
  new Mediator.Mediator();
// We need a method for the request chaining to make it possible to test args
export const testSpy = (args) => args;

/**
 * Converts query stringing into object
 * @param  {string} input  "?xxx=yyy&aaa=bbb" or "#xxx=yyy&aaa=bbb"
 * @return {object}        { xxx: 'yyy', aaa: 'bbb' }
 */
// eslint-disable-next-line no-restricted-globals
export const getQuery = (input = typeof location !== 'undefined' ? location.search : '') => {
  const result = {};
  if (input.length > 2) {
    const queryArr = input.substr(1).split('&');
    for (let i = 0, len = queryArr.length; i < len; ++i) {
      const pair = queryArr[i].split('=');
      if (pair.length) {
        result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
      }
    }
  }
  return result;
};

/**
 * Builds query from the object
 * @param  {string} inputObject  { xxx: 'yyy', aaa: 'bbb' }
 * @return {string}              "xxx=yyy&aaa=bbb" or "#xxx=yyy&aaa=bbb"
 */
export const buildQuery = (inputObject) => {
  /* istanbul ignore next */
  return is.browser() ? param(inputObject, true) : '';
};

/**
 * Takes e-mail address and returns address for receiving e-mail.
 * For example: truststamp@gmail.com returns http://gmail.com
 * @param  {string} emailAddress
 * @return {string}
 */
export const getMailboxAddress = (emailAddress) => {
  if (!is.string.isEmail(emailAddress)) {
    return '';
  }
  let domain = emailAddress.split('@')[1];

  if (domain.split('.').length === 2) { // xxx.tld, but no xxx.yyy.tld
    switch (domain) {
      case 'gmail.com':
        break;
      case 'live.com':
      case 'hotmail.com':
      case 'outlook.com':
        domain = 'mail.live.com';
        break;
      case 'wp.pl':
      case 'onet.pl':
      case 'interia.pl':
      case 'op.pl':
      case 'vp.pl':
      case 'onet.eu':
      case 'poczta.onet.pl':
        domain = `poczta.${domain}`;
        break;
      default:
        domain = `mail.${domain}`;
    }
  }

  return `https://${domain}`;
};

export const getMailboxLink = (emailAddress, linkText) => {
  const inboxAddress = getMailboxAddress(emailAddress);

  if (inboxAddress) {
    return <a href={ inboxAddress } target="_blank" rel="noopener noreferrer">{ linkText }</a>;
  }

  return linkText;
};

/**
 * cacheParam helper adds cache param to the URL.
 */
export const cacheParam = (url) => {
  return `${url}?_=${CACHE_PARAM}`;
};

export const asset = (...args) => {
  let result;
  if (typeof args[0] === 'string' && args.length === 1) {
    // eslint-disable-next-line prefer-destructuring
    result = args[0];
  } else if (args[0]) {
    result = String.raw(...args);
  }

  if (!result) {
    // empty in, empty out
    return '';
  }

  if (result[0] === '/') {
    result = result.substr(1);
  }
  const prefix = /^http/i.test(result) ? '' : getConfig('assetsURL');

  return cacheParam(`${prefix}${result}`);
};

export const getLocaleDateString = (date, locale) => {
  const formatGB = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  const formatUS = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

  const formattedDate = {
    'en-AU': formatGB,
    'en-CA': formatUS,
    'en-GB': formatGB,
    'en-IN': formatUS,
    'en-NZ': formatGB,
    'en-US': formatUS,
    'es-MX': formatGB,
  };

  return formattedDate[locale] || formattedDate['en-US'];
};

/**
 * Takes time difference in milliseconds, and returns it as X hours, Y minutes, Z seconds
 *
 * @param  {number} timeDifferenceInMilliseconds    Time in milliseconds.
 * @param  {string} format                          empty (default) = HH:MM:SS;
 *                                                  'pretty' = X hours, Y minutes, Z seconds
 * @return {string}
 */
export const msToTime = (timeDifferenceInMilliseconds, format) => {
  const timeAsNumber = Number(timeDifferenceInMilliseconds) / 1000;
  const hours = Math.floor(timeAsNumber / 3600);
  const minutes = Math.floor(timeAsNumber % 3600 / 60);
  const seconds = Math.floor(timeAsNumber % 3600 % 60);

  if (format === 'pretty') {
    const hoursDisplay = hours > 0 ? `${hours} hour${hours !== 1 ? 's' : ''}, ` : '';
    const minutesDisplay = minutes > 0 ? `${minutes} minute${minutes !== 1 ? 's' : ''}, ` : '';
    const secondsDisplay = seconds >= 0 ? `${seconds} second${seconds !== 1 ? 's' : ''}` : '';
    return hoursDisplay + minutesDisplay + secondsDisplay;
  }

  if (format === 'generic') {
    if (hours >= 24) {
      const days = Math.trunc(hours / 24);
      return `${days > 1 ? `${days} days ago` : 'a day ago'}`;
    } else if (hours > 0) {
      return `${hours > 1 ? `${hours} hours ago` : 'an hour ago'}`;
    } else if (minutes > 0) {
      return `${minutes > 1 ? `${minutes} minutes ago` : 'a minute ago'}`;
    }
    return `${seconds !== 1 ? `${seconds} seconds ago` : 'a second ago'}`;
  }

  // eslint-disable-next-line prefer-template
  return `${hours}`.padStart(2, '0')
    + ':'
    + `${minutes}`.padStart(2, '0')
    + ':'
    + `${seconds}`.padStart(2, '0');
};

export const getDate = (timestamp) => {
  if (!timestamp) return null;
  // Avoid timezone conversion by using the constructor of Date(year, month, day)
  // for timestamp of yyyy-mm-dd, which applies zero UTC offset
  const match = timestamp.match(/(\d{4})-(\d{2})-(\d{2})/);
  let date;
  if (match) {
    const [, year, month, day] = match;
    date = new Date(year, month - 1, day);
  } else {
    date = new Date(timestamp);
  }
  return Number.isNaN(1 * date) ? '  /  /    ' : getLocaleDateString(date, 'en-US');
};

export const getHumanReadableTimeDiff = (fromDate, toDate = new Date()) => {
  if (!fromDate) throw new Error('First argument should be a date!');
  if (Number.isNaN(Date.parse(fromDate))) throw new Error('The first date is not valid!');
  const timeDiff = Math.abs(toDate.getTime() - new Date(fromDate).getTime());
  const diffInMonths = Math.ceil(timeDiff / (1000 * 3600 * 24 * 30));
  if (Number.isNaN(diffInMonths)) throw new Error('The second date is not valid!');
  if (diffInMonths > 23) {
    return `${Math.round(diffInMonths / 12)} years`;
  } else if (diffInMonths > 11) {
    return '1 year';
  } else if (diffInMonths === 1) {
    return '1 month';
  }
  return `${diffInMonths} months`;
};

export const pascalCase = (str) => {
  return `${camelCase(str).substr(0, 1).toUpperCase()}${camelCase(str).substr(1)}`;
};

/**
 * Prepare symbols dictionary from the array or arguments list.
 * from
 *   symbols('A', 'B', 'C')
 * to
 *   {
 *     A: 'A',
 *     B: 'B',
 *     C: 'C'
 *   }
 * @param  {...[String] or Array} args
 * @return {Object}
 */
export const symbols = function (...args) {
  const arr = args.length > 1 ? args : args[0];
  return arr.reduce((result, val) => {
    result[val] = val;
    return result;
  }, {});
};

export const formatPhone = (phone) => {
  const phoneParts = phone.split('-');
  return `+1 (${phoneParts[0]}) ${phoneParts[1]}-${phoneParts[2]}`;
};

// Hack for cases when few pages uses the same component. Some components does not support
// componentWillReceiveProps that causes weird behaviour on navigation.
export const cloneClass = (Proto) => {
  return class DynamicClass extends Proto {};
};

const loadScriptPoolCache = {};
/**
 * loadScriptPool is used for scripts, that does not have `onLoad` callback properly
 * implemented. It's also used when we're to lazy to read whole documentation for
 * every third party lib.
 * This helper returns the same Promise all the time, so it's safe to call
 * loadScriptPool() multiple times.
 *
 * @param  {string} scriptName Script name to check global[scriptName].
 *                             scriptName can be complex structure like 'obj.arr[12]'
 * @param  {Function} loadCode   Function that will be called once to trigger loading
 * @return {Promise} Promise object
 */
export const loadScriptPool = /* istanbul ignore next */ (scriptName, loadCode) => {
  if (!loadScriptPoolCache[scriptName]) {
    loadCode();

    let interval;
    loadScriptPoolCache[scriptName] = new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        // script didn't load after 90 seconds - trigger failure.
        console.error(`${scriptName} couldn't be loaded after 90 seconds.`); // eslint-disable-line
        clearInterval(interval);
        delete loadScriptPoolCache[scriptName];
        reject();
      }, 90000);
      interval = setInterval(() => { // eslint-disable-line prefer-const
        const obj = _get(global, scriptName);
        if (obj) {
          if (ENV === 'local') {
            console.info(`${scriptName} loaded by loadScriptPool.`); // eslint-disable-line
          }
          clearInterval(interval);
          clearTimeout(timeout);
          resolve(obj);
        }
      }, 333);
    });
  }

  return loadScriptPoolCache[scriptName];
};

/**
 * getClassNamesFromProps is used to extract class names given the Components'
 * props and a set of allowed classes
 *
 * TODO: remove it? Is that used anywhere?
 *
 * @param  {Object} props the Components' props
 * @param  {Object} allowedClasses set of allowed classes
 * @return {Object} an object of classes to pass to `this.cn` or `this.rootcn` method
 */
export const getClassNamesFromProps = (props, allowedClasses) => Object.keys(
  pick(props, allowedClasses)
)
  .map((className) => kebabCase(className))
  .reduce((acc, curr) => {
    acc[`--${curr}`] = true;
    return acc;
  }, {});

export const snakeCase = (text) => kebabCase(text).replace(/-/g, '_');

export const navigate = (url, method = 'push') => new Promise((resolve) => {
  mediator.publish('router:navigate', url);
  browserHistory[method](url);

  // TODO: We assume redirection is done after 120ms.
  //       Refactor it to handle proper navigation events.
  setTimeout(resolve, 120);
});

export const capitalise = (str) => str && `${
  str.trim().substr(0, 1).toUpperCase()
}${
  str.trim().substr(1).toLowerCase()
}`;

export const sentenceCase = (str) => (str || '')
  .split('.')
  .map(capitalise)
  .join('. ');

export const upperCase = (str) => (str || '').toUpperCase();

export const wordUpperCase = (str) => (str || '')
  .split(' ')
  .map((word) => word.toLowerCase())
  .map((word) => ['of', 'and'].includes(word) ?
    word :
    capitalise(word))
  .join(' ');

export const getFileFromBlob = ({ text, extension, name, type }) => {
  const file = new Blob([text], { type });
  const fileName = `${name}.${extension}`;

  // IE11
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(file, fileName);
  } else {
    // TODO: revoke url as described here
    //       https://github.com/nusmodifications/nusmods/pull/575/files
    const downloadUrl = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = downloadUrl;

    // iOS, it doesn't support download and can't have the target
    if (a.download !== undefined) {
      a.download = fileName;
      if (!a.dataset) {
        a.dataset = {};
      }
      a.dataset.downloadurl = [type, a.download, a.href].join(':');
    }
    setTimeout(() => {
      URL.revokeObjectURL(downloadUrl);
    }, 5000);
    createAndClickAnchor(a);
  }
};

export const getFileFromUrl = ({ url, target = '_self' }) => {
  const a = document.createElement('a');
  a.href = url;
  a.target = target;
  createAndClickAnchor(a);
};

export const downloadFileFromUrl = (url) => {
  const a = document.createElement('a');
  a.href = url;

  if (a.download !== undefined) {
    a.download = last(url.split('/'));
    if (!a.dataset) {
      a.dataset = {};
    }
    a.dataset.downloadUrl = ['', a.download, a.href].join(':');
  }
  createAndClickAnchor(a);
};


/* istanbul ignore next */
const isSVGRenderSupported = () => {
  const img = new Image();
  const canvas = document.createElement('canvas');
  img.src = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"></svg>';

  try {
    canvas.getContext('2d').drawImage(img, 0, 0);
    canvas.toDataURL();
  } catch (e) {
    return false;
  } finally {
    canvas.remove();
    img.remove();
  }
  return true;
};

let getHtml2CanvasSvgPromise;
const getHtml2CanvasSvg = () => {
  if (!getHtml2CanvasSvgPromise) {
    // webpack doesn't load html2canvas' svg library
    if (is.browser() && !window.html2canvas && !isSVGRenderSupported()) {
      getHtml2CanvasSvgPromise = getScript(asset`lib/html2canvas/html2canvas.svg.min.js`);
    } else {
      getHtml2CanvasSvgPromise = Promise.resolve();
    }
  }
  return getHtml2CanvasSvgPromise;
};

export const getScreenshot = () =>
  getScript(asset`lib/html2canvas/html2canvas.min.js`)
    .then(getHtml2CanvasSvg)
    .then(() => window.html2canvas(document.body))
    .then((canvas) => canvasToBlobURL(canvas))
    /* eslint-disable no-console */
    .catch((error) => console.error(error));
  /* eslint-enable */

export const hexToRGB = (hex, alpha = 1) => {
  if (/^#(?:[0-9a-f]{3}){1,2}$/i.test(hex)) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
};

/**
 * converts #ABC or #AABBCC or rgb(111, 222, 33) to RGB array
 *
 * @param  {string} _hex    Hex or rgb color
 * @return {Array<number>}  array of separate colors
 */
export const colorToRGBArray = (_hex) => {
  if (_hex instanceof Array && _hex.length === 3) {
    return _hex;
  }
  if (`${_hex}`.startsWith('rgb')) {
    // NOTE: rgba transparency is not supported!
    //       splice(0, 3) part drops transparency from rgba(
    return _hex.match(/\d+/g).map(Number).splice(0, 3);
  }

  const hex = _hex.replace('#', '');

  return hex.length === 3
    ? hex.split('').map((c) => parseInt(c.repeat(2), 16))
    : hex.match(/.{1,2}/g).map((v) => parseInt(v, 16));
};

/**
 * get color brightness. Range from 0 to 1. 0 is black, 1 is white
 *
 * @param  {string} color  hex color
 * @return {number}        float number from 0 to 1
 */
export const getColorBrightness = (color) =>
  (([r, g, b]) =>
    (((r * 299) + (g * 587) + (b * 114)) / 1000))(colorToRGBArray(color)) / 255;

export const domainify = (string = '') => string
  .replace(/[^a-zA-Z0-9]+/gi, ' ')
  .trim()
  .replace(/\s/gi, '-')
  .toLowerCase();

/* eslint-enable */

export const isValidNumber = (candidate) => candidate !== '' &&
  (
    Number.isFinite(Number(candidate)) ||
    Math.abs(candidate) === Infinity
  );

/**
 * Works similar to _.pick but takes Strings and RegExps
 * as second argument.
 *
 * sample usage:
 * advancedPick({ 'data-test': 1, a: 2, b: 3 }, [/^data-/, 'b'])
 *   // --> { 'data-test': 1, b: 3 }
 *
 *
 * @param  {Object} obj   Object to pick from
 * @param  {Array} paths  Array of Strings and RegExps
 * @return {Object}
 */
export const advancedPick = (obj, paths) => pickBy(
  obj,
  (value, name) => paths.some(
    (pathOrRegexp) => pathOrRegexp instanceof RegExp
      ? pathOrRegexp.test(name)
      : pathOrRegexp === name
  )
);

/**
 * Gets branding id from app client subdomain. Examples:
 * ourrerrands.truststamp.net //-> ourerrands
 * ourrerrands.dev.truststamp.net //-> ourerrands
 * ourrerrands.local.truststamp.net //-> ourerrands
 * local.truststamp.net //-> falsy
 *
 * @return {String}
 */
export const getBrandingSubdomain = (brandedHostname = _get(window, 'location.hostname', '')) => {
  const configFrontEndUrl = urlParse(FRONTEND_URL);
  const brandingSubdomain = brandedHostname
    .replace(`${configFrontEndUrl.hostname}`, '')
    .slice(0, -1); // strip last subdomain dot
  return brandingSubdomain;
};

/**
 * Allows to chain function calls, just like the pipeline operator
 *
 *  sample usage:
 *
 *  const someVariable = chain(2)
 *    .pipe(x => x * 3)
 *    .pipe(x => `output is {x}`)
 *    .value;
 *  console.log(someVariable); // 'output is 6'
 *
 * @param  {any} value
 * @return {Object}
 */
export const chain = (value) => ({
  value,
  pipe: function (fn) {
    this.value = fn(this.value);
    return this;
  },
});

export const sentryLog = (error, extra) => {
  console.error('ERROR LOGGED TO SENTRY:', error); // eslint-disable-line no-console
  const Sentry = global.Raven || global.Sentry;

  if (Sentry) { // log it to sentry
    if (extra) {
      Sentry.setExtra('errorDetails', extra);
    }

    // eslint-disable-next-line max-len
    Sentry[typeof error === 'string' ? 'captureMessage' : 'captureException'](error);
    setTimeout(() => {
      Sentry.setExtra('errorDetails', null);
    }, 125);
  } else {
    console.error('ERROR: Sentry is not working.', error); // eslint-disable-line no-console
  }
};

export const sleep = (timeout) => new Promise((resolve) => setTimeout(resolve, timeout));

/**
 * get value of every ref (potentially ValidationInput, Select etc)
 * returned as an object. This should be easly used with validation.
 */
export const getRefValues = (that, prefix = 'form') => {
  const regexp = new RegExp(`^${prefix.replace('.', '\\.')}`);
  return Object.keys(that.refs).reduce((result, key) => {
    if (regexp.test(key)) { // use only refs starting with [prefix]
      result[key.replace(regexp, '')] = that.refs[key].value;
    }
    return result;
  }, {});
};

/**
 * enum function mimics TypeScript's Enum type.
 * the main feature of this is to throw an Error, when you're accessing a
 * object member, that was not declared before.
 *
 * example:
    const ROUTES = enumPolyfill({
      CONTACT: '/contact-us.html',
      HELP: /help.html',
    }, handler);

    navigate(ROUTES.ABOUT_US); // throws an error: `ABOUT_US` is not a member of an object!
 *
 *
 * TODO: replace all enumPolyfill cals with real enum, if migrate to TS.
 * NOTE: This won't work on IE, but also won't break IE.
 *
 * @param {Object} obj
 * @return {Object}
 */
export const enumPolyfill = (object) => {
  const PolyfilledProxy = global.Proxy
    ? global.Proxy
    : (obj) => obj;

  return new PolyfilledProxy(object, {
    get: (obj, prop) => {
      if (!(prop in obj)) throw new Error(`${prop} is not a member of an object!`);
      return obj[prop];
    }
  });
};

/**
 * Simple queue implementation.
 * It can replace Promise.all
 * No concurrency, just one by one.
 *
 * Use it for multiple memory-hungry tasks
 */
function withQueue(ConstructorObject) {
  return class PromiseWithQueue extends ConstructorObject {
    static async queue(arr) {
      const result = [];
      for (let i = 0; i < arr.length; ++i) {
        // eslint-disable-next-line no-await-in-loop
        result.push(await arr[i]());
      }
      return result;
    }
  };
}

export const PromiseWithQueue = withQueue(Promise);


const defaultWebcamParams = {
  minPixels: 640 * 360,
  dest_height: 1600,
  dest_width: 1600,
  image_format: 'jpeg',
  jpeg_quality: 100,
  errorHandler: (err, msg, extendedMsg) => {
    if (extendedMsg) {
      mediator.publish('showHelp', extendedMsg);
    } else {
      mediator.publish('showFloatingText', {
        text: msg,
        isValid: false,
      });
    }
  },
};
/**
 * getWebcamParams:
 * fail-safe configuration for webcam.
 *
 *
 * @param  {Object} extendedParams  extra params
 * @return {Object}                 result params
 */
export const getWebcamParams = (extendedParams) => {
  return extendedParams
    ? {
      ...defaultWebcamParams,
      ...extendedParams,
    }
    : defaultWebcamParams;
};

/**
 * waitFor resolves when condition is met.
 * While waiting, global loader is shown
 *
 * @param  {Function or Promise} conditionFunc
 * @param  {Object} _options      Options
 * @param  {number} _options.interval
 * @param  {boolean} _options.showSpinner
 * @param  {number} _options.timeout   Timeout used when function is provided as the conditionFunc
 * @param  {number} _options.spinnerMessageText   Text displayed on the spinner
 * @return {Promise}               Resolves when condition is met, rejects on timeout
 */
export const waitFor = (conditionFunc, _options = {}) => {
  const options = {
    interval: 1000, // 1s
    showSpinner: true,
    timeout: 10000, // 10s
    spinnerMessageText: '',
    ..._options,
  };

  const startTime = new Date();
  let interval;

  if (options.showSpinner) {
    mediator.publish('GlobalLoader--toggle', true);
    mediator.publish('GlobalLoader--setMessage', options.spinnerMessageText);
  }

  const promise = is.isPromise(conditionFunc)
    ? conditionFunc
    : new Promise((resolve, reject) => {
      function pollingFunc() {
        const value = conditionFunc();

        if (value) return resolve(value);
        // eslint-disable-next-line prefer-promise-reject-errors
        if (Date.now() - startTime > options.timeout) return reject('TIMEOUT');
      }

      interval = setInterval(pollingFunc, options.interval);
      pollingFunc();
    });

  promise.finally(() => {
    clearInterval(interval);

    if (options.showSpinner) {
      mediator.publish('GlobalLoader--toggle', false);
      mediator.publish('GlobalLoader--setMessage', '');
    }
  });

  return promise;
};

/**
 * FPS class helps with logging function performance
 *
 * sample usage:
 *     const fps = new FPS();
 *     worker.onmessage = () => {
 *       fps.count();
 *     };
 *     // ...
 *     worker.terminate();
 *     fps.remove(); // stop logging
 */
export class FPS {
  calls = 0;
  prevCalls = 0;
  interval = 0;

  constructor(option) {
    this.callback = typeof option === 'function'
      ? option
      : console.log.bind(console, option || 'FPS:'); // eslint-disable-line no-console

    this.runTimer();
  }

  count() {
    ++this.calls;
  }

  get() {
    return this.prevCalls;
  }

  runTimer() {
    this.interval = setInterval(() => {
      this.callback(this.calls);
      this.prevCalls = this.calls;
      this.calls = 0;
    }, 1000);
  }

  remove() {
    clearInterval(this.interval);
  }
}

/**
 * Find the most common element in the array
 */
export const mostCommonElement = (arr, minLen = 1) => {
  if (arr.length < minLen) return null;

  const occurences = arr.reduce((acc, el) => {
    acc[el] = (acc[el] || 0) + 1;
    return acc;
  }, {});

  const mostCommon = Object.entries(occurences).sort((a, b) => b[1] - a[1])[0];

  return mostCommon[1] > 1
    ? mostCommon[0]
    : null; // if element occurs just once
};

/**
 * isValidPhoneNumber can be passed directly to the atom model as
 * a phone number validation function
 *
 * @param  {string} input
 * @return {Promise}       when fulfilled - valid, when rejected - invalid
 */
export const isValidPhoneNumber = (input) => {
  return new Promise(async (resolve, reject) => {
    await getScript(asset('lib/libphonenumber-js.min.js'), true);

    if (input.length) {
      const phone = input.startsWith('+') ? input : `+${input}`;

      if (!global.libphonenumber.isValidPhoneNumber(phone)) {
        return reject();
      }
    }

    return resolve(); // 0 length = valid (no error)
  });
}
