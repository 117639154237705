import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { asset } from 'helpers';
import { cacheParam } from 'sf/helpers';
import HeaderTags from 'layout/HeaderTags';
import Script from 'sf/components/Script';

const sentryCode =
  ENV === 'local'
    ? null
    : [
      <script src="https://cdn.ravenjs.com/3.9.1/raven.min.js" />,
      <Script>
        { `if (window.Raven) {
      Raven.config('${SENTRY_URL}').install();
    }` }
      </Script>,
    ];

const typeKitCode = `
  (function(d) {
    var config = {
      kitId: 'dyh0xeh',
      scriptTimeout: 3000,
      async: true
    },
    h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
  })(document);
`;

class Html extends Component {
  static propTypes = {
    children: PropTypes.string,
    description: PropTypes.string,
    entryPoint: PropTypes.string,
    hubspot: PropTypes.bool,
    tagManager: PropTypes.bool,
    title: PropTypes.string,
    typekit: PropTypes.bool,
    sentry: PropTypes.bool,
  };

  static defaultProps = {
    children: null,
    entryPoint: 'app',
    hubspot: true,
    tagManager: true,
    typekit: true,
    sentry: true,
  };

  render() {
    const {
      hubspot,
      tagManager,
      children,
      description,
      title,
      entryPoint,
      typekit,
      sentry,
    } = this.props;

    /* eslint-disable max-len */
    return (
      <html lang="en">
        <head>
          { /* <!-- Start of HubSpot Embed Code --> */ }
          { hubspot && (
            <script
              type="text/javascript"
              id="hs-script-loader"
              async={ true }
              defer={ true }
              src="//js.hs-scripts.com/21849861.js"
            />
          ) }
          { /* <!-- End of HubSpot Embed Code --> */ }
          <link
            rel="stylesheet"
            href={ cacheParam(asset`app-en.css`) }
            type="text/css"
          />
          { /* REDIRECT from ts.net to ts.ai - just a main page */ }
          <Script>
            {
              'if (window.location.hostname === "truststamp.net" && window.location.pathname === "/") { window.location.href = "https://truststamp.ai"; }'
            }
          </Script>
          <Script>
            {
              'if (window.location.pathname.substr(0, 7) === "/voting" ) { window.location = "/voting/index.html"; }'
            }
          </Script>
          { /* redirect for google consent form */ }
          <Script>
            {
              'if (window.location.pathname.substr(0, 13) === "/give_consent" ) { window.location = "https://forms.gle/MqNnbJcaLy94eK8bA"; }'
            }
          </Script>
          { /* Google Tag Manager */ }
          { tagManager && (
            <Script>
              {
                "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-5FQ668H');"
              }
            </Script>
          ) }

          { /* End Google Tag Manager */ }
          <HeaderTags
            description={ description }
            title={ title || '' }
          />
        </head>
        <body className={ `ts-Brand-${BRAND_NAME}` }>
          <div
            id="app"
            dangerouslySetInnerHTML={ { __html: children } }
          />
          { sentry && sentryCode }
          <script defer={ true } src={ asset`${entryPoint}-en.js` } />
          { typekit && <Script>{ typeKitCode }</Script> }
        </body>
      </html>
    );
    /* eslint-enable */
  }
}

export default Html;
