/* eslint-disable max-len */
import React, { Fragment } from 'react';
import BaseComponent from 'components/BaseComponent';

export default class ResourcesForm extends BaseComponent {
  className = 'ts-ResourcesForm';

  static defaultProps = {
    thankYou: false,
  }

  state = {
    formCompleted: false,
  };

  renderSubmittedMessage() {
    return (
      <Fragment>
        <p className={ this.rootcn`__success` }>Thank you for contacting us. Your message has been successfully sent. We will reply as soon as possible.</p>
      </Fragment>
    );
  }

  performSuccessRedirect = () => {
    this.setState({
      formCompleted: true,
    });
    // this.navigate(ROUTES.CCPA_THANK_YOU);
  }

  // form section
  renderFormSection() {
    if (this.state.isMessageSent || this.props.thankYou) {
      return (
        <div className={ this.cn`__confirmation` }>
          <h3
            className={ this.cn`__form__title` }
            ref={ (ref) => ref && scrollTo(ref, 250, 250) }
          >
            Thank you for contacting us.
          </h3>
          <p>
            Your message has been successfully sent. We will reply as soon as
            possible.
          </p>
        </div>
      );
    }

    const CCPAForm = require('./ContactForm');
    return (
      <CCPAForm onDone={ this.performSuccessRedirect } />
    );
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        { !this.state.formCompleted ? this.renderFormSection() : this.renderSubmittedMessage() }
      </div>
    );
  }
}
