import React, { Fragment } from 'react';
import BaseComponent from 'components/BaseComponent';
import VideoAndContent from 'components/VideoAndContent';
import { VIDEO_CONTENT } from './constant';

export default class Process extends BaseComponent {
  className = 'ts-Process';

  render() {
    return (
      <section className={ this.rootcn() }>
        <div className={ this.cn`__container ts-container` }>
          <VideoAndContent content={ VIDEO_CONTENT } />

        </div>
      </section>
    );
  }
}
