import atom from 'atom-js';
import sortBy from 'lodash/sortBy';
import { HELP_CENTER_NOTIFICATION_TYPES } from 'constants/help';

const ORDER_OFFSET = 10;

const NOTIFICATIONS_VALUE_MAP = {
  error: 10,
  success: 20,
  warning: 30,
  info: 40,
};

const model = atom.setup({
  methods: {},
})({
  notifications: [],
  isHelpVisible: false,
  showDemoButton: false,
});

Object.assign(model, {
  addNotification({
    title,
    type,
    value,
    order = 0,
    groupId = '',
    clearOnRouteChange = true,
  }) {
    if (!HELP_CENTER_NOTIFICATION_TYPES.includes(type)) {
      /* eslint-disable max-len, no-console */
      console.error(`Wrong notification type '${type}' supplied. Should be one of: ${HELP_CENTER_NOTIFICATION_TYPES.join(', ')}`);
      /* eslint-enable max-len, no-console */
      return;
    }

    if (ENV !== 'prod' && !value && !title) {
      /* eslint-disable max-len, no-console */
      console.error('[DEBUG] You need to provide either a value or title for the notification');
      /* eslint-enable */
    }

    const notifications = model.get('notifications');

    const duplicatedNotification = notifications.length && notifications.find(
      (notification) => {
        return type === notification.type &&
          title === notification.title &&
          (typeof notification.value !== 'string' || value === notification.value);
      }
    );

    const isHelpVisible = model.get('isHelpVisible')
      || ['error', 'success', 'warning'].includes(type);

    if (duplicatedNotification) {
      model.set({ isHelpVisible });
      return;
    }

    const doesNotifactionGroupExist = notifications.length && notifications.filter(
      (notification) => {
        return notification.groupId === groupId;
      },
    ).length;

    const existingNotifications = notifications.map((notification) => {
      const orderModifier = notification.clearOnRouteChange ? 1 : -1;
      notification.order += doesNotifactionGroupExist ? 0 : orderModifier * ORDER_OFFSET;
      return notification;
    });

    model.set({
      isHelpVisible: isHelpVisible,
      notifications: sortBy(
        [
          ...existingNotifications,
          {
            title,
            type,
            value,
            order,
            groupId,
            clearOnRouteChange,
          },
        ],
        [
          ({ type: notificationType }) => NOTIFICATIONS_VALUE_MAP[notificationType],
          'order',
          'title',
        ],
      ),
    });
  },

  clearNotifications(groupId) {
    const notifications = model.get('notifications');
    if (!groupId) {
      model.set({
        notifications: [
          ...notifications.filter((notification) => {
            return notification.clearOnRouteChange === false;
          }),
        ],
      });
    } else {
      model.set({
        notifications: [
          ...notifications.filter((notification) => {
            return notification.groupId !== groupId;
          }),
        ],
      });
    }
  },
  close() {
    model.set({ isHelpVisible: false });
  },
  moveTo(index) {
    model.set({ firstVisibleCardIndex: index });
  },
  open(groupId) {
    const notifications = model.get('notifications');
    const shouldOpenOn = groupId ? notifications.length &&
      notifications.findIndex(
        (notification) => notification.groupId === groupId
      ) : 0;

    model.set({
      isHelpVisible: true,
      firstVisibleCardIndex: shouldOpenOn,
    });
  },
  toggle(groupId) {
    if (!model.get('isHelpVisible')) {
      model.open(groupId);
    } else {
      model.close();
    }
  },
});

export default model;
