// libs
import React from 'react';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

// layout
import AdminPanelLayout from 'components/AdminPanel/layout/AdminPanelLayout';
import MainLayout from 'layout/MainLayout';
import HeaderTags from 'layout/HeaderTags';

// components
import BaseComponent from 'components/BaseComponent';
import FloatingText from 'sf/components/FloatingText';
import GlobalLoader from 'components/GlobalLoader';
import SessionAlert from 'components/SessionAlert';
import MediatorAlert from 'sf/components/MediatorAlert';

// models
import registration from 'models/registration';
import router from 'models/router';
import user from 'models/user';
import appState from 'models/appState';
import layout from 'models/layout';

// helpers
import { getQuery } from 'sf/helpers';

// redux
import { Provider } from 'react-redux';
import reduxStore from 'reduxStore';

export default class App extends BaseComponent {
  static propTypes = {
    children: PropTypes.node,
    route: PropTypes.object,
    params: PropTypes.object,
  };

  state = {};

  constructor(props) {
    super(props);
    router.set(props);
    this.syncStateWithModel(layout, ['layoutProps', 'customStyles']);
  }

  constructorBrowser() {
    const { redirectionSource } = getQuery();
    if (redirectionSource) {
      appState.set('redirectionSource', redirectionSource);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.location.pathname !== (router.get('location') || {}).pathname
    ) {
      this.publish('locationChange');
    }

    router.set(nextProps);
  }

  componentDidMount() {
    this.syncStateWithModel(user, ['isSignedIn', 'is_realtor']);
    this.syncStateWithModel(registration, ['isRealtor']);
  }


  /*
   * React router renders component inside root component (App), even if route is nested.
   * This method helps to create nested components tree.
   */
  getParentComponent(
    componentName = this.props.params.splat,
    parentRoute = this.props.route,
  ) {
    return parentRoute.childRoutes.find((route) => {
      if (route.childRoutes) {
        return this.getParentComponent(componentName, route);
      }

      return route.path === componentName;
    });
  }

  renderChildren() {
    const parentComponentRoute = this.getParentComponent();
    const ParentComponent = parentComponentRoute
      ? parentComponentRoute.component
      : null;
    if (ParentComponent) {
      return <ParentComponent>{ this.props.children }</ParentComponent>;
    }
    return this.props.children;
  }

  getRouteLayout() {
    let currentRoute =
      this.props.route &&
      this.props.route.childRoutes.find(
        (route) => route.path === this.props.location.pathname,
      );

    if (!currentRoute && this.props.routes) {
      currentRoute = this.props.routes && this.props.routes.pop();
    }

    return currentRoute && currentRoute.layout;
  }

  render() {
    /* eslint-disable no-console */
    const routeLayout = this.getRouteLayout();
    console.assert(
      _get(this.props, 'children.props.route'),
      "react-router children don't have an expected structure",
    );
    /* eslint-enable */
    const { route } = this.props.children.props;
    const {
      route: { className, reportProblem },
    } = this.props;
    const layoutProps = {
      ...this.props.layoutProps,
      ...this.state.layoutProps,
      ...route.layoutProps,
    };
    const contentTheme = route.contentTheme || null;
    const { footerTheme } = route;
    const headerSize = route.headerSize || null;
    const headerTheme = route.headerTheme || null;
    const headerVersion = route.headerVersion || null;
    const isHeaderFloating = route.isHeaderFloating || false;
    const mainClassName = this.cn(
      route.className || className,
      this.state.customStyles && 'custom-brand',
    );
    const mainReportProblem =
      route.reportProblem === false ? false : reportProblem;
    const { showFooterLogo } = route;
    return (
      <Provider store={ reduxStore }>
        { routeLayout === 'AdminPanel' ? (
          <AdminPanelLayout>{ this.renderChildren() }</AdminPanelLayout>
        ) : (
          <MainLayout
            { ...layoutProps }
            brandingName={ BRAND_NAME }
            className={ mainClassName }
            contentTheme={ contentTheme }
            footerTheme={ footerTheme }
            headerSize={ headerSize }
            headerTheme={ headerTheme }
            headerVersion={ headerVersion }
            isHeaderFloating={ isHeaderFloating }
            reportProblem={ mainReportProblem }
            showFooterLogo={ showFooterLogo }
          >
            <HeaderTags
              styleAddOn={ this.state.customStyles }
              helmetized={ true }
            />
            <FloatingText
              ref={ (ref) => {
                this.floatingTextRef = ref;
              } }
            />
            <SessionAlert />
            <div className="ts-container">
              { ['error', 'warning', 'info', 'success'].map((topic) => (
                <MediatorAlert
                  key={ topic }
                  listenTopic={ topic }
                  closeTopic={ ['locationChange', 'photoTaken'] }
                  theme={ topic }
                />
              )) }
            </div>
            <GlobalLoader />
            { this.renderChildren() }
          </MainLayout>
        ) }
      </Provider>
    );
  }
}
