import React from 'react';
import BasePage from 'pages/BasePage';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import { ROUTES } from 'constants';
import AppStoreBadges from 'sf/components/AppStoreBadges';

export default class CorporateSectionComponent extends BasePage {
  className = 'ts-CorporateIndustries';

  static propTypes = {
    data: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
    ]).isRequired,
  };

  render() {
    const { data, sectionClass } = this.props;
    const className = (prefix, modifier) => {
      return {
        [`${prefix}`]: true,
        [`${prefix}--${modifier}`]: modifier,
      };
    };
    return (
      <section className={ this.cn(className('__section', sectionClass)) }>
        { data.map(({
          slug,
          title,
          text,
          image,
          pictureClass,
          textClass,
          isContactButtonShowed,
          contactBtnText,
          appstoreBadges,
          additionalButton,
          linkToFollowText,
        }) => (
          <div key={ slug } className={ this.cn`__section__container` }>
            <div className={ this.cn(className('__section__text-container', textClass)) }>
              <h2 className={ this.cn`__section__title` }>
                { title }
              </h2>
              { typeof text === 'string' ? (
                <p className={ this.cn`__section__paragraph` }>
                  { text }
                  { linkToFollowText && <i><br />{ linkToFollowText }</i> }
                </p>
              ) : <p className={ this.cn`__section__paragraph` }> { text }</p> }
              { isContactButtonShowed &&
                <Button
                  onClick={ () => this.navigate(ROUTES.CONTACT_US) }
                  className={ this.cn`__section__button` }
                >
                  { contactBtnText ? contactBtnText : 'Contact us' }
                </Button> }
              { appstoreBadges &&
                <AppStoreBadges badges={ appstoreBadges } /> }
              { additionalButton &&
                <Button
                  onClick={ () => window.open(additionalButton.href) }
                  className={ this.cn`__section__button` }
                >
                  { additionalButton.caption }
                </Button> }
            </div>
            <div className={
              this.cn(className('__section__picture-container', pictureClass)) }
            >
              <img
                alt=""
                src={ image }
                className={ this.cn`__section__picture` }
              />
            </div>
          </div>
        )) }
      </section>
    );
  }
}
