import React from 'react';
import PropTypes from 'prop-types';

import { ROUTES } from 'constants';
import BasePage from 'pages/BasePage';
import { scrollTo } from 'sf/helpers/domHelper';
import { getQuery } from 'sf/helpers';
import MiniInternalForm from './MiniInternalForm';

export default class SharedMiniContactUs extends BasePage {
  className = 'ts-CorporateContactUsAi';

  static propTypes = {
    thankYou: PropTypes.bool,
  };

  static defaultProps = {
    thankYou: false,
  };

  state = {};

  performSuccessRedirect = () => {
    try {
      parent.postMessage('contact_form_success', '*');
    } catch (e) {
      console.log(e); // eslint-disable-line no-console
    }

    this.navigate(ROUTES.SHARED_CONTACT_US_THANK_YOU);
  };

  componentDidMount() {
    const { source } = getQuery();

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      source,
    });

    const resizeHandler = () => {
      try {
        parent.postMessage({
          height: document.documentElement.scrollHeight,
          width: document.documentElement.scrollWidth,
        }, '*');
      } catch (e) {
        console.log(e); // eslint-disable-line no-console
      }
    };
    this.addEventListener(window, 'resize', resizeHandler);
    resizeHandler();
  }

  renderForm = () => {
    if (this.props.thankYou) {
      return (
        <div>
          <h3
            className={ this.cn`__form__title` }
            ref={ (ref) => ref && scrollTo(ref, 250, 250) }
          >
            Thank you for contacting us.
          </h3>
          <p>
            Your message has been successfully sent. We will reply as soon as
            possible.
          </p>
        </div>
      );
    }

    return (
      <div className={ this.cn`__form-container` }>
        <MiniInternalForm onDone={ this.performSuccessRedirect } source={ this.state.source } />
      </div>
    );
  };

  renderFormSection() {
    return (
      <section className={ this.cn`__form__section __mini-form` }>
        { this.renderForm() }
      </section>
    );
  }

  render() {
    const { source } = this.state;
    const sourceRequiredError = !source || source === 'PROJECT_NAME_HERE';

    if (!this.props.thankYou && sourceRequiredError) {
      return (
        <div className={ this.rootcn() }>
          <strong>source</strong> is required.
        </div>
      );
    }

    return (
      <div className={ this.rootcn('__shared') }>
        { this.renderFormSection() }
      </div>
    );
  }
}
