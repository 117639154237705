/* eslint-disable max-len */
import React, { Fragment } from 'react';
import { Link } from 'react-router';
import { ROUTES } from 'constants';


export const USE_CASES_TITLE = 'Stable IT2 Sample Use Cases';
export const USE_CASES = [
  {
    id: 1,
    title: 'Financial Institutions & Digital Wallets',
    text: 'Enhances security for digital transactions, ensuring compliance with regulatory standards like PSD2 and GDPR while improving user experience.',
    slug: 'financial_wallet',
  },
  {
    id: 2,
    title: 'Identity and Access Management',
    text: 'Provides a secure and seamless alternative to one-time passcodes and traditional MFA methods, significantly reducing the risk of phishing, SIM-swapping, and credential-stuffing attacks.',
    slug: 'multiple-checks-2',
  },
  {
    id: 3,
    title: 'Enhanced KYC Processes',
    text: 'Facilitates secure identity verification through cryptographic anchors generated during enrollment, enhancing trust and compliance in online transactions.',
    slug: 'authenticate-2',
  },
];

export const BIOMFA_TITLE = 'Say NO to OTP';

export const BIOMFA = [
  {
    id: 1,
    title: 'Multi-factor authentication In two steps',
    text: 'Add a low-friction, secure, and privacy-positive second and third authentication factor into your applications/user flows with just one API',
    slug: 'Multi-factor',
  },
  {
    id: 2,
    title: 'Simplified user experience',
    text: 'With cohesive branding across touchpoints, there is no need for third-party authenticator apps and the fragmented experience of switching between platforms to access your accounts',
    slug: 'Simplified',
  },
  {
    id: 3,
    title: 'Unmatched security and fraud-detection',
    text: 'Advanced liveness verification defends against even the most sophisticated spoofing attacks and fraud techniques',
    slug: 'authenticate',
  },
];

export const HOWITWORKS_TITLE = 'MFA in seconds';

export const HOWITWORKS = [
  {
    id: 1,
    title: '1. Accurate and efficient biometric analysis',
    text: <Fragment><Link to={ ROUTES.CAPTURE }>Trust Stamp Capture</Link> leverages advanced biometric analysis with proof-of-life and anti-spoofing tests that determine whether captured data belongs to a real person</Fragment>,
    slug: 'pol',
  },
  {
    id: 2,
    title: '2. Data security layered throughout',
    text: <Fragment>With <Link to={ ROUTES.PROTECT }>Trust Stamp Protect</Link>, transformation algorithms minimize and obfuscate raw information, turning PII into an anonymized representation that serves as an accurate identifier within your systems, but has no external value if ever compromised</Fragment>,
    slug: 'data-security',
  },
  {
    id: 3,
    title: '3. Instant, frictionless authentication',
    text: 'Trust Stamp Biometric MFATM performs a rapid probabilistic 1:1 comparison between the Irreversibly Transformed Identity Token (IT2) generated on enrollment and the IT2 created during authentication for frictionless, protected proofing in seconds',
    slug: 'frictionless-authentication',
  },
];

/* eslint-disable max-len */
export const VIDEO_CONTENT = {
  id: 1,
  subTitle: (<Fragment>BIOMETRIC MFA<sup>TM</sup></Fragment>),
  title: (<Fragment>Trust Stamp’s Biometric MFA<sup>TM</sup> replaces vulnerable one-time passcodes</Fragment>),
  text: 'Build a robust multi-factor authentication framework secured by advanced biometric tokenization technologies, at an unbeatable price point, with unmatched efficiency at every scale.',
  video: 'https://player.vimeo.com/video/697356576',
};

export const BANNER_CONTENT = [
  {
    id: 1,
    title: 'No more one-time passcodes',
    slug: 'close',
    list: [
      'SMS 6-Digit Codes',
      'Email Codes',
      'Authenticator Apps',
      'Hardware Tokens',
      'Segmented User Flows',
    ],
  },
  {
    id: 2,
    title: 'Increased',
    slug: 'tick',
    list: [
      'Protection - World-class biometric security',
      'Simplicity -  It’s just a selfie',
      'Fraud prevention without the costly verification processes',
    ],
  },
];

