import atom from 'atom-js';
import { get, errorHandler } from 'sf/helpers/request';
import { mediator } from 'sf/helpers';

const model = atom({
  captchaUrl: 'backend/contactus/',
  language: 'en',
  showLoading: true,
  captchaToken: '',
});

model.provide('sitekey', async (done) => {
  const endOptions = { disableMediator: !model.get('showLoading') };

  try {
    const { body } = await get(model.get('captchaUrl'), null, endOptions);

    done(body.data.recaptcha_sitekey);
  } catch ({ err, res }) {
    errorHandler(err, res);
  }
});

model.resetCaptcha = async () => {
  if (global.grecaptcha) {
    model.set({ captchaToken: '' });

    try {
      global.grecaptcha.reset();
    } catch (e) {
      // Uncaught Error: No reCAPTCHA clients exist.
    }
  }
};

model.onExpired = () => {
  model.set({
    captchaToken: '',
  });
};

model.executeProgrammatically = () => {
  // NOTE: for invisible recaptcha v2
  if (global.grecaptcha) {
    global.grecaptcha.execute();
  }
};

model.waitForCaptchaTokenReady = async () => {
  const token = model.get('captchaToken');
  if (token) {
    mediator.publish('GlobalLoader--toggle', false);
    return token;
  }
  mediator.publish('GlobalLoader--toggle', true);

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(model.waitForCaptchaTokenReady());
    }, 500);
  });
};

export default model;
