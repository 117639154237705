const React = require('react');
const PropTypes = require('prop-types');
const atom = require('atom-js');

const BaseComponent = require('sf/components/BaseComponent');

class FlashLightButton extends BaseComponent {

	className = 'ts-FlashLightButton';

	static propTypes = {
		webcam: PropTypes.object.isRequired,
		model: PropTypes.object.isRequired,
		cssPrefix: PropTypes.string.isRequired,
		use_ImageCapture: PropTypes.bool,
		use_ImageCapture_flashlight: PropTypes.bool,
	};

	static defaultProps = {};

	state = {
		flashLightEnabled: false,
	};

	handleClick = () => {
		const { webcam } = this.props;
		const track = webcam.getVideo().srcObject.getVideoTracks()[0];
		const flashLightEnabledNow = !this.state.flashLightEnabled;
		track.applyConstraints({
			advanced: [{ torch: flashLightEnabledNow }]
		});
		this.setState({ flashLightEnabled: flashLightEnabledNow });
	}

	render(props) {
		const { use_ImageCapture, photoCapabilities, use_ImageCapture_flashlight } = props;
		const { flashLightEnabled } = this.state;

		if (
			!props.userMedia
			|| !props.live
			|| !use_ImageCapture
			|| !use_ImageCapture_flashlight
			|| !photoCapabilities
			|| !photoCapabilities.fillLightMode
			|| !photoCapabilities.fillLightMode.includes('flash')
		) {
			return null;
		}

		const cn = this.rootcn`${this.props.cssPrefix}__flash-light-button`;

		return (
			<button
				disabled={ !this.props.live }
				className={ `${cn} ${cn}--${flashLightEnabled ? 'enabled' : 'disabled'}` }
				type="button"
				onClick={ this.handleClick }
			>
				{ this.props.flash_light_node }
			</button>
		);
	}
}

module.exports = atom.reactConnect('model', [
	'cssPrefix',
	'flash_light_node',
	'live',
	'photoCapabilities',
	'use_ImageCapture',
	'use_ImageCapture_flashlight',
	'userMedia',
])(FlashLightButton);
