import { ROUTES } from 'constants';

/* eslint-disable max-len */
export const CONTENT = [
  {
    id: 1,
    title: 'Proven Solutions',
    slug: 'award',
    content: {
      text: 'Our secure identity solutions are trusted by leading financial institutions and organisations of all sizes across areas of regulatory compliance, government initiatives, real estate, communications, and humanitarian services.',
      // button: 'Products',
      slug: 'proven-solutions.png',
      // href: ROUTES.IDENTITY_TOOLS,
    },
  },
  {
    id: 2,
    title: 'Innovative R&D',
    slug: 'edit',
    content: {
      text: 'Trust Stamp tackles global challenges in identity with patented transformation algorithms, deep neural networks and other leading-edge AI.',
      // button: 'Solutions',
      slug: 'palm.png',
      // href: ROUTES.IDENTITY_TOOLS,
    },
  },
  {
    id: 3,
    title: 'Focus on Inclusion',
    slug: 'user-check',
    content: {
      text: 'With device and vendor-agnostic technology that can be leveraged to utilise any biometric or other form of sensitive data for secure, privacy-protected identification in online and offline environments, our solutions are designed to work for anyone, anywhere in the world.',
      button: 'Emerging Markets',
      slug: 'inclusion.jpg',
      href: 'https://www.aiid.co/',
      externalLink: true,
    },
  },
  {
    id: 4,
    title: 'Global, Cross-Industry Expertise',
    slug: 'globe',
    content: {
      text: 'Our cross-disciplinary team has strong technical expertise in data, AI, biometrics, machine learning, and maths, along with extensive experience in leadership, management, sales, and corporate development.',
      button: 'Team',
      slug: 'research.svg',
      href: ROUTES.TEAM,
    },
  },
  {
    id: 5,
    title: 'Powerful Partnerships',
    slug: 'message-circle',
    content: {
      text: 'Trust Stamp works with leading innovators in biometrics, artificial intelligence, identity and finance to develop transformative solutions that meet industry needs.',
      button: 'News',
      slug: 'partnerships.svg',
      href: ROUTES.NEWS,
    },
  },
];
