export const FEEDBACKS = {
  documentFound: {
    true: 'ID is visible',
    false: 'ID is not visible',
  },
  faceInDocument: {
    true: 'Face in document detected',
    false: 'No face in document detected',
  },
  noBlurInDoc: {
    true: 'Details are clear',
    false: 'Details are not clear',
  },
  noNoiseInDoc: {
    true: 'Document has no noise',
    false: 'Document has noise',
  }
};

export const CAPTURE_MODE_MANUAL = 'manual';
export const CAPTURE_MODE_AUTO = 'auto';
