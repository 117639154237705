import React from 'react';
import BasePage from 'pages/BasePage';

export default class HeroComponent extends BasePage {
  className = 'ts-CorporateIndustries';

  render() {
    return (
      <section className={ this.cn`__hero __hero--${this.props.extraClass}` }>
        <div className={ this.cn`__hero-color` } />
        <div className={ this.cn`__hero__container` }>
          <h1 className={ this.cn`__hero__title` }>
            { this.props.title }
          </h1>
        </div>
      </section>
    );
  }
}
