/* eslint-disable max-len */
/* global YEAR_CONF */
import React from 'react';
import { Link } from 'react-router';
import BaseComponent from 'components/BaseComponent';
import { asset } from 'sf/helpers';
import Icon from 'sf/components/Icon';
import { BRAND_URL } from '../../constants';
import { TOP_LINKS, BOTTOM_LINKS, SOCIAL_LINKS } from './constants';

export default class Footer extends BaseComponent {
  className = 'ts-Footer';

  render() {
    return (
      <footer className={ this.rootcn() }>
        <div className="ts-container">

          { /* logo */ }
          <div className={ this.cn`__content--logo` }>
            <Link href={ BRAND_URL } rel="noopener noreferrer">
              <img src={ asset`/ai/logo-footer.png` } alt="PixelPin" width="55" />
            </Link>
          </div>

          { /* links */ }
          <div className={ this.cn`__content--left` }>
            <ul>
              { TOP_LINKS.map(({ id, name, url }) => (
                <li key={ id }>
                  <Link href={ `${BRAND_URL}${url}` } rel="noopener noreferrer">{ name }</Link>
                </li>
              )) }
            </ul>

            <ul>
              { BOTTOM_LINKS.map(({ id, name, url }) => (
                <li key={ id }>
                  <Link href={ `${BRAND_URL}${url}` } rel="noopener noreferrer">{ name }</Link>
                </li>
              )) }
            </ul>

            <p>&copy; { YEAR_CONF }, Trust Stamp Malta Limited. Various aspects of PixelPin are protected by Patent, Trademark and Copyright.</p>
          </div>

          { /* social media links */ }
          <div className={ this.cn`__content--right` }>
            <ul>
              { SOCIAL_LINKS.map(({ id, slug, url }) => (
                <li key={ id }>
                  <Link href={ url } rel="noopener noreferrer" target="_blank">
                    <Icon set="fa" size={ 20 } color="#ffffff" type={ slug } />
                  </Link>
                </li>
              )) }
            </ul>
          </div>

        </div>
      </footer>
    );
  }
}
