export const TOP_LINKS = [
  {
    id: 1,
    name: 'Business',
    url: '/business/',
  },
  {
    id: 2,
    name: 'Consumers',
    url: '/consumers/',
  },
  {
    id: 3,
    name: 'Developers',
    url: '/developers/',
  },
  {
    id: 4,
    name: 'About Us',
    url: '/about-us/',
  },
  {
    id: 5,
    name: 'Blog',
    url: '/press-blog/',
  },
];

export const BOTTOM_LINKS = [
  {
    id: 1,
    name: 'Terms and Conditions',
    url: '/terms/',
  },
  {
    id: 2,
    name: 'Privacy Policy',
    url: '/privacy-policy/',
  },
];

export const SOCIAL_LINKS = [
  {
    id: 1,
    slug: 'linkedin',
    url: 'https://www.linkedin.com/company/pixelpin-ltd/',
  },
  {
    id: 2,
    slug: 'twitter',
    url: 'https://twitter.com/PixelPin',
  },
  {
    id: 3,
    slug: 'instagram',
    url: 'https://www.instagram.com/pixelpin/',
  },
  {
    id: 4,
    slug: 'facebook',
    url: 'https://www.facebook.com/PixelPin/',
  },
];
