import { ROUTES } from 'constants';

/* eslint-disable max-len */
export const PRODUCTS = [
  {
    id: 1,
    title: 'AiiD',
    text: 'A Public Benefit Corporation focused on delivering technology to transform service delivery and empower individuals living in poverty',
    button: 'More About AiiD',
    href: 'https://www.aiid.co/',
    slug: 'aiid-logo',
    className: 'aiid',
  },
  {
    id: 2,
    title: 'SaaS Onboarding Tools',
    text: 'Secure, low-cost onboarding tools that empower businesses of all sizes to keep up with the rapid digital transformation',
    button: 'Demo',
    href: `${ROUTES.CONTACT_US}?subject=SaaS-Onboarding-Tools-Demo`,
    className: 'saas',
  },
  {
    id: 3,
    title: 'Trusted Message',
    text: 'Easily and securely transmit private messages, ensuring that only the trusted recipient can read the message.',
    button: 'Free Trial',
    href: 'https://trustedmail.pro/',
    slug: 'trusted-message-logo',
    className: 'trustedmail',
  },
  // {
  //   id: 4,
  //   title: 'Safe14',
  //   text: 'User-enabled location reporting built on privacy and trust',
  //   button: 'Visit the Site',
  //   href: 'https://safe14.com/',
  //   slug: 'safe14-logo',
  //   className: 'safe14',
  // },
  {
    id: 4,
    title: 'Trusted Check-In',
    text: 'The smartphone-based alternative-to-detention solution that provides accountability without the cost, stigma, and upkeep of traditional solutions',
    button: 'Learn More',
    href: 'https://trustedcheck-in.truststamp.ai',
    slug: 'TCI-logo-color-vector',
    className: 'Trusted-Check-In',
  },
  {
    id: 5,
    title: 'Propertymark Passport',
    text: 'A tool that enables Estate Agents to better verify customers\' identities for regulatory compliance in partnership with NAEA Propertymark',
    button: 'Register',
    href: 'https://propertymark.pro/index.html',
    className: 'propertymark',
  },
];
