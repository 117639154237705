/* eslint-disable max-len */

export const CONTENT = [
  {
    id: 1,
    title: '1. Creating identity',
    content: 'Trust Stamp\'s radical innovations in biometrics and data protection empower people to access more with identity that can be safely shared and stored.',
    slug: 'opportunity',
  },
  {
    id: 2,
    title: '2. Powering transactions that enfranchise all parties involved',
    content: 'When our identity technology is used to open a bank account or access a public service, both the individual and organisation achieve higher levels of protection and confidence in the security of their data and the integrity of their systems. Trust Stamp\'s solutions elevate all sides of an identity system.',
    slug: 'privacy',
  },
  {
    id: 3,
    title: '3. Mitigating risk to identity data',
    content: 'Our technology limits the potential for misuse of identity data and damage due to data loss.',
    slug: 'security',
  },
];
