// NOTE: import `sf` as your first dependency, so polyfills are loaded

require('./polyfills/required');
const { HELP_DATA_FORM_VALIDATION_FAIL } = require('sf/messages');
const { mediator } = require('sf/helpers');

const config = {
  SpinnerComponent: null, // pass custom `SpinnerComponent` to replace sf/components/Spinner
  assetsURL: '/', // URL for assets. CDN or application path
  backendURL: '',

  /**
   * errorHandler is meant for generic errors, that are NOT handled by developer.
   * Most of the errors should be handled by endUserErrorHandler.
   */
  errorHandler: function (err, res) {
    try {
      const message = res.body && (res.body.data || res.body.status_message);

      if (message) {
        const validation = res.body && res.body.data && res.body.data.validation;
        if (validation) {
          mediator.publish('showHelp', HELP_DATA_FORM_VALIDATION_FAIL({
            validation,
          }));
          return;
        }

        mediator.publish('showFloatingText', {
          text: message,
          isValid: false,
        });
      } else {
        throw new Error(res);
      }
    } catch (e) {
      mediator.publish('showFloatingText', {
        text: err.toString(),
        isValid: false,
      });
    }
  },
};

export const getConfig = (key) => key === undefined ? config : config[key];
export const configure = (newConfig) => {
  Object.assign(config, newConfig);
};

export default {
  getConfig,
  configure,
};
