/* eslint-disable max-len */
import { ROUTES } from 'constants';

export const TITLE = 'Efficient, tailored solutions that last';

export const CONTENT = [
  {
    id: 1,
    title: 'Enhanced Fraud Detection',
    text: 'Effectively catch individuals using fake or synthetic IDs through our powerful selfie challenge, which detects the same person behind different identities and transactions',
    slug: 'detect-fraud',
    href: ROUTES.CONTACT_US,
    buttonLabel: 'Learn More',
  },
  {
    id: 2,
    title: 'Secure Customer Verification',
    text: 'Even if a fraudster spoofs an ID to match their face, our technology can identify the individual, ensuring that only genuine customers access banking services.',
    slug: 'efficiency-girl',
    href: ROUTES.WHITEPAPER,
    buttonLabel: 'Technical Whitepaper',
  },
  {
    id: 3,
    title: 'Reduction in Financial Losses',
    text: 'Protect your institution from complex fraud schemes and rings, preserving your financial integrity and customer trust.',
    slug: 'adjust',
  }
];

export const VIDEO_CONTENT = {
  id: 1,
  title: 'Proven Success Against Fraud Rings',
  text: 'Our technology has been instrumental in dismantling multiple fraud rings, saving our clients from significant financial losses and reputational damage. Trust Stamp’s biometric deduplication stands as a testament to innovative fraud prevention in the banking sector.',
  video: 'https://player.vimeo.com/video/456577950',
};
