/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';

export default class Resources extends BaseComponent {
  className = 'ts-Resources';

  static propTypes = {
    children: PropTypes.node,
    singleLayout: PropTypes.bool,
    form: PropTypes.bool, // if layout includes form
  };

  static defaultProps = {
    children: '',
    singleLayout: false,
    formLayout: false,
  };

  render() {
    const { singleLayout, formLayout } = this.props;
    return (
      <section className={ this.rootcn({
        '__layout--single': singleLayout,
        '__layout--form': formLayout,
      }) }
      >
        <div className="ts-container">
          { this.props.children }
        </div>
      </section>
    );
  }
}
