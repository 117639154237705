import React, { Fragment } from 'react';

export const HASH_CARDS = [
  /* eslint-disable max-len */
  {
    title: 'What',
    slug: 'icon-evergreen-hash',
    description: 'Irreversible, hashed-tokenized identities',
  },
  {
    title: 'Why',
    slug: 'icon-security',
    description: 'The effectiveness of all identity data is maximized when it is rendered safe to use, store, and share.',
  },
  {
    title: 'How',
    slug: 'icon-neural',
    description: <Fragment>Deep neural networks convert any identifying data into a unique, non-PII IT<sup>2</sup></Fragment>,
  },
  /* eslint-enable */
];

export const LAKE_CARDS = [
  /* eslint-disable max-len */
  {
    title: 'What',
    slug: 'icon-identity-proof',
    description: 'Secure, interoperable identity proofing',
  },
  {
    title: 'Why',
    slug: 'icon-silo',
    description: 'Zero-knowledge authentication provides security beyond that of siloed, legacy authentication systems that are prone to sensitive data leakage and fraud',
  },
  {
    title: 'How',
    slug: 'icon-probabilistic',
    description: <Fragment>Probabilistic AI predicts whether multiple IT<sup>2</sup> came from the same face, without the need to store or share sensitive information.</Fragment>,
  },
  /* eslint-enable */
];

export const USE_CASES1 = [
  {
    title: 'Security',
    subItems: [
      'The hashing process is irreversible',
      <Fragment>The IT<sup>2</sup> is pseudonymized data</Fragment>,
      'No biometric images or templates are stored',
    ],
  },
  {
    title: 'Interoperability',
    subItems: [
      // eslint-disable-next-line max-len
      <Fragment>The identifying data that is converted into the IT<sup>2</sup> can be updated over time</Fragment>,
      // eslint-disable-next-line max-len
      <Fragment>The IT<sup>2</sup> architecture is compatible with any biometric modality</Fragment>,
      // eslint-disable-next-line max-len
      <Fragment>The IT<sup>2</sup> can act as a pivot to external data that can be changed to reflect changes in data needs and access</Fragment>,
    ],
  },
];

export const USE_CASES2 = [
  {
    title: 'Security',
    subItems: [
      // eslint-disable-next-line max-len
      <Fragment>Each “identity” in the Lake is meaningless in absence of a matching IT<sup>2</sup>. Data that is not there can not be leaked</Fragment>,
    ],
  },
  {
    title: 'Interoperability',
    subItems: [
      // eslint-disable-next-line max-len
      <Fragment>All IT<sup>2</sup> within a modality are comparable regardless of origin</Fragment>,
      // eslint-disable-next-line max-len
      'Private Lake partners can benefit from shared access while protecting proprietary intelligence by submitting zero-knowledge-proof queries to other Lakes within a Consortium',
    ],
  },
];

export const PROCESS_CARDS1 = [
  /* eslint-disable max-len */
  {
    title: '1. Capture data',
    slug: 'icon-capture',
    excerpt: 'In existing use cases, a “secure-selfie” video is taken and tested for liveness',
    description: <Fragment>Whatever the source of identity data, it can be transformed and compared as an IT<sup>2</sup>. We can even fuse multiple modalities of biometrics (face, palm, finger, iris, voice, gait, and beyond) into a single IT<sup>2</sup>-identity</Fragment>,
  },
  {
    title: '2. Transform Data',
    slug: 'icon-transform',
    excerpt: <Fragment>Data is irreversibly transformed with no biometric templates stored, thus achieving Trusted Presence<sup>&trade;</sup>.</Fragment>,
    description: '',
  },
  {
    title: '3. Associate external data via pivot points',
    slug: 'icon-pivots',
    excerpt: <Fragment>Link the pseudonymized IT<sup>2</sup> to any desired data (e.g.: username) to facilitate one-to-one matching for future authentication</Fragment>,
    description: <Fragment>Existing use cases link the IT<sup>2</sup> to users’ digital bank login credentials, event-pass registration information, and even transactional history in cases where the IT<sup>2</sup> is used to establish the first form of legal identity</Fragment>,
  },
  /* eslint-enable */
];

export const PROCESS_CARDS2 = [
  /* eslint-disable max-len */
  {
    title: <Fragment>1. Create IT<sup>2</sup></Fragment>,
    slug: 'icon-evergreen-hash',
    excerpt: 'Irreversibly transform identity data and add external pivot points',
    description: '',
  },
  {
    title: '2. Store and de-duplicate in the Lake',
    slug: 'icon-lake-match',
    excerpt: <Fragment>Match the IT<sup>2</sup> against all others in the Lake to determine fraudulent identities and authenticate existing users</Fragment>,
    description: 'A bad actor with multiple synthetic identities can not exist in the Lake, as conflicting credentials tied to the same face are instantly flagged for fraud through de-duplication',
  },
  {
    title: '3. Match against the Consortium',
    slug: 'icon-consortium',
    excerpt: <Fragment>Match the IT<sup>2</sup> against other Lakes to maximize risk-prevention and streamline the customer experience across partner entities</Fragment>,
    description: (
      <Fragment>
        <p>Each Consortium is established on participant-driven parameters</p>
        <p>Zero-Knowledge-Proof protocols allow participants to achieve inter-institutional identity de-duplication without exposing PII or competitive data</p>
        <p>Eg: A bad actor that attempts to distribute synthetic identities across multiple banks in a regional Consortium will still be flagged, benefiting all participants without sharing competitive information</p>
      </Fragment>
    ),
  },
  /* eslint-enable */
];
