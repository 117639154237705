/* global CAPTCHA_SITE_KEY */

import React from 'react';
import PropTypes from 'prop-types';
import at from 'lodash/at';

import Button from 'components/Button';
import Select from 'sf/components/Select';
import ValidationTextArea from 'components/ValidationTextarea';
import { asset } from 'helpers';
import contact from 'models/contact';
import user from 'models/user';
import BasePage from 'pages/BasePage';
import ValidationInput from 'sf/components/ValidationInput';
import PhoneNumberInput from 'sf/components/PhoneNumberInput';
import world from 'sf/models/world';

import Captcha from 'sf/components/Captcha';
import captchaModel from 'sf/components/Captcha/model';
import onFullRender from 'sf/hoc/OnFullRender';

const REASONS = [
  'Biometric MFA',
  'Biometric Identity Proofing',
  'Biometric Duplicate Detection',
  'Trusted Check-In',
  'PII Tokenization',
  'Partnership with Trust Stamp',
  'Investor Questions',
  'Press',
  'Other',
].map((label) => ({ label, value: label }));

class InternalContactForm extends BasePage {
  className = 'ts-CorporateContactUsAi';

  static propTypes = {
    onDone: PropTypes.func.isRequired,
    source: PropTypes.string,
    // Contact form is sent to [sales@truststamp.net, support@truststamp.net,
    // social@truststamp.net, privacy@truststamp.net]
    recipient: PropTypes.oneOf(['sales', 'support', 'social', 'privacy']),
  };

  static defaultProps = {
    source: 'Truststamp.ai',
    recipient: 'sales',
  };

  state = {};

  componentDidMount() {
    contact.set({
      email: user.get('email'),
      first_name: user.get('legal_name'),
    });

    this.syncStateWithModel(contact, [
      'first_name',
      'last_name',
      'title',
      'company_name',
      'email',
      'country',
      'phone',
      'reason',
      'message',
    ]);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    Button.setProcessing(this.submitButton);
    const hideProcessing = () => {
      Button.setProcessing(this.submitButton, false);
    };

    this.formValidation(contact, 'form.')
      .then(() => {
        const done = () => {
          this.props.onDone();
          contact.clear();
        };
        const fail = (err = {}) => {
          if (Object.keys(err.validation || {}).length) {
            this.formValidation(Promise.reject(err.validation));
          }
          console.log(err); // eslint-disable-line no-console
          this.publish('showFloatingText', {
            text: 'Error sending message. Please try again.',
            isValid: false,
          });
        };

        contact
          .sendHubspotMessage(this.props.recipient, {
            includeDebugInfo: false,
            captchaResponse: this.state.captchaResponse,
            source: this.props.source,
          })
          .then(done, fail);
      })
      .finally(hideProcessing);
  };

  resetCaptcha() {
    // reset captcha when validation fails to fix TRST-757
    this.handleCaptchaVerification(null);
    captchaModel.resetCaptcha();
  }

  handleCaptchaVerification = (response) => {
    this.setState({
      captchaResponse: response,
    });
  };

  renderCaptcha() {
    return (
      <div className={ this.cn`__captcha-container` }>
        <Captcha
          showLoading={ false }
          delayedStartTime={ 10000 }
          verificationCallback={ this.handleCaptchaVerification }
          sitekey={ CAPTCHA_SITE_KEY }
          language="en"
          onExpired={ () => {
            this.handleCaptchaVerification(null);
          } }
        />
      </div>
    );
  }

  render() {
    const isFormValid = at(this.state, [
      'first_name',
      'last_name',
      'company_name',
      'email',
      'country',
      'reason',
      'captchaResponse',
    ]).every(Boolean);

    return (
      <div className={ this.rootcn() }>
        <form
          onSubmit={ this.handleSubmit }
          className={ this.cn`__form` }
          name="Trust Stamp Contact Us"
        >
          <ul>
            <div className={ this.cn`__form-group` }>
              <label htmlFor="">First Name *</label>
              <ValidationInput
                className={ this.cn`__input` }
                type="text"
                placeholder="First Name *"
                ref="form.first_name"
                stateLink={ [this, 'first_name'] }
              />
            </div>
            <div className={ this.cn`__form-group` }>
              <label htmlFor="">Last Name *</label>
              <ValidationInput
                className={ this.cn`__input` }
                type="text"
                placeholder="Last Name *"
                ref="form.last_name"
                stateLink={ [this, 'last_name'] }
              />
            </div>
            <div className={ this.cn`__form-group` }>
              <label htmlFor="">Title</label>
              <ValidationInput
                className={ this.cn`__input` }
                type="text"
                placeholder="Title"
                ref="form.title"
                stateLink={ [this, 'title'] }
              />
            </div>
            <hr className={ this.cn`__section` } />
            { /* company details */ }
            <div className={ this.cn`__form-group` }>
              <label htmlFor="">Company Name *</label>
              <ValidationInput
                className={ this.cn`__input` }
                type="text"
                placeholder="Company Name *"
                ref="form.company_name"
                stateLink={ [this, 'company_name'] }
              />
            </div>
            <div className={ this.cn`__form-group` }>
              <label htmlFor="">Company Email Address *</label>
              <ValidationInput
                className={ this.cn`__input` }
                type="text"
                placeholder="E-mail *"
                ref="form.email"
                stateLink={ [this, 'email'] }
              />
            </div>
            <div className={ this.cn`__form-group` }>
              <label htmlFor="">Country *</label>
              <Select
                className={ this.cn`__select` }
                displayName="Country *"
                options={ world.get('countries') }
                placeholder="Country *"
                placeholderAlwaysVisible={ true }
                ref="form.country"
                stateLink={ [this, 'country'] }
              />
            </div>
            <div className={ this.cn`__form-group` }>
              <label htmlFor="">Phone Number</label>
              <PhoneNumberInput
                className={ this.cn`__input` }
                placeholder="xxx xxx xxxx"
                ref="form.phone"
                stateLink={ [this, 'phone'] }
                defaultCountry={
                  this.state.country ? this.state.country.toLowerCase() : 'us'
                }
                countryCodeEditable={ true }
              />
            </div>
            { /* message? */ }
            <hr className={ this.cn`__section` } />

            <div className={ this.cn`__form-group` }>
              <label htmlFor="">Reason for Contact*</label>
              <Select
                className={ this.cn`__select` }
                displayName="Reason for Contact*"
                options={ REASONS }
                placeholder="Reason for Contact*"
                placeholderAlwaysVisible={ true }
                ref="form.reason"
                stateLink={ [this, 'reason'] }
              />
            </div>

            <div className={ this.cn`__form-group` }>
              <label htmlFor="">How can we help?</label>
              <ValidationTextArea
                className={ this.cn`__textarea` }
                rows={ 6 }
                placeholder="Message!"
                ref="form.message"
                stateLink={ [this, 'message'] }
              />
            </div>
          </ul>

          { this.renderCaptcha() }

          <p className={ this.cn`__agree` }>
            By providing my contact information, I authorize Trust Stamp to
            Contact Me with personalized communications about Trust Stamp’s
            solutions. See our{ ' ' }
            <a href="https://truststamp.ai/PrivacyPolicy.html" target="__blank">
              privacy policy
            </a>{ ' ' }
            for more information or to opt-out at any time.
          </p>
          <Button
            className={ this.cn`__button` }
            disabled={ !isFormValid }
            type="submit"
            mainAction={ true }
            ref={ (button) => {
              this.submitButton = button;
            } }
          >
            <span>Contact Us</span>
            <img
              alt=""
              className={ this.cn`__button-icon` }
              src={ asset`ai/arrow.svg` }
            />
          </Button>
        </form>
      </div>
    );
  }
}

export default onFullRender(InternalContactForm);
