/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { asset } from 'sf/helpers';
import BaseComponent from 'components/BaseComponent';

export default class HowItWorks extends BaseComponent {
  className = 'ts-HowItWorks';

  static propTypes = {
    children: PropTypes.node,
    colored: PropTypes.bool, // background color
    content: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        text: PropTypes.node,
        slug: PropTypes.string,
        button: PropTypes.string,
      })
    ),
    sectionTitle: PropTypes.string,
    subTitle: PropTypes.string,
    columnCount: PropTypes.oneOf([
      3,
      4,
    ]),
  };

  static defaultProps = {
    children: '',
    colored: false,
    subTitle: 'HOW IT WORKS',
  };

  render() {
    const { colored, content, sectionTitle, columnCount, subTitle } = this.props;

    const classNameModifiers = {
      '__column--three': columnCount === 3,
      '__column--four': columnCount === 4,
      '__colored': colored,
    };

    return (
      <section className={ this.rootcn(classNameModifiers) } >
        <div className="ts-container">
          <h6>{ subTitle } </h6>
          <h2>{ sectionTitle }</h2>
          <div className={ this.cn`__container` }>

            { /* listing */ }
            { content.map(({ id, slug, title, text, button }) => {
              return (
                <div className={ this.cn`__column` } key={ id }>
                  <div className={ this.cn`__img-container` }>
                    <img src={ asset`ai/${slug}.svg` } alt={ title } height="100" />
                  </div>
                  <h4>{ title }</h4>
                  <p>{ text }</p>
                  { button &&
                    <Link to={ button } className="ts-button--secondary">Learn More</Link>
                  }
                </div>
              );
            }) }

          </div>
        </div>
      </section>
    );
  }
}
