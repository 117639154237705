import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';
import BaseComponent from 'components/BaseComponent';

export default class Animate extends BaseComponent {
  className = 'ts-Animate';

  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: '',
  };

  render() {
    return (
      <CSSTransitionGroup
        className={ this.rootcn`` }
        transitionName={ this.rootcn`` }
        transitionEnterTimeout={ 500 }
        transitionLeaveTimeout={ 300 }
        transitionAppear={ true }
        transitionAppearTimeout={ 500 }
        { ...this.props }
      >
        { this.props.children }
      </CSSTransitionGroup>
    );
  }
}
