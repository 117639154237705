/* eslint-disable max-len */
import React, { Fragment } from 'react';


export const TITLE = 'Stable IT2 Features & Benefits';

export const CONTENT = [
  {
    id: 1,
    title: 'Enhanced Security',
    text: (
      <Fragment>
        <ul>
          <li>Multi-factor authentication integrating biometric, possession, and optional knowledge factors.</li>
          <li>Innovative biometric binding that prevents unauthorized access.</li>
        </ul>
      </Fragment>
    ),
    slug: 'enhanced-security-wire',
  },
  {
    id: 2,
    title: 'Privacy-Centric Compliance',
    text: (
      <Fragment>
        <ul>
          <li>Eliminates the need to store sensitive biometric data.</li>
          <li>Adheres to global privacy regulations, including GDPR and CCPA.</li>
        </ul>
      </Fragment>
    ),
    slug: 'privacy-centric-compliance',
  },
  {
    id: 3,
    title: 'User-Friendly Experience',
    text: (
      <Fragment>
        <ul>
          <li>Seamless and quick biometric verification process.</li>
          <li>Easy integration into existing security frameworks.</li>
        </ul>
      </Fragment>
    ),
    slug: 'user-friendly',
  },
  {
    id: 4,
    title: 'Comprehensive Fraud Detection',
    text: 'Advanced Presentation Attack Detection and Liveness Detection to thwart fraud attempts using deepfakes and voice spoofing.',
    slug: 'comprehensive-fraud-detection',
  }
];

export const HOWITWORKS_TITLE = 'Stable IT2 Technology: A Revolutionary Multifactor Authentication Solution';

export const HOWITWORKS = [
  {
    id: 1,
    title: 'Biometric Binding',
    text: 'Securely links authentication credentials to unique biometric traits like facial recognition, ensuring identity verification is directly tied to the individual and surpassing device-based or knowledge-based security.',
    slug: 'bio-binding',
  },
  {
    id: 2,
    title: 'Privacy-Centric',
    text: 'Trust Stamp’s biometric tokens use biometric-cryptographic techniques to create secure, temporary representations of credentials without storing sensitive biometric data, enhancing security and respecting privacy.',
    slug: 'privacy-centric',
  },
];
