import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Logo from 'components/Logo';
import UserPanel from 'components/UserPanel';
import device from 'models/device';
import user from 'models/user';

export default class LPPageHeader extends BaseComponent {
  className = 'ts-LPPageHeader';

  static propTypes = {
    brandingName: PropTypes.string,
    showRegisterButton: PropTypes.bool,
    size: PropTypes.string,
    theme: PropTypes.string,
    toTheRight: PropTypes.bool,
  };

  static defaultProps = {
    showRegisterButton: false,
  };

  state = {};

  componentDidMount() {
    this.syncStateWithModel(user, ['isSignedIn']);
    this.syncStateWithModel(device, ['smDown']);
  }

  render() {
    const {
      active,
      brandingName,
      fixed,
      showRegisterButton,
      size,
      theme,
      toTheRight,
      visible,
    } = this.props;

    const {
      smDown,
      isSignedIn,
    } = this.state;

    const classNameModifiers = {
      '--big': size === 'big',
      '--light': theme === 'light',
      '--fixed': fixed,
      '--visible': visible,
      [`--branding-${brandingName}`]: brandingName,
    };
    const userPanelWrapperClasses = {
      '__user-panel': true,
      '__user-panel--float-right': toTheRight,
    };

    return (
      <div className={ this.rootcn(classNameModifiers) }>
        <div className="ts-container">
          <Logo
            isSignedIn={ this.state.isSignedIn }
            theme={ theme }
            variant={ brandingName }
          />
          <div className={ this.cn`__menu-title` }>
            Menu
          </div>
          <div className={ this.cn(userPanelWrapperClasses) }>
            <UserPanel
              active={ active }
              brandingName={ brandingName }
              loginPanelStructureElement={ {
                container: smDown ? 'dropdown' : 'navbar',
                href: '/business/LogInCredentials.html',
                type: isSignedIn ? null : 'link',
                value: 'Log In',
                name: 'log-in',
              } }
              fixed={ fixed }
              showRegisterButton={ showRegisterButton }
              visible={ visible }
              type="realtor"
            />
          </div>
        </div>
      </div>
    );
  }
}
