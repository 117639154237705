/* global MediaRecorder, Blob, FileReader */
import is from 'next-is';
import { sleep } from 'sf/helpers';

let Webcam;
let recordedBlobs;
let mediaRecorder;
let videoMimeType;
let maxVideoLengthTimeout;

const isMobile = is.mobile() || is.tablet();

const videoFormats = [
	'video/webm;codecs=h264',
	'video/x-matroska;codecs=h264',
	'video/mp4;codecs=h264',
	'video/webm;codecs=vp9',
	'video/webm;codecs=vp8',
	'video/webm;codecs=daala',
	'video/x-matroska;codecs=avc1',
	'video/mp4;codecs=avc1',
	'video/mp4',
	'video/mpeg',
	'video/hevc',
	'',
];

const videoAudioFormats = [
	'video/webm;codecs=vp9,opus',
	'video/webm;codecs=vp9',
	'video/webm;codecs=vp8,opus',
	'video/webm;codecs=vp8',
	'video/webm;codecs=h264,opus',
	'video/webm;codecs=h264',
	'video/mp4;codecs=avc1,mp4a',
	'video/ogg;codecs=theora,vorbis',
	'video/3gpp;codecs=h263',
	'video/3gpp;codecs=mp4v-es',
	'video/quicktime;codecs=h263',
	'video/x-matroska;codecs=avc1',
	'video/x-matroska;codecs=opus',
	'video/x-matroska;codecs=theora',
	'video/x-matroska;codecs=vorbis',
	'video/x-ms-wmv;codecs=wmv2',
	'video/x-ms-wmv;codecs=wmv3',
	'video/x-ms-wmv;codecs=wma',
	'video/x-flv;codecs=flv1',
	'video/x-flv;codecs=adpcm',
	'video/x-mpegurl',
	'video/mpeg',
	'video/h261',
	'video/h263',
	'video/h263-1998',
	'video/jpeg',
	'video/jpm',
	'video/mj2',
	'',
];
/**
 * Video codec selected by MediaRecorder might not actually work.
 * In such case silent error is triggered and video is not recorded.
 * MAX_MEDIA_RECORDER_RETRIES sets a number of codecs to check before
 * entering a fallback mode.
 *
 * Reference: OL-2269
 *
 * @type {Number}
 */
const MAX_MEDIA_RECORDER_RETRIES = 3;

/**
 * RECORD_TIMESCALE is a value passed to MediaRecorder.start.
 * Set value reflects duration in milliseconds of each blob data.
 *
 * Reference: https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/start
 *
 * @type {Number}
 */
const RECORD_TIMESCALE = 500;

export const RECORDING_SCHEDULED = 1;
export const RECORDING_STARTED = 2;

const VIDEO_FORMAT_EXTENSIONS = {
	'x-matroska': 'mkv',
	'quicktime': 'mov',
	'webm': 'webm',
};

const VideoRecorder = {
	init(webcamObj) {
		Webcam = webcamObj;
		return VideoRecorder;
	},

	checkIfCanRecord() {
		// No Webcam.stream.active means camera not started or not available.
		// No MediaRecorder means - file fallabck video recording will be used.
		return !!(Webcam.stream && Webcam.stream.active) || !global.MediaRecorder;
	},

	resetWebrtcCamera() {
		const { stream } = this;
		if (stream) {
			if (stream.getVideoTracks) {
				// get video track to call stop on it
				const tracks = stream.getVideoTracks();
				if (tracks && tracks[0] && tracks[0].stop) tracks[0].stop();
			} else if (stream.stop) {
				// deprecated, may be removed in future
				stream.stop();
			}
		}

		VideoRecorder.stopRecording();
	},

	async stopRecording(dispatch) {
		clearTimeout(maxVideoLengthTimeout);
		if (!mediaRecorder) {
			return;
		}

		// iOS 15 sometimes forgots to return any mime type. We use mp4, as media encoder would
		// recognise quicktime content type inside a file.
		const mimeType = videoMimeType || mediaRecorder.mimeType || 'video/mp4';

		const preparingTimeStart = Date.now();
		// HACK:
		// mediaRecorder can be inactive in Incognito mode Chrome@Windows 7, even while recording.
		if (mediaRecorder && mediaRecorder.state !== 'inactive') {
			try {
				mediaRecorder.stop();
			} catch (e) { /* noop */ }
			await sleep(100);
		}

		Webcam.params.set('videoRecording', false);
		if (dispatch && recordedBlobs.length) {
			Webcam.dispatch('videoTaken', {
				blob: new Blob(recordedBlobs, { type: mimeType.split(';')[0] }),
				video_preparing_time: Date.now() - preparingTimeStart
			});
		}
	},

	startRecording(retryNumber = 0) {
		const video = Webcam.getVideo();
		if (!video) {
			Webcam.dispatch('error', new Webcam.errors.WebcamError('Video is not loaded yet.'));
			return;
		}

		// phones have trouble with high bitrate for high resolutions
		let bitRateLimiter = 1;
		if (isMobile) {
			bitRateLimiter = Math.min(960 * 800, video.videoWidth * video.videoHeight) / (video.videoWidth * video.videoHeight);
			bitRateLimiter = Math.min(1, bitRateLimiter);
		}

		recordedBlobs = [];
		const autoDetectedBitrate = Webcam.params.get('video_bitrate_per_pixel')
			* video.videoWidth
			* video.videoHeight
			* bitRateLimiter;

		const options = {
			videoBitsPerSecond: autoDetectedBitrate
		};

		const isAudioEnabled = Webcam.params.get('audio');

		if (retryNumber) {
			delete options.videoBitsPerSecond;
			videoMimeType = findSupportedMimeType(isAudioEnabled, retryNumber);
			options.mimeType = videoMimeType;
		} else if (!is.chrome()) {
			videoMimeType = findSupportedMimeType(isAudioEnabled, retryNumber);
			options.mimeType = videoMimeType;
		}

		try {
			mediaRecorder = new MediaRecorder(Webcam.stream, options);
		} catch (e) {
			const alertMsg = 'Exception while creating MediaRecorder: ' // eslint-disable-line
				+ e + '. mimeType: ' + options.mimeType;
			if (process.env.NODE_ENV !== 'production' && Webcam.params.get('verbose')) {
				console.error(alertMsg, e); // eslint-disable-line
			}

			Webcam.dispatch('error', new Webcam.errors.WebcamError(alertMsg));
			return;
		}

		if (process.env.NODE_ENV !== 'production' && Webcam.params.get('verbose')) {
			/* eslint-disable no-console */
			console.log('videoBitsPerSecond', options.videoBitsPerSecond);
			console.log('detectedMimeType', options.mimeType);
			console.log('Created MediaRecorder', mediaRecorder, 'with options', options);
			/* eslint-enable */
		}

		// mediaRecorder.onstop = handleStop;
		mediaRecorder.ondataavailable = (event) => {
			if (event.data) {
				videoMimeType = event.data.type;
				if (event.data.size > 0) {
					recordedBlobs.push(event.data);
				}
			}
		};

		mediaRecorder.onstart = () => {
			Webcam.params.set('videoRecording', RECORDING_STARTED);

			maxVideoLengthTimeout = setTimeout(() => {
				VideoRecorder.stopRecording(true);
			}, Webcam.params.get('video_length'));
		};

		mediaRecorder.onerror = (err) => {
			if (retryNumber < MAX_MEDIA_RECORDER_RETRIES) {
				clearTimeout(maxVideoLengthTimeout);
				VideoRecorder.startRecording(retryNumber + 1);
			} else {
				Webcam.dispatch('error', new Webcam.errors.WebcamError('Recording failed.'));
			}
		};

		mediaRecorder.start(RECORD_TIMESCALE);

		Webcam.params.set('videoRecording', RECORDING_SCHEDULED);
	},

	handleFileFallbackVideoInput(e) {
		if (!e.target.files || !e.target.files.length) {
			return;
		}

		const rawFile = e.target.files[0];
		const rawFileType = rawFile.type;

		if (!/^video\//.test(rawFileType)) {
			return Webcam.dispatch('error', new Webcam.errors.WebcamError('Invalid file type. Please provide video.'));
		}

		const reader = new FileReader();
		reader.onload = (readerEvent) => {
			Webcam.params.set({ lastCameraId: 'fallback' });
			const blob = new Blob([readerEvent.target.result], { type: rawFileType });

			Webcam.dispatch('videoTaken', {
				blob: blob,
				rawFile: rawFile
			});
		};
		reader.readAsArrayBuffer(rawFile);
	},

	videoFormatToExt(mimeType) {
		const videoFormat = mimeType.substr(6).split(';')[0];

		return VIDEO_FORMAT_EXTENSIONS[videoFormat] || videoFormat;
	}
};

function findSupportedMimeType(isAudioEnabled, skip = 0) {
	// chrome can autodetect best mime type pretty well.
	// Other browsers still rely on our fancy list of mime-types
	// https://chromium.googlesource.com/chromium/src/third_party/+/master/blink/renderer/modules/mediarecorder/README.md?autodive=0%2F%2F%2F%2F
	let found = 0;
	const format = isAudioEnabled ? videoAudioFormats : videoFormats;

	for (let i = 0; i < format.length; i++) {
		if (MediaRecorder.isTypeSupported(format[i])) {
			if (++found <= skip) {
				continue;
			}
			return format[i];
		}
	}
}

export default VideoRecorder;
