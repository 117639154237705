/* eslint-disable max-len */
import React from 'react';
import BaseComponent from 'components/BaseComponent';
import { asset } from 'sf/helpers';

export default class CaptureContent extends BaseComponent {
  className = 'ts-CaptureContent';

  render() {
    return (
      <div className={ this.rootcn() } >
        <section>
          <div className="ts-container">
            <div className={ this.cn`__content` }>
              <div className={ this.cn`__column __column--image` }>
                <img src={ asset`ai/palm-rotated.png` } alt="Fit-for-purpose biometric capture and analysis" width="338px" />
              </div>
              <div className={ this.cn`__column __column--content` }>
                <h6>TRUST STAMP CAPTURE</h6>
                <h2>Fit-for-purpose biometric capture and analysis</h2>
                <p>Trust Stamp’s Biometric Capture technology maximises accuracy and speed to ensure that users can easily capture distinguishable biometric features in a repeatable way, for an optimised customer experience during enrolment, account access, recovery, and more, anytime and anywhere.</p>
              </div>
            </div>
            <div className={ this.cn`__content` }>
              <div className={ this.cn`__column __column--content` }>
                <h2>Spoof-resistant</h2>
                <p>By coupling advanced contactless biometric analysis with presentation attack detection, including proof-of-life and anti-spoofing technologies, we can seamlessly determine whether the presented data belongs to a real person.</p>
              </div>
              <div className={ this.cn`__column __column--image` }>
                <img src={ asset`ai/selfie.gif` } alt="Spoof-resistant" width="338px" />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="ts-container">
            <h2>The foundation for a digitally transformed business</h2>
            <p>Maintaining an accurate and efficient process for virtual authentication is fundamental to successfully navigating the digital-first future. With the broad benefits that biometric tools offer, taking the first step to become bometrically-enabled expands the scope of what your organisation can do for user experience, fraud-prevention, customer retention, and more.</p>
            <p>Trust Stamp’s interoperable biometric solutions are designed with the future in mind. Our tools work across systems, vendors, and devices, as the building blocks to a robust identity strategy. Trust Stamp Capture is the first piece.</p>
          </div>
        </section>
      </div>
    );
  }
}
