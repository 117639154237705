import * as types from 'pages/MarketplaceHeroesIndex/MarketplaceHeroesIndex.types';

const initialState = {
  code: '',
  isRegistered: false,
  isFailed: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.REGISTER_SUCCESS: {
      return {
        ...state,
        code: action.data.discount_code,
        isRegistered: true,
      };
    }
    case types.REGISTER_FAILURE: {
      return {
        ...state,
        isFailed: true,
      };
    }
    case types.GET_STARTED:
    case types.DOWNLOAD_MATERIALS:
    default:
      return state;
  }
}
