import React from 'react';
import BaseComponent from 'components/BaseComponent';
import { components } from 'react-select';

export default class CustomMenuList extends BaseComponent {
  render() {
    const { innerProps, selectProps, ...restProps } = this.props;

    // ListBox should have the aria-labelledby attribute.
    // aria-labelledby should take the id of the same visible label as the combobox itself
    const updatedInnerProps = {
      ...innerProps,
      'aria-labelledby': selectProps['aria-labelledby'],
    };

    return (
      <components.MenuList
        { ...restProps }
        className={ this.rootcn() }
        innerProps={ updatedInnerProps }
      />
    );
  }
}
