import { onFullRender } from 'sf/helpers/domHelper';
import onPromise from './OnPromise';


export default function withFullRender(ComposedComponent, exposedFields = []) {
  return onPromise(ComposedComponent, onFullRender(), {
    exposedFields,
    LoadingComponent: null,
    promisedPropName: false,
  });
}
