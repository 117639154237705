import _get from 'lodash/get';
import is from 'next-is';
import world from 'sf/models/world';

export const getStringValidation = (propName) => ({
  [`Please provide your ${propName}`]: (input) => is.string(input)
    && !!input.replace(/[^a-zA-ZÀ-ƒ0-9]/gi, '').length,
});

export const getExactLengthValidation = (length, name) => ({
  [`Your ${name} is too short`]: (input) => is.string.minLen(input, length, true),
  [`Your ${name} is too long`]: (input) => is.string.maxLen(input, length, true),
});

export const getMaxLengthValidation = (maxLength) => ({
  [`It must not be longer than ${maxLength} characters`]: (input) =>
    is.string.maxLen(input, maxLength),
});

export const getCountryValidation = (extraOptions = {}) => ({
  ...getStringValidation('country'),
  'Please provide valid country code': (input) => !![
    ...world.get('countries'),
    ...(Array.isArray(extraOptions) ? extraOptions : [extraOptions]),
  ]
    .map(({ value }) => value)
    .find((code) => code === input),
});

export const getNationalityValidation = () => ({
  ...getStringValidation('nationality'),
  'Please provide valid nationality': (input) => !!world
    .get('nationalities')
    .map(({ value }) => value)
    .find((entry) => entry === input),
});

export const getStateValidation = (extraOptions = {}) => ({
  ...getStringValidation('state'),
  'Please provide valid state code': (input) => !![
    ...world.get('states'),
    ...(Array.isArray(extraOptions) ? extraOptions : [extraOptions]),
  ]
    .map(({ value }) => value)
    .find((code) => code === input),
});

export const isPassword = (input) => is.string.minLen(input, 8, true) &&
    is.string.hasDigits(input) &&
    is.string.hasLowerCaseLetter(input) &&
    is.string.hasUpperCaseLetter(input);

export const passwordValidation = {
  ...getStringValidation('password'),
  'A password must be at least 8 characters long': (input) => is.string.minLen(input, 8, true),
  'A password must contain at least one uppercase character': is.string.hasUpperCaseLetter,
  'A password must contain at least one lowercase character': is.string.hasLowerCaseLetter,
  'A password must contain at least one digit': is.string.hasDigits,
};

export const booleanValidation = {
  'Please answer all the questions': (input) => typeof input === 'boolean',
};

export const handleFormValidation = (context) => (err) => {
  // validation example:
  // { legal_name: "Use only latin letters" }
  const validation = _get(err, 'response.body.data.validation');
  if (Object.keys(validation || {}).length) {
    context.formValidation(Promise.reject(validation));
  }
};
