/* eslint-disable max-len */
import React from 'react';
import help from 'models/help';
import BasePage from 'pages/BasePage';
import { Link } from 'react-router';
import { ROUTES } from 'constants';
import Banner from 'components/Banner';
import HowItWorks from 'components/HowItWorks';
import Benefits from 'components/Benefits';
import TravelHospitalityContent from './components/TravelHospitalityContent';
import { TITLE, HOWITWORKS, TITLE2, SUB_TITLE2, CONTENT } from './constants';

export default class TravelHospitality extends BasePage {
  className = 'ts-TravelHospitality';
  static title = 'Identity Verification for Travel & Hospitality ';
  static description = 'Expedite guest-checkin, increase customer satisfaction and reduce fraud through biometric identity verification. ';

  componentDidMount() {
    help.set({ showDemoButton: true });
  }

  componentWillUnmount() {
    help.set({ showDemoButton: false });

    super.componentWillUnmount();
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        <Banner theme="travel" size="small">
          <h1>Travel & Hospitality </h1>
        </Banner>

        <TravelHospitalityContent />

        <Benefits
          sectionTitle={ TITLE2 }
          sectionSubText={ SUB_TITLE2 }
          content={ CONTENT }
          showSubTitle={ false }
        />

        <HowItWorks
          content={ HOWITWORKS }
          sectionTitle={ TITLE }
          subTitle="Why Trust Stamp"
          columnCount={ 4 }
        />

        <section>
          <div className="ts-container">
            <h6>GET IN TOUCH</h6>
            <h2>Elevate Guest Experience, Foster Customer Advocacy</h2>
            Experience a paradigm shift in guest onboarding with Trust Stamp's
            innovative identity verification technology that empowers you to
            cultivate a loyal customer base by delivering a frictionless and
            secure experience.
            <br />
            <br />
            <Link to={ ROUTES.CONTACT_US } className="ts-button--primary">
              GET IN TOUCH
            </Link>
          </div>
        </section>
      </div>
    );
  }
}
