import React, { Component } from 'react';

export default class En extends Component {
  render() {
    return (
      <div>
        <h1>
          <u>CONDITIONS GÉNÉRALES DE "POWERED BY TRUST STAMP"</u>
        </h1>

        <p>
          <b>Qui êtes-vous ?</b>
        </p>
        <p>
          T Stamp Inc. opérant sous le nom de Trust Stamp (désigné ci-après par
          “Trust Stamp”, “nous” ou “notre”) est une entreprise spécialisée en
          intelligence artificielle, axée sur la fourniture de sécurité
          renforcée grâce à l'authentification et à la vérification biométriques
          de l'identité. “Powered by Trust Stamp” est un service que Trust Stamp
          offre à ses clients (chacun étant une “Entité Vérificatrice” et
          collectivement, les “Entités Vérificatrices”), permettant à l’Entité
          Vérificatrice de vérifier de manière probabiliste l’identité de son
          client (un “Utilisateur Final”) à travers les processus
          d'authentification sécurisée de Trust Stamp.
        </p>

        <p>
          <b>Comment cela fonctionne-t-il ?</b>
        </p>
        <p>
          “Powered by Trust Stamp” authentifie l'identité d'un Utilisateur Final
          en comparant les données biométriques dérivées d'une image ou d'une
          vidéo de l'Utilisateur Final avec des données provenant d'images
          connues de l'Utilisateur Final, des documents d'identité fournis par
          l'Utilisateur Final, ou les deux. Ce processus d'authentification
          biométrique protège à la fois l'Utilisateur Final et l'Entité
          Vérificatrice contre l'accès non autorisé aux comptes et la fraude
          d'identité. Lorsque vous accédez à “Powered by Trust Stamp” pour
          vérifier votre identité auprès d'une Entité Vérificatrice, vous êtes
          un “Utilisateur Final”.
        </p>

        <p>
          <b>
            Comment utilisez-vous les données et les informations biométriques ?
          </b>
        </p>
        <p>
          La comparaison des mesures biométriques sert à la fois à vérifier
          l'identité d'un Utilisateur Final et à confirmer que les documents
          d'identité soumis par un Utilisateur Final appartiennent à cet
          Utilisateur Final. Nous collectons et traitons vos mesures
          biométriques brutes dans ce but. Nous traitons vos mesures
          biométriques brutes, qu'elles proviennent d'une image en direct ou
          d'un document, à l'aide d'un processus propriétaire pour convertir ces
          mesures brutes en un Jeton d'Identité Irréversiblement Transformé
          (IT2)™. L'IT2 est un dérivé tokenisé unique de vos mesures
          biométriques brutes, mais il ne peut pas être rétro-conçu pour recréer
          vos mesures brutes. Tel qu'utilisé dans ces Conditions, les “Données
          Biométriques” incluent vos mesures biométriques brutes mais ne
          comprennent pas l'IT2. Bien que le processus utilisé pour créer l'IT2
          soit irréversible, l'IT2 conserve des propriétés pouvant être
          utilisées pour vérifier votre identité de manière probabiliste. En
          d'autres termes, nous pouvons comparer un IT2 généré actuellement à un
          IT2 généré à partir de vos Données Biométriques dans le passé ou dans
          le futur pour vérifier votre identité. Nous utilisons ce processus de
          comparaison probabiliste propriétaire pour vérifier votre identité
          pour une Entité Vérificatrice.
        </p>

        <p>
          <u>
            <b>
              Les Petites Lettres
              <i>(très important ; ne vous déconcentrez pas maintenant)</i>
            </b>
          </u>
        </p>
        <p>
          <b>
            Veuillez noter que ces Conditions incluent, entre autres choses, une
            clause d'arbitrage contraignante qui exige que vous vous soumettiez
            à un arbitrage contraignant et final sur une base individuelle pour
            résoudre les différends, plutôt qu'à un procès devant jury ou à une
            action collective.
          </b>
        </p>
        <ol>
          <li>
            <p>
              <u>Acceptation; Politique de Confidentialité. </u>
              Vous devez avoir dix-huit (18) ans ou plus (ou l'âge de la
              majorité légale dans votre juridiction de résidence, si plus
              élevé) pour utiliser Powered by Trust Stamp (appelé ci-après les
              “Services”) afin de vérifier votre identité ou d'authentifier vos
              documents d'identité. En accédant et/ou en utilisant, ou en
              continuant d'accéder ou d'utiliser, les Services de quelque
              manière que ce soit, vous déclarez que (a) vous avez au moins
              dix-huit (18) ans ou l'âge minimum requis pour conclure un contrat
              légalement contraignant dans votre juridiction de résidence, et
              que vous avez le droit, l'autorité et la capacité de conclure ces
              Conditions ; (b) vous acceptez ces Conditions, qui incorporent
              également par référence la Politique de Confidentialité de Trust
              Stamp, disponible ici https://truststamp.ai/PrivacyPolicy.html (la
              “Politique de Confidentialité”), chacune de ces politiques pouvant
              être mise à jour de temps à autre sans préavis, comme précisé dans
              chaque document ; (c) s'il y a eu des modifications substantielles
              de ces conditions depuis votre dernière utilisation des Services,
              vous reconnaissez et acceptez que votre accès ou votre utilisation
              continue des Services constitue votre acceptation des Conditions
              modifiées ; et (d) vous consentez à recevoir des communications de
              notre part par voie électronique et acceptez que ces
              communications électroniques, avis et/ou publications satisfassent
              à toutes les exigences légales exigeant que ces communications
              soient écrites.
            </p>
          </li>

          <li>
            <p>
              <u>Accès aux Services.</u>
              Sous réserve de votre conformité à ces Conditions, nous vous
              accordons une licence limitée, non exclusive, non
              sous-licensiable, révocable et non transférable pour accéder et
              utiliser les Services. Il est une condition de votre utilisation
              des Services que les informations que vous fournissez soient
              correctes, actuelles et complètes. Votre utilisation des Services
              se fait à vos propres risques et vous reconnaissez que le
              processus utilisé est probabiliste et ne peut fournir une
              vérification absolue de votre identité. Tous les droits non
              expressément accordés dans les présentes sont réservés par Trust
              Stamp. Vous êtes responsable de toutes les dispositions
              nécessaires pour accéder aux Services. Les Services incluront,
              sans limitation, tout service, application ou extension que nous
              proposons directement ou que vous téléchargez ou recevez d'une
              Entité Vérificatrice ou de magasins d'applications tiers, sous
              réserve des conditions de ces magasins ou de leurs fournisseurs.
              Pour utiliser les Services, vous êtes responsable de disposer du
              matériel, des logiciels et de l'accès à Internet compatibles. Nous
              ne faisons aucune déclaration ni garantie concernant les
              dispositifs que vous utilisez pour accéder ou utiliser les
              Services, y compris en ce qui concerne la compatibilité de tout
              dispositif avec les Services.
            </p>
          </li>

          <li>
            <b>
              <p>
                <u>
                  Consentement pour la Collecte, le Traitement et l'Utilisation
                  des Données Biométriques.
                </u>
                Lorsque vous utilisez les Services, nous vous demanderons de
                fournir des images de vous-même (c'est-à-dire un "selfie")
                (votre "Image de Vérification"), un ou plusieurs documents
                d'identité officiels avec photo (votre "Document
                d'Identification"), ou les deux. En utilisant les Services, vous
                consentez à la collecte, au traitement, au stockage et à
                l'utilisation de vos données biométriques que nous obtenons à
                partir de votre Image de Vérification, de votre(s) Document(s)
                d'Identification, et des images photographiques connues ou
                précédemment vérifiées de vous fournies par l'Entité
                Vérificatrice (votre "Image d'Identification"). Nous utiliserons
                votre IT2 dérivé de votre Image de Vérification, de vos
                Documents d'Identification et de l'Image d'Identification pour
                vérifier et authentifier votre identité, à travers un traitement
                automatisé, en tant que la même personne représentée sur l'Image
                de Vérification et les Documents d'Identification ou sur l'Image
                de Vérification et l'Image d'Identification, en fonction de la
                manière dont l'Entité Vérificatrice a demandé que nous
                vérifiions votre identité. Vous n'êtes pas obligé de donner
                votre consentement, et nous ne collecterons, traiterons,
                stockerons ou utiliserons vos Données Biométriques ou l'IT2 sans
                votre consentement. Cependant, si vous choisissez de ne pas
                donner votre consentement, vous ne pourrez pas vérifier et
                authentifier votre identité auprès de l'Entité Vérificatrice en
                utilisant les Services, ce qui pourrait affecter vos
                interactions ultérieures avec l'Entité Vérificatrice. Vous
                devrez interagir directement avec l'Entité Vérificatrice pour
                résoudre ces problèmes.
                <br />
                <br />
                Sauf disposition contraire dans ces Conditions, nous ne
                divulguerons ni ne diffuserons vos Données Biométriques ou l'IT2
                à quiconque, sauf à l'Entité Vérificatrice et à nos filiales et
                sociétés affiliées, sans avoir préalablement obtenu votre
                consentement écrit à une telle divulgation ou diffusion, sauf si
                cette divulgation est :
                <br />
                <ol>
                  <li>
                    <p>
                      requise par la législation fédérale, étatique, provinciale
                      ou municipale ;
                    </p>
                  </li>
                  <li>
                    <p>
                      pour signaler l'utilisation frauduleuse ou la tentative
                      d'utilisation frauduleuse des Services aux autorités
                      légales compétentes ; ou
                    </p>
                  </li>
                  <li>
                    <p>
                      requise conformément à une demande, un ordre, une
                      assignation, un mandat ou un acte écrit valide émis par un
                      tribunal compétent ou un autre organisme gouvernemental
                      légalement habilité à émettre un tel processus légal.
                    </p>
                  </li>
                </ol>
                Une copie de ces Conditions est disponible à l'adresse suivante
                :{ ' ' }
                <a
                  href="https://truststamp.ai/terms-and-conditions.html?lang=fr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://truststamp.ai/terms-and-conditions.html?lang=fr
                </a>
              </p>
            </b>
          </li>

          <li>
            <p>
              <u>Divulgation des Données à l'Entité Vérificatrice.</u>
              Une Entité Vérificatrice peut souhaiter conserver votre Image de
              Vérification, l'Image du Document d'Identité et les données
              extraites ainsi que l'IT2 (ensemble, les “Vos Données”) pour une
              utilisation future dans la vérification de votre identité. Vous
              nous autorisez à divulguer votre IT2 à une Entité Vérificatrice à
              cette fin. Vous consentez à ce que cette Entité Vérificatrice
              partage Vos Données avec nous à l'avenir et à notre utilisation de
              Vos Données pour : (i) vérifier votre identité et (ii) détecter
              les activités illégales ou frauduleuses. Vous comprenez et
              acceptez qu'une fois transférées à une Entité Vérificatrice, la
              conservation, le stockage et l'utilisation de Vos Données seront
              soumis aux conditions, modalités et accords entre vous et cette
              Entité Vérificatrice. Vous acceptez que nous ne soyons pas
              responsables des actes de cette Entité Vérificatrice concernant
              Vos Données telles que divulguées à l'Entité Vérificatrice.
            </p>
          </li>

          <li>
            <p>
              <u>
                Consentement pour le Traitement et le Partage de l'Image de
                Vérification et des Données de Document d'Identification.
              </u>
              En fournissant votre Image de Vérification, vous donnez
              expressément votre consentement pour l'utilisation, le traitement
              automatisé et le partage de votre Image de Vérification avec
              l'Entité de Vérification à des fins de vérification d'identité.
              Vous garantissez et déclarez que l'image téléchargée est bien la
              vôtre et non celle d'un tiers. Vous confirmez que vous disposez de
              tous les droits et autorisations nécessaires pour soumettre cette
              image aux fins indiquées. En fournissant une image de votre ou de
              vos Document(s) d'Identification, vous déclarez être le
              propriétaire légal et l'utilisateur de ce ou ces Document(s)
              d'Identification et consentez à l'utilisation par nos soins de la
              reconnaissance optique de caractères (OCR), du balayage numérique
              et par radiofréquence, ainsi que d'autres technologies pour lire
              toute donnée contenue dans ou sur votre Document d'Identification,
              pouvant inclure, sans s'y limiter, votre nom, adresse, âge, date
              de naissance, lieu de naissance, statut de citoyenneté, couleur
              des yeux, couleur des cheveux, taille, poids, race, ethnie, numéro
              du document d'identité, date d'émission ou lieu d'émission (les
              "Informations d'Identification"). En soumettant une image de votre
              Document d'Identification, vous reconnaissez également comprendre
              et accepter que (1) nous pouvons partager vos Informations
              d'Identification, y compris l'image de votre Document
              d'Identification, avec l'Entité de Vérification, (2) l'utilisation
              et la disposition ultérieures de vos Informations d'Identification
              que nous transférons à l'Entité de Vérification seront régies
              exclusivement par les termes, conditions et accords entre vous et
              cette Entité de Vérification, (3) dans la mesure permise par la
              loi et sans vous identifier comme étant le sujet de ces données,
              nous pouvons utiliser vos données à des fins d'amélioration, de
              développement et d'entraînement de nos systèmes et services. Cela
              inclut, mais sans s'y limiter, l'utilisation des données dans des
              modèles d'apprentissage automatique, des analyses de données, et
              d'autres processus de recherche et développement, et (4) sous
              réserve des limitations de responsabilité supplémentaires
              ci-dessous, vous, en votre nom et au nom de vos héritiers et
              ayants droit, acceptez d'indemniser, de libérer et de tenir Trust
              Stamp à l'abri de toute réclamation liée à l'utilisation ou à la
              disposition ultérieure de vos Informations d'Identification, y
              compris, sans s'y limiter, toute réclamation fondée sur (a)
              l'utilisation, la divulgation ou la disposition inappropriées de
              vos Informations d'Identification, (b) la fraude et le vol
              d'identité, (c) l'atteinte à la vie privée, ou (d) l'infliction
              négligente ou intentionnelle de détresse émotionnelle. Si vous
              n'acceptez pas ces limitations, vous ne devez PAS soumettre une
              image de votre Document d'Identification.
            </p>
          </li>

          <li>
            <p>
              <u>Conservation des Données.</u>
              Vos Données peuvent être conservées conformément à nos obligations
              envers une Entité de Vérification. Les Entités de Vérification
              définissent une période de conservation pour tout ou partie de vos
              Données. Sauf ordonnance judiciaire, assignation, mandat,
              injonction ou autre obligation légale, nous ne conserverons en
              aucun cas vos Données Biométriques, IT2 et Informations
              d'Identification pour une durée supérieure à trente-six (36) mois.
              En attendant, vous pouvez demander la suppression de vos Données.
              Pour demander la suppression de vos Données, veuillez soumettre
              une demande par e-mail à{ ' ' }
              <a href="mailto:privacy@truststamp.net">
                privacy@truststamp.net{ ' ' }
              </a>{ ' ' }
              ou par courrier à l'adresse suivante :
              <br />
              <br />
              Suppression des Données : Trust Stamp <br />
              1st and 2nd Floors, 3017 Bolling Way NE
              <br />
              Atlanta, GA, 30305
              <br />
              <br />
              Une demande de suppression de vos Données Biométriques ou IT2
              n'affectera pas une vérification déjà complétée. La suppression de
              vos Données Biométriques ou IT2 peut prendre jusqu'à dix jours
              ouvrables à compter de la réception de la demande.
            </p>
          </li>

          <li>
            <p>
              <u>Données de l'Appareil et de la Session.</u>
              Nous pouvons également collecter des données sur l'appareil depuis
              lequel le Document d'Identification a été transmis ("Données de
              l'Appareil") et sur la session de connexion internet durant
              laquelle votre Document d'Identification, Image d'Identification
              ou Image de Vérification nous a été transmis, y compris l'adresse
              de Protocole Internet (IP) ("Données de la Session"). Les Données
              de l'Appareil peuvent inclure des informations provenant des
              capteurs de l'appareil, telles que les données de l'accéléromètre.
              Vous consentez à la capture et à la conservation de ces Données de
              l'Appareil et de la Session, ainsi qu'à leur partage avec l'Entité
              de Vérification.
            </p>
          </li>

          <li>
            <p>
              <u>Contenu.</u>
              Vous acceptez qu'en conformité avec ces Conditions, et en vous
              fondant sur vos garanties expresses concernant les données que
              vous envoyez ou recevez via l'utilisation des Services, Trust
              Stamp n'exerce aucun contrôle éditorial ni autre contrôle
              subjectif sur le contenu des données, communications ou messages
              pouvant être envoyés par l'Entité de Vérification. Trust Stamp
              n'exerce aucun contrôle sur les informations retransmises par
              l'Entité de Vérification et ne peut être tenu responsable de
              l'exactitude, de la légalité ou de la correction de ces
              informations. Vous êtes seul responsable de vérifier l'exactitude,
              la légalité et l'adéquation des informations et services que vous
              obtenez auprès d'une Entité de Vérification ou de toute tierce
              partie via ou à travers votre utilisation des Services.
            </p>
          </li>

          <li>
            <p>
              <u>Stockage des Données.</u>
              Nous utiliserons un standard de soin raisonnable pour stocker,
              transmettre et protéger contre toute divulgation les données
              électroniques, y compris les données biométriques, collectées
              auprès de vous. Ce stockage, cette transmission et cette
              protection contre toute divulgation seront effectués de manière
              équivalente ou plus protectrice que la façon dont nous stockons,
              transmettons et protégeons d'autres informations confidentielles
              et sensibles.
            </p>
          </li>
          <li>
            <p>
              <u>Aucune Garantie de Service.</u>
              Notre objectif est de rendre les Services toujours disponibles.
              Cependant, il peut arriver que nous devions temporairement ou
              définitivement modifier ou désactiver les Services. Nous nous
              réservons le droit d'interrompre, de modifier, de suspendre ou de
              désactiver, en tout ou en partie, l'accès aux Services, ou
              d'imposer des limites d'utilisation ou d'accès aux Services, avec
              ou sans préavis, et dans tous les cas sans engager notre
              responsabilité. En aucun cas, nous ne serons responsables de
              l'interruption, de la modification, de la suspension ou de la
              désactivation de l'accès aux Services, à tout moment ou pour toute
              durée.
            </p>
          </li>
          <li>
            <p>
              <u>Utilisation Légale Uniquement.</u>
              Vous pouvez utiliser les Services uniquement à des fins légales et
              conformément à ces Conditions. Nous ne sommes pas tenus de faire
              appliquer les Conditions en votre nom contre un autre Utilisateur,
              personne ou entité. Vous acceptez de ne pas, et ne devez pas,
              aider, encourager ou permettre à d'autres d'utiliser les Services
              :
            </p>
            <ol type="a">
              <li>
                <p>
                  à des fins autres que celles expressément autorisées par ces
                  Conditions;
                </p>
              </li>
              <li>
                <p>
                  de manière abusive, diffamatoire, offensive, menaçante,
                  envahissante des droits de confidentialité d'autrui, ou
                  susceptible de causer un préjudice à toute personne ou entité;
                </p>
              </li>
              <li>
                <p>
                  pour violer toute loi ou réglementation nationale, régionale,
                  fédérale, étatique, locale ou internationale applicable, ou
                  pour encourager toute activité illégale;
                </p>
              </li>
              <li>
                <p>
                  pour créer, envoyer, recevoir sciemment, afficher, transmettre
                  ou utiliser tout matériel qui:
                </p>
                <ol type="i">
                  <li>
                    <p>
                      enfreint un brevet, une marque déposée, un secret
                      commercial, un droit d'auteur ou d'autres droits de
                      propriété intellectuelle de toute autre personne;
                    </p>
                  </li>
                  <li>
                    <p>
                      contient des images de pornographie infantile ou de
                      matériel d'abus sexuel sur des enfants;
                    </p>
                  </li>
                  <li>
                    <p>
                      viole autrement, ou contribue à la violation des droits
                      légaux (y compris les droits à l'image et à la vie privée)
                      des autres ou contient tout matériel susceptible
                      d'entraîner une responsabilité civile ou criminelle en
                      vertu des lois ou règlements applicables, ou qui est en
                      conflit avec ces Conditions ou notre Politique de
                      Confidentialité ; ou est susceptible de tromper ou de
                      confondre toute personne;
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  pour violer ces Conditions ou toute autre règle ou politique
                  publiée par nous sur notre site web ou autrement fournie;
                </p>
              </li>
              <li>
                <p>
                  pour transmettre des virus informatiques, des vers, des
                  défauts, des chevaux de Troie ou d'autres logiciels
                  malveillants;
                </p>
              </li>
              <li>
                <p>
                  pour accéder de manière non autorisée à tout réseau
                  informatique ou violer autrement la sécurité de tout réseau.
                </p>
              </li>
            </ol>
            <p>
              Vous acceptez de ne pas, et de ne pas aider, encourager ou
              permettre à d’autres de:
            </p>
            <ol type="a" start="8">
              <li>
                <p>
                  Ingénierie inverse, décompilation, désassemblage ou toute
                  autre opération sur les Services qui pourrait révéler ou
                  divulguer tout code source, secret commercial ou autre
                  information propriétaire;
                </p>
              </li>
              <li>
                <p>
                  Modifier, adapter, approprier, reproduire, distribuer,
                  traduire, créer des œuvres dérivées ou des adaptations des
                  Services, les afficher publiquement, les vendre, les échanger
                  ou les exploiter de toute autre manière, sauf autorisation
                  expresse de notre part;
                </p>
              </li>
              <li>
                <p>
                  Supprimer ou modifier tout avis de droits d'auteur, marque
                  déposée ou autre avis de droits de propriété apparaissant sur
                  une partie des Services ou sur tout matériel imprimé ou copié
                  à partir des Services;
                </p>
              </li>
              <li>
                <p>
                  Enregistrer, traiter, recueillir ou extraire des informations
                  sur d’autres utilisateurs;
                </p>
              </li>
              <li>
                <p>
                  Utiliser tout robot, script ou autre processus automatique ou
                  moyen pour accéder aux Services à toute fin;
                </p>
              </li>
              <li>
                <p>
                  Supprimer, contourner, désactiver, endommager ou interférer
                  autrement avec les caractéristiques de sécurité ou autres
                  restrictions des Services;
                </p>
              </li>
              <li>
                <p>
                  Tenter d’accéder de manière non autorisée, d’interférer avec,
                  d’endommager ou de perturber toute partie des Services, le
                  serveur sur lequel les Services sont stockés ou tout serveur,
                  ordinateur ou base de données connecté aux Services ; Attaquer
                  les Services via une attaque par déni de service, un déni de
                  service distribué (DDoS), ou toute autre forme de cyberattaque
                  ; ou tenter d’interférer de toute autre manière avec le bon
                  fonctionnement des Services.
                </p>
              </li>
            </ol>
            <p>
              Les restrictions ci-dessus s'appliquent uniquement dans la mesure
              permise par la loi applicable. Néanmoins, vous acceptez de ne pas
              agir en contradiction avec ces restrictions (même si vos actions
              sont autrement légales) sans nous fournir un préavis écrit de
              trente (30) jours à l'adresse{ ' ' }
              <a href="mailto:support@truststamp.net">support@truststamp.net</a>
              , indiquant votre intention de le faire, accompagné de toute
              information que nous pourrions raisonnablement exiger pour nous
              donner l'occasion d'accommoder ou d'aborder autrement votre action
              proposée, ce qui sera à notre entière discrétion.
            </p>
          </li>
          <li>
            <p>
              <u>Soumissions Frauduleuses.</u>
              Toute soumission ou tentative de soumission d'une Image de
              Vérification, d'une Image d'Identification ou d'un Document
              d'Identification qui est frauduleuse ou effectuée dans le but de
              commettre une fraude, y compris, sans limitation, les actions en
              violation du Paragraphe 11 ci-dessus, sera considérée comme une
              Soumission Frauduleuse. Nonobstant les termes ci-dessus, si vous
              faites une Soumission Frauduleuse, vous acceptez que nous
              puissions conserver, traiter, stocker et utiliser toutes les
              données soumises, y compris les Données Biométriques et les
              Informations d'Identification, pendant une période allant jusqu'à
              dix (10) ans aux fins de (i) l'investigation et la poursuite des
              activités frauduleuses et (ii) la formation et l'amélioration de
              nos systèmes pour la détection et la prévention de la fraude. Sur
              demande écrite à{ ' ' }
              <a href="mailto:support@truststamp.net">support@truststamp.net</a>
              , vous pouvez demander que vos données ne soient pas utilisées à
              cette fin. Cependant, pour honorer votre demande, nous pourrions
              exiger que vous nous fournissiez une documentation établissant
              votre identité en tant que propriétaire légitime de toute donnée
              associée à une Soumission Frauduleuse.
            </p>
          </li>
          <li>
            <p>
              <u>Frais de citation à comparaître.</u>
              Si nous sommes tenus de fournir des informations en réponse à une
              ordonnance du tribunal, une citation à comparaître, un mandat, une
              requête ou une enquête légale ou gouvernementale similaire
              relative à votre utilisation des Services, nous pourrions vous
              facturer les coûts engagés. Ces frais peuvent inclure le temps
              raisonnable passé par nos avocats et employés pour récupérer les
              dossiers, préparer les documents, et participer à une déposition
              ou à une audience judiciaire.
            </p>
          </li>
          <li>
            <p>
              <u>Propriété Intellectuelle.</u>
              Vous conserverez tous les droits, titres et intérêts sur le
              matériel, le contenu, les données et les informations (y compris
              vos informations personnelles) que vous soumettez lors de
              l'utilisation des Services (collectivement, votre “Contenu”). Sous
              réserve de ces Conditions, vous nous accordez la permission
              d'utiliser ou de divulguer votre Contenu uniquement dans la mesure
              nécessaire pour fournir les Services ou comme autrement autorisé
              par ces Conditions. Vous déclarez et garantissez que :
            </p>
            <ol type="a">
              <li>
                <p>
                  Vous possédez ou avez autrement obtenu tous les droits,
                  autorisations et consentements nécessaires pour soumettre tout
                  votre Contenu aux Services et pour accorder les droits qui
                  nous sont accordés dans ces Conditions ;
                </p>
              </li>
              <li>
                <p>
                  Vous ne soumettrez ni n'autoriserez la collecte, le
                  traitement, le stockage ou l'utilisation de données
                  biométriques provenant d'images ou de pièces d'identité photo
                  dans lesquelles vous n'êtes pas la personne représentée ;
                </p>
              </li>
              <li>
                <p>
                  Votre Contenu, ainsi que sa soumission et son utilisation
                  telles que vous les autorisez dans ces Conditions, ne violera
                  pas (i) toute loi ou réglementation applicable ou (ii) tout
                  droit de propriété intellectuelle, de confidentialité, de
                  publicité ou autre d'un tiers.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <b>
                <u>Résiliation des Services. </u>
                Trust Stamp peut résilier votre accès à tout ou partie des
                Services à tout moment, avec ou sans motif, avec ou sans
                préavis, avec effet immédiat. Si vous souhaitez résilier votre
                inscription et votre compte, vous pouvez le faire à tout moment
                en nous contactant à{ ' ' }
                <a href="mailto:support@truststamp.net">
                  support@truststamp.net
                </a>
                . En cas de résiliation, tous les droits et obligations des
                parties cesseront, et vous devrez immédiatement cesser
                d'utiliser les Services, sauf que (a) toutes les obligations
                accumulées avant la date effective de résiliation, (b) tous les
                recours en cas de violation de ces Conditions, et (c) les
                dispositions des Sections 4, 5, 12, 13, 16, 17, 18, 19, 20 et 21
                continueront de s'appliquer.
              </b>
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>EXCLUSION DE GARANTIES.</b>
              </u>
              Trust Stamp décline expressément toutes les garanties de quelque
              nature que ce soit, qu'elles soient expresses, implicites,
              statutaires ou autres, y compris, mais sans s'y limiter, les
              garanties d'exactitude, de commercialisation, de non-contrefaçon,
              de jouissance paisible ou d'adéquation à un usage particulier.
              Dans toute la mesure permise par la loi applicable, les Services
              sont fournis exclusivement sur une base "EN L'ÉTAT" et "SELON
              DISPONIBILITÉ". Trust Stamp ne garantit pas l'exactitude, la
              ponctualité, l'exhaustivité ou l'utilité des Services ou de tout
              contenu. Vous acceptez d'utiliser les Services à vos propres
              risques. Trust Stamp ne garantit pas l'absence d'interférences
              avec votre jouissance des Services, que les fonctions contenues
              dans les Services ou les services fournis par ou à travers les
              Services répondront à vos exigences, que le fonctionnement des
              Services sera ininterrompu ou exempt d'erreurs, ou que les défauts
              des Services seront corrigés. Sans limiter ce qui précède, Trust
              Stamp décline toute garantie découlant de la pratique commerciale,
              de l'exécution ou de l'usage commercial. Aucune information orale
              ou écrite fournie par Trust Stamp ou ses représentants ne doit
              être considérée comme créant une garantie. Si vous n'êtes pas
              satisfait des Services ou de l'une de ces Conditions, votre seul
              recours est de cesser d'utiliser les Services. Certaines
              juridictions n'autorisent pas l'exclusion des garanties implicites
              ou les limitations des droits statutaires applicables aux
              consommateurs. Si vous résidez dans une telle juridiction, les
              exclusions et limitations ci-dessus peuvent ne pas s'appliquer à
              vous.
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>LIMITATION DE RESPONSABILITÉ.</b>
              </u>
              VOUS RECONNAISSEZ QUE VOTRE ACCÈS AUX SERVICES ET VOTRE
              UTILISATION DE CES SERVICES, AINSI QUE TOUTE CONFIANCE ACCORDÉE À
              L'UN OU L'AUTRE, SE FAIT À VOS PROPRES RISQUES. DANS LA LIMITE
              MAXIMALE AUTORISÉE PAR LA LOI, EN AUCUN CAS TRUST STAMP OU L'UN DE
              SES PROPRIÉTAIRES, GÉRANTS, DIRIGEANTS, ADMINISTRATEURS, MEMBRES,
              ACTIONNAIRES, AFFILIÉS, FILIALES, EMPLOYÉS, CONTRACTANTS, AGENTS
              OU REPRÉSENTANTS NE SERA RESPONSABLE POUR DES DOMMAGES
              CONSÉCUTIFS, EXEMPLAIRES, INDIRECTS, ACCESSOIRES OU SPÉCIAUX, Y
              COMPRIS, SANS LIMITATION, LES DOMMAGES POUR PERTE DE PROFITS,
              EXPOSITION OU PERTE DE DONNÉES, INTERRUPTION D'ACTIVITÉ ET/OU TOUT
              AUTRE DOMMAGE OU PERTE COMMERCIAUX, DÉCOULANT DE OU EN RELATION
              AVEC LES SERVICES, QUE TRUST STAMP AI ÉTÉ OU NON INFORMÉ DE LA
              POSSIBILITÉ DE TELS DOMMAGES, QUELLE QUE SOIT LA THÉORIE DE
              RESPONSABILITÉ (Y COMPRIS LE CONTRAT, LE DÉLIT, LA NÉGLIGENCE OU
              AUTRE) DÉCOULANT DE, EN RELATION AVEC OU RÉSULTANT DES SERVICES.
              VOUS RECONNAISSEZ SPÉCIFIQUEMENT QUE LE TEMPS D'ARRÊT DES
              ORDINATEURS ET LES VIRUS SONT DES RISQUES INHÉRENTS À
              L'UTILISATION D'INTERNET ET DE TOUS PRODUITS LOGICIELS, ET VOUS
              ACCEPTEZ DE PRENDRE LA RESPONSABILITÉ POUR TOUT DOMMAGE OU HARMES
              DE TOUT TYPE RÉSULTANT DE CES RISQUES POTENTIELS. VOUS
              RECONNAISSEZ ÉGALEMENT SPÉCIFIQUEMENT QUE VOUS POURRIEZ DIVULGUER
              DES INFORMATIONS SENSIBLES, PRIVÉES ET CONFIDENTIELLES, Y COMPRIS
              DES DONNÉES BIOMÉTRIQUES ET DES INFORMATIONS BIOMÉTRIQUES,
              VOUS-CONCERNANT PAR VOTRE UTILISATION DES SERVICES ET VOUS
              ACCEPTEZ DE PRENDRE LA RESPONSABILITÉ POUR TOUT DOMMAGE OU HARMES
              DE TOUT TYPE RÉSULTANT DE LA DIVULGATION DE TEL CONTENU. EN AUCUN
              CAS NOTRE RESPONSABILITÉ TOTALE ENVERS VOUS POUR TOUT DOMMAGE
              (AUTRE QUE CE QUI PEUT ÊTRE REQUIS PAR LA LOI APPLICABLE) NE
              DÉPASSERA LE PLUS ÉLEVÉ DES MONTANTS SUIVANTS : (A) LE MONTANT QUE
              VOUS AVEZ PAYÉ POUR LES SERVICES AU COURS DES SIX (6) MOIS
              PRÉCÉDANT LA RÉCLAMATION APPLICABLE, LE CAS ÉCHÉANT, OU (B) CENT
              DOLLARS ($100,00). IL EST DE L'INTENTION DE VOUS ET DE NOUS QUE
              CETTE DISPOSITION SOIT INTERPRÉTÉE PAR UN TRIBUNAL COMME ÉTANT LA
              LIMITATION DE RESPONSABILITÉ LA PLUS LARGE AUTORISÉE PAR LA LOI
              APPLICABLE. CERTAINES JURIDICTIONS NE PERMETTENT PAS LA LIMITATION
              OU L'EXCLUSION DE DOMMAGES ACCESSOIRES, CONSÉCUTIFS OU AUTRES
              TYPES DE DOMMAGES, AINSI CERTAINES DES LIMITATIONS CI-DESSUS
              PEUVENT NE PAS S'APPLIQUER À VOUS. CES LIMITATIONS S'APPLIQUERONT
              MÊME SI LE REMÈDE STIPULÉ CI-DESSUS ÉCHOUE À SON OBJECTIF
              ESSENTIEL.
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>ARBITRAGE OBLIGATOIRE ET LIANT.</b>{ ' ' }
              </u>
              TOUTE DISPUTE, DIFFÉRENCE, CONTROVERSE OU RÉCLAMATION DÉCOULANT DE
              OU EN RELATION AVEC CES TERMES OU VOTRE UTILISATION DES SERVICES
              SERA RÉSOLUE PAR UN ARBITRAGE FINAL ET LIANT CONFORMÉMENT AUX
              RÈGLES DES SERVICES DE MÉDIATION ET D'ARBITRAGE HENNING À ATLANTA,
              GEORGIE. L'ARBITRAGE SE DÉROULERA AVEC UN SEUL ARBITRE. L'ARBITRE
              PEUT ACCORDER DES FRAIS ET/OU DES HONORAIRES D'AVOCATS À LA PARTIE
              GAGNANTE. VOUS COMPRENEZ QUE L'ARBITRAGE EST FINAL ET LIANT ET QUE
              VOUS RENONCEZ À VOS DROITS À D'AUTRES PROCÉDURES DE RÉSOLUTION
              (TELLES QUE L'ACTION EN JUSTICE OU LA PROCÉDURE ADMINISTRATIVE).
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>RENONCIATION AUX ACTIONS COLLECTIVES ET AUX JURYS.</b>
              </u>
              EN CE QUI CONCERNE VOTRE UTILISATION DES SERVICES, QUE VOUS LES
              AYEZ OBTENUS OU UTILISÉS À DES FINS PERSONNELLES, COMMERCIALES OU
              AUTRES, TOUTES LES RÉCLAMATIONS DOIVENT ÊTRE INTRODUITES À TITRE
              INDIVIDUEL, ET NON EN TANT QUE DEMANDEUR OU MEMBRE D’UNE CLASSE
              DANS UNE ACTION COLLECTIVE, UNE ACTION COLLECTIVE, UNE ACTION EN
              QUALITÉ DE PROCUREUR GÉNÉRAL PRIVÉ, OU AUTRE PROCÉDURE
              REPRÉSENTATIVE. CETTE RENONCIATION S'APPLIQUE ÉGALEMENT À
              L'ARBITRAGE COLLECTIF, ET UN ARBITRE NE PEUT PAS REGROUPER LES
              RÉCLAMATIONS DE PLUS D'UNE PERSONNE DANS UNE ACTION. VOUS ACCEPTEZ
              QU'EN ACCEPTANT CES TERMES, VOUS ET TRUST STAMP RENONCEZ CHACUN AU
              DROIT À UN JURY OU À PARTICIPER À UNE ACTION COLLECTIVE, UNE
              ACTION COLLECTIVE, UNE ACTION EN QUALITÉ DE PROCUREUR GÉNÉRAL
              PRIVÉ OU AUTRE PROCÉDURE REPRÉSENTATIVE DE TOUTE NATURE.
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>INDEMNISATION.</b>
              </u>
              VOUS ACCEPTEZ D’INDEMNISER ET DE GARANTIR TRUST STAMP, SES
              FILIALES, ET SES AFFILIÉS AINSI QUE LEURS RESPECTIFS
              PROPRIÉTAIRES, DIRECTEURS, RESPONSABLES, DIRIGEANTS, MEMBRES,
              ACTIONNAIRES, EMPLOYÉS, CONTRACTANTS, AGENTS, CONCÉDANTS DE
              LICENCE ET FOURNISSEURS CONTRE TOUTES RÉCLAMATIONS,
              RESPONSABILITÉS, DOMMAGES, JUGEMENTS, RÉCOMPENSES, PERTES, COÛTS,
              DÉPENSES ET/OU FRAIS (Y COMPRIS LES FRAIS RAISONNABLES D’AVOCATS)
              DÉCOULANT DE (A) VOTRE VIOLATION DE CES TERMES ; (B) VOTRE
              UTILISATION OU ACCÈS AUX SERVICES ; (C) VOTRE VIOLATION DE TOUT
              DROIT DE TIERS, Y COMPRIS, SANS LIMITATION, TOUT DROIT DE
              PROPRIÉTÉ INTELLECTUELLE OU DROIT À L’IMAGE ; OU (D) TOUTE
              RÉCLAMATION SELON LAQUELLE VOTRE UTILISATION DES SERVICES A CAUSÉ
              DES DOMMAGES À UN TIERS.
            </p>
          </li>
          <li>
            <p>
              <u>Modification des Conditions.</u>
              Nous nous réservons le droit de modifier ces Conditions, ainsi que
              d'ajouter de nouveaux termes ou conditions supplémentaires
              concernant votre utilisation des Services, pour toute raison, sans
              préavis, à tout moment. Ces modifications et termes
              supplémentaires seront immédiatement effectifs et intégrés dans
              ces Conditions. Votre utilisation continue des Services sera
              considérée comme une acceptation de ces modifications. Nous vous
              informerons des changements importants en vous demandant de les
              accepter avant toute utilisation future des Services. Les
              Conditions actuelles seront également disponibles sur notre site
              web. Ce qui constitue des “changements importants” sera déterminé
              à notre seule discrétion, de bonne foi, en utilisant le bon sens
              et un jugement raisonnable.
            </p>
          </li>
          <li>
            <p>
              <u>Conditions Générales. </u>
              Ces Conditions constituent l'intégralité de l'accord entre vous et
              Trust Stamp concernant les Services et remplacent toutes les
              ententes, accords, déclarations et garanties antérieurs ou
              contemporains, qu'ils soient écrits ou oraux, relatifs aux
              Services. Aucun abandon de ces Conditions de notre part ne sera
              considéré comme un abandon supplémentaire ou continu de cette
              condition ou de toute autre condition, et notre échec ou retard à
              faire valoir un droit ou une disposition en vertu de ces
              Conditions ne constituera pas un abandon de ce droit ou de cette
              disposition. Si une disposition de ces Conditions est jugée
              invalide, illégale ou inapplicable par un tribunal compétent pour
              quelque raison que ce soit, cette disposition sera modifiée pour
              refléter l'intention des parties ou éliminée dans la mesure
              minimale nécessaire pour que les autres dispositions des
              Conditions continuent à être pleinement applicables. Les
              Conditions, ainsi que les droits ou obligations qui en découlent,
              ne peuvent être cédés, transférés ou sous-licenciés par vous sauf
              avec notre consentement écrit préalable, mais peuvent être cédés
              ou transférés par nous sans restriction. Toute tentative de
              cession de votre part violera ces Conditions et sera nulle. Les
              titres des sections de ces Conditions sont uniquement à titre de
              commodité et n'ont aucun effet juridique ou contractuel. Vous
              accordez par la présente à Trust Stamp le droit de prendre les
              mesures raisonnablement nécessaires ou appropriées pour faire
              respecter et/ou vérifier la conformité à toute partie de ces
              Conditions. Vous acceptez que nous ayons le droit, sans
              responsabilité envers vous, de divulguer toute donnée et/ou
              information aux autorités judiciaires, aux fonctionnaires
              gouvernementaux et/ou à un tiers, comme nous le jugeons
              raisonnablement nécessaire ou approprié pour faire respecter et/ou
              vérifier la conformité à toute partie de ces Conditions (y
              compris, mais sans s'y limiter, notre droit de coopérer avec tout
              processus légal relatif à votre utilisation des Services, et/ou
              une réclamation de tiers selon laquelle votre utilisation des
              Services est illégale et/ou enfreint les droits de ce tiers).
            </p>
          </li>
          <li>
            <p>
              <u>Droit Applicable et Juridiction.</u>
              Ces Conditions, ainsi que la licence accordée par celles-ci,
              seront régies par les lois de l'État de Géorgie, sans tenir compte
              de ses principes de conflit de lois. Pour toute réclamation à
              laquelle la disposition d'arbitrage obligatoire contenue dans les
              présentes est jugée inapplicable, vous acceptez de vous soumettre
              à la juridiction personnelle des tribunaux fédéraux et d'État
              situés à Atlanta, Géorgie. Votre utilisation des Services peut
              également être soumise à d'autres lois locales, d'État, fédérales
              ou internationales.
            </p>
            <b>
              <p>
                En fournissant volontairement une Image de Vérification ou un
                Document d'Identification, vous reconnaissez et acceptez ces
                Conditions et êtes:
              </p>

              <ol type="a">
                <li>
                  <p>
                    représentant que vous avez lu, compris et consenti à ces
                    Conditions dans leur intégralité, y compris toutes les
                    dispositions relatives à la collecte, au traitement, au
                    stockage et à l'utilisation de vos Données Biométriques ;
                  </p>
                </li>
                <li>
                  <p>
                    représentant que vous êtes la personne représentée sur
                    l'Image de Vérification ; que l'Image de Vérification est
                    une image en direct, non altérée de vous, capturée par la
                    caméra de l'appareil utilisé dans le processus
                    (c'est-à-dire, pas, par exemple, une photo d'une photo ou
                    une vidéo d'une vidéo) ; que votre Document d'Identification
                    est un document légalement produit, valablement émis par la
                    juridiction qui y est indiquée, et que votre image et autres
                    données ne sont pas altérées ;
                  </p>
                </li>
                <li>
                  <p>
                    nous désignant comme votre agent uniquement dans le but
                    limité de signer électroniquement ces Conditions en votre
                    nom et demandant que nous ajoutions et associions votre
                    adresse IP ainsi que la date et l'heure de votre
                    reconnaissance de ces Conditions comme votre signature
                    électronique légalement contraignante attestant de votre
                    compréhension, reconnaissance écrite et acceptation de ces
                    Conditions ;
                  </p>
                </li>
                <li>
                  <p>
                    acceptant que votre utilisation continue des Services
                    constitue votre consentement continu à ces Conditions.
                  </p>
                </li>
              </ol>
            </b>
          </li>
        </ol>
      </div>
    );
  }
}
