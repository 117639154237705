import React from 'react';
import { ROUTES } from 'constants';
import BasePage from 'pages/BasePage';
import { scrollTo } from 'sf/helpers/domHelper';
import { getQuery } from 'sf/helpers';
import CCPAForm from './CCPAForm';

export default class SharedCCPAForm extends BasePage {
  className = 'ts-CCPAPrivacyRequest';

  static defaultProps = {
    thankYou: false,
  };

  state = {};

  performSuccessRedirect = () => {
    this.navigate(ROUTES.SHARED_CCPA_THANK_YOU);
  };

  componentDidMount() {
    const { source } = getQuery();

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      source,
    });
  }

  // banner section
  renderMainSection() {
    return (
      <section className={ this.cn`__hero` }>
        <div className={ this.cn`__hero-color` } />
        <div className={ this.cn`__hero__container` }>
          <h2 className={ this.cn`__hero__title` }>
            CCPA Privacy Request<br />Trust Stamp
          </h2>
        </div>
      </section>
    );
  }

  // form section
  renderFormSection() {
    if (this.props.thankYou) {
      return (
        <div className={ this.cn`__confirmation` }>
          <h3
            className={ this.cn`__form__title` }
            ref={ (ref) => ref && scrollTo(ref, 250, 250) }
          >
            Thank you for contacting us.
          </h3>
          <p>
            Your message has been successfully sent. We will reply as soon as
            possible.
          </p>
        </div>
      );
    }

    return (
      <CCPAForm onDone={ this.performSuccessRedirect } source={ this.state.source } />
    );
  }

  render() {
    const { source } = this.state;
    const sourceRequiredError = !source || source === 'PROJECT_NAME_HERE';

    if (!this.props.thankYou && sourceRequiredError) {
      return (
        <div className={ this.cn`__error-message` }>
          <strong>source</strong> is required.
        </div>
      );
    }

    return (
      <div className={ this.rootcn() }>
        { /* banner */ }
        { this.renderMainSection() }
        { /* form */ }
        { this.renderFormSection() }
      </div>
    );
  }
}
