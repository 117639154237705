/* eslint-disable max-len */
import { registerLanguageFile } from 'sf/helpers/l10n';

module.exports = registerLanguageFile({
  ACTION_CLICK: 'Click',
  ACTION_TAP: 'Tap',
  TAKE_PHOTO_BUTTON: '<%= actionName %> to take a picture',
  UPLOAD_PHOTO_BUTTON: '<%= actionName %> to upload file',
  TAKE_SELFIE: 'Take a selfie',
  TAKE_PHOTO: 'Take a photo',

  TAKE_VIDEO_BUTTON: '<%= actionName %> to take a video',
  RECORD: 'Record',
  RECORD_STOP: 'Stop recording',

  TAKE_SELFIE_BUTTON: '<%= actionName %> to take a selfie',
  TAKE_VIDEO: 'Take a video',
  WEBCAM_BACK: 'Back',
  WEBCAM_SWITCH_CAMERA: 'Switch camera',

  CAMERA_WARNING_TITLE: 'Camera warning:',
  CAMERA_ERROR_TITLE: 'Camera Error:',
  HELP_MESSAGE_LOW_CAMERA_RESOLUTION_CONTENT: 'Your camera seems to record very low resolution images. Please use a device with a better camera',

  HELP_MESSAGE_CAMERA_NOT_WORKING_TITLE: 'Camera is not working',
  HELP_MESSAGE_CAMERA_NOT_WORKING_CONTENT: 'Please enable your camera.',

  HELP_MESSAGE_CAMERA_NOT_FOUND_TITLE: 'Camera not detected',
  HELP_MESSAGE_CAMERA_NOT_FOUND_CONTENT: 'Please make sure you have a working camera.',

  HELP_MESSAGE_SELECT_IMAGE_IE10: 'Please try again and select "Take Photo" option.',

  HELP_MESSAGE_CAMERA_IN_USE_CONTENT: 'Your camera is already in use. Please disable applications using webcam and try again.',

  IN_APP_BROWSER_FACEBOOK_TITLE: 'YOU ARE CURRENTLY USING THE WEBSITE WITHIN FACEBOOK APP.',
  IN_APP_BROWSER_FACEBOOK_CONTENT: 'FOR SECURITY REASONS, PLEASE OPEN THIS PAGE IN A BROWSER/CHROME APP.',

  IN_APP_BROWSER_3RD_PARTY_TITLE: 'YOU ARE CURRENTLY USING THE WEBSITE WITHIN AN APPLICATION. FOR SECURITY REASONS,',
  IN_APP_BROWSER_3RD_PARTY_CONTENT: 'PLEASE TAP ON YOUR BROWSER ICON (<%= ICON %>) BELOW TO SWITCH TO A WEB BROWSER.',
  IN_APP_BROWSER_3RD_PARTY_CONTENT_IOS13: 'PLEASE TAP ON THE ICON <%= DIRECTION %> AND SWITCH TO A WEB BROWSER.',
  ARROW_DIRECTION_BELOW: 'BELOW',
  ARROW_DIRECTION_ABOVE: 'ABOVE',

  CHANGES_NOT_SAVED: 'Changes you made may not be saved.',

  UNEXPECTED_ERROR_TITLE: 'An unexpected error occurred',
  UNEXPECTED_ERROR_CONTENT: 'Please try again',
  PROCESSING: 'Processing...',
  UPLOADING: 'Uploading...',

  HELP_DATA_FORM_VALIDATION_FAIL_HEADER: 'I’M SORRY',
  HELP_DATA_FORM_VALIDATION_FAIL_TITLE: 'There are errors in following fields:',

  POSSIBLE_CAMERA_PROBLEM_MESSAGE: 'In case of camera issues, enable your camera in browser\'s settings',
  POSSIBLE_CAMERA_PROBLEM_MESSAGE_IOS: 'In case of camera issues, enable your camera in browser\'s settings or switch to Safari',
  POSSIBLE_CAMERA_PROBLEM_MESSAGE_ANDROID: 'In case of camera issues, enable your camera in browser\'s settings or switch to Chrome',

  BARCODE_CAMERA_READER_TEXT: 'Point the laser at a barcode. Hold still.',
  BARCODE_CAMERA_READER_TIMEOUT_TEXT: 'Please capture the barcode manually using the camera button.',

  QRCODE_CAMERA_READER_TEXT: 'Point the laser at a qrcode. Hold still.',
  QRCODE_CAMERA_READER_TIMEOUT_TEXT: 'Please capture the qrcode manually using the camera button.',

  HOLD_STILL: 'Hold still',
  INSTRUCTION_FACE_FIT: 'Please fit your entire face in the oval',
  INSTRUCTION_MOVE_CLOSER: 'Move closer',
  INSTRUCTION_MOVE_SIDE_TO_SIDE: 'Move your head side to side',
  RECORDING: 'Recording',
  PERFECT: 'Perfect!',
  RECORDING_STARTS_IN: 'Recording starts in',
  PHOTO_CAPTURE_IN: 'Taking photo in',
  MULTIPLE_FACES_DETECTED: 'Multiple faces detected',
  INSTRUCTION_DOCUMENT_FIT: 'Please fit your document in the frame',
  INSTRUCTION_MOVE_FARTHER: 'Move farther away',
  INSTRUCTION_KEEP_HEAD_STRAIGHT: 'Keep your head straight',
  INSTRUCTION_REMOVE_MASK: 'Remove your mask',
  INSTRUCTION_REMOVE_GLASSES: 'Remove your glasses',
  INSTRUCTION_ADJUST_LIGHTING: 'Ensure Good Frontal Lighting',
  CAPTURE_SELFIE_MANUALLY: 'Please capture the selfie manually using the camera button.',
  INSTRUCTION_MOVE_HEAD_TO_RIGHT_SIDE: 'Move your head to the right',
  INSTRUCTION_MOVE_HEAD_TO_LEFT_SIDE: 'Move your head to the left',
  CAPTURE_VIDEO_MANUALLY: 'Please record the video manually using the camera button.',
  PLAY_ANIMATION_TEXT: 'Play the animation',
});
