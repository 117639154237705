import React from 'react';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import is from 'next-is';
import PropTypes from 'prop-types';
import Ink from 'react-ink';
import BaseComponent from 'components/BaseComponent';

// Accessing camera seems to block UI. We need to give some time to javascript engine
// so CSS changes apply to the screen.
const DOM_FLUSH_TIMEOUT = 60;
const NO_INK = [
  'link',
  'link-unstyled',
  'no-theme',
];
let endTimeout;

export default class Button extends BaseComponent {
  className = 'ts-Button';

  static propTypes = {
    disableInk: PropTypes.bool,
    // TODO: Deprecate visuallyDisabled in favor of disabled
    visuallyDisabled: PropTypes.bool,
    mainAction: PropTypes.bool,
    children: PropTypes.node,
    size: PropTypes.oneOf(['huge', 'big', 'medium', 'small']),
    iconPosition: PropTypes.oneOf(['left', 'right']),
    shape: PropTypes.oneOf(['circle', '']),
    theme: PropTypes.oneOf([
      'success',
      'primary',
      'secondary',
      'ternary',
      'info',
      'warning',
      'danger',
      'link',
      'link-unstyled',
      'blue',
      'dark',
      'disabled',
      'no-theme',
      '', // It's used in SignOutModal.js
    ]),
    onClick: PropTypes.func,
  };

  static defaultProps = {
    disableInk: false,
    visuallyDisabled: false,
    mainAction: false,
    size: 'medium',
    theme: 'success',
    shape: '',
  };

  /**
   * setProcessing static method is shorthand for:
   * if (this.refs.buttonInstance) {
   *   this.refs.buttonInstance.setProcessing();
   * }
   */
  static setProcessing(buttonInstance, processing, callback) {
    if (buttonInstance) {
      if (ENV === 'local' && !buttonInstance.setProcessing) {
        // eslint-disable-next-line
        console.error('[DEBUG] Please provide `setProcessing` method to your button component.');
      }
      buttonInstance.setProcessing(processing, callback);
    } else if (callback) {
      callback();
    }
  }

  /**
   * setDisabled static method is shorthand for:
   * if (this.refs.buttonInstance) {
   *   this.refs.buttonInstance.setDisabled();
   * }
   */
  static setDisabled(buttonInstance, disabled, callback) {
    if (buttonInstance) {
      if (ENV === 'local' && !buttonInstance.setDisabled) {
        // eslint-disable-next-line
        console.error('[DEBUG] Please provide `setDisabled` method to your button component.');
      }
      buttonInstance.setDisabled(disabled, callback);
    } else if (callback) {
      callback();
    }
  }

  state = {};

  handleClick = (event) => {
    if (this.props.onClick) {
      this.props.onClick(event);
    }

    if (this.props.onClick && !is.iOS()) {
      const { body } = document;
      body.classList.add('app-in-transition');
      clearTimeout(endTimeout);
      endTimeout = setTimeout(() => {
        // on touch devices multiple events are called on click, this might
        // cause weird behaviour like dialog disapear right after showing up.
        body.classList.remove('app-in-transition');
      }, 250);
    }
  }

  setDisabled(disabled = true, callback) {
    this.setState(
      { disabled },
      callback ? debounce(callback, DOM_FLUSH_TIMEOUT) : undefined
    );
  }

  setProcessing(processing = true, callback) {
    this.setState(
      { processing },
      callback ? debounce(callback, DOM_FLUSH_TIMEOUT) : undefined
    );
  }


  render() {
    const { size, theme, shape, iconPosition } = this.props;
    const disabled = typeof this.state.disabled === 'boolean' ?
      this.state.disabled : this.props.disabled; // state.disabled > props.disabled.
    const { processing } = this.state;

    const classNames = {
      [`--${theme}`]: theme,
      [`--${size}`]: true,
      [`--shape-${shape}`]: shape,
      [`--icon-${iconPosition}`]: iconPosition,
      '--main-action': this.props.mainAction,
      '--disabled': processing || disabled || this.props.visuallyDisabled,
      '--processing': processing,
    };

    const isInked = !this.props.disabled &&
      !this.props.disableInk &&
      !NO_INK.includes(theme);

    return (
      <button
        { ...omit(this.props, [
          'size', 'theme', 'shape', 'mainAction', 'iconPosition',
          'visuallyDisabled', 'disableInk',
        ]) }
        className={ this.rootcn(classNames) }
        disabled={ processing || disabled }
        onClick={ this.handleClick }
      >
        <i className={ this.cn`__spinner` } />
        { this.state.msg || this.props.children }
        { isInked && <Ink duration={ 400 } /> }
      </button>
    );
  }
}
