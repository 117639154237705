import React from 'react';
import BaseComponent from 'components/BaseComponent';

export default class BannerPixelPin extends BaseComponent {
  className = 'ts-BannerPixelPin';

  render() {
    return (
      <div className={ this.rootcn() }>
        <div className="ts-container">
          <h1>CCPA Privacy Request<br />PixelPin</h1>
        </div>
      </div>
    );
  }
}
