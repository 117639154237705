import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, autoRehydrate } from 'redux-persist';
import is from 'next-is';
import thunk from 'redux-thunk';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import noop from 'no-op';
import reducers, { reducersMap } from 'reducers';

const composeEnhancers =
  ENV === 'local' && is.browser() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) :
    compose;

const reduxStore = createStore(
  reducers,
  {},
  composeEnhancers(
    applyMiddleware(
      thunk,
    ),
    autoRehydrate({
      log: ENV === 'local',
    })
  )
);

// INFO: It's easier to control what we want to persist than what we have to exclude
const reducersWhitelist = ['businessSignUp', 'form', 'resetPIN', 'profile'];
const reducersBlacklist = Object.keys(reducersMap).filter(
  (reducer) => !reducersWhitelist.includes(reducer)
);
// INFO: Excluding some fields from whitelisted reducer
const formBlacklistFilter = createBlacklistFilter(
  'form',
  ['reportProblem']
);

let persistor = noop;
if (is.browser()) {
  persistor = persistStore(
    reduxStore,
    {
      blacklist: reducersBlacklist,
      transforms: [
        formBlacklistFilter,
      ],
      debounce: 500,
    }
  );
}

export { persistor };
export default reduxStore;
