/* eslint-disable max-len */
import React from 'react';
import BaseComponent from 'components/BaseComponent';
import { asset } from 'sf/helpers';

export default class IdentifyContent extends BaseComponent {
  className = 'ts-IdentifyContent';

  render() {
    return (
      <section className={ this.rootcn() } >
        <div className="ts-container">
          <div className={ this.cn`__column __column--left` }>
            <img src={ asset`ai/verify-phone.jpg` } alt="TRUST STAMP IDENTIFY" width="100%" />
          </div>
          <div className={ this.cn`__column __column--right` }>
            <h6>TRUST STAMP IDENTIFY</h6>
            <h2>Automated document verification with a multi-faceted approach to fraud prevention</h2>
            <p>ID documents once set the standard for proving authentic identity. With the rise in virtual transactions and bad actor sophistication, robust document verification procedures are a critical component in any organisation's fraud prevention and risk management strategy.</p>
            <p>Trust Stamp's real time document analysis with advanced anti-spoofing algorithms makes comprehensive ID verification as simple as capturing a photo. You can determine whether a government-issued ID document is authentic without compromising on user experience.</p>
          </div>
        </div>
      </section>
    );
  }
}
