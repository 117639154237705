import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import brandingVariables from 'brandingVariables';
import BaseComponent from 'components/BaseComponent';
import { BRANDINGS, LOGO_VARIANTS } from 'constants';

export default class Logo extends BaseComponent {
  className = 'ts-Logo';

  static propTypes = {
    appName: PropTypes.string,
    isSignedIn: PropTypes.bool,
    theme: PropTypes.oneOf([
      'default',
      'light',
    ]),
    variant: PropTypes.oneOf([
      'default',
      'realtor',
      'ai',
    ]),
  };

  static defaultProps = {
    appName: 'TrustStamp',
    isSignedIn: false,
    theme: 'default',
    variant: 'default',
  };

  state = { mounted: false };

  componentDidMount() {
    this.setState({ mounted: true }); // eslint-disable-line
  }

  getLogoProps = (variant) => LOGO_VARIANTS[
    LOGO_VARIANTS.hasOwnProperty(variant) ?
      variant :
      BRANDINGS.DEFAULT
  ];

  render() {
    const { theme, variant } = this.props;
    const { mounted } = this.state;
    const brandingClassNames = {
      '__link': true,
      '--hidden': !mounted,
      'ts-branding': true,
      'ts-branding--dark': mounted &&
        theme === 'light' &&
        variant !== BRANDINGS.REALTOR,
      'ts-branding--re': mounted &&
        theme !== 'light' &&
        variant === BRANDINGS.REALTOR,
      'ts-branding--re-dark': mounted &&
        theme === 'light' &&
        variant === BRANDINGS.REALTOR,
    };
    const wrapperClassNames = {
      '__primary-brand': mounted && variant !== BRANDINGS.REALTOR,
      '__re-brand': mounted && variant === BRANDINGS.REALTOR,
    };
    const {
      content,
      secondaryContent,
      secondaryTo,
      to,
      toSignedIn,
    } = this.getLogoProps(variant);
    return (
      <div
        className={ this.rootcn() }
      >
        <div className={ this.cn(wrapperClassNames) }>
          <Link
            className={ this.cn(brandingClassNames) }
            to={ this.props.isSignedIn ? toSignedIn : to }
          >
            { brandingVariables.appName || content }
          </Link>
        </div>
        { secondaryTo &&
          <Link
            className={ this.cn`__secondary-brand` }
            to={ secondaryTo }
          >
            { secondaryContent }
          </Link>
        }
      </div>
    );
  }
}
