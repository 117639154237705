import atom from 'atom-js';
import _get from 'lodash/get';
import config from 'config';
import { post, postMultipart, errorHandler } from 'sf/helpers/request';
import help from 'models/help';
import user from 'models/user';
import * as ProfileActions from 'pages/Profile/Profile.actions';
import reduxStore from 'reduxStore';
import { buildQuery } from 'sf/helpers';
import { dataURItoImage } from 'sf/helpers/canvas';

const model = atom.setup({
  methods: {
    async sendSelfie(resolve, reject, photoDataURI) {
      const image = await dataURItoImage(photoDataURI);

      postMultipart('backend/login/photo/', 'LOGIN')
        .attach('photo', image.data, `photo.${image.extension}`)
        .end((err, res) => {
          if (err) {
            errorHandler(err, res);
            reject();
          } else {
            resolve();
          }
        });
    },
    sendPINCode(resolve, reject, pinCode) {
      const MPHDiscountCode = _get(reduxStore.getState(), 'profile.mph_discount_code');
      post('backend/login/pin_code/', 'LOGIN')
        .send({
          [MPHDiscountCode ? 'discount_code' : null]: MPHDiscountCode,
          pin_code: pinCode,
        })
        .end((err, res) => {
          if (err) {
            const bodyData = err.response && err.response.body && err.response.body.data || {};
            if (bodyData.errorCode !== 404) {
              errorHandler(err, res);
            }
            reject(bodyData);
          } else {
            const { data } = res.body;
            if (data.discount_code && data.discount_code.message) {
              reduxStore.dispatch(
                ProfileActions.clearMPHDiscountCode()
              );
              if (data.discount_code.code === 200) {
                help.addNotification({
                  type: 'success',
                  value: data.discount_code.message,
                  clearOnRouteChange: false,
                });
              } else {
                help.addNotification({
                  type: 'error',
                  value: data.discount_code.message,
                  clearOnRouteChange: false,
                });
              }
            }
            user.set('token', data.token);
            reduxStore.dispatch(ProfileActions.requestProfileData());
            resolve(data.token);
          }
        });
    },
    sendResetPINEmail(resolve, reject, data) {
      post('backend/login/reset_pin/', 'LOGIN')
        .send(data)
        .end((err, res) => {
          if (err) {
            errorHandler(err, res);
            reject();
          } else {
            resolve();
          }
        });
    },
  },
})();

Object.assign(model, {
  logInWithService(service_name) {
    const token = user.get('token');
    const pinResetCode = user.get('pinResetCode');
    const MPHDiscountCode = _get(reduxStore.getState(), 'profile.mph_discount_code');
    /* eslint-disable */
    const callbackQueryParams = buildQuery({
      ts_app_url: `${location.protocol}//${location.host}`,
      login_token: token,
      [MPHDiscountCode ? 'discount_code' : null]: MPHDiscountCode,
      [pinResetCode ? 'code' : null]: pinResetCode,
    });
    const queryParams = buildQuery({
      service: 'social_login',
      callback_uri: `${BACKEND_URL}/login/select_socialnetwork?${callbackQueryParams}`
    });
    window.location.href = `//${config.oneAllApp}.api.oneall.com/socialize/connect/direct/${service_name}/?${queryParams}`;
    /* eslint-enable */
  },
});

if (!model.get('isRealtor')) {
  model.set('isRealtor', global.REALTOR);
}

export default model;
