import atom from 'atom-js';
import _get from 'lodash/get';
import is from 'next-is';
import {
  hexToRGB,
} from 'sf/helpers';
import {
  get,
  postMultipart,
  requestEnd,
} from 'sf/helpers/request';

const getBrandingSubdomain = (brandedHostname = '') => {
  return !brandedHostname && is.browser()
    ? _get(window, 'location.hostname', '').replace(`.${BRANDING_BASE_DOMAIN}`, '')
    : brandedHostname.replace(`.${BRANDING_BASE_DOMAIN}`, '');
};

function shadeColor(color, percent) {
  /* eslint-disable no-bitwise */
  const f = parseInt(color.slice(1), 16);
  const t = percent < 0 ? 0 : 255;
  const p = percent < 0 ? percent * -1 : percent;
  const R = f >> 16;
  const G = f >> 8 & 0x00FF;
  const B = f & 0x0000FF;
  return `#${(0x1000000
    + (Math.round((t - R) * p) + R) * 0x10000
    + (Math.round((t - G) * p) + G) * 0x100
    + (Math.round((t - B) * p) + B)
  ).toString(16).slice(1)}`;
  /* eslint-enable */
}

function getStyles({
  baseBrandColor,
  navigationBarColor,
}) {
  /* eslint-disable max-len */
  if (!baseBrandColor) return '';
  const darkerBrandColor = shadeColor(baseBrandColor, -0.2);
  const lighterBrandColor = shadeColor(baseBrandColor, 0.2);
  const lightBrandColor = shadeColor(baseBrandColor, 0.8);
  const defaultColorToReplace = '#007253';
  return `
    .custom-brand .ts-PageHeader--branding-organization {
      background: ${navigationBarColor};
    }
    .custom-brand .ts-Breadcrumbs__item--active:after {
      background: ${baseBrandColor};
    }
    .custom-brand .ts-Breadcrumbs__item--active:before {
      border-color: ${baseBrandColor};
    }
    .custom-brand .ts-Breadcrumbs__item--active {
      color: ${baseBrandColor};
    }
    .custom-brand .ts-ChangeSelfie__tips-list li:before {
      color: ${baseBrandColor};
    }

    .custom-brand .ts-ValidationInput input:focus:not(.ts-ValidationInput__field--danger) {
      border-color: ${baseBrandColor};
    }

    .custom-brand .ts-ValidationTextarea textarea:focus:not(.ts-ValidationTextarea__field--danger) {
      border-color: ${baseBrandColor};
    }

    .custom-brand .ts-MultipleInput__select.is-focused:not(.is-disabled) .Select-control {
      border-color: ${baseBrandColor};
    }

    .custom-brand .ts-Select__field.is-focused:not(.is-disabled) .Select-control  {
      border-color: ${baseBrandColor};
    }

    .custom-brand .ts-Select__field .Select-option.is-focused {
      background-color: ${hexToRGB(baseBrandColor, 0.2)};
    }

    .custom-brand .ts-DatePicker__calendar .react-datepicker__day:hover,
    .custom-brand .ts-DatePicker__calendar .react-datepicker__day--keyboard-selected,
    .custom-brand .ts-DatePicker__calendar .react-datepicker__day--selected {
      background-color: ${baseBrandColor};
      color: #ffffff !important;
    }

    @keyframes pulseCustomColor {
      0% {
        background: ${hexToRGB(baseBrandColor, 0.2)};
        box-shadow: none;
        color: #ffffff; }
      50% {
        background: ${hexToRGB(lighterBrandColor, 1)};
        box-shadow: 0 0 15px 0 rgba(102, 113, 120, 0.2);
        color: #ffffff;
        opacity: 1; }
      100% {
        background: ${hexToRGB(baseBrandColor, 0.2)};
        box-shadow: none;
        color: #ffffff; }
    }

    .custom-brand .ts-Button--processing {
      animation: pulseCustomColor 4s ease-in-out infinite;
    }

    .custom-brand path[fill="${defaultColorToReplace}"],
    .custom-brand rect[fill="${defaultColorToReplace}"],
    .custom-brand ellipse[fill="${defaultColorToReplace}"],
    .custom-brand rect[fill="#00B06F"],
    .custom-brand polyline[fill="${defaultColorToReplace}"] {
      fill: ${baseBrandColor};
    }

    .custom-brand .ts-StatusBackground-default,
    .custom-brand .ts-StatusBackground--default {
      background-color: ${baseBrandColor}; }
    .custom-brand .ts-Button--theme-action {
      background-color: ${baseBrandColor}; }

    .custom-brand .ts-Link,
    .custom-brand .ts-Button--theme-link-unstyled {
      color: ${baseBrandColor} !important;
    }

    .custom-brand .ts-Link:focus,
    .custom-brand .ts-Link:hover {
      color: ${lightBrandColor} !important;
    }

    .custom-brand .ts-PageHeader .ts-UserPanel__navbar-item--log-in a,
    .custom-brand .ts-PageHeader .ts-UserPanel__navbar-item a:hover,
    .custom-brand .ts-PageHeader--light .ts-UserPanel__navbar-item a,
    .custom-brand .ts-PageHeader--light .ts-UserPanel__navbar-item a:hover {
      color: ${baseBrandColor};
    }

    .custom-brand .ts-Index__section--sign-up {
      background: ${baseBrandColor};
    }

    .custom-brand .ts-UserPanel__button--big {
      border-color: ${darkerBrandColor};
    }

    .custom-brand .ts-InputReduxForm--active .ts-InputReduxForm__field
    {
      border-color: ${baseBrandColor};
      box-shadow: inset 0 0 0 1px ${darkerBrandColor};
    }

    .custom-brand .ts-Button--success,
    .custom-brand .ts-Button--theme-success {
      background: ${baseBrandColor};
    }

    .custom-brand .ts-Button--ternary:hover {
      color: ${baseBrandColor};
    }

    .custom-brand .ts-Button--accent,
    .custom-brand .ts-Button--accent:focus,
    .custom-brand .ts-Button--accent:active{
      background: ${hexToRGB(baseBrandColor, 1)};
      color: #f8f8f8;
    }

    .custom-brand .ts-Button--theme-action.ts-Button--theme-disabled,
    .custom-brand .ts-Button--theme-action.ts-Button--theme-disabled:focus,
    .custom-brand .ts-Button--theme-action.ts-Button--theme-disabled:active,
    .custom-brand .ts-Button--theme-action.ts-Button--disabled,
    .custom-brand .ts-Button--theme-action.ts-Button--disabled:focus,
    .custom-brand .ts-Button--theme-action.ts-Button--disabled:active {
      background: ${hexToRGB(baseBrandColor, 0.2)};
    }

    .custom-brand .ts-Button--theme-action:focus,
    .custom-brand .ts-Button--theme-action:hover {
      background-color: ${darkerBrandColor}; }

    .custom-brand .ts-Button--theme-disabled,
    .custom-brand .ts-Button--theme-disabled:focus,
    .custom-brand .ts-Button--theme-disabled:active,
    .custom-brand .ts-Button--disabled,
    .custom-brand .ts-Button--disabled:focus,
    .custom-brand .ts-Button--disabled:active {
      background-color: ${hexToRGB(baseBrandColor, 0.2)};
    }

    .custom-brand .ts-UserPanel__navbar-item--notification .ts-Button {
      background-color: transparent;
    }

    .custom-brand .ts-themed-color {
      background-color: none;
      color: ${baseBrandColor};
    }

    .custom-brand .ts-Button--theme-icon-action {
      background-color: none;
      color: ${baseBrandColor};
    }

    .custom-brand .ts-Button--theme-icon-action:focus,
    .custom-brand .ts-Button--theme-icon-action:hover {
      color: ${darkerBrandColor} !important;
    }

    .ts-PaymentCard__input-wrap {
        height: 55px;
    }

    .custom-brand .ts-Button--theme-action.ts-Button--outlined {
      border-color: ${baseBrandColor};
      color: ${baseBrandColor};
    }

    .custom-brand .ts-Button--theme-action.ts-Button--outlined:focus,
    .custom-brand .ts-Button--theme-action.ts-Button--outlined:hover {
      border-color: ${darkerBrandColor};
      color: ${darkerBrandColor};
    }

    .custom-brand .ts-Button--theme-icon-action.ts-Button--disabled {
      background: none;
      color: #bbbbbe;
    }

    .custom-brand .ts-Button--theme-disabled,
    .custom-brand .ts-Button--theme-disabled:focus,
    .custom-brand .ts-Button--theme-disabled:active,
    .custom-brand .ts-Button--disabled,
    .custom-brand .ts-Button--disabled:focus,
    .custom-brand .ts-Button--disabled:active {
      background-color: ${hexToRGB(baseBrandColor, 0.2)};
    }

    .custom-brand .ts-Button--secondary,
    .custom-brand .ts-Button--theme-action {
        background-color: ${baseBrandColor};
      }

    .custom-brand .ts-Stamp__coin {
      background-color: ${baseBrandColor};
    }

    .custom-brand .ts-Stamp__coin:after {
      color: ${lightBrandColor} !important;
    }

    .custom-brand .ts-Button .ts-IconButton__icon:not('.ts-Button--medium ts-Button--disabled') svg rect[fill] {
      fill: ${baseBrandColor};
    }

    .custom-brand .ts-UserPanel__dropdown-item--active a:link,
    .custom-brand .ts-UserPanel__dropdown-item--active a:visited,
    .custom-brand .ts-UserPanel__dropdown-item--active a:hover,
    .custom-brand .ts-UserPanel__dropdown-item--active a:active,
    .custom-brand .ts-UserPanel__navbar-item--active a:link,
    .custom-brand .ts-UserPanel__navbar-item--active a:visited,
    .custom-brand .ts-UserPanel__navbar-item--active a:hover,
    .custom-brand .ts-UserPanel__navbar-item--active a:focus,
    .custom-brand .ts-UserPanel__navbar-item--active a:active {
      color: ${baseBrandColor};
    }

    .custom-brand .ts-UserPanel__dropdown-item--active a:hover,
    .custom-brand .ts-UserPanel__navbar-item--active a:hover {
      color: ${darkerBrandColor}; }

    .custom-brand .ts-CreditsAmountIcon {
      background-color: ${baseBrandColor}; }

    .custom-brand .ts-Radio__radio--selected {
      box-shadow: inset 0 0 0 5px #ffffff, inset 0 0 0 12px ${baseBrandColor}; }

    .custom-brand .ts-PageTitle--underline:after {
      background-color: ${baseBrandColor};
    }

    .custom-brand .ts-services__title-icon {
      color: ${baseBrandColor};
    }

    .custom-brand .ts-Input:focus {
      border-color: ${baseBrandColor};
      -webkit-box-shadow: 1px 3px 9px 0 #f8f8f8, inset 0 0 0 1px ${baseBrandColor};
      box-shadow: 1px 3px 9px 0 #f8f8f8, inset 0 0 0 1px ${baseBrandColor};
    }

    .custom-brand .ts-Select__field .Select-control:focus {
      border-color: ${baseBrandColor};
      -webkit-box-shadow: 1px 3px 9px 0 #f8f8f8, inset 0 0 0 1px ${baseBrandColor};
      box-shadow: 1px 3px 9px 0 #f8f8f8, inset 0 0 0 1px ${baseBrandColor};
    }

    .custom-brand .ts-Select__field .Select-menu-outer {
      border-left-color: ${baseBrandColor};
      border-right-color: ${baseBrandColor};
      border-bottom-color: ${baseBrandColor};
    }

  `;
  /* eslint-enable max-len */
}

const model = atom.setup({
  validation: {
    customStyles: null,
    customScripts: null,
    footerTheme: null,
    status: null,
    wideHeader: null,
    withUserPanel: null,
  },
  methods: {
    setBrandingData: (resolve, reject, brandingData) => {
      const { organizationColor, navigationBarColor, logo } = brandingData;
      const styles = getStyles({
        baseBrandColor: organizationColor,
        headerorganizationColor: navigationBarColor,
      });
      const logoUrl = _get(logo, 'preview');
      model.set({
        customStyles: styles,
        branding_logo_url: logoUrl,
        organizationColor,
      });
      resolve();
    },
    saveBrandingData: (resolve, reject, brandingData) => {
      const { baseBrandColor, navigationBarColor, logo } = brandingData;
      postMultipart('backend/organization_admin_panel/branding/', true)
        .field({ name: getBrandingSubdomain() })
        .attach('logo', logo)
        .field('branding_info', JSON.stringify({
          baseBrandColor,
          navigationBarColor,
        }))
        .end(requestEnd(resolve, reject));
    },
    getBrandingDataBySubdomain: (resolve, reject) => {
      const brandingSubdomain = getBrandingSubdomain();
      if (brandingSubdomain) {
        model.getBrandingData(getBrandingSubdomain())
          .then(resolve)
          .catch(reject);
      } else {
        reject();
      }
    },
    getBrandingData: (resolve, reject, brandingId) => {
      model.set({
        isCustomStyleLoading: true,
      });
      get('backend/organization_admin_panel/branding/', 'UNIVERSAL')
        .query({ name: brandingId })
        .end((err, res) => {
          if (err) {
            resolve({
              styles: null,
              logo: null,
            });
          } else {
            const brandingInfo = _get(res.body, 'data.branding_info', {});
            const styles = getStyles(brandingInfo);
            const logoUrl = _get(res.body, 'data.logo');
            model.set({
              customStyles: styles,
              branding_logo_url: logoUrl,
              ...brandingInfo,
            });
            resolve({
              styles: styles,
              branding_logo_url: logoUrl,
              ...brandingInfo,
            });
          }
          model.set({
            isCustomStyleLoading: false,
          });
        });
    },
  },
})({});
export default model;
