/* eslint-disable max-len */
import React from 'react';
import BaseComponent from 'components/BaseComponent';
import VideoAndContent from 'components/VideoAndContent';
import { VIDEO_CONTENT } from './constants';


export default class VerifyContent extends BaseComponent {
  className = 'ts-VerifyContent';

  render() {
    return (
      <div className={ this.rootcn() } >

        <VideoAndContent content={ VIDEO_CONTENT } />

        <br />
        <section>
          <div className="ts-container">
            <h2>A key component of your digital transformation</h2>
            <p>Biometric tools offer an accurate and efficient process for virtual authentication. Trust Stamp Verify has broad benefits to user experience, fraud-prevention, customer retention, and more, so you can successfully navigate the digital-first future while prioritising the security of your organisation and your users. Trust Stamp’s interoperable biometric solutions are designed with the future in mind. Our tools work across systems, vendors, and devices, as the building blocks to a robust identity strategy. Trust Stamp Verify empowers your users to quickly and securely prove identity, without putting sensitive data at risk.</p>
          </div>
        </section>
      </div>
    );
  }
}
