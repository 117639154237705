import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import Icon from 'sf/components/Icon';

export default class IconButton extends BaseComponent {
  className = 'ts-IconButton';

  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    buttonType: PropTypes.string,
    type: PropTypes.string.isRequired,
    theme: PropTypes.string,
    iconSize: PropTypes.number,
    set: PropTypes.string,
    transparent: PropTypes.bool,
  };

  static defaultProps = {
    iconSize: 24,
    set: 'io',
    theme: 'link',
  };

  render() {
    return (
      <Button
        className={ this.rootcn({ '--transparent': this.props.transparent }) }
        disabled={ this.props.disabled }
        theme={ this.props.theme }
        onClick={ this.props.onClick }
        onBlur={ this.props.onBlur }
        type={ this.props.buttonType }
      >
        <Icon
          className={ this.cn`__icon` }
          set={ this.props.set }
          type={ this.props.type }
          size={ this.props.iconSize }
        />
        { this.props.children }
      </Button>
    );
  }
}
