/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import BasePage from 'pages/BasePage';
import { ROUTES } from 'constants';

export default class CCPAPrivacyRequest extends BasePage {
  className = 'ts-CCPAPrivacyRequest';
  static title = 'CCPA Privacy Request';
  // eslint-disable-next-line max-len
  static description = 'We research and leverage artificial intelligence and machine learning to deliver the most comprehensive facial biometric identity authentication solution.';

  static defaultProps = {
    thankYou: false,
  }

  state = {};

  performSuccessRedirect = () => {
    this.navigate(ROUTES.CCPA_THANK_YOU);
  }

  // banner section
  renderMainSection() {
    return (
      <section className={ this.cn`__hero` }>
        <div className={ this.cn`__hero-color` } />
        <div className={ this.cn`__hero__container` }>
          <h1 className={ this.cn`__hero__title` }>
            CCPA Privacy Request<br />Trust Stamp
          </h1>
        </div>
      </section>
    );
  }

  // form section
  renderFormSection() {
    if (this.state.isMessageSent || this.props.thankYou) {
      return (
        <div className={ this.cn`__confirmation` }>
          <h3
            className={ this.cn`__form__title` }
            ref={ (ref) => ref && scrollTo(ref, 250, 250) }
          >
            Thank you for contacting us.
          </h3>
          <p>
            Your message has been successfully sent. We will reply as soon as
            possible.
          </p>
        </div>
      );
    }

    const CCPAForm = require('./CCPAForm');
    return (
      <CCPAForm onDone={ this.performSuccessRedirect } />
    );
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        { /* banner */ }
        { this.renderMainSection() }
        { /* form */ }
        { this.renderFormSection() }
      </div>
    );
  }
}
