/* global ASSETS_URL */

require('sf').configure({
  SpinnerComponent: require('components/Spinner'),
  assetsURL: ASSETS_URL,
  errorHandler: customErrorHandler,
  backendURL: BACKEND_URL,
});


// require instead of imports to load polyfills first.
const React = require('react');
const { Router, browserHistory, match } = require('react-router');
const Html = require('layout/Html');
const is = require('next-is');
const { createRoot } = require('react-dom/client');
const Routes = require('./routes');
const setupAuthorization = require('../../setupAuthorization');

const { mediator } = require('sf/helpers');

const help = require('models/help');

require('bootstrap/scss/bootstrap.scss');
require('../../app.scss');

if (!is.browser()) {
  // list all modules required for back-end rendering.
  module.exports = {
    './routes': Routes,
    './layout/Html': Html,
  };
} else {
  setupAuthorization();


  // global.require = require;
  // front-end: init app.
  match({
    history: browserHistory,
    routes: Routes,
  }, (error, redirectLocation, renderProps) => {
    const root = createRoot(document.querySelector('#app'));
    root.render(<Router { ...renderProps } />);

    is.appendBrowsers();
  });
}

function customErrorHandler(err, res, level = 'error') {
  try {
    const resData = res.body.data;
    if (resData && resData.message) {
      if (level === 'error') {
        let errorMessage = resData.message;
        // override main response message with validator ones (if they exist)
        if (resData.validation) {
          errorMessage = Object.values(resData.validation);
        }
        if (errorMessage && !Array.isArray(errorMessage)) {
          errorMessage = [errorMessage];
        }
        help.clearNotifications();
        errorMessage.forEach((value) => {
          help.addNotification({
            type: 'error',
            title: 'Error',
            value,
          });
        });
        help.open();
      } else {
        mediator.publish(level, resData.message);
      }
    } else {
      console.error(resData); // eslint-disable-line no-console
    }
  } catch (e) {
    console.error(err.toString()); // eslint-disable-line no-console
  }
}
