/* eslint-disable max-len */
import React from 'react';
import BasePage from 'pages/BasePage';
import { Link } from 'react-router';
import Banner from 'components/Banner';

export default class DigitalIdentity extends BasePage {
  className = 'ts-DigitalIdentity';
  static title = 'Digital Identity';

  renderContent() {
    return (
      <section>
        <div className="ts-container">
          <h2>
            MEDICI Global’s Invisible FinTech Podcast visits Identity and
            Tokenization with Trust Stamp
          </h2>
          <div className={ this.cn`__info` }>
            Via{ ' ' }
            <Link
              href="https://gomedici.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              MEDICI Global
            </Link>
          </div>
          <p>
            Trust Stamp Chief Innovation Officer and former VP of Humanitarian &
            Development (Digital Identity) at Mastercard, Raman Narayanswamy,
            joined MEDICI Global for an episode in their Invisible FinTech -
            Open Banking and APIs podcast series hosted by Amit Goel (Founder of
            MEDICI).
          </p>

          <p>
            Amit and Raman discussed the significance of tokenization in digital
            identity, with key insights highlighted below. Listen to the
            { ' ' }<Link
              href="https://open.spotify.com/episode/0eEP0fRi9mclqdiqs28xfs"
              rel="noopener noreferrer"
              target="_blank"
            >
              full episode
            </Link>{ ' ' }
            on how tokenization enhances security, privacy, and
            consumer control for globally inclusive identity frameworks.
          </p>

          <h5>
            Amit: Could you give us a very high level overview of what Trust
            Stamp does, and how it looks at identity and tokenization?
          </h5>
          <p>
            <strong>Raman:</strong> Trust Stamp’s technology tokenizes biometrics, which is one
            of the hardest data sets in processing aspects because of its
            “fuzzy” nature. We work with tokenization because we are primarily a
            privacy-first, dignity-first company. Today, digital identity does
            not solve for many of the aspects of privacy and consumer control,
            or users’ control of their own identity. Our focus has been on
            taking existing data-driven, or specifically biometrics-driven
            identity, and making it able to prove identity with zero-knowledge,
            or in complete blindness, to say “I am the same person, I exist, I
            am here”. That’s our primary goal. We have started with biometrics,
            and we have been able to prove that we can do token matching in the
            fuzzy realm using biometrics
          </p>
          <h5>
            Amit: How do you think digital identity is evolving beyond
            authentication and KYC? A lot of people, when talking about digital
            identity, talk about KYC, authentication, etc. I think some points
            that you touched upon suggest that it is a much broader field.
          </h5>
          <p>
            <strong>Raman:</strong> You highlighted two very important aspects. Most identity
            platforms start from KYC - they are centered around KYC. In
            consumers’ relationships with any institution, the KYC component is
            probably only one time, or maybe once in every five years or so. You
            don’t produce a driver's license to get into your car to drive -
            that is the kind of analogy I want to bring to the table here.
            Authentication is 99% of your digital existence. It is where you
            assert your identity, saying that I exist, I am here, and I am the
            same person. We flipped the model on its head and asked how we can
            make identity functional, and not just a KYC component. It has to
            work across the board, whether it is tied to your mobile device,
            whether it is on a chip-based card, or whether it’s just you,
            yourself. Can an identity work if I just show up without any device?
          </p>

          <h5>
            Amit: What are the frameworks we devise for certain geographies and
            industries, and what are the problems are they trying to solve? For
            our listeners who are coming from FinTech and financial services, I
            think it is important for them to understand how the experts think
            about it.
          </h5>

          <p>
            <strong>Raman:</strong> Geographies add significant complexity to how identity or any
            data is processed. For the most part, solutions out there are
            designed for the connected world. If you have internet connection or
            cell phone connection, most of your identity works quite seamlessly.
            It doesn’t work very well in hard to reach places, where a large
            portion of this planet lives. You cannot reach them there; it does
            not work in an offline context. How can we push the cryptography and
            the processing capability to the edge? Can any device in the
            marketplace become an acceptance device, specifically for identity,
            and in the future, for any other transaction? It is extremely
            important to create a solution that will scale across the board, all
            the way from connected environments to offline environments.
          </p>
          <h5>
            Amit: What actually is tokenization of identity, and why is it
            necessary?
          </h5>
          <p>
            <strong>Raman:</strong> If you want to process your biometrics today, or if you want
            to produce a digital identity today, you basically end up leaving
            your fingerprints everywhere. How do you know that the service
            provider is not storing it and using it at a future time? How do you
            know that it is not being intercepted or prone to a
            man-in-the-middle attack? All of these identifiers are directly
            linked to you, so any compromise in the digital realm is going to
            have devastating consequences for the consumer. Imagine if your
            biometric was compromised. What is the remediation here? Compare it
            to a financial instrument. If you lose your credit card or your
            debit card, they can be very easily revoked and replaced. The loss
            can be quantified, monetized, and replaced. That is not the case in
            terms of identity. The reason for tokenization when looking at
            identity is that it is un-linkable to the human - only the human can
            link it to themselves. It is completely obfuscated and cannot be
            reversed-engineered to point to a single person.
          </p>

          <h5>
            Amit: I have been asked this question many times, especially with
            respect to biometrics. If somebody steals your biometric data, what
            happens? The person has only one set of biometrics, so that can pose
            a big challenge.
          </h5>

          <p>
            <strong>Raman:</strong> It is an insurmountable challenge. That is why in the
            tokenization realm, you can revoke and reissue a token. You never
            compromise the underlying sensitive value; the biometric is never
            the ecosystem, anywhere. We derive the tokens using the biometric.
            If the token gets compromised for whatever reason, the keys can be
            revoked and new tokens can be issued. We provide this granular
            control.
          </p>

          <p>
            Most people talk about consumer control of their digital identity,
            but consumer control doesn’t exist until and unless a consumer can
            actually revoke it themselves. Today, most of your digital identity
            is controlled by institutions. They give you the digital identity,
            and everything is controlled by them. What if the consumer was able
            to say, “I don’t want to be part of the relationship anymore”? That
            is true consumer control. With tokenization, any party, either the
            network that is servicing the token, the issuer of the token, or
            consumer themselves, can revoke the token for security and privacy
            purposes.
          </p>

          <h5>
            Amit: What type of tokenization are we talking about broadly here?
            Is it hardware-based or software-based? Given that there are so many
            different types of needs and scenarios, how do you fit the model
            into various needs and scenarios which are out there?
          </h5>

          <p>
            <strong>Raman:</strong> The token transformation itself is software-based. The
            matching component can also be software-based. For the most part,
            the solution is delivered as APIs and SDKs. It can be pushed to the
            edge and pushed to offline environments. We do prefer certain
            hardware restrictions in terms of processing capability, etc., and
            we prefer devices that have secure execution environments if the
            process needs to be pushed to the edge, especially if you make it an
            acceptance device. For the most part, the solution needs no specific
            hardware - no specific scanners for the biometrics, etc. Any
            smartphone with a five megapixel camera can process the biometric
            without having the expensive physical scanners in the marketplace.
          </p>

          <h5>
            Amit: How do we push this to the edge? Are you talking of
            decentralized identity here?
          </h5>
          <p>
            <strong>Raman:</strong> Yes, it works both as a decentralized and centralized model.
            Typically, centralized models are in large ecosystems such as national
            IDs, EIDs, or other large networks. If you are looking at verifiable
            credentials like COVID19 certificates, these are typically
            decentralized.
          </p>
          <h5>
            Amit: What are the biggest drivers in this marketplace? There are a
            lot of realities on the ground; things are changing fast in certain
            countries. What is driving this particular space?
          </h5>
          <p>
            <strong>Raman:</strong> When we started, the biggest concern was primarily privacy.
            All of the biometric implementations we saw were challenging. They
            were all centrally managed, centrally located, and they couldn’t
            really scale beyond immediate government use. How do you put a
            biometric solution out there that cannot be exploited? How do you
            put it out there for commercial use? That is the major challenge.
            The fundamental component is privacy. How can we exchange biometrics
            without ever having to store biometrics anywhere in the ecosystem –
            not in the last mile, and not in the central server? Is there a
            mechanism where we can actually assert an identity without ever
            exposing the underlying sensitive value, in this case the biometric
            itself?
          </p>

          <h5>
            Amit: It may also be very useful for our audience to know of some
            examples of use cases for FinTech. From an identity and trust
            perspective, what are some use cases you can mention?{ ' ' }
          </h5>
          <p>
            <strong>Raman:</strong> Look at what’s emerging in the market today, like digital
            wallets, etc. Most of them are protected using a passcode or PIN, or
            they are device-dependent using a FIDO-like solution where you need
            a smartphone to be able to process financial transactions either
            through a digital wallet or something stored on the phone itself.
            They have a limited reach because they only work in the connected
            world with someone who has an expensive smartphone in their pocket
            or other device access. How do we extend these technologies to
            anybody and everybody? How do I become the financial instrument
            itself? Can I unlock that capability virtually, where only I can
            unlock it and authorize the processing of a payment? How do we
            tie a virtual wallet to an identity where you don’t need an
            instrument, like a smartphone, or a payment card? You can just walk
            up to a payment terminal and assert your identity by using your face
            and a second modality, making it multi-factor.
          </p>

          <h5>
            Amit: Given that you were at Mastercard, handling digital identity,
            how did that transition happen to Trust Stamp? What was it that you
            looked forward to as the Chief Innovation Officer of Trust Stamp?
          </h5>

          <p>
            <strong>Raman:</strong> Trust Stamp came through Mastercard’s Start Path program,
            their incubator for innovative companies. We took their technology
            and had them implement certain ideas, specifically for the
            underserved community and for financial inclusion. How do I provide
            an identity to somebody who has nothing – no piece of paper that can
            prove who they are? How do you give them a mechanism to transact
            digitally and build a digital existence without a government backing
            or government identity? That was the principle behind what we
            started at Mastercard.
          </p>

          <p>
            I feel that there is tremendous potential in the technology,
            especially when it comes to where this tokenization can go - not
            just biometrics, but all personal information. Today, personal
            information is exchanged maybe at best over secure channels, but it
            is still exchanged in raw form. Can we tokenize and bring fuzzy
            matching to raw data?
          </p>
          <p>
            Where this token technology can go is limitless. Once you enter into
            the world of tokens, the possibilities extend beyond the boundaries
            of what current security models will allow you to do. Because you
            are dealing in tokens, or one-way cryptograms or hashes, you are not
            really exposing anything. You are eliminating the privacy and
            security concerns you would have in a normal digital transaction.{ ' ' }
          </p>
          <h5>Amit: Is there anything else that you would like to share? </h5>
          <p>
            <strong>Raman:</strong> Consider today what has happened in some parts of the world
            where digital identity, especially biometrics, have been left behind
            and compromised. With tokenization technology, this data could not
            have been exploited. The keys can be revoked and the data can be
            nullified very easily, without any compromise to the citizens. As we
            go more and more into a digital existence, this becomes very
            critical. How do we prevent exploitation of digital assets? How do
            we ensure consumer trust on a platform where they know that they are
            in full control and there are mitigation mechanisms if anything were
            to go wrong? That is the most fundamental way to look at how
            identity and data processes will go in the future. We believe that
            zero-knowledge-proofs will become the cornerstone for good security,
            absolute privacy, and even human dignity when it comes to digital
            existence.
          </p>
          <h5>
            Amit: During the introduction, you talked about your company having
            a privacy-first and dignity-first approach to identity. What is
            dignity-first? Can you expand on that and also touch upon how
            inclusion becomes an important aspect?
          </h5>
          <p>
            <strong>Raman:</strong> Take a fundamental concept: I am human, and there are certain
            privileges that come with that. Because I don’t exist on any
            government’s radar or civil registry, I am actually excluded. I am
            excluded from having a bank account, from working in the digital
            economy, getting benefits, etc. I am forced to live in a cash
            economy. That itself is debilitating because it is the most
            inefficient way to live; you practically don’t exist.
          </p>
          <p>
            The second aspect of it involves the cultural component. If you
            don’t have these things, getting access to digital economies becomes
            almost impossible. How do I prove that I’m Raman if I don’t have
            anything like paperwork to prove it? Moreover, imagine if we only
            used face technologies for biometrics. What happens when you go to
            countries where women cannot participate because of their cultural
            limitations around taking a facial picture? How do you solve for an
            identity which will work across the board, crossing cultural
            boundaries and boundaries where abject poverty excludes you from the
            digital marketplace? How do I provide health services, other
            financial services, or even basic educational services to you
            digitally, if you cannot exist digitally?{ ' ' }
          </p>
          <p>
            Not only is privacy the most “holy grail” to achieve, but so is
            dignity and making sure the solution addresses anything and
            everything that can possibly go wrong, especially from the culture
            aspect. We make sure we leave no stone unturned, especially from a
            perspective of inclusion.
          </p>

          <div className={ this.cn`__footnotes` }>
            <h5>
              About{ ' ' }
              <Link
                href="https://gomedici.com/studio/podcasts"
                rel="noopener noreferrer"
                target="_blank"
              >
                MEDICI Invisible FinTech - Open Banking & APIs
              </Link>{ ' ' }
            </h5>
            <small>
              <em>
                This Podcast by MEDICI is hosted by Amit Goel (Founder of
                MEDICI). And focuses on the part of FinTech innovation that is
                invisible to the end-customers. The e-plumbing and financial
                rewiring that is rewriting how financial services are delivered
                and experienced. Fintech, banking and tech folks come here to
                learn about how financial firms (old and new) are leveraging the
                power of open banking and APIs
              </em>
            </small>
          </div>
        </div>
      </section>
    );
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        { /* banner */ }
        <Banner theme="globe" size="small">
          <h1>Digital Identity Blog</h1>
        </Banner>

        { this.renderContent() }
      </div>
    );
  }
}
