import atom from 'atom-js';
import is from 'next-is';
import { post, get, errorHandler, requestEnd } from 'sf/helpers/request';
import { trackEvent } from 'models/analytics';
import { mediator } from 'sf/helpers';

const model = atom.setup({
  methods: {
    acceptInvitation(resolve, reject, invitationObj) {
      post(invitationObj.accept_url, 'UNIVERSAL')
        .type('form')
        .end(requestEnd(resolve, reject));
    },

    rejectInvitation(resolve, reject, invitationObj) {
      post(invitationObj.reject_url, 'UNIVERSAL')
        .type('form')
        .end(requestEnd(resolve, reject));
    },

    getPendingInvitations(resolve, reject) {
      get('backend/invitation/pending/', 'UNIVERSAL')
        .query({
          page: 1,
          number_per_page: 999,
        })
        .end((err, res) => {
          if (err) {
            errorHandler(err, res);
            reject();
          } else {
            const { data } = res.body;
            if (ENV !== 'prod') {
              /* eslint-disable no-console, max-len */
              console.assert(is.number.isInteger(data.current_page), 'wrong current_page in invitation/pending/');
              console.assert(is.isArray(data.data), 'wrong data in invitation/pending/');
              console.assert(is.number.isInteger(data.number_of_pages), 'wrong number_of_pages in invitation/pending/');
              /* eslint-enable */
            }

            // TODO: Move to HelpCenter
            mediator.publish('notification', 'pendingInvitations', data.data.length);

            model.set({
              pendingInvitations: data.data,
            }).then(resolve);
          }
        });
    },

    getAcceptedInvitations(resolve, reject) {
      get('backend/invitation/accepted_requests/', 'UNIVERSAL')
        .query({
          page: 1,
          number_per_page: 999,
        })
        .end((err, res) => {
          if (err) {
            errorHandler(err, res);
            reject();
          } else {
            model.set({
              acceptedInvitations: res.body.data.data,
            }).then(resolve);
          }
        });
    },

    getInfo(resolve, reject, invitationKey) {
      get(`backend/invitation/get_info/${invitationKey}/`, 'UNIVERSAL')
        .end((err, res) => {
          if (err) {
            errorHandler(err, res);
            reject();
          } else {
            model.set({
              credit_price: res.body.data.credit_price,
            }).then(() => resolve(res.body.data.credit_price));
          }
        });
    },

    getList(resolve, reject) {
      get('backend/invitation/history/', 'UNIVERSAL')
        .query({
          page: 1,
          number_per_page: 999, // TODO: pagination. no time for that now
        })
        .end((err, res) => {
          if (err) {
            errorHandler(err, res);
            reject();
          } else {
            model.set({
              invitations: res.body.data.data,
              current_page: res.body.data.current_page,
              number_of_pages: res.body.data.number_of_pages,
            }).then(() => resolve(res.body.data.data));
          }
        });
    },

    getReport(resolve, reject, id) {
      get(`backend/organization_admin_panel/profile_report/${id}`, 'UNIVERSAL')
        .end((err, res) => {
          if (err) {
            errorHandler(err, res);
            reject();
          } else {
            model.set({
              invitation_details: res.body.data,
            }).then(() => resolve(res.body.data));
          }
        });
    },

    reSendInvitation(resolve, reject, resendUrl) {
      post(resendUrl, 'UNIVERSAL')
        .type('form')
        .end((err, res) => {
          if (err) {
            reject(res.body.data.message);
          } else {
            resolve();
          }
        });
    },

    sendInvitation(resolve, reject, contact, senderPays) {
      const trackEventName = senderPays ?
        'WEBAPP_VERIFICATION_SENT' :
        'WEBAPP_INVITATION_SENT';

      trackEvent(trackEventName, {
        email: is.string.isEmail(contact) && contact,
        phone: is.string.isPhone(contact, 'us') && contact,
      });

      post('backend/invitation/send/', 'UNIVERSAL')
        .type('form')
        .send({
          contact,
          sender_pays: senderPays,
        })
        .end((err, res) => {
          if (err) {
            reject(res.body.data.message);
          } else {
            resolve();
          }
        });
    },
  },
})({
  userInput: '',
});

export default model;
