/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router';
import BaseComponent from 'components/BaseComponent';
import { asset } from 'sf/helpers';
import { ROUTES } from 'constants';

export default class DeduplicationChecksContent extends BaseComponent {
  className = 'ts-DeduplicationChecksContent';

  render() {
    return (
      <div className={ this.rootcn() }>
        <section>
          <div className="ts-container">
            <div className={ this.cn`__column __column--left` }>
              <h6>DEDUPLICATION & GREYLIST CHECKS</h6>
              <h2>Catch Repeat Fraud with Trust Stamp</h2>
              <p>
                Are you struggling with repeat fraudsters slipping through? A
                significant portion of fraud is carried out by repeat offenders
                who bypass traditional KYC checks. Despite changing their
                identities, their faces remain the same. With Trust Stamp,
                leverage our biometric tokens to prevent bad actors from getting
                through, thereby enhancing your account opening security.
                Fortify your defenses with our biometric-based identity
                verification solutions.
              </p>
              <br />
              <Link to={ ROUTES.CONTACT_US } className="ts-button--filled">Get in Touch</Link>
            </div>
            <div className={ this.cn`__column __column--right` }>
              <img
                src={ asset`ai/deduplication-checks.svg` }
                alt="Deduplication & Greylist Checks"
                width="217px"
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
}
