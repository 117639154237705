import React from 'react';
import { compose } from 'recompose';
import pick from 'lodash/pick';
import throttle from 'lodash/throttle';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import Dialog, { DialogText } from 'components/Dialog';
import help from 'models/help';
import reportProblem from 'models/reportProblem';
import user from 'models/user';
import { getOffset } from 'sf/helpers/domHelper';
import { Row, Col } from 'sf/components/Grid';
import Icon from 'sf/components/Icon';
import Input from 'sf/components/Input';
import TextArea from 'sf/components/TextArea';
import withStateLink from 'sf/hoc/StateLink';
import withValidation from 'sf/hoc/Validation';

const ValidationInputWithStateLink = compose(
  withStateLink,
  withValidation,
)(Input);

const ValidationTextAreaWithStateLink = compose(
  withStateLink,
  withValidation,
)(TextArea);

const FORM_FIELDS = ['email', 'name', 'message'];

const INITIAL_STATE = {
  email: undefined,
  name: undefined,
  message: undefined,
};

// TODO: Clear the form on submit
export default class ReportProblem extends BaseComponent {
  className = 'ts-ReportProblem';
  bottomOffset = 50;

  state = {
    closeToBottom: false,
    ...INITIAL_STATE,
  };

  componentDidUpdate(_props, { isDialogVisible }) {
    if (isDialogVisible !== this.state.isDialogVisible) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState({
        email: user.get('email'),
        name: user.get('legal_name'),
      });
      /* eslint-enable */
      this.dialogInstance.toggle(this.state.isDialogVisible);
    }
  }

  componentDidMount() {
    this.addEventListener(window, 'scroll', this.handleScrollThrottled);
    this.syncStateWithModel(reportProblem, ['isDialogVisible']);
  }

  handleScroll = () => {
    const closeToBottom = getOffset().top + window.innerHeight >
      document.documentElement.offsetHeight - this.bottomOffset;
    this.setState({ closeToBottom });
  };

  handleScrollThrottled = throttle(this.handleScroll, 50);

  dismissDialog = () => {
    if (this.state.isDialogVisible) {
      reportProblem.hideReportProblemDialog();
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const isFormValid = await this.formValidation(
      reportProblem.set(pick(this.state, FORM_FIELDS)),
    )
      .catch(() => false);

    if (!isFormValid) return;

    const isRequestFailed = await reportProblem
      .submitProblem()
      .catch(() => true);

    this.setState(INITIAL_STATE);

    if (isRequestFailed) return;

    reportProblem.hideReportProblemDialog();

    help.addNotification({
      title: 'Your report has been submitted',
      type: 'success',
      value: 'We will get back to you within 24 hours',
    });
  };

  renderButton() {
    return (
      <div
        className={ this.cn`__button` }
        onClick={ reportProblem.showReportProblemDialog }
        role="button"
        tabIndex={ 0 }
      >
        <div className={ this.cn`__button-icon` }>
          <Icon set="io" size={ 19 } type="alert-circled" />
        </div>
        <div className={ this.cn`__button-label` }>
          Report a problem
        </div>
      </div>
    );
  }

  renderDialog() {
    return (
      <Dialog
        className={ this.cn`__dialog` }
        onDismiss={ this.dismissDialog }
        ref={ (dialog) => { this.dialogInstance = dialog; } }
        title="Report a problem"
      >
        <DialogText>
          <div>
            You can report every problem you encounter here.
          </div>
          <div>
            We will get back to you within 24 hours.
          </div>
        </DialogText>
        <Row center={ ['xxsm'] }>
          <Col xxsm={ 12 } xsm={ 10 }>
            { this.renderForm() }
          </Col>
        </Row>
      </Dialog>
    );
  }

  renderForm = () => {
    return (
      <form
        className={ this.cn`__form` }
        onSubmit={ this.handleSubmit }
      >
        <Row>
          <Col xsm={ 6 }>
            <div className={ this.cn`__name` }>
              <span className={ this.cn`__label` }>Your name</span>
              <span className={ this.cn`__input` }>
                <ValidationInputWithStateLink
                  ref="name"
                  stateLink={ [this, 'name'] }
                  placeholder="John Smith"
                />
              </span>
            </div>
          </Col>
          <Col xsm={ 6 }>
            <div className={ this.cn`__email` }>
              <span className={ this.cn`__label` }>Your e-mail</span>
              <span className={ this.cn`__input` }>
                <ValidationInputWithStateLink
                  ref="email"
                  stateLink={ [this, 'email'] }
                  placeholder="john.smith@example.com"
                />
              </span>
            </div>
          </Col>
        </Row>
        <div className={ this.cn`__message` }>
          <span className={ this.cn`__label` }>Describe your problem</span>
          <span className={ this.cn`__input` }>
            <ValidationTextAreaWithStateLink
              /* eslint-disable max-len */
              placeholder="Please try to describe what you were trying to do and what happened. It’ll also be helpful if you let us know what device you are using."
              /* eslint-enable max-len */
              ref="message"
              stateLink={ [this, 'message'] }
              rows="8"
              style={ { height: 140 } }
            />
          </span>
        </div>
        <div className={ this.cn`__submit-button` }>
          <Button
            disabled={ this.shouldDisableSubmitButton() }
            type="submit"
          >
            Send
          </Button>
        </div>
      </form>
    );
  }

  shouldDisableSubmitButton = () => !Object.values(
    pick(
      this.state,
      ['email', 'name', 'message'],
    )
  )
    .every(Boolean);

  render() {
    const classNames = {
      '--active': this.props.isDialogVisible,
      '--bottom': this.state.closeToBottom,
    };
    return (
      <div className={ this.rootcn(classNames) }>
        { this.renderButton() }
        { this.renderDialog() }
      </div>
    );
  }
}
