import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Render from 'components/Render';

export default class Box extends BaseComponent {
  className = 'ts-Box';

  static propTypes = {
    children: PropTypes.node,
    fluid: PropTypes.bool,
    hover: PropTypes.bool,
    onClick: PropTypes.func,
    title: PropTypes.string,
  };

  static defaultProps = {
    children: '',
    fluid: null,
    hover: null,
    onClick: noop,
    title: null,
  };

  render() {
    const { children, fluid, hover, onClick, title } = this.props;
    const clickable = onClick !== noop;
    const classNames = {
      '--clickable': clickable,
      '--fluid': fluid,
      '--hover': hover,
    };
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <div
        className={ this.rootcn(classNames) }
        onClick={ onClick }
        role={ clickable ? 'button' : null }
      >
        <Render when={ title }>
          <div className={ this.cn`__title` }>
            { title }
          </div>
        </Render>
        { children }
      </div>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
  }
}
