import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import Ink from 'react-ink';
import BaseComponent from 'components/BaseComponent';
import Render from 'components/Render';
import Icon from 'sf/components/Icon';

export default class PillButton extends BaseComponent {
  className = 'ts-PillButton';

  static propTypes = {
    children: PropTypes.node,
    icon: PropTypes.string,
    iconSet: PropTypes.string,
    isInked: PropTypes.bool,
    onClick: PropTypes.func,
    shell: PropTypes.bool, // Transparent background theme
    size: PropTypes.oneOf(['small', 'large']),
    type: PropTypes.oneOf([
      'success', 'warning', 'error', 'default', 'transparent',
    ]),
  };

  static defaultProps = {
    children: '',
    icon: null,
    iconSet: 'io',
    isInked: true,
    onClick: noop,
    shell: false,
    size: 'small',
    type: 'default',
  };

  render() {
    const className = {
      [`--${this.props.type}`]: true,
      [`--${this.props.size}`]: true,
      '--hover': this.props.onClick !== noop,
      '--shell': this.props.shell,
    };
    return (
      <button
        className={ this.rootcn(className) }
        onClick={ this.props.onClick }
      >
        <Render when={ this.props.icon }>
          <span className={ this.cn`__icon` }>
            { this.props.icon &&
            <Icon
              set={ this.props.iconSet }
              type={ this.props.icon }
            />
            }
          </span>
        </Render>
        <span className={ this.cn`__label` }>
          { this.props.children }
        </span>
        { this.props.isInked && <Ink duration={ 400 } /> }
      </button>
    );
  }
}
