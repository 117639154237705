
import React from 'react';
import PropTypes from 'prop-types';
import at from 'lodash/at';

import Button from 'components/Button';
import ValidationTextArea from 'components/ValidationTextarea';
import { asset } from 'helpers';
import contact from 'models/contact';
import BasePage from 'pages/BasePage';
import PrivacyPolicy from 'components/PrivacyPolicyDialog';
import ValidationInput from 'sf/components/ValidationInput';

export default class MiniInternalForm extends BasePage {
  className = 'ts-CorporateContactUsAi';

  static propTypes = {
    onDone: PropTypes.func.isRequired,
    source: PropTypes.string,
    // Contact form is sent to [sales@truststamp.net, support@truststamp.net,
    // social@truststamp.net, privacy@truststamp.ai]
    recipient: PropTypes.oneOf(['sales', 'support', 'social', 'privacy']),
  };

  static defaultProps = {
    source: 'Truststamp.ai',
    recipient: 'sales',
  }

  state = {};

  componentDidMount() {
    this.syncStateWithModel(contact, [
      'email',
      'first_name',
      'message',
    ]);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    Button.setProcessing(this.submitButton);
    const hideProcessing = () => {
      Button.setProcessing(this.submitButton, false);
    };

    this.formValidation(contact, 'form.')
      .then(() => {
        const done = () => {
          this.props.onDone();
          contact.clear();
        };
        const fail = (err = {}) => {
          if (Object.keys(err.validation || {}).length) {
            this.formValidation(Promise.reject(err.validation));
          }
          console.log(err); // eslint-disable-line no-console
        };

        contact
          .sendExtendedMessage(this.props.recipient, {
            includeDebugInfo: false,
            captchaResponse: false,
            source: this.props.source,
          })
          .then(done, fail);
      })
      .finally(hideProcessing);
  };

  render() {
    const isFormValid = at(this.state, [
      'email',
      'message',
      'first_name',
      'privacy',
    ])
      .every(Boolean);

    return (
      <div className={ this.rootcn() }>
        <form onSubmit={ this.handleSubmit } className={ this.cn`__form` }>
          <ValidationInput
            className={ this.cn`__input` }
            type="text"
            placeholder="First Name *"
            ref="form.first_name"
            stateLink={ [this, 'first_name'] }
          />
          <ValidationInput
            className={ this.cn`__input` }
            type="text"
            placeholder="E-mail *"
            ref="form.email"
            stateLink={ [this, 'email'] }
          />
          <ValidationTextArea
            className={ this.cn`__textarea` }
            rows={ 6 }
            placeholder="How can we help?"
            ref="form.message"
            stateLink={ [this, 'message'] }
          />
          <div className={ this.cn`__checkbox-row` }>
            { /* // privacy checkbox */ }
            <ValidationInput
              className={ this.cn`__checkbox` }
              type="checkbox"
              ref="form.privacy"
              stateLink={ [this, 'privacy'] }
            />
            <span className={ this.cn`__checkbox-text` }>
              I agree to <PrivacyPolicy />
            </span>
          </div>

          <Button
            className={ this.cn`__button` }
            disabled={ !isFormValid }
            type="submit"
            mainAction={ true }
            ref={ (button) => {
              this.submitButton = button;
            } }
          >
            <span>Send</span>
            <img
              alt=""
              className={ this.cn`__button-icon` }
              src={ asset`ai/arrow.svg` }
            />
          </Button>
        </form>
      </div>
    );
  }
}
