import React from 'react';
import { throttle } from 'lodash';
import Button from 'components/Button';
import BasePage from 'pages/BasePage';
import { ROUTES, YOUTUBE_ASPECT_RATIO } from 'constants';
import { onFullRender } from 'sf/helpers/domHelper';
import device from 'sf/models/device';

const LIST = [
  'Banking/FinTech',
  'Biometrically Secured Email',
  'KYC/AML Compliance',
  'Law Enforcement',
  'P2P Transactions, Social Media and Sharing Economy',
  'Real Estate',
];

export default class AboutUs extends BasePage {
  className = 'ts-CorporateAboutUs';
  static title = 'About Us';
  // eslint-disable-next-line max-len
  static description = 'We research and leverage artificial intelligence and machine learning to deliver the most comprehensive facial biometric identity authentication solution.';

  state = {};

  componentDidMount() {
    this.syncStateWithModel(device, ['xsmUp', 'smUp']);
    this.addEventListener(window, 'resize', this.calculatePlayerWidthThrottled);
    onFullRender().then(this.calculatePlayerWidthThrottled);
  }

  calculatePlayerWidth = () => {
    const computedStyles = window.getComputedStyle(this.playerContainer);
    const [paddingLeft, paddingRight] = ['padding-left', 'padding-right']
      .map((prop) => computedStyles.getPropertyValue(prop))
      .map((value) => parseInt(value.replace('px', ''), 10));
    const playerWidth = this.playerContainer.getBoundingClientRect().width -
      paddingLeft -
      paddingRight;
    this.setState({ playerWidth });
  };

  calculatePlayerWidthThrottled = throttle(this.calculatePlayerWidth, 50);

  renderMainSection() {
    return (
      <section className={ this.cn`__hero` }>
        <div className={ this.cn`__hero-color` } />
        <div className={ this.cn`__hero__container` }>
          <h1 className={ this.cn`__hero__title` }>
            About<br /> Trust Stamp
          </h1>
        </div>
      </section>
    );
  }

  renderMainSectionAboutUs() {
    return (
      <div>
        <section className={ this.cn`__about-us` }>
          <div className={ this.cn`__left` }>
            <h2 className={ this.cn`__about-us-title` }>
              Who are You? and Do I Trust You?
            </h2>
            { /* eslint-disable max-len */ }
            <p className={ this.cn`__about-us-paragraph` }>
              …are the most fundamental questions in every transaction and yet with an onslaught of fraudulent synthetic identities, data hacks, email phishing and account-takeover the answers are increasingly in doubt
            </p>
            <p className={ this.cn`__about-us-paragraph` }>
              Truststamp.ai creates proprietary artificial intelligence solutions; researching and leveraging facial biometric science and wide-scale data mining to deliver insightful identity &amp; trust predictions while identifying and defending against fraudulent identity attacks.
            </p>
            <p className={ this.cn`__about-us-paragraph` }>
              We utilize the cutting-edge power and agility of GPU processing and neural-networks to process data faster and more effectively than has ever previously been possible, as well as deliver results at a disruptively low cost that allows usage across multiple industries, including:
            </p>
            { /* eslint-enable */ }
            <ul className={ this.cn`__about-us__list` }>
              { LIST.map((item) => (
                <li>{ item }</li>
              )) }
            </ul>
          </div>
          <div
            className={ this.cn`__right` }
            ref={ (ref) => { this.playerContainer = ref; } }
          >
            <iframe
              className={ this.cn`__player` }
              width={ this.state.playerWidth || '100%' }
              height={ (this.state.playerWidth * YOUTUBE_ASPECT_RATIO) || '100%' }
              src="https://www.youtube.com/embed/Z1DW1n7GnOQ"
              allow="encrypted-media"
              allowFullScreen={ true }
              frameBorder="0"
            />
            <Button
              onClick={ () => this.navigate(ROUTES.CONTACT_US) }
              className={ this.cn`__about-us__button` }
            >
              Learn more
            </Button>
          </div>
        </section>
      </div>
    );
  }


  render() {
    return (
      <div className={ this.rootcn() }>
        { this.renderMainSection() }
        { this.renderMainSectionAboutUs() }
      </div>
    );
  }
}
