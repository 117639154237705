import React, { Component } from 'react';

export default class Es extends Component {
  render() {
    return (
      <div>
        <h1>
          <u>TÉRMINOS Y CONDICIONES “POWERED BY TRUST STAMP”</u>
        </h1>

        <p>
          <b>¿Quiénes somos?</b>
        </p>
        <p>
          T Stamp Inc., que opera bajo el nombre comercial de Trust Stamp
          (denominado en lo sucesivo como “Trust Stamp” o “nosotros”), es una
          empresa de inteligencia artificial enfocada en proporcionar mayor
          seguridad mediante la autenticación y verificación de identidad
          biométrica. "Powered by Trust Stamp" es un servicio que Trust Stamp
          ofrece a nuestros clientes (cada uno de ellos una “Entidad
          Verificadora” y colectivamente, “Entidades Verificadoras”) que permite
          a la Entidad Verificadora verificar de manera probabilística la
          identidad de su cliente (un “Usuario Final”) a través de los procesos
          seguros de autenticación de Trust Stamp.
        </p>

        <p>
          <b>¿Cómo funciona?</b>
        </p>
        <p>
          "Powered by Trust Stamp" autentifica la identidad de un Usuario Final
          mediante la comparación de datos biométricos derivados de una imagen o
          video del Usuario Final con los datos obtenidos de imágenes conocidas
          del Usuario Final, documentos de identidad proporcionados por el
          Usuario Final, o ambos. Este proceso de autenticación biométrica
          protege tanto al Usuario Final como a la Entidad Verificadora contra
          el acceso no autorizado a cuentas y el fraude de identidad. Cuando
          accede a "Powered by Trust Stamp" para verificar su identidad ante una
          Entidad Verificadora, usted es un “Usuario Final”.
        </p>

        <p>
          <b>¿Cómo usan los datos e información biométrica?</b>
        </p>
        <p>
          La comparación de mediciones biométricas sirve como mecanismo tanto
          para verificar la identidad de un Usuario Final como para confirmar
          los documentos de identidad enviados por dicho Usuario Final. Es con
          este propósito por el cual recopilamos y procesamos sus mediciones
          biométricas en bruto. Procesamos sus mediciones biométricas en bruto,
          ya sea a partir de una imagen en vivo o de un documento, a través de
          un proceso patentado para convertir las mediciones en bruto en un
          Irreversibly Transformed Identity Token (IT2)™. El IT2 es un derivado
          tokenizado único de sus mediciones biométricas en bruto, pero no se
          puede aplicar ingeniería inversa para recrear las mediciones brutas
          originales. Tal y como se utiliza en estos Términos, "Datos
          Biométricos" incluye sus mediciones biométricas en bruto, pero no
          incluye el IT2. Aunque el proceso utilizado para crear el IT2 es
          irreversible, el IT2 conserva propiedades que pueden usarse para
          verificar su identidad de manera probabilística. En otras palabras,
          podemos comparar un IT2 generado actualmente con uno generado a partir
          de sus Datos Biométricos en el pasado o futuro para verificar su
          identidad. Usamos este proceso patentado de comparación probabilística
          para verificar su identidad para una Entidad Verificadora.
        </p>

        <p>
          <u>
            <b>
              La letra pequeña{ ' ' }
              <i>(cosas muy importantes; no se distraiga ahora)</i>
            </b>
          </u>
        </p>
        <p>
          <b>
            Tenga en cuenta que estos Términos incluyen, entre otras cosas, una
            disposición de arbitraje vinculante que le obliga a someterse a
            arbitraje vinculante y final de manera individual para resolver
            disputas, en lugar de un juicio con jurado o una demanda colectiva.
          </b>
        </p>
        <ol>
          <li>
            <p>
              <u>Aceptación; Política de Privacidad.</u>
              Debe tener dieciocho (18) años o más (o la mayoría de edad legal
              en su jurisdicción de residencia, en caso de que ésta sea mayor)
              para utilizar Powered by Trust Stamp (denominado en lo sucesivo
              como "Servicios") para verificar su identidad o autenticar sus
              documentos de identidad. Al acceder y/o utilizar, o continuar
              accediendo o utilizando los Servicios de cualquier manera, usted
              declara que (a) tiene al menos dieciocho (18) años o la edad
              mínima requerida para involucrarse en un contrato legalmente
              vinculante en su jurisdicción de residencia, y tiene el derecho,
              autoridad y capacidad para aceptar estos Términos; (b) acepta
              estos Términos, que también incorporan por referencia la Política
              de Privacidad de Trust Stamp, disponible aquí:
              https://truststamp.ai/PrivacyPolicy.html (la "Política de
              Privacidad"), la cual puede actualizarse ocasionalmente sin previo
              aviso, según lo dispuesto en cada documento; (c) si ha habido
              cambios materiales en estos términos desde la última vez que
              accedió o utilizó los Servicios, usted reconoce y acepta que su
              acceso o uso continuado de los Servicios constituye su aceptación
              de los Términos modificados; y (d) consiente recibir
              comunicaciones electrónicas de nuestra parte y acepta que dichas
              comunicaciones, avisos y/o publicaciones electrónicas satisfacen
              cualquier requisito legal de que dichas comunicaciones sean por
              escrito.
            </p>
          </li>

          <li>
            <p>
              <u>Acceso a los Servicios. </u>
              Sujeto a su conformidad con estos Términos, le otorgamos una
              licencia limitada, no exclusiva, no sublicenciable, revocable e
              intransferible para acceder y utilizar los Servicios. Es una
              condición de su uso de los Servicios que la información que
              proporcione sea correcta, actual y completa. El uso de los
              Servicios es bajo su propio riesgo y reconoce que el proceso
              utilizado es probabilístico y no puede proporcionar una
              verificación absoluta de tu identidad. Cualquier derecho no
              otorgado expresamente aquí está reservado por Trust Stamp. Usted
              es responsable de hacer todos los arreglos necesarios para acceder
              a los Servicios. Los Servicios incluirán, sin limitación,
              cualquier servicio, aplicación o extensión que le ofrezcamos
              directamente o que descargue o reciba de una Entidad Verificadora
              o de tiendas de aplicaciones de terceros, sujeto a los términos de
              dichas tiendas o proveedores. Para usar los Servicios, usted es
              responsable de contar con hardware, software y acceso a internet
              compatibles. No hacemos declaraciones ni garantías con respecto a
              los dispositivos que use para acceder a los Servicios, incluyendo
              su compatibilidad.
            </p>
          </li>

          <li>
            <b>
              <p>
                <u>
                  Consentimiento para la Recopilación, Procesamiento y Uso de
                  Datos Biométricos.
                </u>
                Al utilizar los Servicios, le solicitaremos que proporcione
                imágenes de usted mismo (i.e., un "selfie") (su "Imagen de
                Verificación"), documentos oficiales de identidad con foto (sus
                "Documentos de Identificación"), o ambos. Al utilizar los
                Servicios, usted consiente nuestra recopilación, procesamiento,
                almacenamiento y uso de sus datos biométricos derivados de su
                Imagen de Verificación, sus Documentos de Identificación y
                cualquier imagen fotográfica conocida o previamente verificada
                que nos proporcione la Entidad Verificadora (su "Imagen de
                Identificación"). Utilizaremos su IT2 derivado de su Imagen de
                Verificación, Documentos de Identificación e Imagen de
                Identificación con el fin de verificar y autenticar su
                identidad, a través de un procesamiento automatizado, como la
                misma persona representada en la Imagen de Verificación y en los
                Documentos de Identificación o en la Imagen de Verificación y la
                Imagen de Identificación, dependiendo de la manera en que la
                Entidad Verificadora haya solicitado que verifiquemos su
                identidad. No está obligado a proporcionar su consentimiento, y
                no recopilaremos, procesaremos, almacenaremos ni utilizaremos
                sus Datos Biométricos o IT2 sin su consentimiento. Sin embargo,
                si decide no otorgar su consentimiento, no podrá verificar y
                autenticar su identidad con la Entidad Verificadora utilizando
                los Servicios, lo cual puede afectar a sus interacciones
                posteriores con la Entidad Verificadora. Deberá interactuar
                directamente con la Entidad Verificadora para resolver tales
                problemas.
                <br />
                <br />
                Salvo que se disponga lo contrario en estos Términos, no
                divulgaremos ni difundiremos sus Datos Biométricos o IT2 a nadie
                más que la Entidad Verificadora y nuestras subsidiarias y
                afiliadas sin obtener primero su consentimiento por escrito para
                dicha divulgación o difusión, a menos que dicha divulgación sea:
                <br />
                <ol>
                  <li>
                    <p>
                      requerida por leyes federales, estatales o provinciales o
                      por ordenanzas municipales;
                    </p>
                  </li>
                  <li>
                    <p>
                      para informar sobre el uso fraudulento o intento de uso
                      fraudulento de los Servicios a las autoridades legales
                      correspondientes;
                    </p>
                  </li>
                  <li>
                    <p>
                      o requerida de acuerdo con un mandato, demanda, orden,
                      citación, orden judicial o escrito emitido por un tribunal
                      de jurisdicción competente u otro organismo gubernamental
                      legalmente facultado para emitir dicho proceso legal.
                    </p>
                  </li>
                </ol>
                Una copia de estos Términos está disponible en:{ ' ' }
                <a
                  href="https://truststamp.ai/terms-and-conditions.html?lang=es"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://truststamp.ai/terms-and-conditions.html?lang=es
                </a>
              </p>
            </b>
          </li>

          <li>
            <p>
              <u>Divulgación de Datos a la Entidad Verificadora.</u>
              Una Entidad Verificadora puede desear conservar su Imagen de
              Verificación, Imagen de Documento de Identidad y los datos
              extraídos e IT2 (en conjunto, "Sus Datos") para usarlos en la
              verificación de su identidad en el futuro. Usted autoriza que
              divulguemos su IT2 a una Entidad Verificadora con ese fin. Usted
              consiente que dicha Entidad Verificadora comparta Sus Datos con
              nosotros en el futuro y que utilicemos Sus Datos para: (i)
              verificar su identidad y (ii) detectar actividades ilegales o
              fraudulentas. Usted entiende y acepta que, una vez transferidos a
              una Entidad Verificadora, la retención, almacenamiento y uso de
              Sus Datos estarán sujetos a los términos, condiciones y acuerdos
              entre usted y dicha Entidad Verificadora. Usted acepta que no
              somos responsables de los actos de dicha Entidad Verificadora con
              respecto a Sus Datos divulgados a la Entidad Verificadora.
            </p>
          </li>

          <li>
            <p>
              <u>
                Consentimiento para el Procesamiento y Compartición de Datos de
                Imagen de Verificación y del Documento de Identificación.
              </u>
              Al proporcionar su Imagen de Verificación, da su consentimiento
              expreso para que ésta se utilice, procese mediante procesamiento
              automatizado y se comparta con la Entidad Verificadora con el
              propósito de verificar su identidad. Garantiza y declara que la
              imagen subida es suya y no de un tercero. Confirma que tiene todos
              los derechos y permisos necesarios para enviar esta imagen con los
              fines indicados. Al proporcionar una imagen de su Documento de
              Identificación, declara que usted es el propietario y usuario
              legal de dicho Documento de Identificación, y consiente el uso de
              tecnología de reconocimiento óptico de caracteres (OCR), escaneo
              digital y de radiofrecuencia, y otras tecnologías para leer los
              datos contenidos en su Documento de Identificación, los cuales
              pueden incluir, entre otros, su nombre, dirección, edad, fecha de
              nacimiento, lugar de nacimiento, estado de ciudadanía, color de
              ojos, color de cabello, estatura, peso, raza, etnia, número del
              documento de identidad, fecha de emisión o lugar de emisión (los
              "Datos del Documento de Identificación"). Al enviar una imagen de
              su Documento de Identificación, también reconoce que entiende y
              acepta que (1) podemos compartir su Información de Identificación,
              incluida la imagen de su Documento de Identificación, con la
              Entidad Verificadora, (2) el uso posterior y la disposición de su
              Información de Identificación que transferimos a la Entidad
              Verificadora se regirán únicamente por los términos, condiciones y
              acuerdos entre usted y dicha Entidad Verificadora, (3) en la
              medida en que sea legal, y sin identificarle a usted como el
              sujeto de dichos datos, podemos usar sus Datos para el propósito
              de la mejora, desarrollo y capacitación de nuestros sistemas y
              servicios. Esto incluye, entre otros, el uso de los datos en
              modelos de aprendizaje automático, análisis de datos y otros
              procesos de investigación y desarrollo, y (4) sujeto a las
              limitaciones adicionales de responsabilidad a continuación, usted,
              en su nombre y en nombre de tus herederos y cesionarios, acuerda
              indemnizar, liberar y eximir de responsabilidad a Trust Stamp de
              cualquier y todas las reclamaciones relacionadas con el uso y
              disposición posterior de su Información de Identificación,
              incluidas, entre otras, reclamaciones basadas en (a) el uso,
              divulgación o disposición indebida de su Información de
              Identificación, (b) fraude e identidad, (c) invasión de la
              privacidad o (d) causación intencional o negligente de angustia
              emocional. Si no acepta estas limitaciones, NO debe enviar una
              imagen de tu Documento de Identificación.
            </p>
          </li>

          <li>
            <p>
              <u>Retención de Datos.</u>
              Sus Datos pueden ser retenidos de acuerdo con nuestras
              obligaciones hacia una Entidad Verificadora. Las Entidades
              Verificadoras establecen un período para la retención de la
              totalidad o parte de sus Datos. Salvo que exista una orden
              judicial, citación, orden, mandato u otro requisito legal que lo
              obligue, en ningún caso retendremos sus Datos Biométricos, IT2 e
              Información de Identificación por más de treinta y seis (36)
              meses. Mientras tanto, puede solicitar que eliminemos sus Datos.
              Para solicitar la eliminación de sus Datos, envíe una solicitud
              por correo electrónico a{ ' ' }
              <a href="mailto:privacy@truststamp.net">
                privacy@truststamp.net{ ' ' }
              </a>{ ' ' }
              o por correo a:
              <br />
              <br />
              Data Deletion: Trust Stamp
              <br />
              1st and 2nd Floors, 3017 Bolling Way NE
              <br />
              Atlanta, GA, 30305
              <br />
              <br />
              Una solicitud para que eliminemos sus Datos Biométricos o IT2 no
              afectará a una verificación completada. La eliminación de sus
              Datos Biométricos o IT2 puede tardar hasta diez días hábiles a
              partir de la recepción de la solicitud.
            </p>
          </li>

          <li>
            <p>
              <u>Datos del Dispositivo y Sesión.</u>
              También podemos recopilar datos sobre el dispositivo desde el cual
              se nos transmitió el Documento de Identificación ("Datos del
              Dispositivo") y sobre la sesión de conexión a internet durante la
              cual se nos transmitió su Documento de Identificación, Imagen de
              Identificación o Imagen de Verificación, incluyendo la dirección
              de Protocolo de Internet (IP) ("Datos de la Sesión"). Los Datos
              del Dispositivo pueden incluir información de los sensores del
              dispositivo, como los datos del acelerómetro. Usted consiente que
              capturemos y retengamos los Datos del Dispositivo y los Datos de
              la Sesión, y que compartamos dicha información con la Entidad
              Verificadora.
            </p>
          </li>

          <li>
            <p>
              <u>Contenido.</u>
              Acepta que, de acuerdo con estos Términos y confiando en sus
              garantías expresas sobre los datos que envía o recibe al utilizar
              los Servicios, Trust Stamp no tiene ni ejerce ningún control
              editorial u otro control subjetivo sobre el contenido de los
              datos, comunicaciones o mensajes que puedan ser enviados por la
              Entidad Verificadora. Trust Stamp no ejerce ningún control sobre
              la información retransmitida por la Entidad Verificadora y no se
              le puede responsabilizar por la precisión, exactitud o legalidad
              de dicha información. Usted es el único responsable de verificar
              la precisión, legalidad y adecuación de cualquier información y
              servicios que obtenga de una Entidad Verificadora o de terceros a
              través de su uso de los Servicios.
            </p>
          </li>

          <li>
            <p>
              <u>Almacenamiento de Datos.</u>
              Acepta que, de acuerdo con estos Términos y confiando en sus
              garantías expresas sobre los datos que envía o recibe al utilizar
              los Servicios, Trust Stamp no tiene ni ejerce ningún control
              editorial u otro control subjetivo sobre el contenido de los
              datos, comunicaciones o mensajes que puedan ser enviados por la
              Entidad Verificadora. Trust Stamp no ejerce ningún control sobre
              la información retransmitida por la Entidad Verificadora y no se
              le puede responsabilizar por la precisión, exactitud o legalidad
              de dicha información. Usted es el único responsable de verificar
              la precisión, legalidad y adecuación de cualquier información y
              servicios que obtenga de una Entidad Verificadora o de terceros a
              través de su uso de los Servicios.
            </p>
          </li>
          <li>
            <p>
              <u>Almacenamiento de Datos.</u>
              Usaremos un estándar de cuidado razonable para almacenar,
              transmitir y proteger contra la divulgación cualquier dato
              electrónico, incluidos los datos biométricos, recopilados de
              usted. Dicho almacenamiento, transmisión y protección contra la
              divulgación se realizará de una manera que sea igual o más
              protectora que la forma en que almacenamos, transmitimos y
              protegemos otra información confidencial y sensible.
            </p>
          </li>
          <li>
            <p>
              <u>Sin Garantía de Servicio.</u>
              Nuestro objetivo es que los Servicios estén siempre disponibles.
              Sin embargo, puede haber momentos en que necesitemos modificar o
              desactivar temporal o permanentemente los Servicios. Nos
              reservamos el derecho de descontinuar, modificar, suspender o
              desactivar, total o parcialmente, el acceso a los Servicios, o
              imponer límites de uso o acceso a los Servicios, ya sea con o sin
              previo aviso, y en todos los casos sin responsabilidad alguna. En
              ningún caso seremos responsables por la descontinuación,
              modificación, suspensión o desactivación del acceso a los
              Servicios en cualquier momento o por cualquier duración.
            </p>
          </li>
          <li>
            <p>
              <u>Uso Legal Únicamente.</u>
              Usted puede usar los Servicios solo para fines legales y de
              acuerdo con estos Términos. No tenemos la obligación de hacer
              cumplir los Términos en su nombre contra otro Usuario Final,
              persona o entidad. Usted acepta no usar, ni ayudar, alentar o
              permitir a otros usar los Servicios:
            </p>
            <ol type="a">
              <li>
                <p>
                  Para cualquier propósito que no esté expresamente permitido
                  bajo estos Términos;
                </p>
              </li>
              <li>
                <p>
                  De una manera que sea abusiva, difamatoria, ofensiva,
                  amenazante, invasiva del derecho a la privacidad de otra
                  persona o que razonablemente podría esperarse que cause daño a
                  cualquier persona o entidad;
                </p>
              </li>
              <li>
                <p>
                  Para violar cualquier ley o regulación nacional, regional,
                  federal, estatal, local o internacional aplicable, o para
                  fomentar cualquier actividad ilegal;
                </p>
              </li>
              <li>
                <p>
                  Para crear, enviar, recibir, mostrar, transmitir o usar
                  cualquier material que:
                </p>
                <ol type="i">
                  <li>
                    <p>
                      Infrinja cualquier patente, marca registrada, secreto
                      comercial, derechos de autor u otros derechos de propiedad
                      intelectual de cualquier persona;
                    </p>
                  </li>
                  <li>
                    <p>
                      Contenga imágenes de pornografía infantil o material de
                      abuso sexual infantil;
                    </p>
                  </li>
                  <li>
                    <p>
                      Viole, o contribuya a la violación de, los derechos
                      legales (incluidos los derechos de publicidad y
                      privacidad) de otros o contenga material que pueda dar
                      lugar a responsabilidad civil o penal bajo las leyes o
                      regulaciones aplicables, o que de otro modo pueda estar en
                      conflicto con estos Términos o nuestra Política de
                      Privacidad; o sea probable que engañe o confunda a
                      cualquier persona;
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  Para violar estos Términos u otras reglas o políticas
                  publicadas por nosotros en nuestro sitio web o proporcionadas
                  de otra manera;
                </p>
              </li>
              <li>
                <p>
                  Para transmitir virus informáticos, gusanos, defectos,
                  caballos de Troya u otro malware;
                </p>
              </li>
              <li>
                <p>
                  Para obtener acceso no autorizado o violar la seguridad de
                  cualquier red informática.
                </p>
              </li>
            </ol>
            <p>También acepta no ayudar, alentar o permitir a otros:</p>
            <ol type="a" start="8">
              <li>
                <p>
                  Realizar ingeniería inversa, descompilar, desensamblar o
                  realizar cualquier operación con los Servicios que revele o
                  divulgue el código fuente, secretos comerciales u otra
                  información confidencial;
                </p>
              </li>
              <li>
                <p>
                  Modificar, adaptar, reproducir, distribuir, traducir, crear
                  obras derivadas, exhibir públicamente, vender, comercializar o
                  explotar de cualquier manera los Servicios, excepto cuando
                  esté expresamente autorizado por nosotros;
                </p>
              </li>
              <li>
                <p>
                  Eliminar o modificar cualquier aviso de derechos de autor,
                  marca registrada u otros derechos de propiedad que aparezca en
                  cualquier parte de los Servicios o en materiales impresos o
                  copiados de los Servicios;
                </p>
              </li>
              <li>
                <p>
                  Grabar, procesar, recopilar o extraer información sobre otros
                  usuarios;
                </p>
              </li>
              <li>
                <p>
                  Usar cualquier robot, script u otro proceso automático para
                  acceder a los Servicios con cualquier propósito;
                </p>
              </li>
              <li>
                <p>
                  Eliminar, eludir, deshabilitar, dañar o interferir de otro
                  modo con cualquier característica de seguridad o restricciones
                  de los Servicios;
                </p>
              </li>
              <li>
                <p>
                  Intento de obtener acceso no autorizado, interferir, dañar o
                  interrumpir cualquier parte de los Servicios, el servidor en
                  el que están almacenados los Servicios o cualquier servidor,
                  computadora o base de datos conectada a los Servicios; atacar
                  los Servicios mediante denegación de servicio (DoS),
                  denegación de servicio distribuida (DDoS) u otra forma de
                  ciberataque; o intentar interferir de cualquier otra manera
                  con el funcionamiento adecuado de los Servicios.
                  <br />
                  <br />
                  Las restricciones anteriores sólo se aplicarán en la medida en
                  que lo permita la ley aplicable. No obstante, usted acepta no
                  actuar en contra de ellas (incluso si sus acciones son
                  legalmente permisibles) sin proporcionarnos un aviso previo
                  por escrito de treinta (30) días a través de{ ' ' }
                  <a href="mailto:support@truststamp.net">
                    support@truststamp.net
                  </a>
                  , junto con cualquier información que podamos solicitar
                  razonablemente para darnos la oportunidad de adaptarnos o
                  abordar de otra manera su acción propuesta, lo cual será de
                  nuestra entera y absoluta discreción.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <u>Honorarios de Citación.</u>
              Si se nos exige proporcionar información en respuesta a una orden
              judicial, citación, orden de arresto, mandato u otra consulta
              legal o gubernamental relacionada con su uso de los Servicios,
              podemos cobrarle por nuestros costos. Estos honorarios pueden
              incluir el tiempo razonable de abogados y empleados dedicado a
              recuperar los registros, preparar documentos y participar en una
              deposición o audiencia judicial.
            </p>
          </li>
          <li>
            <p>
              <u>Propiedad Intelectual. </u>
              Usted retendrá todos los derechos, títulos e intereses sobre el
              material, contenido, datos e información (incluida su información
              personal) que envíe mientras use los Servicios (en conjunto, su
              "Contenido"). Sujeto a estos Términos, nos otorga permiso para
              usar o divulgar su Contenido únicamente en la medida necesaria
              para proporcionar los Servicios o según lo permitido por estos
              Términos. Afirma y garantiza que:
            </p>
            <ol type="a">
              <li>
                <p>
                  Usted es el propietario o ha obtenido de otra manera todos los
                  derechos, autorizaciones y permisos necesarios para enviar
                  todo su Contenido a los Servicios y para otorgar los derechos
                  concedidos en estos Términos;
                </p>
              </li>
              <li>
                <p>
                  No enviará ni intentará autorizar la recolección,
                  procesamiento, almacenamiento o uso de datos biométricos de
                  ninguna imagen o identificación fotográfica en la que usted no
                  sea la persona representada; y
                </p>
              </li>
              <li>
                <p>
                  Su Contenido y el envío y uso del mismo según lo autoriza
                  usted en estos Términos no violarán (i) ninguna ley o
                  regulación aplicable o (ii) ningún derecho de propiedad
                  intelectual, privacidad, publicidad u otros derechos de
                  terceros.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <u>Cese de los Servicios.</u>
              Trust Stamp puede cesar su acceso a la totalidad o a cualquier
              parte de los Servicios en cualquier momento, con o sin causa, con
              o sin previo aviso, con efecto inmediato. Si desea terminar su
              registro y cuenta, puede hacerlo en cualquier momento
              contactándonos a support@truststamp.net. Tras cualquier cese,
              todos los derechos y obligaciones de las partes cesarán, y usted
              deberá cesar inmediatamente el uso de los Servicios, excepto que
              (a) todas las obligaciones que hayan surgido antes de la fecha
              efectiva de la terminación, (b) todos los recursos para el
              incumplimiento de estos Términos, y (c) las disposiciones de las
              Secciones 4, 5, 12, 13, 16, 17, 18, 19, 20 y 21 sobrevivirán.
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>EXCLUSIÓN DE GARANTÍAS.</b>
              </u>
              TRUST STAMP POR LA PRESENTE RENUNCIA A TODAS LAS GARANTÍAS DE
              CUALQUIER TIPO, YA SEAN EXPRESAS, IMPLÍCITAS, ESTATUTARIAS U
              OTRAS, INCLUYENDO, PERO NO LIMITÁNDOSE A, CUALQUIER GARANTÍA DE
              EXACTITUD, COMERCIALIDAD, NO INFRACCIÓN, DISFRUTE PACÍFICO O
              IDONEIDAD PARA UN PROPÓSITO PARTICULAR. EN LA MEDIDA MÁXIMA
              PERMITIDA POR LA LEY APLICABLE, LOS SERVICIOS SE OFRECEN
              EXCLUSIVAMENTE "TAL Y COMO SON" Y "SEGÚN DISPONIBILIDAD". TRUST
              STAMP NO GARANTIZA LA EXACTITUD, PUNTUALIDAD, COMPLETITUD O
              UTILIDAD DE LOS SERVICIOS O CUALQUIER CONTENIDO. USTED ACEPTA USAR
              LOS SERVICIOS BAJO SU PROPIO RIESGO. TRUST STAMP NO OFRECE
              GARANTÍA CON RESPECTO A INTERFERENCIAS EN SU DISFRUTE DE LOS
              SERVICIOS, TALES COMO QUE LAS FUNCIONES CONTENIDAS EN LOS
              SERVICIOS, O SERVICIOS REALIZADOS O PROPORCIONADOS POR O A TRAVÉS
              DE ‘LOS SERVICIOS’ SATISFACERÁN SUS NECESIDADES, QUE EL
              FUNCIONAMIENTO DE ‘LOS SERVICIOS’ SERÁ ININTERRUMPIDO O LIBRE DE
              ERRORES, O QUE SE CORREGIRÁN LOS DEFECTOS EN ‘LOS SERVICIOS’. SIN
              LIMITAR LO ANTERIOR, TRUST STAMP RENUNCIA A CUALQUIER GARANTÍA
              DERIVADA DEL CURSO DE LAS NEGOCIACIONES, EL CURSO DE LA EJECUCIÓN
              O EL USO COMERCIAL. NINGUNA INFORMACIÓN ORAL O ESCRITA
              PROPORCIONADA POR TRUST STAMP O SUS REPRESENTANTES SE CONSIDERARÁ
              COMO UNA GARANTÍA. SI USTED ESTÁ INSATISFECHO CON LOS SERVICIOS O
              CON CUALQUIERA DE ESTOS TÉRMINOS, SU ÚNICO RECURSO ES DEJAR DE
              UTILIZAR LOS SERVICIOS. ALGUNAS JURISDICCIONES NO PERMITEN LA
              EXCLUSIÓN DE GARANTÍAS IMPLÍCITAS O LIMITACIONES A LOS DERECHOS
              ESTATUTARIOS APLICABLES DE UN CONSUMIDOR. SI USTED ES RESIDENTE DE
              UNA JURISDICCIÓN DE ESTE TIPO, LA EXCLUSIÓN Y LIMITACIONES
              ANTERIORES PUEDEN NO APLICARSE A USTED.
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>LIMITACIÓN DE RESPONSABILIDAD.</b>
              </u>
              USTED RECONOCE QUE SU ACCESO Y USO DE LOS SERVICIOS Y CUALQUIER
              CONFIANZA EN CUALQUIERA DE ELLOS SON DE SU EXCLUSIVA
              RESPONSABILIDAD. EN LA MEDIDA MÁXIMA PERMITIDA POR LA LEY, EN
              NINGÚN CASO SERÁ TRUST STAMP O CUALQUIERA DE SUS PROPIETARIOS,
              GERENTES, OFICIALES, DIRECTORES, MIEMBROS, ACCIONISTAS, AFILIADOS,
              FILIALES, EMPLEADOS, CONTRATISTAS, AGENTES O REPRESENTANTES
              RESPONSABLES DE NINGÚN DAÑO INDIRECTO, ESPECIAL O INCIDENTAL
              INCLUYENDO, SIN LIMITACIÓN, DAÑOS POR PÉRDIDA DE GANANCIAS,
              EXPOSICIÓN O PÉRDIDA DE DATOS, INTERRUPCIÓN DE NEGOCIO Y/O
              CUALQUIER OTRO DAÑO O PÉRDIDA COMERCIAL, QUE SURJA O ESTÉ
              RELACIONADO CON LOS SERVICIOS, YA SEA QUE TRUST STAMP HAYA SIDO O
              NO INFORMADO DE LA POSIBILIDAD DE DICHOS DAÑOS, BAJO CUALQUIER
              TIPO DE RESPONSABILIDAD (INCLUYENDO CONTRATO, NEGLIGENCIA, O DE
              CUALQUIER OTRA FORMA) QUE SURJA DE, ESTÉ RELACIONADA CON, O
              RESULTE DE LOS SERVICIOS. USTED RECONOCE ESPECÍFICAMENTE QUE EL
              TIEMPO DE INACTIVIDAD DEL ORDENADOR Y LOS VIRUS SON RIESGOS
              INHERENTES AL USO DE INTERNET Y CUALQUIER PRODUCTO DE SOFTWARE, Y
              ACEPTA ASUMIR LA RESPONSABILIDAD DE CUALQUIER DAÑO O PERJUICIO DE
              CUALQUIER TIPO O CARÁCTER QUE SURJA DE ESTOS POSIBLES DAÑOS. USTED
              TAMBIÉN RECONOCE ESPECÍFICAMENTE QUE PUEDE ESTAR DIVULGANDO
              INFORMACIÓN SENSIBLE, PRIVADA Y CONFIDENCIAL, INCLUYENDO DATOS
              BIOMÉTRICOS E INFORMACIÓN BIOMÉTRICA, SOBRE USTED MISMO A TRAVÉS
              DEL USO QUE USTED REALIZA DE LOS SERVICIOS Y ACEPTA ASUMIR LA
              RESPONSABILIDAD POR CUALQUIER DAÑO O PERJUICIO DE CUALQUIER TIPO O
              CARÁCTER QUE SURJA DE LA DIVULGACIÓN DE TAL CONTENIDO. EN NINGÚN
              CASO NUESTRA RESPONSABILIDAD TOTAL HACIA USTED POR CUALQUIER DAÑO
              (A EXCEPCIÓN DE LOS QUE PUEDAN SER REQUERIDOS POR LA LEY
              APLICABLE) EXCEDERÁ EL MAYOR DE (A) EL MONTO PAGADO POR USTED POR
              LOS SERVICIOS EN LOS SEIS (6) MESES ANTERIORES A LA RECLAMACIÓN
              APLICABLE, SI LA HUBIERE, O (B) CIEN DÓLARES ($100.00). ES
              INTENCIÓN SUYA Y NUESTRA QUE ESTA DISPOSICIÓN SEA INTERPRETADA POR
              UN TRIBUNAL COMO LA LIMITACIÓN DE RESPONSABILIDAD MÁS AMPLIA
              PERMITIDA POR LA LEY APLICABLE. ALGUNAS JURISDICCIONES NO PERMITEN
              LA LIMITACIÓN O EXCLUSIÓN DE DAÑOS INCIDENTALES, CONSECUENTES O DE
              OTROS TIPOS, POR LO QUE ALGUNAS DE LAS LIMITACIONES ANTERIORES
              PUEDE QUE NO SE APLIQUEN A USTED. ESTAS LIMITACIONES SE APLICARÁN
              AUNQUE EL RECURSO ESTABLECIDO ANTERIORMENTE NO CUMPLA SU PROPÓSITO
              ESENCIAL.
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>ARBITRAJE OBLIGATORIO Y VINCULANTE. </b>{ ' ' }
              </u>
              CUALQUIER DISPUTA, DIFERENCIA, CONTROVERSIA O RECLAMO QUE SURJA DE
              O ESTÉ RELACIONADO CON ESTOS TÉRMINOS O EL USO QUE USTED REALICE
              DE LOS SERVICIOS SERÁ RESUELTO MEDIANTE ARBITRAJE FINAL Y
              VINCULANTE DE ACUERDO CON LAS REGLAS DE HENNING MEDIATION AND
              ARBITRATION SERVICES EN ATLANTA, GEORGIA. EL ARBITRAJE SE LLEVARÁ
              A CABO ANTE UN SOLO ARBITRADOR. EL ARBITRADOR PODRÁ CONCEDER
              COSTOS Y/O HONORARIOS DE ABOGADOS A LA PARTE GANADORA.USTED
              ENTIENDE QUE EL ARBITRAJE ES FINAL Y VINCULANTE Y QUE USTED ESTÁ
              RENUNCIANDO A SU DERECHO A OTROS PROCESOS DE RESOLUCIÓN (TALES
              COMO ACCIONES LEGALES O PROCEDIMIENTOS ADMINISTRATIVOS).
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>RENUNCIA A ACCIONES COLECTIVAS Y JUICIO POR JURADO.</b>
              </u>
              CON RESPECTO A SU USO DE LOS SERVICIOS, INDEPENDIENTEMENTE DE SI
              HA OBTENIDO O UTILIZADO LOS SERVICIOS PARA FINES PERSONALES,
              COMERCIALES U OTROS, TODAS LAS RECLAMACIONES DEBERÁN SER
              PRESENTADAS EN SU CAPACIDAD INDIVIDUAL, Y NO COMO DEMANDANTE O
              MIEMBRO DE UNA CLASE EN CUALQUIER ACCIÓN COLECTIVA SUPUESTA,
              ACCIÓN COLECTIVA, ACCIÓN DE ABOGADO GENERAL PRIVADO U OTRO
              PROCEDIMIENTO REPRESENTATIVO. ESTA RENUNCIA TAMBIÉN SE APLICA AL
              ARBITRAJE COLECTIVO, Y UN ARBITRADOR NO PODRÁ CONSOLIDAR LAS
              RECLAMACIONES DE MÁS DE UNA PERSONA EN NINGUNA ACCIÓN. USTED
              ACEPTA QUE, AL COMPROMETERSE CON ESTOS TÉRMINOS, USTED Y TRUST
              STAMP ESTÁN RENUNCIANDO A SU DERECHO A UN JUICIO POR JURADO O A
              PARTICIPAR EN UNA ACCIÓN COLECTIVA, ACCIÓN COLECTIVA, ACCIÓN DE
              ABOGADO GENERAL PRIVADO U OTRO PROCEDIMIENTO REPRESENTATIVO DE
              CUALQUIER TIPO.
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>INDEMNIZACIÓN.</b>
              </u>
              USTED ACEPTA INDEMNIZAR Y MANTENER A SALVO A TRUST STAMP, SUS
              FILIALES Y AFILIADOS, Y SUS RESPECTIVOS PROPIETARIOS, GERENTES,
              DIRECTIVOS, OFICIALES, DIRECTORES, MIEMBROS, ACCIONISTAS,
              EMPLEADOS, CONTRATISTAS, AGENTES, LICENCIATARIOS Y PROVEEDORES DE
              CUALQUIER RECLAMO, RESPONSABILIDAD, DAÑOS, FALLOS, PREMIOS,
              PÉRDIDAS, COSTOS, GASTOS Y/O HONORARIOS (INCLUYENDO HONORARIOS
              RAZONABLES DE ABOGADOS) QUE RESULTEN DE (A) SU VIOLACIÓN DE ESTOS
              TÉRMINOS; (B) SU USO O ACCESO A LOS SERVICIOS; (C) SU VIOLACIÓN DE
              CUALQUIER DERECHO DE TERCEROS, INCLUYENDO, SIN LIMITACIÓN,
              CUALQUIER DERECHO DE PROPIEDAD INTELECTUAL O DERECHO DE
              PUBLICIDAD; O (D) CUALQUIER RECLAMO DE QUE SU USO DE LOS SERVICIOS
              CAUSÓ DAÑO A UN TERCERO.
            </p>
          </li>
          <li>
            <p>
              <u>Modificación de los Términos.</u>
              Nos reservamos el derecho de modificar estos Términos y de agregar
              nuevos términos o condiciones adicionales sobre su uso de los
              Servicios, por cualquier motivo, sin previo aviso, en cualquier
              momento. Dichas modificaciones y términos y condiciones
              adicionales serán efectivos de inmediato e incorporados a estos
              Términos. El uso continuo de los Servicios por parte de usted se
              considerará una aceptación de dichos cambios. Le notificaremos
              sobre cambios materiales requiriéndole que los acepte antes de
              utilizar los Servicios en el futuro. Los Términos actuales también
              estarán disponibles en nuestro sitio web. Lo que constituye
              "cambios materiales" se determinará a nuestra sola discreción, de
              buena fe y utilizando el sentido común y un juicio razonable.
            </p>
          </li>
          <li>
            <p>
              <u>Términos Generales.</u>
              Estos Términos constituyen el acuerdo único y completo entre usted
              y Trust Stamp con respecto a los Servicios y reemplazan todos los
              entendimientos, acuerdos, representaciones y garantías anteriores
              y contemporáneos, tanto escritos como orales, respecto a los
              Servicios. Ninguna renuncia a estos Términos por nuestra parte se
              considerará una renuncia adicional o continua a dicho término o
              condición, o a cualquier otro término o condición, y nuestra falta
              o demora en hacer valer un derecho o disposición bajo estos
              Términos no constituirá una renuncia a dicho derecho o
              disposición. Si alguna disposición de estos Términos es
              considerada por un tribunal competente como inválida, ilegal o
              inaplicable por cualquier motivo, dicha disposición será
              modificada para reflejar la intención de las partes o eliminada en
              la medida estrictamente necesaria para que las disposiciones
              restantes de los Términos continúen en pleno vigor y efecto. Los
              Términos, y cualquier derecho u obligación en virtud de los
              mismos, no son asignables, transferibles o sublicenciables por
              usted, excepto con nuestro consentimiento previo por escrito, pero
              pueden ser asignados o transferidos por nosotros sin restricción.
              Cualquier intento de asignación por su parte violará estos
              Términos y será nulo. Los títulos de las secciones en estos
              Términos son solo para conveniencia y no tienen efecto legal o
              contractual. Por la presente, usted otorga a Trust Stamp el
              derecho de tomar medidas razonablemente necesarias o apropiadas
              para hacer cumplir y/o verificar el cumplimiento de cualquier
              parte de estos Términos. Acepta que tenemos el derecho, sin
              responsabilidad hacia usted, de divulgar cualquier dato y/o
              información a las autoridades policiales, funcionarios
              gubernamentales y/o a un tercero, según consideremos
              razonablemente necesario o apropiado para hacer cumplir y/o
              verificar el cumplimiento de cualquier parte de estos Términos
              (incluyendo, pero no limitado a, nuestro derecho a cooperar con
              cualquier proceso legal relacionado con su uso de los Servicios
              y/o con una reclamación de un tercero que alegue que su uso de los
              Servicios es ilegal y/o infringe los derechos de dicho tercero).
            </p>
          </li>
          <li>
            <p>
              <u>Ley Aplicable y Jurisdicción.</u>
              Estos Términos, y la licencia otorgada en el mismo, se regirán por
              las leyes del Estado de Georgia, sin tener en cuenta sus
              principios de conflicto de leyes. Para cualquier reclamación a la
              que se considere inaplicable la disposición de arbitraje
              obligatorio contenida en estos Términos, usted acuerda someterse a
              la jurisdicción personal de los tribunales federales y estatales
              ubicados en Atlanta, Georgia. El uso de los Servicios también
              puede estar sujeto a otras leyes locales, estatales, federales o
              internacionales.
            </p>
            <b>
              <p>
                Al proporcionar voluntariamente una Imagen de Verificación o un
                Documento de Identificación, está reconociendo y aceptando estos
                Términos y:
              </p>

              <ol type="a">
                <li>
                  <p>
                    Declara que ha leído, comprendido y consiente estos Términos
                    en su totalidad, incluyendo todas las disposiciones
                    relacionadas con la recopilación, procesamiento,
                    almacenamiento y uso de tus Datos Biométricos;
                  </p>
                </li>
                <li>
                  <p>
                    Declara que es la persona que aparece en la Imagen de
                    Verificación; que la Imagen de Verificación es una imagen en
                    vivo, sin alterar, de usted capturada por la cámara del
                    dispositivo utilizado en el proceso (es decir, no es, por
                    ejemplo, una foto de una foto o un video de un video); que
                    su Documento de Identificación es un documento legalmente
                    producido y válidamente emitido por la jurisdicción que se
                    refleja en él, en el cual su imagen y otros datos están sin
                    alterar;
                  </p>
                </li>
                <li>
                  <p>
                    Nos designa como su agente únicamente para el propósito
                    limitado de colocar su firma electrónica en estos Términos
                    en su nombre, y ordena que coloquemos y asociemos su
                    dirección IP y la fecha y hora de su reconocimiento de estos
                    Términos como su firma electrónica legalmente vinculante que
                    atestigua su comprensión y aceptación escrita de estos
                    Términos;
                  </p>
                </li>
                <li>
                  <p>
                    Acepta que su uso continuo de los Servicios constituye su
                    consentimiento continuo a estos Términos.
                  </p>
                </li>
              </ol>
            </b>
          </li>
        </ol>
      </div>
    );
  }
}
