import React from 'react';
import omit from 'lodash/omit';
import noop from 'no-op';
import PropTypes from 'prop-types';
import Measure from 'react-measure';
import BaseComponent from 'components/BaseComponent';

export default function Measured(ComposedComponent) {
  class MeasurementComposedComponent extends BaseComponent {
    state = {
      dimensions: {},
    };

    /* eslint-disable react/no-unused-prop-types */
    static propTypes = {
      onMeasure: PropTypes.func,
    };
    /* eslint-enable react/no-unused-prop-types */

    static defaultProps = {
      onMeasure: noop,
    };

    measure() {
      this.refs.measured.measure();
    }

    render() {
      return (
        <Measure
          bounds={ true }
          onResize={ (clientRect) => {
            this.props.onMeasure(clientRect.bounds);
            this.setState({
              dimensions: clientRect.bounds,
            });
          } }
          ref="measured"
        >
          { ({ measureRef }) => (
            <div
              className={ this.props.className }
              ref={ measureRef }
            >
              <ComposedComponent
                { ...omit(this.props, ['onMeasure', 'className']) }
                { ...this.state }
              >
                { this.props.children }
              </ComposedComponent>
            </div>
          ) }
        </Measure>
      );
    }
  }

  return MeasurementComposedComponent;
}
