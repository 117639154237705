/* eslint-disable max-len */
import React, { Fragment } from 'react';

export const TITLE = 'Remove risk, enhance utility';

export const HOWITWORKS = [
  {
    id: 1,
    // title: 'Remove sensitive data',
    text: 'Are compliant with all federal and state data laws',
    slug: 'checked',
  },
  {
    id: 2,
    // title: 'Store safely',
    text: (
      <Fragment>
        Minimise potential liability for non-compliance with biometric and data
        protection regulations
      </Fragment>
    ),
    slug: 'biometric_and_data',
  },
  {
    id: 3,
    // title: 'Do more, more efficiently',
    text: (
      <Fragment>
        Reduce potential financial and reputational risks from hacks and data
        leaks
      </Fragment>
    ),
    slug: 'hacker',
  },
  {
    id: 4,
    // title: 'Do more, more efficiently',
    text: (
      <Fragment>
        Provide end-users with the information required to ensure
        informed-consent
      </Fragment>
    ),
    slug: 'search',
  },
];

export const BANNER_CONTENT = [
  {
    id: 1,
    title: 'More',
    slug: 'tick',
    list: [
      'Actionable insights',
      'System longevity',
      'Rapid deployment, scalability and adaptability',
      'Cost savings',
    ],
  },
  {
    id: 2,
    title: 'Less',
    slug: 'close',
    list: [
      'Risk to your organisation and users',
      'Time wasted for you and your customers',
      'Computational resources required',
      'Data stored',
    ],
  },
];

export const TITLE2 = 'Security, Efficiency & Privacy';
export const SUBTEXT2 = 'Privtech Certified ™ applications provide cryptographically secured digital certification as to the security level afforded to the end user:'
export const CONTENT = [
  {
    id: 1,
    title: 'Privtech Level-4',
    text: (
      <Fragment>
        &#10003; No biometric image or raw template ever leaves the user device{ ' ' }
        <br />
        &#10003; An Irreversibly Transformed Identity Token is encrypted and
        stored only on the user-device (it never leaves the device)
      </Fragment>
    ),
    slug: 'level4',
  },
  {
    id: 2,
    title: 'Privtech Level-3',
    text: (
      <Fragment>
        &#10003; No biometric image or raw template ever leaves the user device{ ' ' }
        <br />
        &#10003; An Irreversibly Transformed Identity Token is sent to and
        stored on the enterprise or government server encrypted in transit and
        at rest
      </Fragment>
    ),
    slug: 'level3',
  },
  {
    id: 3,
    title: 'Privtech Level-2',
    text: (
      <Fragment>
        &#10003; An encrypted biometric image or raw template is sent from the
        user device to the enterprise or government server and deleted following
        conversion to an Irreversibly Transformed Identity Token
      </Fragment>
    ),
    slug: 'level2',
  },
  {
    id: 4,
    title: 'Privtech Level-1',
    text: (
      <Fragment>
        &#10003; An encrypted biometric image or raw template is sent from the
        user device to the enterprise or government server and stored in
        access-controlled encrypted cold-storage following creation and storage
        of an Irreversibly Transformed Identity Token
      </Fragment>
    ),
    slug: 'level1',
  },
];

export const VIDEO_CONTENT = {
  id: 1,
  title: 'The core technology',
  text:
    'Trust Stamp Protect creates pseudonymised token identifiers for accurate, protected and low-footprint identification and deduplication',
  video: 'https://player.vimeo.com/video/499024505',
};

export const TITLE3 =
  'Advanced AI-powered tools and machine learning techniques';

export const HOWITWORKS2 = [
  {
    id: 1,
    title: 'Transformed Identity Tokens (IT2)',
    text:
      'Replace biometric images and templates with Irreversibly Transformed Identity Tokens (IT2)',
    slug: 'face_it2',
  },
  {
    id: 2,
    title: 'Meet use case',
    text:
      'Provide government and enterprise users with a range of processes to minimise the collection and storage of biometric data required to meet the use case',
    slug: 'data_store',
  },
  {
    id: 3,
    title: 'No biometrics or personal data required',
    text:
      'Do not require biometric or other personal data to be processed or stored by a biometric service provider of other third-party',
    slug: 'no-biometrics',
  },
  {
    id: 4,
    title: 'Transparency ',
    text:
      'Provide end-users with transparency as to the use and disposition of their biometric data',
    slug: 'transparency-data',
  },
];
