/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router';
import BaseComponent from 'components/BaseComponent';
import { asset } from 'sf/helpers';
import { CONTENT } from './constant';

export default class Content extends BaseComponent {
  className = 'ts-Content';

  render() {
    return (
      <section className={ this.rootcn() }>
        <div className="ts-container">
          <h2>Products that fit your needs</h2>
          <p>
            Our solutions can stand alone, seamlessly integrate with existing
            systems, or work alongside other Trust Stamp products to provide a
            new functionality for your organisation. Whatever your needs, Trust
            Stamp's flexible product structure offers a way to establish, grow,
            and strengthen your systems for account access, fraud prevention,
            compliance and more.
          </p>

          { /* product listing */ }
          { CONTENT.map(
            ({
              id,
              subtitle,
              title,
              content,
              href,
              button,
              slug,
              className,
              blank
            }) => (
              <article key={ id } className={ this.cn`__article--${className}` }>
                <div className={ this.cn`__column--text` }>
                  <h6>{ subtitle }</h6>
                  <h3>{ title }</h3>
                  <p>{ content }</p>
                  <Link
                    to={ href }
                    target={ blank ? '_blank' : '' }
                    className="ts-button--tertiary"
                  >
                    { button }
                  </Link>
                </div>
                <div className={ this.cn`__column--image` }>
                  <img src={ asset`ai/${slug}` } alt={ title } />
                </div>
              </article>
            )
          ) }
        </div>
      </section>
    );
  }
}
