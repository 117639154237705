/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';

export default class VideoAndContent extends BaseComponent {
  className = 'ts-VideoAndContent';

  static propTypes = {
    content: PropTypes.shape({
      id: PropTypes.number,
      subTitle: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
      link: PropTypes.string,
      video: PropTypes.string,
      linkText: PropTypes.string,
    }),
    colored: PropTypes.bool,
  };

  static defaultProps = {
    colored: false,
    subTitle: '',
  }

  render() {
    const { content, colored } = this.props;
    return (
      <section className={ this.rootcn`${colored ? '--colored' : ''}` } >
        <div className="ts-container">
          <div className={ this.cn`__column` }>
            <h6>{ content.subTitle }</h6>
            <h2>{ content.title }</h2>
            <p>{ content.text }</p>
            { content.link &&
              <Link to={ content.link } target="_blank" className="ts-button--filled">
                { content.linkText }
              </Link>
            }
          </div>
          <div className={ this.cn`__column __video` }>
            <iframe src={ content.video } width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen={ true } />
          </div>
        </div>
      </section>
    );
  }
}
