/* eslint-disable max-len */
import React from 'react';
import BaseComponent from 'components/BaseComponent';
import { asset } from 'sf/helpers';
import { Link } from 'react-router';
import { ROUTES } from 'constants';

export default class CrypoContent extends BaseComponent {
  className = 'ts-CrypoContent';

  render() {
    return (
      <div className={ this.rootcn() } >
        <section>
          <div className="ts-container">
            <div className={ this.cn`__column __column--left` }>
              <img src={ asset`ai/crypto-vault.png` } alt="Secure account-credential and private key storage vault" width="456px" />
            </div>
            <div className={ this.cn`__column __column--right` }>
              <h2>Secure account-credential and private key storage vault</h2>
              <p>In an increasingly digitized global economy, providing a safekeeping service for account access credentials and encryption keys enables banks and other institutions to meet the financial service needs of customers today. Trust Stamp’s crypto key vault solution leverages proven <Link to={ ROUTES.CAPTURE }>facial biometric authentication</Link> and <Link to={ ROUTES.PROTECT }>irreversible data transformation</Link> technology together with the Amazon Quantum Ledger Database (QLDB) to protect sensitive user information while ensuring long-term data protection and access credential availability.  </p>
              <p>With no requirements for identity verification and no identifiable personal data ever stored or shared, we eliminate the potential for sensitive biometric data, account credentials, or encryption keys to be exposed or compromised.</p>
            </div>
          </div>
        </section>
        <section>
          <div className="ts-container">
            <div className={ this.cn`__column __column--left` }>
              <h2>Streamlined compliance for the crypto space</h2>
              <p>For DeFi platforms and investors, issuers, exchanges, payments services and/or wallet providers, meeting KYC/AML requirements is not just a legal imperative, it is also critical to building trust with regulators and users, and to deterring fraudulent transactions.</p>
              <p>Trust Stamp combines deep experience in the banking and FinTech arenas with proprietary cutting-edge technology, providing on-demand authentication with the low-friction user experience expected by participants in the digital economy.</p>
            </div>
            <div className={ this.cn`__column __column--right` }>
              <img src={ asset`ai/angled-computer2.png` } alt="Streamlined compliance for the crypto space" width="456px" />
            </div>
          </div>
        </section>
        <section>
          <div className="ts-container">
            <div className={ this.cn`__column __column--left` }>
              <img src={ asset`ai/id-rotated.png` } alt="Streamlined compliance for the crypto space" width="302px" />
            </div>
            <div className={ this.cn`__column __column--right` }>
              <p>We empower businesses with a completely automated transaction including our <Link to={ ROUTES.CAPTURE }>biometric tools</Link> and <Link to={ ROUTES.IDENTIFY }>identity document authentication</Link>, along with the range of data calls required to create a comprehensive KYC/AML/antifraud solution.</p>
              <p>Our multi-layer, automated, risk-based process speeds transactions for both institutions and customers and provides audit trails, compliance reports, and fuzzy logic to identify risky transactions and potential non-compliance.</p>
              <p>Combined with regulatory and data protection expertise, Trust Stamp’s patented technologies provide not only effective compliance, but also a valuable competitive edge with seamless integration. Our low-code implementation APIs guide you to get up and running in a single working day.</p>
            </div>
          </div>
        </section>
        <section>
          <div className="ts-container">
            <div className={ this.cn`__column __column--left` }>
              <h2>Secure, encrypted communication using facial biometrics</h2>
              <p>Our biometrically signed and authenticated customer email service ensures confidentiality and prevents email spoofing, man-in-the-middle attacks and account takeovers.</p>
            </div>
            <div className={ this.cn`__column __column--right` }>
              <img src={ asset`ai/tm.png` } alt="Secure, encrypted communication using facial biometrics" width="217px" />
            </div>
          </div>
        </section>
      </div>
    );
  }
}
