import React from 'react';

export const HELP_CENTER_NOTIFICATION_TYPES = ['success', 'error', 'info', 'warning'];

/* eslint-disable max-len, quotes */

// You can adapt external (backend, 3-rd party) messages to a user friendly ones with an adaptResponseMessage(message) helper.
// Pass a full or part of external message as an object key and provide HELP_CENTER_NOTIFICATIONS key as a value to get a fancy message
// ex.
// export const HELP_CENTER_ADAPTED_NOTIFICATIONS_KEYS = {
//   'Guru Meditation 00FA0EC1.00AC20B0': 'SOFTWARE_FAILURE'
// };

// export const HELP_CENTER_NOTIFICATIONS = {
//   'SOFTWARE_FAILURE': 'Software Failure. Press left mouse button to continue.'
// }

export const HELP_CENTER_ADAPTED_NOTIFICATIONS_KEYS = {
  identity_is_linked_to_another_user: 'IDENTITY_LINKED',
};

export const HELP_CENTER_NOTIFICATIONS = {
  IDENTITY_LINKED: 'Selected social media account is used in another profile.',
  MISMATCHED_SURNAME: 'To get a score higher than 400, please change your Social Media names to match your Trust Stamp name.',
  NONEXISTENT_ADDRESS: `Your address doesn't exist or is mistyped. Please contact us to change it.`,
  ONLY_ONE_NETWORK: 'You only have one Social Network connected which is not enough to get a high score.',
  PHOTO_MISMATCH: 'Your Selfie and Driver Licence picture do not match. Please retake them to get a score higher than 400.',
  YOUNG_NETWORKS: 'To get a higher score, add more Social Networks that are older and have more connections',
  ONLY_FRONT_OF_DRIVERS_LICENSE: 'You provided only a front side of your driving license but back side is required as well. Please retake photos of your driving license.',
  SELFIE_FAILED_TWO_TIMES: {
    title: 'If your selfie just doesn’t work',
    value: (
      <span>
        Please try doing these:
        <ul>
          <li>Extend your arm fully when taking a photo</li>
          <li>Try to find a place with good frontal lighting</li>
          <li>Try to face the camera straight, not at an angle</li>
        </ul>
      </span>
    ),
  },
  SOCIAL_MEDIA_TRUST_SCORE: {
    title: 'How do you use my Social Media data?',
    value: 'We use data like Social Media to calculate a Trust Score. We cross-reference data from Social Media (including name, photos, number of connections and account age) with other data sources to assess trustworthiness.',
  },
  SOCIAL_MEDIA_LOG_IN: {
    title: 'How is my Social Media Network used by Trust Stamp?',
    value: 'Social Media Networks are primary log in methods for Trust Stamp. You will have to use one of them every time you want to log in to the service.',
  },
  SOCIAL_MEDIA_REQUIRED: {
    title: `What should I do if I don't have a Social Network?`,
    value: `You should consider creating one. New Social Networks (that don't have much activity) don't get high scores but will allow you to use the service. In case you are not able to create a social network, you can always contact us.`,
  },
  PHONE_NUMBER_REQUIRED: {
    title: 'Why do you need my phone number?',
    value: 'Your phone number is one of the authentication methods that we use. You can also share it with other people (or businesses) if you want them to contact you this way.',
  },
  US_PHONE_NUMBER_REQUIRED: {
    title: `I don't have an US phone number. What should I do?`,
    value: `In case you don't have an US phone number, you should contact us.`,
  },
  NO_VERIFICATION_CODE: {
    title: `I didn't get the code. What should I do?`,
    value: 'Please check if your number is correct. You can use Trust Stamp to resend the code. If the problem persists, please contact us.',
  },
  WHAT_IS_THE_VERIFICATION_CODE: {
    title: 'Why do I need to verify my phone number?',
    value: 'Phone number is one of the authentication methods in Trust Stamp.  We need to ensure that people are giving us correct information.',
  },
  DATA_PURPOSE: {
    title: 'Why do you need my personal data (address/DOB/etc)?',
    value: 'Your personal data is cross-referenced with other data sources to calculate your Trust Score. The more reliable your data, the higher your Trust Score.',
  },
  DATA_USAGE: {
    title: 'How will you use my personal data?',
    value: 'We will cross-reference it with over 200 data sources. These sources include your Driver Licence, Social Media profiles, and also government databases.',
  },
  DATA_SAFETY: {
    title: 'Is the data I provide you safe?',
    value: 'We use bank-level security protocols. Every piece of your data is encrypted and protected. Trust Stamp also does not store any information that isn’t necessary for login purposes.',
  },
  FULL_NAME_FORMAT: {
    title: 'How should I provide my full name?',
    value: 'The proper syntax is: NAME, MIDDLE NAME (or initial), SURNAME.',
  },
  WHICH_EMAIL: {
    title: 'Which email should I use?',
    value: 'You should probably use the address you use the most. It will allow you to respond quicker to requests and profile shares from other Trust Stamp users.',
  },
  NO_EMAIL: {
    title: `I don't have an e-mail address. What should I do?`,
    value: 'You should consider creating one. In case you are not able to create an e-mail, you can always contact us.',
  },
  ZIP_CODE_FORMAT: {
    title: 'What format should I provide my zip code in?',
    value: 'You should provide it as a standard five digit reference. There should be no symbols before or after the zip code. To use a real world example, 90210 is a proper zip code.',
  },
  NO_ADDRESS: {
    title: `I don't have a US based address. What should I do?`,
    value: `Currently, Trust Stamp is only available to US customers. In case you don't have a US address, please contact us.`,
  },
  ADDRESS_LINES: {
    title: 'What is address line 1 and 2?',
    value: 'You should provide your street address in Address Line 1. Address line 2 is meant for further details such as suite or apartment number.',
  },
  PIN_FORMAT: {
    title: 'What should my PIN be?',
    value: 'Your PIN should consist of four numbers. It should be a number that you will easily remember. You will be required to input it every time you want to access your Trust Stamp.',
  },
  PIN_USAGE: {
    title: 'How often will I use my PIN?',
    value: `It will be required every time you want to log in or pay with credits to unlock someone else's profile.`,
  },
  FORGOT_WHICH_NETWORK: {
    title: 'I forgot which Social Media Network I used to create an account.',
    value: 'Please try logging in with different accounts until you find the one you used to create your Trust Stamp. In case you are still unable to log in, please contact us.',
  },
  NO_WEBCAM: {
    title: 'My computer or cell phone does not have a camera. What should I do?',
    value: 'You should use another device to register an account. You need to take a photo of your Driver Licence and a selfie to create a Trust Stamp.',
  },
  WHY_IS_SELFIE_NEEDED: {
    title: 'Why is my selfie needed?',
    value: 'Selfies are used during the login process to ensure the safety of your account. This guarantees that no-one but you can log into Trust Stamp.',
  },
  FORGOT_PIN: {
    title: 'I seem to have forgotten my PIN. What should I do?',
    value: 'To reset your pin, please contact us.',
  },
  WHAT_IS_A_PIN: {
    title: 'What is the PIN?',
    value: 'A PIN is a four-digit number you used while registering your Trust Stamp account. We use it as a method to ensure the safety of your account.',
  },
  INCORRECT_PIN: {
    title: `If you can't remember your PIN`,
    value: 'Please contact us. We can help you reset your PIN.',
  },
  CANT_REMEMBER_BOA_USERNAME: {
    title: `I don't remember my NRDS Account username, what should I do?`,
    value: `Your username is the e-mail address you used in the registration. If you can't remember which one you used - please contact us.`,
  },
  FORGOT_PASSWORD: {
    title: 'I forgot my password. What should I do?',
    value: 'Please contact us. We can help you reset your password.',
  },
  INCORRECT_BOA_CREDENTIALS: {
    title: `If you can't remember your NRDS Account username or password`,
    value: 'Please contact us. We can help you recover your log in data.',
  },
  YOUR_ID_PICTURE: {
    title: `Your ID picture`,
    value: 'As another layer of security, we will use face matching technology to compare the video of yourself and the picture in the ID. We will also use software to recognise text to prefill some form fields and make the process of applying faster.',
  },
  BACK_OF_THE_ID: {
    title: `Back of the ID`,
    value: 'We use the back of your ID to confirm the validity of the document. We use Software to read the data to provide an additional layer of security.',
  },
  SELFIE_VIDEO_UNKNOWN_ERROR: {
    title: `We couldn’t verify that it’s really you`,
    value: (
      <div>
        Our software creates a model of your face. Please try again doing these:
        <ul>
          <li>Make sure that you start with your arm fully extended</li>
          <li>Find better, frontal lighting</li>
          <li>Make sure that you are clearly visible during the entire process</li>
        </ul>
      </div>
    ),
  },
  SELFIE_VIDEO_SCORE_FAILURE: {
    title: `We couldn’t verify that it’s really you`,
    value: (
      <div>
        Our software creates a model of your face. Please try again doing these:
        <ul>
          <li>Make sure that you start with your arm fully extended</li>
          <li>Find better, frontal lighting</li>
          <li>Make sure that you are clearly visible during the entire process</li>
        </ul>
      </div>
    ),
  },
  SELFIE_VIDEO_FACE_NOT_FOUND: {
    title: `We couldn’t find a face in your video`,
    value: (
      <span>
        Please try doing these:
        <ul>
          <li>Extend your arm fully when taking the video</li>
          <li>Try to find a place with good frontal lighting</li>
          <li>Try to face the camera straight, not at an angle</li>
        </ul>
      </span>
    ),
  },
};
/* eslint-enable max-len, quotes */
