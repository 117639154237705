/* eslint-disable import/prefer-default-export */

export const REACT_SELECT_THEME = {
  borderRadius: 4,
  colors: {
    // selected list element and border of active dropdown. default: #2684FF
    primary: 'var(--color_primaryButtonFill)',
    primary75: '#4C9AFF',
    primary50: '#B2D4FF',
    // focused list element. default: #DEEBFF
    primary25: 'var(--color_primaryButtonOutline)',

    danger: '#DE350B',
    dangerLight: '#FFBDAD',

    neutral0: 'hsl(0, 0%, 100%)',
    neutral5: 'hsl(0, 0%, 95%)',
    neutral10: 'hsl(0, 0%, 90%)',
    neutral20: 'hsl(0, 0%, 80%)',
    neutral30: 'hsl(0, 0%, 70%)',
    neutral40: 'hsl(0, 0%, 60%)',
    neutral50: 'hsl(0, 0%, 50%)',
    neutral60: 'hsl(0, 0%, 40%)',
    neutral70: 'hsl(0, 0%, 30%)',
    neutral80: 'hsl(0, 0%, 20%)',
    neutral90: 'hsl(0, 0%, 10%)',
  },
  spacing: {
    baseUnit: 5,
    controlHeight: 43,
    menuGutter: 8, // select options distance from the input
  }
};
