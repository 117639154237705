import atom from 'atom-js';
import { WOOPRA_EVENTS_MAP } from 'constants';
import { loadScriptPool } from 'sf/helpers';
import user from 'models/user';

const model = atom.setup({
  methods: {
    updateUserInfo(resolve, reject) {
      const cb = () => {
        model.identify();
        resolve();
      };
      model.setup().then(cb, reject);
    },

    trackEvent(resolve, reject, customEventName, properties = null) {
      if (customEventName instanceof Array) {
        const proceedPromiseQueue = () => {
          if (!customEventName.length) {
            resolve();
          }
          const eventName = customEventName.shift();
          if (eventName) { // IE11 doesn't handle shift the same as other browsers;
            model.trackEvent(eventName, properties).then(proceedPromiseQueue, reject);
          }
        };

        proceedPromiseQueue();
        return;
      }

      if (!WOOPRA_EVENTS_MAP[customEventName] && ENV !== 'prod') {
        console.info(`Not recognized event '${customEventName}'`); // eslint-disable-line
      }
      const track = () => {
        const eventProperties = properties || WOOPRA_EVENTS_MAP[customEventName].properties || {};

        let eventName = WOOPRA_EVENTS_MAP[customEventName].name;
        if (global.REALTOR) {
          eventName = eventName.replace(/^signup\./, 'appsignup.');
          eventName = eventName.replace(/^webapp\./, 'app.');
        }

        global.woopra.track(eventName, eventProperties);
      };
      const cb = () => {
        track();
        resolve();
      };
      model.setup().then(cb, reject);
    },
  },
})();

Object.assign(model, {
  identify() {
    global.woopra.identify(Object.assign({
      avatar: user.get('absolute_small_photo_url'),
      name: user.get('legal_name'),
    }, user.pick([
      'address',
      'city',
      'date_birth',
      'email',
      'phone',
      'state',
      'zip_code',
    ]))).push();
  },

  setup() {
    const promise = loadScriptPool('woopra', () => { /* Woopra is loaded by tag manager */ });
    promise.then(model.identify);
    return promise;
  },
});

export default model;
