import React, { Fragment } from 'react';
import { ROUTES } from 'constants';

export const PRESS_TYPES = {
  YOUTUBE: Symbol('YOUTUBE')
};

/* eslint-disable max-len */
export const ARTICLES = [
  {
    date: 'February 18, 2016',
    title: 'Atlanta Startup Crop to Meet for TechCrunch Pitch-Off on Feb. 25',
    slug: 'atlanta-startup',
    url: 'https://hypepotamus.com/events/tech-crunch-meetup-atlanta/'
  },
  {
    date: 'February 22, 2016',
    title: 'QC FinTech Class of 2016',
    slug: 'qc-fintech',
    url: 'http://www.qcfintech.co/blog/qc-fintech-class-of-2016'
  },
  {
    date: 'April 19, 2016',
    title: (
      <Fragment>
        Second Century Ventures<sup>&reg;</sup> Announces 2016 REach
        <sup>&reg;</sup> Accelerator Class
      </Fragment>
    ),
    slug: 'second-century',
    url:
      'https://www.nar.realtor/news-releases/2016/04/second-century-ventures-announces-2016-reach-accelerator-class'
  },
  {
    date: 'June 8, 2016',
    title: 'Realtor-Backed Incubator Invests in Ethereum Identity Startup',
    slug: 'realtor-backed',
    url: 'http://www.coindesk.com/realtor-incubator-ethereum-startup/'
  },
  {
    date: 'June 13, 2016',
    title: 'Trust Stamp Announces the World’s 1st Charitable DAO',
    slug: 'trust-stamp-announces',
    url:
      'https://letstalkpayments.com/trust-stamp-announces-the-worlds-1st-charitable-dao/'
  },
  {
    date: 'June, 14, 2016',
    title:
      'Another Trail Blazed: World’s First Charitable Ethereum-Based DAO Launched',
    slug: 'another-trail',
    url:
      'http://coinalert.eu/20160614051741-Another+Trail+Blazed-+World%E2%80%99s+First+Charitable+Ethereum-Based+DAO+Launched.html'
  },
  {
    date: 'July 5, 2016',
    title: 'Atlanta Company Wins Global Startup Award and Funding',
    slug: 'atlanta-company',
    url: 'http://www.pr.com/press-release/678235'
  },
  {
    date: 'July 19, 2016',
    title:
      'Blockchain Company Trust Camp Wins €100,000 Global Startup Award and Funding',
    slug: 'blockchain-company',
    url:
      'http://www.the-blockchain.com/2016/07/19/blockchain-company-wins-100000-global-startup-award-and-funding/'
  },
  {
    date: 'September 27, 2016',
    title: 'SixThirty CYBER Announces Inaugural Accelerator Class',
    slug: 'sixthirty-cyber',
    url:
      'http://eqstl.com/sixthirty-cyber-announces-inaugural-accelerator-class/'
  },
  {
    date: 'Oct. 12, 2016',
    title: "Trust Stamp Makes Facebook's Marketplace an Even Safer Place",
    slug: 'truststamp-makes',
    url:
      'https://finance.yahoo.com/news/trust-stamp-makes-facebooks-marketplace-180000035.html'
  },
  {
    date: 'November 14, 2016',
    title: 'Trust Stamp Reducing the Risk of Contact IRL',
    slug: 'trust-stamp-reducing',
    url:
      'http://www.11alive.com/entertainment/television/programs/atlanta-tech-edge/trust-stamp-reducing-the-risk-of-contact-irl/351697309'
  },
  {
    date: 'November 16, 2016',
    title: (
      <Fragment>
        Second Century Ventures Invests in Trust Stamp and Keeping Realtors
        <sup>&reg;</sup> Safe
      </Fragment>
    ),
    slug: 'second-century',
    url:
      'https://www.nar.realtor/news-releases/2016/11/second-century-ventures-invests-in-trust-stamp-and-keeping-realtors-safe'
  },
  {
    date: 'November 16, 2016',
    title:
      'Trust Stamp, a safety tool for real estate agents, nabs NAR funding',
    slug: 'trust-stamp-safety',
    url:
      'https://www.inman.com/2016/11/16/trust-stamp-safety-tool-real-estate-agents-nabs-nar-funding/'
  },
  {
    date: 'January, 2017',
    title: 'FinTech Atlanta - The Ecosystem',
    slug: 'fintech-atlanta',
    url: 'https://www.fintechatlanta.org/the-ecosystem'
  },
  {
    date: 'January 4, 2017',
    title: 'FinTech Startup Does Away With Stranger Danger',
    slug: 'fintech-startup',
    url: 'https://hypepotamus.com/companies/trust-stamp/'
  },
  {
    date: 'January 11, 2017',
    title:
      'Andrew Gowasack, Trust Stamp, making the peer-to-peer sharing economy safe',
    slug: 'Andrew-Gowasack',
    url:
      'http://mastermindyourlaunch.libsyn.com/65-andrew-gowasack-trust-stamp-making-the-peer-to-peer-sharing-economy-safer'
  },
  {
    date: 'January 11, 2017',
    title: 'MASTERMIND YOUR LAUNCH: Andrew Gowasack CEO of Trust Stamp',
    slug: 'mastermind-your',
    url: 'https://breaker.audio/e/10457552'
  },
  {
    date: 'February 6, 2017',
    title:
      'Atlanta FinTech Startup Grabs Attention from the South East to Silicon Valley',
    slug: 'atlanta-fintech-grabs',
    url: 'http://www.pr.com/press-release/704376'
  },
  {
    date: 'February 9, 2017',
    title: 'Trust Stamp – Winner of the 2017 TAG FinTech Innovation Award',
    slug: 'truststam-winner',
    url:
      'http://www.wheregeorgialeads.com/accolade/2017-tag-fintech-innovation-award/'
  },
  {
    date: 'March 8, 2017',
    title:
      'Atlanta Tech Village Based Trust Stamp Has Partnered with a Leading Central & Eastern...',
    slug: 'atlanta-tech',
    url: 'http://www.pr.com/press-release/708142'
  },
  {
    date: 'March 9, 2017',
    title:
      'Cybersecurity Startup Launches ATV-Based Finnovation Studio For Entrepreneurs',
    slug: 'cybersecurity-startup',
    url: 'https://hypepotamus.com/news/finnovation-studio/'
  },
  {
    date: 'April 3, 2017',
    title: 'Georgia Association of REALTORS : GAR Partners with Trust Stamp',
    slug: 'georgia-assocation',
    url:
      'http://www.4-traders.com/news/Georgia-Association-of-REALTORS-GAR-Partners-with-Trust-Stamp--24152937/'
  },
  {
    date: 'April 20, 2017',
    title: 'SE Tech Podcast Ep. 24 – Trust Stamp with Andrew Gowasack',
    slug: 'se-tech-podcast',
    url:
      'http://www.mmmtechlaw.com/se-tech-podcast-ep-24-trust-stamp-with-andrew-gowasack/'
  },
  {
    date: 'April 20, 2017',
    title: (
      <Fragment>
        Trust Stamp Provides Safety to Georgia REALTORS<sup>&reg;</sup>
      </Fragment>
    ),
    slug: 'truststamp-provides',
    url: 'http://www.pr.com/press-release/711435'
  },
  {
    date: 'April 20, 2017',
    title: 'Atlanta FinTech Startup Invited to Money 20/20 Startup Challenge',
    slug: 'atlanta-fintech-invited',
    url: 'http://www.pr.com/press-release/713536'
  },
  {
    date: 'April 21, 2017',
    title: 'Trust Stamp Aims to Impress in Money20/20 Startup Challenge',
    slug: 'truststamp-aims',
    url:
      'https://findbiometrics.com/trust-stamp-money2020-startup-challenge-404215/'
  },
  {
    date: 'May 1, 2017',
    title:
      'New CRMLS Marketplace Product "Trust Stamp" Offers Security for Agents',
    slug: 'new-crmls',
    url:
      'https://finance.yahoo.com/news/crmls-marketplace-product-trust-stamp-190000868.html'
  },
  {
    date: 'May 5, 2017',
    title: 'Trust Stamp Joins the 2017 Cohort of Coca Cola’s BridgeCommunity',
    slug: 'truststam-joins',
    url:
      'http://www.rfdtv.com/story/35355411/trust-stamp-joins-the-2017-cohort-of-coca-colas-bridgecommunity'
  },
  {
    date: 'May 9, 2017',
    title: 'BridgeCommunity Startup',
    slug: 'bridgecommunity',
    url:
      'http://bridgecommunity.com/blog/welcome-2017-bridgecommunity-startup-cohort/'
  },
  {
    date: 'May 11, 2017',
    title:
      'Enterprise Ethereum Alliance Adds Digital Identity Startup, Trust Stamp, to Its Membership',
    slug: 'enterprise-ethereum-adds',
    url:
      'http://www.weny.com/story/35404310/enterprise-ethereum-alliance-adds-digital-identity-startup-trust-stamp-to-its-membership'
  },
  {
    date: 'May 22, 2017',
    title: 'Enterprise Ethereum Alliance Announces 86 New Members',
    slug: 'enterprise-ethereum-annouces',
    url:
      'https://www.ethnews.com/enterprise-ethereum-alliance-announces-new-members'
  },
  {
    date: 'June 21, 2017',
    title: 'Export Challenge Names First 28 Awardees to Receive $5,000',
    slug: 'export-challenge',
    url:
      'https://www.globalatlanta.com/export-challenge-names-first-28-awardees-receive-5000/'
  },
  {
    date: 'July 11, 2017',
    title: "10 Amazing AI Startups You Won't Find in Silicon Valley",
    slug: '10-amazing',
    url:
      'https://www.inc.com/lisa-calhoun/10-disruptive-artificial-intelligence-startups-not.html'
  },
  {
    date: 'August, 2017',
    title: 'Trust Stamp: Tackling Identity Fraud with Blockchain',
    slug: 'truststamp-tackling',
    url: 'https://blockchain.cioreview.com/vendor/2017/trust_stamp'
  },
  {
    date: 'September 6, 2017',
    title:
      'Meet the Real Estate Tech Founder: Andrew Gowasack from Trust Stamp',
    slug: 'meet-the-real',
    url:
      'http://geekestateblog.com/meet-real-estate-tech-founder-andrew-gowasack-trust-stamp/'
  },
  {
    date: 'September 14, 2017',
    title:
      'Fifty Tech Companies Selected to Shine at 10th Annual Venture Atlanta',
    slug: 'fifty-tech',
    url:
      'https://venturebeat.com/2017/09/14/fifty-rising-star-tech-companies-selected-to-shine-at-10th-annual-venture-atlanta/'
  },
  {
    date: 'September 15, 2017',
    title: 'Trust Stamp Selected to Present at Venture Atlanta',
    slug: 'truststamp-selected',
    url: 'https://www.pr.com/press-release/729919'
  },
  {
    date: 'October 9, 2017',
    title: 'The BridgeCommunity Halo Effect',
    slug: 'bridgecommunity-halo',
    url: 'http://bridgecommunity.com/blog/the-bridgecommunity-halo-effect/'
  },
  {
    date: 'October 21, 2017',
    title: 'Atlanta is Center of FinTech Universe',
    slug: 'atlanta-is-center',
    url: 'http://www.gafollowers.com/atlanta-is-center-of-fintech-universe/'
  },
  {
    date: 'October 24, 2017',
    title:
      'Georgia’s Major Fintech Players Help Startups Bring the Money to Vegas',
    slug: 'georgia-major',
    url:
      'https://hypepotamus.com/news/atlanta-fintech-partnerships-at-money2020/'
  },
  {
    date: 'November 3, 2017',
    title: 'Second Century Ventures Makes Second Investment in Trust Stamp',
    slug: 'second-century',
    url:
      'https://www.prnewswire.com/news-releases/second-century-ventures-makes-second-investment-in-trust-stamp-300549304.html'
  },
  {
    date: 'November 3, 2017',
    title: 'Second Century Ventures backs Trust Stamp',
    slug: 'second-century-backs',
    url:
      'https://www.pehub.com/2017/11/second-century-ventures-backs-trust-stamp/#'
  },
  {
    date: 'November 7, 2017',
    title: (
      <Fragment>
        Trust Stamp Announces Free Safety Tool For All Realtors<sup>&reg;</sup>
      </Fragment>
    ),
    slug: 'truststamp-announces-free-youtube',
    url: 'https://www.youtube.com/embed/HzRsxn4cJPY',
    type: PRESS_TYPES.YOUTUBE
  },
  {
    date: 'November 7, 2017',
    title: 'Trust Stamp Announces Free Safety Tool for All Realtors',
    slug: 'truststamp-announces-free',
    url:
      'https://www.broadwayworld.com/bwwgeeks/article/Trust-Stamp-Announces-Free-Safety-Tool-for-All-Realtors-20171107'
  },
  {
    date: 'November 8, 2017',
    title: 'Identity verification app Trust Stamp now free for Realtors',
    slug: 'identity-verification',
    url:
      'https://www.inman.com/2017/11/08/identity-verification-app-trust-stamp-now-free-for-realtors/'
  },
  {
    date: 'November 8, 2017',
    title: (
      <Fragment>
        Trust Stamp Is Now a Free Safety Tool for REALTORS<sup>&reg;</sup>
      </Fragment>
    ),
    text:
      'Trust Stamp, an identification authenticity tool that leverages artificial intelligence and blockchain technologies to verify prospective clients…',
    slug: 'truststamp-is-now',
    url:
      'http://realtormag.realtor.org/daily-news/2017/11/08/trust-stamp-now-free-safety-tool-for-realtors'
  },
  {
    date: 'January 23, 2018',
    title: 'Trust Stamp will use selfies to protect your email',
    text:
      'Can we trust in facial recognition to end wire fraud? The new Trusted Mail plugin for Gmail and Outlook seeks to do just that...',
    slug: 'truststamp-will',
    url:
      'https://www.inman.com/2018/01/23/trust-stamp-will-use-selfies-to-protect-your-email/'
  },
  {
    date: 'January 24, 2018',
    title:
      'Polish-made AI brings digital transaction security to a new level (in Polish)',
    text:
      'Wykrywa przywłaszczenia i próby fałszowania tożsamości, z powodzeniem zastępuje hasła i wykorzystuje do tego jedną z niewielu rzeczy…',
    slug: 'polish-made',
    url:
      'https://businessinsider.com.pl/opracowana-w-polsce-ai-wynosi-bezpieczenstwo-cyfrowych-transakcji-na-nowy-poziom/90c6fjp'
  },
  {
    date: 'January 30, 2018',
    title:
      'Inside Hacker Connect 2018: Predictive Analytics, Biometrics, and Blockchain',
    text:
      'Some of the most defining technologies in real estate were debated and discussed at the Hacker Connect conference on January 22…',
    slug: 'inside-hacker',
    url:
      'https://propmodo.com/inside-hacker-connect-2018-predictive-analytics-biometrics-blockchain/'
  },
  {
    date: 'January, 2018',
    title:
      'Free for Members: Trust Stamp. New tool uses social media data to enhance identity verification...',
    text:
      'When scheduling a meeting with a new client or prospect for the first time, feel more confident about your personal safety…',
    slug: 'free-for',
    url:
      'http://realtormag.realtor.org/news-and-commentary/your-nar/article/2018/01/free-for-members-trust-stamp'
  },
  {
    date: 'February 13, 2018',
    title:
      '10Clouds Partners With Trust Stamp to Work on a Revolutionary Facial Authentication Technology',
    text:
      'Trust Stamp’s biometric authentication system utilizes blockchain, machine learning, and computer vision to answer two questions…',
    slug: 'truststamp-biometric',
    url:
      'https://10clouds.com/blog/trust-stamp-sunflower-ai-facial-authentication-interview/'
  },
  {
    date: 'February 21, 2018',
    title: 'Tech Connect NY 18: The app that wants to keep you safe',
    text:
      '"Who are you? And should I trust you?" These are the two essential questions asked and answered by the artificial intelligence (AI) built into...',
    slug: 'tech-connect',
    url:
      'https://www.inman.com/2018/02/21/tech-connect-ny-18-the-app-that-wants-to-keep-you-safe/'
  },
  {
    date: 'February 23, 2018',
    title:
      'Technology Association of Georgia Unveils the Top 40 Innovative Technology Companies in Georgia',
    text:
      'TAG’S Top 40 Awards recognize Georgia-based technology companies for their innovation, financial impact, and their efforts…',
    slug: 'technology-association',
    url:
      'http://middlegeorgiaceo.com/features/2018/02/technology-association-georgia-unveils-top-40-innovative-technology-companies-georgiaa/'
  },
  {
    date: 'February 27, 2018',
    title:
      '13 ATDC-Connected Startups Named to ‘TAG Top 40 Innovative Technology Companies’ List',
    text:
      'The Technology Association of Georgia announced the Top 40 Most Innovative Technology Companies in Georgia…',
    slug: '13-atdc',
    url:
      'http://metroatlantaceo.com/news/2018/02/13-atdc-connected-startups-named-tag-top-40-innovative-technology-companies-list/'
  },
  {
    date: 'March 1, 2018',
    title: 'NAEA Propertymark in new drive to combat money laundering',
    text:
      'Propertymark Passport has launched and is now available to all NAEA Propertymark members. Developed with Trust Stamp…',
    slug: 'naea-propertymark',
    url:
      'http://www.propertyreporter.co.uk/finance/naea-propertymark-in-new-drive-to-comabt-money-laundering.html'
  },
  {
    date: 'March 1, 2018',
    title:
      'TAG Unveils the 2018 Top 10 Innovative Technology Companies in Georgia',
    text:
      'The Technology Association of Georgia (TAG) today announced the Top 10 Innovative Technology Companies in Georgia.',
    slug: 'tag-unveils',
    url:
      'http://www.tagonline.org/news-press/tag-unveils-the-2018-top-10-innovative-technology-companies-in-georgia/'
  },
  {
    date: 'March 8, 2018',
    title: "New tool eases burden of agents' anti-money laundering compliance",
    text:
      'Propertymark Passport, developed with identity verification consultancy Trust Stamp, is aimed at making submissions under…',
    slug: 'new-tool',
    url:
      'https://www.estateagenttoday.co.uk/breaking-news/2018/2/new-tool-eases-burden-of-agents-anti-money-laundering-compliance'
  },
  {
    date: 'March 13, 2018',
    title: 'Trust Stamp named a TOP 10 innovative technology company',
    text:
      "The Technology Association of Georgia (TAG), the state's leading association dedicated to the promotion and economic advancement...",
    slug: 'truststamp-named',
    url:
      'http://markets.businessinsider.com/news/stocks/trust-stamp-named-a-top-10-innovative-technology-company-1018644219'
  },
  {
    date: 'March 19, 2018',
    title: 'New cybersecurity businesses flourish in Atlanta',
    text:
      'CEOs who worry about cyber-attacks may take comfort in knowing that several Atlanta-based companies and start-ups are working...',
    slug: 'new-cybersecurity',
    url:
      'https://www.bizjournals.com/atlanta/news/2018/03/19/new-cybersecurity-businesses-flourish-in-atlanta.html'
  },
  {
    date: 'April 11, 2018',
    title: 'Trust Stamp Among Startups to Showcase at First Ever Next Gen Park',
    text:
      "Trust Stamp today announced that it has been selected to participate in Next Gen Park at TRANSACT -- the world's largest payments...",
    slug: 'next-gen-park',
    url:
      'http://markets.businessinsider.com/news/stocks/trust-stamp-among-startups-to-showcase-at-first-ever-next-gen-park-1021174019'
  },
  {
    date: 'April 11, 2018',
    title:
      'World’s Largest Payments Tech Event to Highlight Southern Fintech Startups, Atlanta HBCU Entrepreneurs',
    text:
      'The Electronic Transactions Association (ETA) announced today that four payments and fintech-related startup programs will send select...',
    slug: 'worlds-largest-payments',
    url:
      'https://hypepotamus.com/news/electronic-transactions-association-selects-southern-startup-accelerators/'
  },
  {
    date: 'May 8, 2018',
    title: '11 Atlanta FinTech Companies That Have Raised More Than $1 Million',
    text:
      'As FinTech South 2018 is underway at Mercedes-Benz stadium Tuesday, we at Atlanta Inno decided to take a look at how much “transaction alley” has grown.',
    slug: '11-atlanta-fintech-companies',
    url:
      'https://www.americaninno.com/atlanta/fundings/10-atlanta-fintech-companies-raise-more-than-1-million/'
  },
  {
    date: 'May 12, 2018',
    title: 'Trust Stamp wins the People’s Choice Award at Plug and Play',
    text: 'Trust Stamp wins the People’s Choice Award at Plug and Play',
    url: 'https://www.youtube.com/embed/z7Mu9wShYCE',
    type: PRESS_TYPES.YOUTUBE
  },
  {
    date: 'May 21, 2018',
    title: 'Growing Fintech industry employs tens of thousands in Georgia',
    text:
      'More than 70 percent of all credit card transactions are now being processed out of metro Atlanta and that means security is a top priority.',
    slug: 'growing-fintech-industry-employs',
    url:
      'https://www.ajc.com/news/local/growing-fintech-industry-employs-tens-thousands-georgia/DfTsd99FAq0Q7i3J3gkSWK/'
  },
  {
    date: 'June 12, 2018',
    title:
      '+25% CAGR to Be Achieved by Passive authentication Market by Extensive market growth by focusing on Top Key Vendors',
    text:
      'The growing want for the frictionless authentication among organizations is anticipated to drive the growth of the passive...',
    slug: '25-cagr-to-be-achieved',
    url:
      'https://www.satprnews.com/2018/06/11/25-cagr-to-be-achieved-by-passive-authentication-market-by-extensive-market-growth-by-focusing-on-top-key-vendors-like-nuance-communications-nudata-security-pindrop-rsa-security-securedtouch-tru/'
  },
  {
    date: 'June 20, 2018',
    title:
      'Atlanta Startup Trust Stamp Is Using Biometrics to Identify Victims Of Cross-Border Human Trafficking',
    text:
      'This week, Attorney Generals from the U.S. and Mexico will unveil a new system for finding victims of the human trafficking...',
    slug: 'atlanta-startup-trust-stamp-is',
    url:
      'https://hypepotamus.com/news/trust-stamp-identifies-human-trafficking-victims/'
  },
  {
    date: 'June 25, 2018',
    title:
      'Trust Stamp develops human trafficking victim identification system with facial recognition',
    text:
      'Artificial intelligence startup Trust Stamp has developed a system to find victims of human trafficking using facial recognition...',
    slug: 'trust-stamp-develops-human',
    url:
      'https://www.biometricupdate.com/201806/trust-stamp-develops-human-trafficking-victim-identification-system-with-facial-recognition'
  },
  {
    date: 'July, 2018',
    title: 'Andrew Gowasack CEO, Trust Stamp',
    text:
      'With the rise of social media fake accounts and stolen identities, how do we trust someone we’ve met online?',
    slug: 'andrew-gowasack-ceo-trust-stamp',
    url: 'https://simplybuckhead.com/andrew-gowasack/'
  },
  {
    date: 'August 30, 2018',
    title:
      'TrustStamp and The CWAG Alliance Partnership Introduce Technology to Ease Data Sharing Among Law Enforcement',
    text:
      'The Conference of Western Attorneys General Alliance Partnership and TrustStamp are proud to introduce a proof of concept model that...',
    slug: 'trust-stamp-cwag-general-alliance',
    url:
      'https://markets.businessinsider.com/news/stocks/truststamp-and-the-conference-of-western-attorneys-general-alliance-partnership-introduce-technology-to-ease-data-sharing-among-law-enforcement-1027498459'
  },
  {
    date: 'August 30, 2018',
    title:
      'TrustStamp biometric human trafficking detection system to be piloted in U.S. and Mexico',
    text:
      'TrustStamp and The Conference of Western Attorneys General (CWAG) Alliance Partnership have announced the introduction of a proof of...',
    slug: 'trust-stamp-detection-system',
    url:
      'https://www.biometricupdate.com/201808/truststamp-biometric-human-trafficking-detection-system-to-be-piloted-in-u-s-and-mexico'
  },
  {
    date: 'September 16, 2018',
    title:
      'Plug and Play ADGM Announces First Five Corporate Partners and Seven Startups to Join Fintech Innovation Platform in Abu Dhabi',
    text:
      'Plug and Play, the largest global innovation platform, and Abu Dhabi Global Market (ADGM), the leading international financial centre...',
    slug: 'plug-and-play-first-partners',
    url:
      'https://www.prnewswire.com/news-releases/plug-and-play-adgm-announces-first-five-corporate-partners-and-seven-startups-to-join-fintech-innovation-platform-in-abu-dhabi-300713361.html'
  },
  {
    date: 'September 25, 2018',
    title:
      'Emergent Technology™ To Introduce Identity Verification Across Its Platforms',
    text:
      'Identity and biometric hashing technology to be added to the Company’s payments and gold supply chain solutions.',
    slug: 'emergent-to-introduce-identity-verification',
    url:
      'https://www.emergenttechnology.com/emergent-technology-to-introduce-identity-verification-across-its-platforms/'
  },
  {
    date: 'September 27, 2018',
    title: 'Trust Stamp facial biometrics added to EmTech fintech platforms',
    text:
      'Emergent Technology Holdings (EmTech) has announced a strategic investment in Trust Stamp, and will deploy artificial intelligence and biometric hashing technology from Trust Stamp...',
    slug: 'trust-stamp-facial-biometrics-added',
    url:
      'https://www.biometricupdate.com/201809/trust-stamp-facial-biometrics-added-to-emtech-fintech-platforms'
  },
  {
    date: 'October 15, 2018',
    title: 'Metro Atlanta Export Stories - Trust Stamp',
    slug: 'metro-atlant-export-stories-trust-stamp',
    url: 'https://www.youtube.com/embed/n-bimm7nIwg',
    type: PRESS_TYPES.YOUTUBE
  },
  {
    date: 'October 16, 2018',
    title:
      'Andrew Gowasack with Trust Stamp, Landon Bennett with Ad Reform & Userfeed, Ugo Ezeamuzie with Bloveit',
    text:
      'Andrew Gowasack is the CEO and a co-founder of Trust Stamp. An economist by training, Andrew began his career in financial services sales...',
    slug: 'andrew-gowasack-with-trust-stamp-landon',
    url: 'https://businessradiox.com/podcast/atlantabusinessradio/trust-stamp/'
  },
  {
    date: 'November 9, 2018',
    title: 'Taking a Do-it-Together Approach: Side-by-Side with Startups',
    text:
      'Leave the do-it-yourself projects at home and make way for do-it-together.',
    slug: 'taking-a-do-it-together-approach',
    url:
      'https://newsroom.mastercard.com/news-briefs/taking-a-do-it-together-approach-side-by-side-with-startups/'
  },
  {
    date: 'December 19, 2018',
    title:
      'The Paypers launches the Web Fraud Prevention, Identity Verification & Authentication Guide 2018/2019',
    text:
      'The Web Fraud Prevention, Identity Verification & Authentication Guide 2018 is now live, offering a complete overview of the latest trends into...',
    slug: 'the-paypers-launches-the-web-fraud-prevention',
    url:
      'https://www.thepaypers.com/online-payments/the-paypers-launches-the-web-fraud-prevention-identity-verification-authentication-guide-2018-2019/776408-3'
  },
  {
    date: 'January 14, 2019',
    title:
      'Andrew Gowasack with TrustStamp, Charu Thomas with Oculogx and Zachary Aten with Grassroots Labs',
    text:
      'Andrew Gowasack is the CEO and a co-founder of TrustStamp. His primary focus is seeking out and building the strategic partnerships needed to...',
    slug: 'andrew-gowasack-with-truststamp-charu',
    url: 'https://businessradiox.com/podcast/atlantabusinessradio/atdc-4/'
  },
  {
    date: 'January 25, 2019',
    title: 'TAG Unveils Top 40 Innovative Georgia Companies',
    text:
      'The Technology Association of Georgia has announced its annual Top 40 Innovative Companies.',
    slug: 'tag-unveils-top-40-innovative-georgia-companies',
    url:
      'https://www.americaninno.com/atlanta/atlanta-startups/tag-unveils-top-40-innovative-georgia-companies/'
  },
  {
    date: 'February 25, 2019',
    title: 'Account takeover and step up authentication',
    text:
      'While the sophistication with which fraudsters attack has increased dramatically, so have the tools businesses can use to combat them.',
    slug: 'account-takeover-and-step-up-authentication',
    url:
      'https://www.thepaypers.com/thought-leader-insights/account-takeover-and-step-up-authentication/777444'
  },
  {
    date: 'March, 2019',
    title: 'Identity matters',
    text:
      'Trust Stamp offers AI-powered facial biometric solutions to keep internet fraudsters at bay.',
    slug: 'identity-matters',
    url:
      'https://issuu.com/entmagazineme/docs/entrepreneur_middle_east_march_2019/80'
  },
  {
    date: 'March, 2019',
    title: 'Emergent Technology acquires Interpay Africa',
    text:
      'The acquisition expands Emergent Technology Holdings LP’s payments capabilities in Africa for international merchant clients and strengthens Interpay Africa’s local presence.',
    slug: 'emergent-technology-acquires-interpay-africa',
    url:
      'https://www.emergenttechnology.com/emergent-technology-acquires-interpay-africa/'
  },
  {
    date: 'March, 2019',
    title: 'iOi Speakers',
    text:
      'iOi offers an incredible two days of learning, exclusive insights, and networking opportunities. It is open to anyone interested in innovation, opportunities and investments...',
    slug: 'ioi-speakers',
    url: 'https://www.nar.realtor/events/ioi/ioi-speakers'
  },
  {
    date: 'March 7, 2019',
    title:
      'NASDAQ #TradeTalks: Tokenized Identity for Global Financial Inclusion',
    slug: 'tradetalks-tokenized-identity-for-global-financial-inclusion',
    url: 'https://www.youtube.com/embed/baWClpnBRT8',
    type: PRESS_TYPES.YOUTUBE
  },
  {
    date: 'April 17, 2019',
    title:
      'ATDC RADIO: Sneh Parmar with Parker Solutions and Alex Valdes with Trust Stamp',
    text:
      'Alex Valdes joined Trust Stamp over two years ago, while the company was still in its first year, because he immediately identified with the company’s mission to protect...',
    slug: 'atdc-radio-sneh-parmar-with-parker-solutions',
    url:
      'https://businessradiox.com/podcast/atlantabusinessradio/parker-solutions/'
  },
  {
    date: 'May 9, 2019',
    title: 'Trust Stamp Expands Leadership Team, Addressing Financial Crime',
    text:
      'Artificial Intelligence Powered Identity Authentication Company Names US Marshals Service Senior Inspector and Marine Corps Veteran as Director of Financial Crime Services.',
    slug: 'trust-stamp-expands-leadership-team',
    url: 'https://www.pr.com/press-release/784581'
  },
  {
    date: 'May 14, 2019',
    title:
      'Fintech: What is the future of money and transactions? | FutureX Live: The Next 10',
    slug: 'fintech-what-is-the-future-of-money',
    url: 'https://www.youtube.com/embed/dE05SxbC1_U',
    type: PRESS_TYPES.YOUTUBE
  },
  {
    date: 'July 24, 2019',
    title:
      'Trust Stamp Partners with Vital4 to take KYC / AML to theNext Level',
    slug: 'trust-stamp-partners-with-vital4',
    url: '/press/vital4.html'
  },
  {
    date: 'August 18, 2019',
    title:
      'U.K.’s National Cyber Security Centre Selects Trust Stamp for Participation in the NCSC Cyber Accelerator',
    slug: 'ncsc-press-release-cover',
    url:
      'https://atdc.org/blog/u-k-s-national-cyber-security-centre-selects-trust-stamp-for-participation-in-the-ncsc-cyber-accelerator/'
  },
  {
    date: 'August 28, 2019',
    title:
      'Trust Stamp Provides Biometric Check-In Technology for the 2019 Annual IAFCI Training Conference',
    slug: 'iafci-press-release-cover',
    url:
      'https://www.issuewire.com/trust-stamp-provides-biometric-check-in-technology-for-the-2019-annual-iafci-training-conference-1643066916379233'
  },
  {
    date: 'September 19, 2019',
    title: "Simon Says, Let's Talk Business: Scott Francis with Trust Stamp",
    slug: 'business-radiox-press-cover',
    url: 'https://businessradiox.com/podcast/simonsays/trust-stamp-2/'
  },
  {
    date: 'October 9, 2019',
    title: 'John Bridge on Protecting Biometric Identity Against Data Breaches',
    slug: 'biometric-breaches-article-john',
    url: 'https://hypepotamus.com/community/biometric-identity-security/'
  },
  {
    date: 'October 14, 2019',
    title: 'Gareth Genner represents Trust Stamp at Pitch@Palace 12.0',
    url: 'https://www.youtube.com/embed/i-tm9z3iguk',
    type: PRESS_TYPES.YOUTUBE
  },
  {
    date: 'October 16, 2019',
    title:
      'Trust Stamp named in Gartner September 2019 Market Guide for Identity Proofing and Corroboration',
    slug: 'identity-proofing-and-corroboration',
    url:
      'https://www.issuewire.com/trust-stamp-recognized-by-gartner-as-a-representative-vendor-in-identity-proofing-and-corroboration-1647454152454070'
  },
  {
    date: 'November 4, 2019',
    title:
      'Trust Stamp Participates in QITCOM 2019 after being Selected as a Global Top-50 Startup',
    slug: 'qitcom-article-cover',
    url:
      'https://www.issuewire.com/trust-stamp-participates-in-qitcom-2019-after-being-selected-as-a-global-top-50-startup-1649232943834439'
  },
  {
    date: 'November 11, 2019',
    title:
      'Former United States Marshals Service Director Joins Advisory Board',
    slug: 'stacia-hylton-pr-cover',
    url:
      'https://www.newswire.com/news/former-united-states-marshals-service-director-stacia-hylton-joins-21031647'
  },
  {
    date: 'November 18, 2019',
    title: 'Co-Founder of Women in Identity Joins Advisory Board',
    slug: 'e-lindley-pr-cover',
    url:
      'https://www.newswire.com/news/trust-stamp-expands-advisory-board-addressing-globally-inclusive-21031646'
  },
  {
    date: 'December 3, 2019',
    title:
      'Trust Stamp is selected for Participation in the Blue Tulip Awards Class of 2020',
    slug: 'blue-tulip-article-cover',
    url:
      'https://www.newswire.com/news/accentures-blue-tulip-awards-selects-trust-stamp-for-participation-in-21046068'
  },
  {
    date: 'January 16, 2020',
    title:
      "Accenture's Blue Tulip Awards Names Trust Stamp as a Top 25 Finalist",
    slug: 'bluetulip-cover',
    url:
      'https://www.newswire.com/news/accentures-blue-tulip-awards-names-trust-stamp-as-a-top-25-candidate-21077116'
  },
  {
    date: 'January 23, 2020',
    title: 'Trust Stamp Receives Nomination for the CNP Vendor Awards',
    slug: 'cnp-website-cover',
    url:
      'https://www.newswire.com/news/trust-stamp-receives-nomination-for-the-cnp-awards-best-identity-21081832'
  },
  {
    date: 'January 30, 2020',
    title: 'Trust Stamp Receives Investment from Mastercard',
    slug: 'mastercard-website-cover',
    url:
      'https://www.prnewswire.com/news-releases/trust-stamp-receives-investment-from-mastercard-300995877.html?tc=eml_cleartime'
  },
  {
    date: 'February 4, 2020',
    title: 'Global Enterprise Security Leader Joins Advisory Board',
    slug: 'b-christie-pr-cover',
    url:
      'https://www.newswire.com/news/trust-stamp-welcomes-global-enterprise-security-leader-to-advisory-21089372'
  },
  {
    date: 'March 9, 2020',
    title: 'Gender-Equal Tech Company Strives for Gender Parity on Board',
    slug: 'truststamp-women-pr-cover',
    url:
      'https://www.newswire.com/news/majority-women-employed-tech-company-celebrates-internal-successes-in-21109420'
  },
  {
    date: 'March 17, 2020',
    title:
      'TrustStamp Seeking to raise $5.4M from public market, Concurrent to Expansions in Team',
    slug: 'truststamp-raise-pr-cover',
    url:
      'https://www.newswire.com/news/trust-stamp-seeking-to-raise-5-4m-from-public-market-concurrent-to-21112845'
  },
  {
    date: 'March 27, 2020',
    title:
      'Trust Stamp Expands Science Team and Develops Touchless Fingerprint Solutions',
    slug: 'truststamp-science-team-pr-cover',
    url:
      'https://www.biometricupdate.com/202003/trust-stamp-adds-ai-and-ml-experts-focusing-on-biometric-technology-for-public-health-and-security'
  },
  {
    date: 'April 6, 2020',
    title:
      'Trust Stamp Develops Secure Location-Tracking Tool Built on Privacy and Trust for COVID-19',
    slug: 'truststamp-safe14-cover',
    url:
      'https://www.biometricupdate.com/202004/trust-stamp-launches-biometric-privacy-preserving-location-tracking-tech5-and-icomply-offer-remote-id-support'
  },
  {
    date: 'April 16, 2020',
    title:
      'Former British Intelligence Cybersecurity and Technology Experts Join Advisory Board',
    slug: 'truststamp-daryl-niel',
    url:
      'https://www.newswire.com/news/privacy-focused-identity-company-welcomes-former-british-intelligence-21129981'
  },
  {
    date: 'April 23, 2020',
    title:
      'Extra Crunch - 6 Investment Trends that Could Emerge from the COVID-19 Pandemic',
    slug: 'techcrunch-cover',
    url:
      'https://techcrunch.com/2020/04/22/6-investment-trends-that-could-emerge-from-the-covid-19-pandemic/?guccounter=1'
  },
  {
    date: 'April 29, 2020',
    title: 'Trust Stamp Launches Public Benefit Corporation Subsidiary',
    slug: 'truststamp-aiid',
    url:
      'https://www.fintechfutures.com/2020/04/mastercard-backed-trust-stamp-spins-out-subsidiary-aiid-global/'
  },
  {
    date: 'May 5, 2020',
    title: 'SE Tech Podcast with Andrew Gowasack',
    slug: 'se-tech-cover',
    url:
      'https://soundcloud.com/setech_podcast/ep-99-andrew-gowasack-president-co-founder-of-trust-stamp'
  },
  {
    date: 'May 12, 2020',
    title: 'Trust Stamp Enters OnRamp Insurance Accelerator',
    slug: 'truststamp-onramp-insurance-accelerator',
    url:
      'https://mobileidworld.com/trust-stamp-enters-onramp-insurance-accelerator-051203/'
  },
  {
    date: 'May 22, 2020',
    title: 'Gareth Genner on the Impact of COVID-19',
    slug: 'truststamp-covid-19',
    url: 'https://www.seedinvest.com/blog/seedinvest/trust-stamp-covid-19'
  },
  {
    date: 'May 29, 2020',
    title: <Fragment>Andrew Gowasack on ATDC's #TechTakeFive</Fragment>,
    slug: 'truststamp-atdc',
    url: 'https://www.youtube.com/embed/A4ar1s-OnC0',
    type: PRESS_TYPES.YOUTUBE
  },
  {
    date: 'June 1, 2020',
    title: 'Trust Stamp Plans to List on Euronext Dublin',
    slug: 'truststamp-euronext',
    url:
      'https://www.independent.ie/business/irish/trust-stamp-to-float-for-29m-in-years-first-ipo-here-39248710.html'
  },
  {
    date: 'June 4, 2020',
    title: 'Trust Stamp Launches Malta Subsidiary with Government Backing',
    slug: 'truststamp-malta',
    url:
      'https://www.biometricupdate.com/202006/trust-stamp-launching-malta-subsidiary-with-government-support-to-deliver-biometric-id-to-africa'
  },
  {
    date: 'June 9, 2020',
    title: 'Enforcing AML/KYC Compliance When Legal Precedent Does Not Apply',
    slug: 'truststamp-pymnts',
    url: 'https://www.pymnts.com/tracker/digital-identity-june-2020/'
  },
  {
    date: 'June 11, 2020',
    title: 'Trust Stamp Named Top Finance Innovation',
    slug: 'truststamp-bluetulip',
    url:
      'https://bluetulipawards.nl/en-us/articles/the-winners-of-the-blue-tulip-awards-2020'
  },
  {
    date: 'June 12, 2020',
    title:
      'Trust Stamp Review: This Insurtech Startup Offers Facial Recognition for Good',
    slug: 'truststamp-investorplace',
    url:
      'https://investorplace.com/2020/06/trust-stamp-review-facial-recognition-startup/'
  },
  {
    date: 'June 25, 2020',
    title: 'Winner Finance Blue Tulip Awards 2020 | Trust Stamp',
    slug: 'bluetulip-finance',
    url: 'https://www.youtube.com/embed/8D4VLqH2q8k',
    type: PRESS_TYPES.YOUTUBE
  },
  {
    date: 'June 26, 2020',
    title: 'Signed, sealed, encrypted: This digital ID is all yours',
    slug: 'truststamp-digital-id',
    url:
      'https://mastercardcontentexchange.com/perspectives/2020/signed-sealed-encrypted-this-digital-id-is-all-yours/'
  },
  {
    date: 'June 30, 2020',
    title: 'Trust Stamp Joins FIS FinTech Accelerator',
    slug: 'fintech-accelerator',
    url:
      'https://finance.yahoo.com/news/fis-selects-ten-startups-2020-120000644.html'
  },
  {
    date: 'July 5, 2020',
    title: 'Blue Tulip Awards Winner Interview',
    slug: 'bluetulip-interview',
    url:
      'https://www.rtlnieuws.nl/economie/business/artikel/5169887/een-digitale-identiteit-voor-iedereen-en-circulair-piepschuim'
  },
  {
    date: 'July 7, 2020',
    title: 'Trust Stamp Joins LORCA Cyber Accelerator',
    slug: 'lorca-cyber-accelerator',
    url:
      'https://www.lorca.co.uk/17-companies-from-across-the-uk-and-the-globe-join-lorcas-fifth-cyber-accelerator/'
  },
  {
    date: 'July 30, 2020',
    title: 'Silicon Canals Interview with Blue Tulip Awards Winners',
    slug: 'interview-bta',
    url:
      'https://siliconcanals.com/news/blue-tulip-awards-2020-winners-finance-security-trust-stamp-pandora-intelligence/?utm_content=bufferefb51&utm_medium=social&utm_source=twitter.com&utm_campaign=buffer'
  },
  {
    date: 'August 4 2020',
    title:
      'Trust Stamp, 10Clouds Team up to Bring Security Solutions to Small Businesses',
    slug: 'mobileidworld',
    url:
      'https://mobileidworld.com/trust-stamp-partners-10clouds-bring-security-solutions-small-businesses-080405/'
  },
  {
    date: 'August 12 2020',
    title: 'Trust Stamp Ranks on 2020 Inc. 5000 List',
    slug: 'truststamp-inc5000',
    url:
      'https://finance.yahoo.com/news/trust-stamp-ranks-no-771-120000921.html'
  },
  {
    date: 'September 1 2020',
    title: 'Trust Stamp Closes $6.5M Regulation A Offering on SeedInvest',
    slug: 'truststamp-seedinvest',
    url:
      'https://markets.businessinsider.com/news/stocks/trust-stamp-closes-6-5m-regulation-a-offering-on-seedinvest-1029551610'
  },
  {
    date: 'September 8 2020',
    title:
      'Trust Stamp Joins Dubai International Financial Centre FinTech Hive Accelerator',
    slug: 'truststamp-difc',
    url:
      'https://globalfintechseries.com/trust-stamp-joins-dubai-international-financial-centre-fintech-hive-accelerator-programme/'
  },
  {
    date: 'September 16 2020',
    title: 'FIS Fintech Founder Feature',
    slug: 'truststamp-fis-andrew',
    url: 'https://www.venturecenter.co/blog/truststamp'
  },
  {
    date: 'October 5 2020',
    title:
      'Malta Minister for the Economy, Investment and Small Businesses Visits Trust Stamp',
    slug: 'truststamp-gng-malta',
    url:
      'https://www.businesstoday.com.mt/business/business/1134/american_startup_establishes_malta_presence?_ke=eyJrbF9lbWFpbCI6ICJubmFpa0B0cnVzdHN0YW1wLm5ldCIsICJrbF9jb21wYW55X2lkIjogIk5ianFLeCJ9#.X4bo7dBKhHY'
  },
  {
    date: 'October 7 2020',
    title:
      'Partnering with OneBanks to Improve Financial Service Accessibility',
    slug: 'truststamp-onebanks',
    url:
      'https://www.biometricupdate.com/202010/onebanks-integrates-trust-stamp-face-biometrics-to-improve-financial-service-accessibility'
  },
  {
    date: 'October 9 2020',
    title: 'Top Emerging RegTech Startups of 2020',
    slug: 'truststamp-regtech-award',
    url: 'https://tracxn.com/d/emerging-startups/regtech-startups-2020'
  },
  {
    date: 'October 15 2020',
    title: 'Trust Stamp Wins "Audience Choice" at FIS FinTech Demo Day',
    slug: 'truststamp-audience-choice',
    url: 'https://www.venturecenter.co/blog/demoday'
  },
  {
    date: 'October 16 2020',
    title: "Finalist in World Bank's ID4D Mission Billion Challenge",
    slug: 'truststamp-mission-billion',
    url:
      'https://www.biometricupdate.com/202010/world-bank-competition-seeking-digital-id-projects-for-the-most-vulnerable-nears-end'
  },
  {
    date: 'November 10 2020',
    title: 'Progress Towards Direct Listing',
    slug: 'truststamp-dtc',
    url: ROUTES.ANNOUCEMENTS
  },
  {
    date: 'November 16 2020',
    title: 'Science in the City, Malta',
    slug: 'truststamp-SITC',
    url:
      'https://timesofmalta.com/articles/view/what-can-you-expect-from-this-years-science-in-the-city.832243'
  },
  {
    date: 'November 20 2020',
    title: 'Pre-Admission Announcement - Euronext Growth',
    slug: 'truststamp-preadmission',
    url:
      'https://www.londonstockexchange.com/news-article/market-news/pre-admission-announcement-euronext-growth/14763100'
  },
  {
    date: 'November 20 2020',
    title: 'US tech company Trust Stamp applies for listing in Dublin',
    slug: 'truststamp-irishtimes',
    url:
      'https://www.irishtimes.com/business/technology/us-tech-company-trust-stamp-applies-for-listing-in-dublin-1.4414909'
  },
  {
    date: 'December 8 2020',
    title: 'Trust Stamp lists on Euronext Growth Dublin',
    slug: 'truststamp-listing',
    url: 'https://player.vimeo.com/video/488400620',
    type: PRESS_TYPES.YOUTUBE
  },
  {
    date: 'December 8 2020',
    title: "IAG's Hangar 51 Accelerator Cohort",
    slug: 'truststamp-hangar51',
    url:
      'https://centreforaviation.com/news/iag-selects-22-finalists-for-hangar-51-global-accelerator-starts-1042078'
  },
  {
    date: 'December 8 2020',
    title: 'Trust Stamp Lists on Euronext Dublin',
    slug: 'truststamp-euronext-listing',
    url:
      'https://www.euronext.com/en/about/media/euronext-press-releases/trust-stamp-lists-euronext-dublin'
  },
  {
    date: 'December 10 2020',
    title: 'Identity to Empower Global Progress',
    slug: 'truststamp-whoswhomt',
    url:
      'https://whoswho.mt/en/trust-stamp-asserts-commitment-to-establishing-identity-to-empower-global-progress'
  },
  {
    date: 'December 10 2020',
    title: 'ID4Africa Innovation World Tour',
    slug: 'truststamp-ID4Africa',
    url: 'https://www.youtube.com/embed/943VN4bDanY',
    type: PRESS_TYPES.YOUTUBE
  },
  {
    date: 'December 13 2020',
    title: 'Growth in Ireland',
    slug: 'truststamp-ireland',
    url:
      'https://www.businesspost.ie/business/us-technology-firm-trust-stamp-set-to-open-irish-office-f0159b07'
  },
  {
    date: 'December 18 2020',
    title: 'LORCA 2021 Cyber Predictions',
    slug: 'truststamp-lorca',
    url: 'https://www.lorca.co.uk/2021-cyber-predictions/'
  },
  {
    date: 'December 31 2020',
    title: "Biometric Update's Year in a Glance",
    slug: 'truststamp-biometricUpdate',
    url:
      'https://www.biometricupdate.com/202012/disruption-boosts-new-markets-for-biometrics-as-digital-id-undergoes-shock-evolution'
  },
  {
    date: 'January 8 2021',
    title: 'Director Appointments',
    slug: 'truststamp-directors',
    url:
      'https://www.londonstockexchange.com/news-article/IRSH/trust-stamp-announces-director-appointments/14819039'
  },
  {
    date: 'January 8 2021',
    title: 'Trust Stamp and DirectID partner',
    slug: 'trustsamp-directID',
    url:
      'https://www.intelligentcio.com/north-america/2021/01/08/trust-stamp-and-directid-empower-users-through-secure-open-banking-tools/'
  },
  {
    date: 'February 18 2021',
    title: 'The Challenge Anything Culture with Andrew Gowasack',
    slug: 'zero-5000-truststamp',
    url:
      'https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkLnBvZGJlYW4uY29tL3plcm90bzUwMDAvZmVlZC54bWw/episode/emVyb3RvNTAwMC5wb2RiZWFuLmNvbS8zZDI3MmYzZC0yOWI4LTM2NzItODFhNi03ZDcyNWU2MWU0NGE?hl=en-MT&ved=2ahUKEwiP_4vjy5PvAhUXwQIHHXAjAvIQieUEegQIBhAO&ep=6'
  },
  {
    date: 'March 15 2021',
    title: 'Trust Stamp Joins OTCQX',
    slug: 'OTCQX-truststamp',
    url:
      'https://www.prnewswire.com/news-releases/otc-markets-group-welcomes-t-stamp-inc-to-otcqx-301246891.html'
  },
  {
    date: 'March 15 2021',
    title: '$5M Funding Round Launched',
    slug: 'funding-round-truststamp',
    url:
      'https://www.bloomberg.com/press-releases/2021-03-15/t-stamp-inc-announces-regulation-d-reg-d-offering'
  },
  {
    date: 'March 24 2021',
    title: 'PixelPin Acquisition',
    slug: 'truststamp-pixelpin',
    url:
      'https://hypepotamus.com/feature/atlantas-trust-stamp-acquires-image-based-authentication-startup-out-of-london/'
  },
  {
    date: 'March 25 2021',
    title: 'Medical QR Code Finalist',
    slug: 'hic-truststamp',
    url:
      'https://www.biometricupdate.com/202103/trust-stamp-enters-hague-innovators-challenge-with-biometric-medical-qr-code-technology'
  },
  {
    date: 'March 26 2021',
    title: 'Patent | Identity & Trust Verification Tool',
    slug: 'trustscore-truststamp',
    url:
      'https://www.marketwatch.com/press-release/t-stamp-inc---identity-trust-verification-tool-patent-granted-2021-03-26?siteid=bigcharts&dist=bigcharts&tesla=y'
  },
  // {
  //   date: 'April 7 2021',
  //   title: 'Data Breaches: Uncovering the Known and the Unknown',
  //   slug: 'data-breaches-truststamp',
  //   url: ROUTES.DATA_BREACHES,
  // },
  {
    date: 'April 7 2021',
    title: 'Strategic Investment from Second Century Ventures',
    slug: 'investment-truststamp',
    url:
      'https://finance.yahoo.com/news/t-stamp-inc-announces-strategic-102000558.html?guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAHGYz5RQbY8K6wdJJh5yLGlUs0FXdUE3yOYi1WGnKAUC9iES0pDgzjxY2bLNLLV3Ep2jwuf6rLwpPenY3JOsG0uinJ6ufytLYlhkClZzN2RxcZNhkNW7FY3JzRnBbPpxXLhRNQacKdGJmcYBSrFQsO_XRdy5-XhLvBoKt19x_ygK&guccounter=2'
  },
  {
    date: 'April 8 2021',
    title: 'NASDAQ filing, Directorate & Corporate Governance Changes',
    slug: 'nasdaq-truststamp',
    url:
      'https://finance.yahoo.com/finance/news/t-stamp-inc-announces-nasdaq-084500917.html'
  },
  {
    date: 'April 21 2021',
    title: 'Gareth Genner Joins UK FinTech Week',
    slug: 'ukftw-truststamp',
    url:
      'https://financemalta.org/news/financemalta-promotes-malta-during-the-uk-fintech-week/'
  },
  {
    date: 'June 2 2021',
    title: 'Trust Stamp Competes for the 2021 Hague Innovator of the Year',
    slug: 'investment-truststamp',
    url: 'https://player.vimeo.com/video/557954078',
    type: PRESS_TYPES.YOUTUBE
  },
  {
    date: 'June 2 2021',
    title: 'Malta CEOs Interview with Gareth Genner',
    slug: 'malta-ceo',
    url:
      'https://maltaceos.mt/no-one-has-a-monopoly-on-ideas-trust-stamp-ceo-gareth-genner/'
  },
  {
    date: 'June 3 2021',
    title:
      'John Bridge Joins the US Department of Commerce Trade Finance Advisory Council',
    slug: 'jb-tfac',
    url:
      'https://www.otcmarkets.com/stock/IDAI/news/Trust-Stamp-Executive-Appointed-to-US-Department-of-Commerce-Trade-Finance-Advisory-Council?id=305770&_ke=eyJrbF9jb21wYW55X2lkIjogIk5ianFLeCIsICJrbF9lbWFpbCI6ICJzdGFuanJzZWVkaW52ZXN0QG55bS5odXNoLmNvbSJ9'
  },
  {
    date: 'June 4 2021',
    title: 'Trust Stamp Raises $4M in Private Offering',
    slug: 'ts-funding-june21',
    url:
      'https://finance.yahoo.com/finance/news/t-stamp-inc-announces-successful-060000212.html'
  },
  {
    date: 'August 11 2021',
    title: '"Can You Hack the System?" with Science in the City, Malta ',
    slug: 'articles-cover-hack-the-system',
    url:
      'https://www.facebook.com/events/valletta-design-cluster/can-you-hack-the-system/1433487090362749/'
  },
  {
    date: 'August 19 2021',
    title:
      'Trust Stamp Announces Stock Split in Pursuit of Uplisting to Nasdaq Capital Market',
    slug: 'articles-covers-stock-split-split',
    url:
      'https://www.yahoo.com/now/trust-stamp-announces-stock-split-060000362.html'
  },
  {
    date: 'August 25 2021',
    title: 'Trust Stamp Seeks to Raise US $5M From Public',
    slug: 'articles-cover-5M-raise',
    url: 'https://www.yahoo.com/now/trust-stamp-seeks-raise-us-173000954.html'
  },
  {
    date: 'August 25 2021',
    title: 'Founding Member of Cryptocurrency Compliance Cooperative',
    slug: 'articles-cover-ccc',
    url:
      'https://www.prnewswire.com/news-releases/top-bitcoin-atm-operators-and-blockchain-indu[…]ew-cryptocurrency-compliance-cooperative-ccc-301362382.html'
  },
  {
    date: 'September 2 2021',
    title:
      'Trust Stamp Welcomes Former VP, Humanitarian & Development (Digital Identity) at Mastercard',
    slug: 'articles-cover-raman-cio',
    url:
      'https://finance.yahoo.com/news/t-stamp-inc-announces-former-060000989.html'
  },
  {
    date: 'September 9 2021',
    title:
      'European Association for Biometrics Virtual Talk with Dr. Norman Poh',
    slug: 'articles-cover-eab-norman',
    url: 'https://eab.org/events/program/privacy.html'
  },
  {
    date: 'September 17 2021',
    title:
      "Trust Stamp's Biometric Privacy Technology to Serve 30M People by Mastercard and Paycode",
    slug: 'articles-cover-mastercard',
    url:
      'https://www.biometricupdate.com/202109/trust-stamp-tokenized-biometrics-for-mastercard-financial-inclusion-initiative'
  },
  {
    date: 'September 27 2021',
    title: 'Trust Stamp Awarded $3.9M DHS Contract',
    slug: 'awarded-3M',
    url:
      'https://finance.yahoo.com/news/trust-stamp-awarded-3-9m-060000121.html'
  },
  {
    date: 'September 28 2021',
    title: 'Strong Half-Year 2021 Performance',
    slug: 'articles-cover-half-year-report',
    url:
      'https://www.otcmarkets.com/stock/IDAI/news/T-Stamp-Inc-Announces-Half-Year-Results?id=322690'
  },
  {
    date: 'November 10 2021',
    title: 'Uncrackable Biometric Security',
    slug: 'VentureBeat_article_cover_Jan_2022',
    url:
      'https://venturebeat.com/2021/11/10/trust-stamp-lets-investors-get-in-on-uncrackable-unstealable-biometric-security/ '
  },
  {
    date: 'November 11 2021',
    title: 'Board Expansion for NASDAQ Uplisting',
    slug: 'Nasdaq_article_cover_Jan_2022',
    url:
      'https://www.proactiveinvestors.com/companies/news/965993/trust-stamp-announces-board-expansion-as-it-progresses-to-nasdaq-listing-965993.html '
  },
  {
    date: 'November 11 2021',
    title: 'The Potential Answer to Identity Theft',
    slug: 'Entrepreneur_article_cover_Jan_2022',
    url: 'https://www.entrepreneur.com/article/396274'
  },
  {
    date: 'November 15 2021',
    title: 'New Crypto-Asset Subsidiary',
    slug: 'Crypto_asset_subsidiary_article_cover_Jan_2022',
    url:
      'https://finance.yahoo.com/news/trust-stamp-establishes-metapresence-tm-090000101.html'
  },
  {
    date: 'December 2 2021',
    title: 'Gareth Genner on Benzinga All Access',
    slug: 'Benzinga_article_cover_Jan_2022',
    url:
      'https://finance.yahoo.com/news/trust-stamp-ceo-participate-benzinga-132000824.html'
  },
  {
    date: 'December 15 2021',
    title: 'Strategic Growth in Africa',
    slug: 'Africa_growth_article_cover_January_2022',
    url:
      'https://finance.yahoo.com/news/trust-stamp-announces-strategic-growth-151500416.html'
  },
  {
    date: 'January 5 2022',
    title: 'Innovative IP Portfolio Advancement',
    slug: 'IP-articles-covers',
    url:
      'https://www.nasdaq.com/press-release/trust-stamp-advances-innovative-ip-portfolio-2022-01-05'
  },
  {
    date: 'January 7 2022',
    title: 'US $8.84M Raised',
    slug: '8.84M-raised-articles-covers',
    url:
      'https://www.nasdaq.com/press-release/trust-stamp-reports-us-%248.84m-raised-2022-01-07'
  },
  {
    date: 'January 10 2022',
    title: '2021 Year in Review | Transformational Growth',
    slug: '2021-review-articles-covers',
    url:
      'https://www.nasdaq.com/press-release/2021-year-in-review-trust-stamp-highlights-transformational-growth-2022-01-10'
  },
  {
    date: 'January 27 2022',
    title: 'Trust Stamp Receives Approval for Nasdaq Listing',
    slug: 'Nasdaq-approval-articles-covers',
    url:
      'https://www.nasdaq.com/press-release/trust-stamp-receives-approval-for-nasdaq-listing-2022-01-27'
  },
  {
    date: 'January 31 2022',
    title: 'Trust Stamp Lists on Nasdaq Capital Market: IDAI',
    slug: 'Nasdaq-listing-articles-covers',
    url:
      'https://www.nasdaq.com/press-release/trust-stamp-begins-trading-on-nasdaq-capital-market-2022-01-31?utm_source=email&utm_medium=email&utm_campaign=%5BALL%5D%20Listing%20ceremony&_kx='
  },
  {
    date: 'February 3 2022',
    title: 'Trust Stamp Rings Nasdaq Opening Bell',
    slug: 'Nasdaq-bell-articles-covers',
    url: 'https://player.vimeo.com/video/672806276?h=26706b4a4e',
    type: PRESS_TYPES.YOUTUBE
  },
  {
    date: 'February 7 2022',
    title: 'Global Leadership Appointment',
    slug: 'Global_leadership_article_cover',
    url:
      'https://whoswho.mt/en/trust-stamp-promotes-josh-allen-to-ceo-for-malta-and-rwanda-subsidiaries'
  },
  {
    date: 'February 22 2022',
    title: 'R&D Advancement | IP and Personnel Growth',
    slug: 'IP_and_personnel_growth_article_cover',
    url:
      'https://www.ipohub.io/companies/trust-stamp-inc/news/trust-stamp-advances-innovative-rd-with-ip-and-personnel-growth-220220221330'
  },
  {
    date: 'February 24 2022',
    title: 'Privacy-First Identity Orchestration Platform Launch',
    slug: 'Orchestration_layer_article_cover',
    url:
      ' https://www.biometricupdate.com/202202/trust-stamp-bundles-biometrics-into-new-low-code-identity-orchestration-platform'
  },
  {
    date: 'March 11 2022',
    title: 'Transforming Inclusive Finance with VIVA Finance ',
    slug: 'VIVA_article_cover',
    url:
      'https://m.bankingexchange.com/recent-articles/item/9189-trust-stamp-and-viva-finance-team-up-to-drive-financial-inclusion'
  },
  {
    date: 'April 11 2022',
    title: 'Trusted Rwanda Hackathon Winners',
    slug: 'Hackathon_winners_article_cover',
    url:
      'https://martechseries.com/sales-marketing/id-mgmt/trust-stamp-announces-winners-of-trusted-rwanda-hackathon/'
  },
  {
    date: 'April 12 2022',
    title: 'Record Revenue for Q4 and FY 2021',
    slug: 'FY_2021_results_article_cover',
    url:
      'https://www.globenewswire.com/news-release/2022/04/12/2421396/0/en/Trust-Stamp-announce[…]2021-with-Trust-Stamp-s-momentum-continuing-into-2022.html'
  },
  {
    date: 'April 28 2022',
    title: "First-of-it's-kind Biometric Multi-Factor Authentication",
    slug: 'Biometric_MFA_article_cover',
    url:
      'https://marketdepth.com/trust-stamp-launches-innovative-biometric-multi-factor-authentication-solution/'
  },
  {
    date: 'May 2 2022',
    title: 'Malta Acceleration Program Founding Members',
    slug: 'Malta_Acceleration_Program_article_cover',
    url:
      'https://investors.truststamp.ai/trust-stamp-signs-mou-with-malta-enterprise-and-plug-and-play/'
  },
  {
    date: 'May 5 2022',
    title: 'The Quantum Foundation | Non-Profit for Inclusion',
    slug: 'Quantum_Foundation_article_cover',
    url:
      'https://findbiometrics.com/trust-stamp-establishes-new-non-profit-promote-inclusion-malta-050501/'
  },
  {
    date: 'May 10 2022',
    title: 'MoU with the African Institute for Mathematical Sciences',
    slug: 'AIMS_article_cover',
    url:
      'https://www.biometricupdate.com/202205/trust-stamp-joins-forces-with-rwandan-institute-to-increase-equality-in-biometrics-performance'
  },
  {
    date: 'May 13 2022',
    title: '430% Increase in Revenue YoY',
    slug: 'Q1_2022_results_article_cover',
    url:
      'https://www.proactiveinvestors.com/companies/news/982218/trust-stamp-sees-1q-revenue-soar-[…]vernment-and-financial-services-client-base-982218.html'
  },
  {
    date: 'May 17 2022',
    title: 'Transforming Rental Accessibility and Risk Management with letus',
    slug: 'Letus_article_cover',
    url:
      'https://finance.yahoo.com/news/trust-stamp-partners-letus-deliver-132100681.html'
  },
  {
    date: 'May 31 2022',
    title:
      'Trust Stamp Among The Finalists For The TAG’s ADVANCE Fintech Awards',
    slug: 'Article_Cover_TAG',
    url:
      'https://www.tagonline.org/tagwire/technology-association-of-georgia-announces-advance-award-finalists-for-fintech-south/'
  },
  {
    date: 'Jul 13 2022',
    title: 'Trust Stamp Receives Notice of Allowance for U.S Patent.',
    slug: 'Patent_Notice',
    url:
      'https://www.proactiveinvestors.com/companies/news/987371/trust-stamp-granted-notice-of-allowance-for-us-patent-on-its-biometric-authentication-technology-987371.html'
  },
  {
    date: 'September 28, 2022',
    title:
      'Trust Stamp Unveils Privtech™ - a New Government-Focused Privacy-First Identity Technology',
    slug: 'PrivTech',
    url:
      'https://www.globenewswire.com/news-release/2022/09/28/2524311/0/en/Trust-Stamp-Unveils-Privtech-a-New-Government-Focused-Privacy-First-Identity-Technology.html'
  },
  {
    date: 'October 12, 2022',
    title:
      'Trust Stamp’s path to UK Govt biometric software contracts eases with G-Cloud 13 listing',
    slug: 'G_Cloud_13_listing',
    url:
      'https://www.biometricupdate.com/202210/trust-stamps-path-to-uk-govt-biometric-software-contracts-eases-with-g-cloud-13-listing'
  },
  {
    date: 'November 10, 2022',
    title: '309% YoY Revenue Increase',
    slug: 'Q3_2022_results_article_cover',
    url:
      'https://www.globenewswire.com/news-release/2022/11/10/2553851/0/en/Trust-Stamp-Achieves[…]e-of-309-and-Gross-Profit-Increase-of-915-for-Q3-2022.html'
  },
  {
    date: 'January 13, 2023',
    title:
      'Trust Stamp Partners with Simunix to Provide Biometric Identification Technology to the UK Market',
    slug: 'IDAI_simunix_press_release',
    url:
      'https://www.globenewswire.com/news-release/2023/01/13/2588584/0/en/Trust-Stamp-Partners-with-Simunix-to-Provide-Biometric-Identification-Technology-to-the-UK-Market.html'
  },
  {
    date: 'January 23, 2023',
    title:
      'Trust Stamp Receives Notice of Allowance from the US Patent and Trademark Office',
    slug: 'US-Patent-and-Trademark',
    url:
      'https://www.globenewswire.com/news-release/2023/01/23/2593210/0/en/Trust-Stamp-Receives-Notice-of-Allowance-from-the-US-Patent-and-Trademark-Office.html'
  },
  {
    date: 'January 24, 2023',
    title:
      "Trust Stamp Announces Rapid Customer Adoption of Privacy-First Identity Orchestration Platform; Outpacing Management's Expectations",
    slug: 'Rapid-Customer-Adoption',
    url:
      'https://www.globenewswire.com/news-release/2023/01/24/2594217/0/en/Trust-Stamp-Announces-Rapid-Customer-Adoption-of-Privacy-First-Identity-Orchestration-Platform-Outpacing-Management-s-Expectations.html'
  },
  {
    date: 'January 30, 2023',
    title:
      'Trust Stamp Announces the Latest Addition to its Low-code Orchestration Platform, The Driver License Data Verification (DLDV) System',
    slug: 'DLDV',
    url:
      'https://www.globenewswire.com/en/news-release/2023/01/30/2597554/0/en/Trust-Stamp-Announces-the-Latest-Addition-to-its-Low-code-Orchestration-Platform-The-Driver-License-Data-Verification-DLDV-System.html'
  },
  {
    date: 'February 21, 2023',
    title:
      'Trust Stamp bolster patent protection for AI authentication technology.',
    slug: 'PR',
    url:
      'https://www.proactiveinvestors.com/companies/news/1006812/trust-stamp-bolster-patent-protection-for-ai-authentication-technology-1006812.html'
  },
  {
    date: 'March 30, 2023',
    title: '2022 Financial Results and Business Update',
    slug: 'earnings-release-full-year-2022',
    url:
      'https://finance.yahoo.com/news/trust-stamp-reports-2022-financial-210500737.html'
  },
  {
    date: 'May 15, 2023',
    title: 'Q1 2023 Financial Results',
    slug: 'q1-2023-financial-results',
    url:
      'https://www.globenewswire.com/news-release/2023/05/15/2668776/0/en/Trust-Stamp-Reports-Q1-2023-Financial-Results-and-Provides-Business-Update.html'
  },
  {
    date: 'May 19, 2023',
    title: 'Trust Stamp Named 2023 GLOBE Award Recipient',
    slug: 'trust-stamp-named-2023-globe-award-recipient',
    url:
      'https://investors.truststamp.ai/trust-stamp-named-2023-globe-award-recipient/'
  },
  {
    date: 'May 23, 2023',
    title:
      'Trust Stamp Announces Notice of Allowance for New U.S. Patent Related to its Artificial Intelligence (AI) Based Biometric Authentication Technology',
    slug:
      'trust-stamp-announces-notice-of-allowance-for-new-u-s-patent-related-to-its-artificial-intelligence-ai-based-biometric-authentication-technology',
    url:
      'https://investors.truststamp.ai/trust-stamp-announces-notice-of-allowance-for-new-u-s-patent-related-to-its-artificial-intelligence-ai-based-biometric-authentication-technology/'
  },
  {
    date: 'May 31, 2023',
    title:
      'Trust Stamp Announces Notice of Allowance for New U.S. Patent Related to Identity Authentication Via the Control of Third-Party Accounts',
    slug:
      'trust-stamp-announces-notice-of-allowance-for-new-u-s-patent-related-to-identity-authentication-via-the-control-of-third-party-accounts',
    url:
      'https://investors.truststamp.ai/trust-stamp-announces-notice-of-allowance-for-new-u-s-patent-related-to-identity-authentication-via-the-control-of-third-party-accounts/'
  },
  {
    date: 'June 01, 2023',
    title:
      'Trust Stamp Announces Pricing of $2.9 Million Registered Direct Offering and Concurrent Private Placement Priced At-The-Market',
    slug:
      'trust-stamp-announces-pricing-of-2-9-million-registered-direct-offering-and-concurrent-private-placement-priced-at-the-market',
    url:
      'https://investors.truststamp.ai/trust-stamp-announces-pricing-of-2-9-million-registered-direct-offering-and-concurrent-private-placement-priced-at-the-market/'
  },
  {
    date: 'August 01, 2023',
    title: 'Announcing 31% Increase in Financial Services Customer Base',
    slug: 'truststamp-pr-update',
    url:
      'https://www.globenewswire.com/news-release/2023/08/01/2715917/0/en/Trust-Stamp-Announces-a-Thirty-One-Percent-Increase-in-its-Financial-Services-Customer-Base-in-the-last-six-months.html'
  },
  {
    date: 'August 02, 2023',
    title:
      'Trust Stamp Receives Privacy-Secured Biometric Identification and Verification Patent',
    slug:
      'trust-stamp-receives-privacy-secured-biometric-identification-and-verification-patent',
    url:
      'https://investors.truststamp.ai/trust-stamp-receives-privacy-secured-biometric-identification-and-verification-patent/'
  },
  {
    date: 'August 11, 2023',
    title:
      'Trust Stamp releases a White Paper detailing its newly launched AI-powered suite of facial attributes and quality assessment tools (FAIQA) using an unsupervised machine learning model',
    slug: 'facial-attributes-and-quality-assessment-faiqa-whitepaper',
    url:
      'https://investors.truststamp.ai/trust-stamp-releases-a-white-paper-detailing-its-newly-launched-ai-powered-suite-of-facial-attributes-and-quality-assessment-tools-faiqa-using-an-unsupervised-machine-learning-model/'
  },
  {
    date: 'August 11, 2023',
    title:
      'Trust Stamp receives notice of a new patent issuance for AI-powered tokenization technology from the US Patent and Trademark Office',
    slug:
      'trust-stamp-receives-notice-of-a-new-patent-issuance-for-ai-powered-tokenization-technology-from-the-us-patent-and-trademark-office',
    url:
      'https://investors.truststamp.ai/trust-stamp-receives-notice-of-a-new-patent-issuance-for-ai-powered-tokenization-technology-from-the-us-patent-and-trademark-office/'
  },
  {
    date: 'August 14, 2023',
    title:
      'Trust Stamp Reports Financial Results for the quarter and period ended June 30, 2023 and Provides Business Update',
    slug: 'Trust-Stamp-Reports-Financial-Results-for-the-quarter',
    url:
      'https://investors.truststamp.ai/trust-stamp-reports-financial-results-for-the-quarter-and-period-ended-june-30-2023-and-provides-business-update/'
  },
  {
    date: 'August 17, 2023',
    title:
      'Trust Stamp receives notice of a new patent issuance for identity authentication technology using social media and other user account access from the US Patent and Trademark Office',
    slug:
      'trust-stamp-receives-notice-of-a-new-patent-issuance-for-identity-authentication-technology-using-social-media-and-other-user-account-access-from-the-us-patent-and-trademark-office',
    url:
      'https://investors.truststamp.ai/trust-stamp-receives-notice-of-a-new-patent-issuance-for-identity-authentication-technology-using-social-media-and-other-user-account-access-from-the-us-patent-and-trademark-office'
  },
  {
    date: 'August 18, 2023',
    title:
      'Trust Stamp has been issued a provisional patent by the US Patent and Trademark Office for Semi-Supervised and Unsupervised Biometric Recognition using its AI-Powered technology',
    slug:
      'trust-stamp-has-been-issued-a-provisional-patent-by-the-us-patent-and-trademark-office-for-semi-supervised-and-unsupervised-biometric-recognition-using-its-ai-powered-technology',
    url:
      'https://investors.truststamp.ai/trust-stamp-has-been-issued-a-provisional-patent-by-the-us-patent-and-trademark-office-for-semi-supervised-and-unsupervised-biometric-recognition-using-its-ai-powered-technology/'
  },
  {
    date: 'August 24, 2023',
    title:
      'Trust Stamp announces proposed action to address suspected naked short-selling',
    slug:
      'trust-stamp-announces-proposed-action-to-address-suspected-naked-short-selling',
    url:
      'https://investors.truststamp.ai/trust-stamp-announces-proposed-action-to-address-suspected-naked-short-selling/'
  },
  {
    date: 'August 28, 2023',
    title:
      'Trust Stamp adds six veteran sales team members to drive adoption of its Orchestration Layer',
    slug:
      'rust-stamp-adds-six-veteran-sales-team-members-to-drive-adoption-of-its-orchestration-layer-through-direct-sales-with-another-two-onboarding-before-the-end-of-q3',
    url:
      'https://investors.truststamp.ai/trust-stamp-adds-six-veteran-sales-team-members-to-drive-adoption-of-its-orchestration-layer-through-direct-sales-with-another-two-onboarding-before-the-end-of-q3/'
  },
  {
    date: 'August 30, 2023',
    title: "Trust Stamp Embraces Denmark's D-Seal Initiative",
    slug: 'D-Seal-Initiative',
    url:
      'https://blogs.truststamp.ai/trust-stamp-embraces-denmarks-d-seal-initiative'
  },
  {
    date: 'September 06, 2023',
    title:
      'Trust Stamp receives notice of the issuance of its 18th patent from the US Patent',
    slug: 'patent-issuance-notice',
    url:
      'https://investors.truststamp.ai/trust-stamp-receives-notice-of-the-issuance-of-its-18th-patent-from-the-us-patent-and-trademark-office-together-with-notification-of-an-additional-patent-allowance-further-expanding-upon-the-utility-o/'
  },
  {
    date: 'September 18, 2023',
    title:
      'Trust Stamp ® announces groundbreaking technology for the global Alternative To Detention (“ATD”) Market',
    slug:
      'trust-stamp-announces-groundbreaking-technology-for-the-global-alternative-to-detention-atd-market',
    url:
      'https://investors.truststamp.ai/trust-stamp-announces-groundbreaking-technology-for-the-global-alternative-to-detention-atd-market/'
  },
  {
    date: 'September 22, 2023',
    title:
      'Trust Stamp to Participate in the Virtual Tech Conference Series: Emerging Growth in A.I., Presented by Maxim Group LLC on Tuesday, September 26th & Wednesday, September 27th at 8:00 A.M. ET.',
    slug:
      'trust-stamp-to-participate-in-the-emerging-growth-in-ai-conference-series-by-maxim-group-llc',
    url:
      'https://investors.truststamp.ai/trust-stamp-to-participate-in-the-virtual-tech-conference-series-emerging-growth-in-a-i-presented-by-maxim-group-llc-on-tuesday-september-26th-wednesday-september-27th-at-800-a-m-et/'
  },
  {
    date: 'November 1, 2023',
    title:
      'Trust Stamp Announces Financial Results for the Third Quarter Ended September 30, 2023',
    slug:
      'trust-stamp-announces-the-fortieth-financial-institution-to-start-integration-with-the-companys-orchestration-layer',
    url:
      'https://investors.truststamp.ai/trust-stamp-announces-that-the-company-will-report-financial-results-for-the-third-quarter-ended-september-30-2023-on-tuesday-november-7-2023-after-market-close/'
  },
  {
    date: 'November 1, 2023',
    title:
      'Trust Stamp Announces Financial Results for the Third Quarter Ended September 30, 2023',
    slug: 'q3-financial-results',
    url:
      'https://investors.truststamp.ai/trust-stamp-announces-that-the-company-will-report-financial-results-for-the-third-quarter-ended-september-30-2023-on-tuesday-november-7-2023-after-market-close/'
  },
  {
    date: 'November 07, 2023',
    title:
      'Trust Stamp Provides Business Report and Financial Results for the Quarter Ended September 30, 2023, Including Positive EBITDA for Q3',
    slug: 'trust-stamp-reports-q3-financial-results',
    url:
      'https://investors.truststamp.ai/trust-stamp-provides-a-business-report-and-financial-results-for-the-quarter-and-period-ended-september-30-2023-including-positive-ebitda-for-q3/'
  },
  {
    date: 'November 08, 2023',
    title:
      "Fortune 500 Life Insurance Company Adopts Trust Stamp's AI-Powered Low-Code Orchestration Platform",
    slug:
      'fortune-500-life-insurance-company-adopts-trust-stamps-ai-powered-low-code-orchestration-platform',
    url:
      'https://investors.truststamp.ai/fortune-500-life-insurance-company-adopts-trust-stamps-ai-powered-low-code-orchestration-platform/'
  },
  {
    date: 'December 01, 2023',
    title:
      "Trust Stamp Announces the Fortieth Financial Institution to Start Integration with the Company's Orchestration Layer",
    slug:
      'trust-stamp-announces-the-fortieth-financial-institution-to-start-integration-with-the-companys-orchestration-layer',
    url:
      'https://investors.truststamp.ai/trust-stamp-announces-the-fortieth-financial-institution-to-start-integration-with-the-companys-orchestration-layer/'
  },
  {
    date: 'December 08, 2023',
    title: 'Trust Stamp Selected for ICBA ThinkTECH Accelerator',
    slug: 'Trust_Stamp_Selected_for_ICBA',
    url:
      'https://investors.truststamp.ai/trust-stamp-selected-for-icba-thinktech-accelerator/'
  },
  {
    date: 'December 18, 2023',
    title: 'Trust Stamp Partners with Partisia and launches Global Secure Data',
    slug: 'truststamp-partners-with-partisia',
    url:
      'https://investors.truststamp.ai/trust-stamp-partners-with-partisia-and-launches-global-secure-data/'
  },
  {
    date: 'December 28, 2023',
    title:
      'Trust Stamp has partnered with Scurid to deliver highly secure, AI-powered in-field authentication for seamless human-machine interaction',
    slug: 'trust-stamp-partners-with-scurid',
    url:
      'https://investors.truststamp.ai/trust-stamp-has-partnered-with-scurid-to-deliver-highly-secure-ai-powered-in-field-authentication-for-seamless-human-machine-interaction/'
  },
  {
    date: 'January 04, 2024',
    title:
      'Trust Stamp announces a patent filing for a groundbreaking AI-powered facial recognition technology',
    slug: 'trust-stamp-announces-a-patent-filing',
    url:
      'https://investors.truststamp.ai/trust-stamp-announces-a-patent-filing-for-a-groundbreaking-ai-powered-facial-recognition-technology/'
  },
  {
    date: 'January 5, 2024',
    title:
      'Trust Stamp publishes a White Paper on the potential impact of Quantum Computing on legacy biometric systems',
    slug: 'whitepaper-on-the-impact-of-quantum-computing-on-biometric-systems',
    url:
      'https://investors.truststamp.ai/pr/trust-stamp-publishes-a-white-paper-on-the-potential-impact-of-quantum-computing-on-legacy-biometric-systems'
  },
  {
    date: 'January 8, 2024',
    title:
      'Trust Stamp ® Announces that Forty-Five customers started or completed integration with the Company’s Orchestration Layer by the end of 2023',
    slug: '45-financial-institutions-in-the-ol',
    url:
      'https://investors.truststamp.ai/pr/trust-stamp-announces-that-fortyfive-customers-started-or-completed-integration-with-the-companys-orchestration-layer-by-the-end-of-2023'
  },
  {
    date: 'January 19, 2024',
    title:
      'Trust Stamp announces the continued growth of its patent portfolio for AI-powered biometric technology',
    slug:
      'trust-stamp-expands-patent-portfolio-for-AI-powered-biometric- technology',
    url:
      'https://investors.truststamp.ai/pr/trust-stamp-announces-the-continued-growth-of-its-patent-portfolio-for-aipowered-biometric-technology'
  },
  {
    date: 'February 16, 2024',
    title: 'Urgent Warning: Trust Stamp Alerts iOS Users of New Trojan',
    slug: 'urgent-warning-trust-stamp-alerts-ios-users-of-new-trojan',
    url: 'https://investors.truststamp.ai/press-release?i=131192'
  },
  {
    date: 'February 16, 2024',
    title:
      'Federal Reserve Lists Trust Stamp for Synthetic Identity Fraud Combat',
    slug: 'federal-reserve-lists-trust-stamp-for-synthetic-fraud-combat',
    url: 'https://investors.truststamp.ai/press-release?i=131195 '
  },
  {
    date: 'February 26, 2024',
    title: 'Trust Stamp Expands Patent Portfolio with AI Identity Solutions',
    slug: 'trust-stamp-expands-patent-portfolio-with-ai-identity-solutions',
    url: 'https://investors.truststamp.ai/press-release?i=131385'
  },
  {
    date: 'January 29, 2024',
    title:
      'Trust Stamp Announces Alliance with ManTech to Provide AI-Powered Identity Management Technology',
    slug: 'trust-stamp-teams-up-with-mantech',
    url: 'https://investors.truststamp.ai/press-release?i=130438'
  },
  {
    date: 'March 07, 2024',
    title:
      'Trust Stamp launches a new banking-focused product using its AI-Powered Identity Authentication',
    slug:
      'trust-stamp-announces-the-launch-of-a-new-banking-focused-product-utilizing-its-ai-powered-identity-authentication',
    url: 'https://investors.truststamp.ai/press-release?i=131898'
  },
  {
    date: 'March 07, 2024',
    title: 'Trust Stamp Expands Patent Portfolio with AI Identity Solutions',
    slug: 'trust-stamp-expands-patent-portfolio-with-ai-identity-solutions-1',
    url: 'https://investors.truststamp.ai/press-release?i=131904'
  },
  {
    date: 'March 12, 2024',
    title:
      'Trust Stamp releases a White Paper on its AI-Powered Stable-IT2 Technology',
    slug:
      'trust-stamp-releases-a-white-paper-on-its-ai-powered-stable-it2-technology',
    url: 'https://investors.truststamp.ai/press-release?i=131994'
  },
  {
    date: 'March 13, 2024',
    title:
      'Trust Stamp announces the release of its new AI-Powered Age Estimation technology',
    slug:
      'trust-stamp-announces-the-release-of-its-new-ai-powered-age-estimation-technology',
    url: 'https://investors.truststamp.ai/press-release?i=132026'
  },
  {
    date: 'March 22, 2024',
    title:
      ' U.S. Court of Appeals for the Fifth Circuit agrees that digital facial images may be used for age verification',
    slug:
      'us-court-of-appeals-for-the-fifth-circuit-agrees-that-digital-facial-images-may-be-used-for-age-verification',
    url: 'https://investors.truststamp.ai/press-release?i=132292'
  },
  {
    date: 'March 27, 2024',
    title:
      'Trust Stamp Provides a Business Report and Financial Results for the Year Ended December 31, 2023',
    slug:
      'trust-stamp-provides-a-business-report-and-financial-results-for-the-year-ended-december-31-2023',
    url: 'https://investors.truststamp.ai/press-release?i=132658'
  },
  {
    date: 'March 28, 2024',
    title:
      'Trust Stamp announces the 50th Financial Institution to onboard to its low-code Orchestration Layer platform via its Channel Partnership',
    slug: '50th-financial-institution-in-the-ol',
    url:
      'https://investors.truststamp.ai/pr/trust-stamp-announces-the-50th-financial-institution-to-onboard-to-its-lowcode-orchestration-layer-platform-via-its-channel-partnership'
  },
  {
    date: 'April 03, 2024',
    title:
      'Announcing the 50th Financial Institution to start integration with the Orchestration Layer',
    slug:
      'trust-stamp-announces-the-50th-financial-institution-to-start-integration-with-the-companys-orchestration-layer',
    url: 'https://investors.truststamp.ai/press-release?i=132665'
  },
  {
    date: 'April 08, 2024',
    title:
      'Trust Stamp® has been accepted as a participant of the United Nations Global Compact',
    slug:
      'trust-stamp-has-been-accepted-as-a-participant-of-the-united-nations-global-compact',
    url: 'https://investors.truststamp.ai/press-release?i=133004'
  },
  {
    date: 'May 2, 2024',
    title:
      'Trust Stamp announces the allowance of a new patent taking its portfolio of AI-powered patents into data protection applications',
    slug:
      'trust-stamp-expands-patent-portfolio-with-data-protection-applications',
    url:
      'https://investors.truststamp.ai/pr/trust-stamp-announces-the-allowance-of-a-new-patent-taking-its-portfolio-of-aipowered-patents-into-data-protection-applications'
  },
  {
    date: 'May 14, 2024',
    title:
      'Trust Stamp Provides a Business Report and Financial Results for the Three Months Ended March 31, 2024',
    slug: 'q1-2024-financial-results',
    url: 'https://investors.truststamp.ai/press-release?i=134165'
  },
  {
    date: 'May 23, 2024',
    title:
      'Trust Stamp announces that it is filing a patent for an AI-based Age Estimation Calibration Algorithm',
    slug: 'patent-filing',
    url: 'https://investors.truststamp.ai/press-release?i=134639'
  },
  {
    date: 'May 23, 2024',
    title:
      'Trust Stamp announces the allowance of a new patent taking its portfolio of 20 Allowed and Issued AI-powered patents into data protection applications',
    slug: 'allowance-new-patent',
    url: 'https://investors.truststamp.ai/press-release?i=134628'
  },
  {
    date: 'June 03, 2024',
    title:
      'Trust Stamp CEO to Present at "Charting the Course: Navigating the Intersection of TMT and Business in the AI Era" Conference, Presented by Maxim Group LLC on Tuesday, June 4th & Wednesday, June 5th, at 8:00 a.m. ET',
    slug: 'charting-the-course-conference',
    url: 'https://investors.truststamp.ai/press-release?i=134809'
  },
  {
    date: 'June 18, 2024',
    title: 'Trust Stamp launch AI-powered software on the NayaOne Marketplace',
    slug: 'trust-stamp-launch-ai-powered-software-on-the-nayaone-marketplace',
    url: 'https://investors.truststamp.ai/press-release?i=135178'
  },
  {
    date: 'June 18, 2024',
    title:
      'Trust Stamp Provides Identity Verification Tools to Help Financial Institutions Fight Fraud and Protect Consumers',
    slug: 'trust-stamp-helps-financial-institutions-prevent-fraud',
    url:
      'https://www.cardrates.com/news/trust-stamp-helps-financial-institutions-prevent-fraud/'
  },
  {
    date: 'June 18, 2024',
    title: 'Trust Stamp launch AI-powered software on the AWS Marketplace',
    slug: 'trust-stamp-launch-ai-powered-software-on-the-aws-marketplace',
    url: 'https://investors.truststamp.ai/press-release?i=135174'
  },
  {
    date: 'June 24, 2024',
    title:
      'Trust Stamp announces an AI-powered solution for Deep Fake and other Injection Attacks',
    slug: 'trust-stamp-solution-for- deepfake-and-injection-attacks',
    url:
      'https://investors.truststamp.ai/pr/trust-stamp-announces-an-aipowered-solution-for-deep-fake-and-other-injection-attacks'
  },
  {
    date: 'July 11, 2024',
    title:
      'Trust Stamp announces H1-2024 growth and 69th customer on its low-code platform.',
    slug: 'h1-business-growth',
    url:
      'https://investors.truststamp.ai/pr/trust-stamp-president-announces-substantial-h12024-growth-including-the-69th-customer-on-its-lowcode-orchestration-layer-platform'
  },
  {
    date: 'July 12, 2024',
    title:
      'Trust Stamp evolves as a SaaS provider of AI technologies, applying for its 37th patent.',
    slug: 'global-saas-ai-provider',
    url:
      'https://investors.truststamp.ai/pr/trust-stamp-discusses-its-evolution-to-serving-diverse-global-markets-as-a-saas-provider-of-artificial-intelligence-powered-technologies-and-the-application-for-its-37th-patent'
  },
  {
    date: 'July 18, 2024',
    title:
      'Trust Stamp announces a Letter of Intent for a transformative strategic alliance',
    slug:
      'trust-stamp-announces-a-letter-of-intent-for-a-transformative-strategic-alliance',
    url:
      'https://investors.truststamp.ai/pr/trust-stamp-announces-a-letter-of-intent-for-a-transformative-strategic-alliance'
  },
  {
    date: 'July 23, 2024',
    title:
      'Trust Stamp Partners with Botdoc to Bring New Innovative ID Security to the Automotive Industry',
    slug: 'trust-stamp-botdoc',
    url:
      'https://investors.truststamp.ai/pr/trust-stamp-partners-with-botdoc-to-bring-new-innovative-id-security-to-the-automotive-industry'
  },
  {
    date: 'August 13, 2024',
    title:
      'Trust Stamp Provides a Business Report and Financial Results for the Six Months Ended June 30, 2024',
    slug: 'financial-results',
    url:
      'https://investors.truststamp.ai/pr/trust-stamp-provides-a-business-report-and-financial-results-for-the-six-months-ended-june-30-2024'
  },
  {
    date: 'August 7, 2024',
    title:
      'Trust Stamp Licenses AI Technologies for Hydrogen-Powered Drones, Generating $5M in Q3 2024',
    slug: 'trust-stamp-licenses-ai-tech',
    url:
      'https://investors.truststamp.ai/pr/trust-stamp-has-licensed-its-computer-vision-and-other-aibased-technologies-for-use-in-hydrogenpowered-search-rescue-humanitarian-services-surveillance-and-delivery-drones-in-a-transaction-generating-5000000-of-income-for-trust-stamp-in-q3-of-2024'
  },
  {
    date: 'September 3, 2024',
    title:
      'Trust Stamp Announces Pricing of Registered Direct Offering and Warrant Inducement, Priced At-The-Market, for Aggregate Gross Proceeds of $2.0 Million',
    slug: 'trust-stamp-direct-offering-and-warrant-inducement',
    url:
      'https://investors.truststamp.ai/pr/trust-stamp-announces-pricing-of-registered-direct-offering-and-warrant-inducement-priced-atthemarket-for-aggregate-gross-proceeds-of-20-million'
  },
  {
    date: 'September 9, 2024',
    title:
      'Trust Stamp ® announces the issuance of a new patent covering the invention of a Personal Identifiable Information Encoder',
    slug: 'trust-stamp-expands-patent-portfolio-with-pii-encoder',
    url:
      'https://investors.truststamp.ai/pr/trust-stamp-announces-the-issuance-of-a-new-patent-covering-the-invention-of-a-personal-identifiable-information-encoder'
  },
  {
    date: 'October 23, 2024',
    title: 'Trust Stamp Enhances Biometric Security with Palm-Enhanced Cryptographic Solution',
    slug: 'trust-stamp-enhances-biometric-security-with-palmenhanced-cryptographic-solution',
    url: 'https://investors.truststamp.ai/pr/trust-stamp-enhances-biometric-security-with-palmenhanced-cryptographic-solution'
  },
  {
    date: 'December 2, 2024',
    title: 'Trust Stamp to Provide Strategic Update and Announces Annual General Meeting Details',
    slug: 'trust-stamp-to-provide-strategic-update-and-announces-annual-general-meeting-details',
    url: 'https://investors.truststamp.ai/pr/trust-stamp-to-provide-strategic-update-and-announces-annual-general-meeting-details'
  },
  {
    date: 'December 5, 2024',
    title: 'Trust Stamp Announces Pricing of $3.0 Million Registered Direct Offering',
    slug: 'trust-stamp-announces-pricing-of-30-million-registered-direct-offering',
    url: 'https://investors.truststamp.ai/pr/trust-stamp-announces-pricing-of-30-million-registered-direct-offering'
  },
  {
    date: 'December 9, 2024',
    title: 'Correction: Trust Stamp to Provide Strategic Update and Announces Annual General Meeting Details',
    slug: 'correction-trust-stamp-to-provide-strategic-update-and-announces-annual-general-meeting-details',
    url: 'https://investors.truststamp.ai/pr/correction-trust-stamp-to-provide-strategic-update-and-announces-annual-general-meeting-details-'
  },
  {
    date: 'January 2, 2025',
    title: 'Trust Stamp Announces Reverse Stock Split Effective January 6, 2025',
    slug: 'trust-stamp-announces-reverse-stock-split-effective-january-6-2025',
    url: 'https://investors.truststamp.ai/pr/trust-stamp-announces-reverse-stock-split-effective-january-6-2025'
  },
  {
    date: 'January 3, 2025',
    title: 'Trust Stamp Previews Strategic Announcements',
    slug: 'trust-stamp-previews-strategic-announcements',
    url: 'https://investors.truststamp.ai/pr/trust-stamp-previews-strategic-announcements'
  },
  {
    date: 'January 6, 2025',
    title: 'Trust Stamp announces a strategic partnership projected to generate up to $4.3m of revenue receipts in 202',
    slug: '4.3m-of-revenue',
    url: 'https://investors.truststamp.ai/pr/trust-stamp-announces-a-strategic-partnership-projected-to-generate-up-to-43m-of-revenue-receipts-in-2025'
  },
  {
    date: 'January 7, 2025',
    title: 'Trust Stamp Announces Pricing of $3.5 Million Registered Direct Offering Priced At-The-Market Under Nasdaq Rules',
    slug: 'trust-stamp-announces-pricing-of-35-million-registered-direct-offering',
    url: 'https://investors.truststamp.ai/pr/trust-stamp-announces-pricing-of-35-million-registered-direct-offering-priced-atthemarket-under-nasdaq-rules'
  },
  {
    date: 'January 15, 2025',
    title: 'Trust Stamp previews AGM',
    slug: 'trust-stamp-previews-agm',
    url: 'https://investors.truststamp.ai/pr/trust-stamp-previews-agm'
  },
];

export const EVENTS_TO_ATTEND = [
  {
    title: 'RSA Conference 2020',
    url: 'https://www.rsaconference.com/usa',
    date: 'February 24 - 28',
    address: 'San Francisco, USA'
  },
  {
    title: 'TAG Summit',
    url: 'http://www.tagsummit.com/',
    date: 'March 3 - 4',
    address: 'Atlanta, USA'
  },
  {
    title: 'Fintech South 2020',
    url: 'http://www.fintechsouth.com/',
    date: 'April 20 - 21',
    address: 'Atlanta, USA'
  },
  {
    title: 'Identiverse',
    url: 'https://identiverse.com/',
    date: 'June 8 - 11',
    address: 'Denver, USA'
  },
  {
    title: 'North American Banking Innovation Technology Summit',
    url:
      'https://gdsgroup.com/events/financial-services/banking-innovation-june-2020/',
    date: 'June 15 - 17',
    address: 'Naples, USA'
  }
];

export const EVENTS_TO_SPEAK = [
  {
    title: 'RSA Conference 2020',
    url: 'https://www.swift.com/events',
    date: 'February 28, 8:30 - 9:20AM',
    address: 'San Francisco, USA',
    sub: 'Speaker: Emma Lindley',
    theme:
      '“Crushing Biases: Why the Digital Identity Industry Needs Diversity”'
  }
];
