import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import PageHeader from 'components/PageHeader';

export default class PageHeaderFloating extends BaseComponent {
  className = 'ts-PageHeaderFloating';

  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: '',
  };

  render() {
    return (
      <div className={ this.rootcn`` }>
        <PageHeader { ...this.props } className={ this.cn`__page-header` } />
      </div>
    );
  }
}
