// Time to do some sketchy s**t... doo da doo da,
// Hope I get away with it oh doo da day

const type = 'application/javascript';

const getCrossOriginWorkerURL = (originalWorkerUrl, _options = {}) => {
  const options = {
    // skipSameOrigin disabled on the local ENV to make
    // polyfills/required Worker check work
    skipSameOrigin: ENV === 'local' ? false : true,
    useBlob: true,

    ..._options,
  };

  if (
    options.skipSameOrigin && (
      !originalWorkerUrl.includes('://')
      || originalWorkerUrl.includes(window.location.origin)
    )) {

    // The same origin - Worker will run fine
    return Promise.resolve(originalWorkerUrl);
  }

  return new Promise((resolve, reject) =>
    fetch(originalWorkerUrl)
      .then((res) => res.text())
      .then((codeString) => {
        const workerPathURL = originalWorkerUrl.includes('://')
          ? originalWorkerUrl
          : `${window.location.origin}${originalWorkerUrl}`

        let workerPath = new URL(workerPathURL).href.split('/');
        workerPath.pop();

        const importScriptsFix = `const _importScripts = importScripts;
const _fixImports = (url) => new URL(url, '${workerPath.join('/') + '/'}').href;
importScripts = (...urls) => _importScripts(...urls.map(_fixImports));`;

        let finalURL = `data:${type},${encodeURIComponent(importScriptsFix + codeString)}`;

        if (options.useBlob) {
          finalURL = URL.createObjectURL(
            new Blob([`importScripts("${finalURL}")`], { type })
          )
        }
        resolve(finalURL);
      })
      .catch(reject)
  );
};

/**
 * getCrossOriginWorker takes an original worker URL (address
 * starting with htttps:// preffered) and tries to load it on
 * third party domain bypassing CSP settings.
 * - first attempt tries blob:
 * - second attempt tries data: URL
 * - third attempt tries original URL
 *
 * When worker can't be load, returned promise is rejected.
 *
 * @param  {string} originalWorkerUrl
 * @param  {Number} attempt
 * @return {Promise<Worker>}
 */
function getCrossOriginWorker(originalWorkerUrl, attempt = 0) {
  return new Promise(async (resolve, reject) => {
    let workerURL;
    let worker;
    let isDone = false;
    function tryAgain(lastError) {
      if (attempt === 2) {
        return reject(lastError);
      }
      return getCrossOriginWorker(originalWorkerUrl, attempt + 1)
        .then(resolve)
        .catch(reject);
    }

    switch (attempt) {
      case 0:
        workerURL = await getCrossOriginWorkerURL(originalWorkerUrl);
        break;
      case 1:
        workerURL = await getCrossOriginWorkerURL(originalWorkerUrl, { useBlob: false });
        break;
      case 2:
        workerURL = originalWorkerUrl;
        break;
      default:
        throw new Error('getCrossOriginWorker out of boundaries');
    }

    try {
      worker = new Worker(workerURL);
    } catch(err) {
      return tryAgain(err);
    }

    worker.onerror = (err) => {
      // NOTE: Firefox blocks the Worker silently.
      //       We can check it's status by checking onerror message
      isDone = true;
      tryAgain(err);
    };

    setTimeout(() => {
      if (isDone) return;

      worker.onerror = null;
      resolve(worker);
    }, 166);
  });
}

module.exports = {
  getCrossOriginWorkerURL,
  getCrossOriginWorker
};
