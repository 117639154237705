import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';

export default class Spinner extends BaseComponent {
  className = 'ts-Spinner';

  static propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'big']),
  };

  static defaultProps = {
    size: 'medium',
  };

  render() {
    const circlesMarkup = Array.from({ length: 4 }, (item, index) => (
      <div
        key={ `spinner-circle-${index}` }
        className={ this.cn(`__circle --${this.props.size}__circle-${index}`) }
      />
    ));
    return (
      <div className={ this.rootcn(`--${this.props.size}`) }>
        { circlesMarkup }
      </div>
    );
  }
}
