/* eslint-disable max-len */
import React, { Fragment } from 'react';
import { Link } from 'react-router';
import { ROUTES } from 'constants';

export const TITLE = 'Remove risk, enhance utility';

export const HOWITWORKS = [
  {
    id: 1,
    title: 'Remove sensitive data',
    text: 'Replace biometric templates and scans with a less meaningful numerical representation to remove sensitive data from the reach of criminals',
    slug: 'protect-transform',
  },
  {
    id: 2,
    title: 'Store safely',
    text: <Fragment>Large databases of biometric data present a lucrative honeypot for fraudsters. The (IT)<sup>2</sup> poses none of the risk, and has the potential to offer longer retention periods under privacy regulations</Fragment>,
    slug: 'protect',
  },
  {
    id: 3,
    title: 'Do more, more efficiently',
    text: <Fragment>The pseudonymised data serves as an accurate, secure identifier within your systems. Build off of the (IT)<sup>2</sup> foundation to use tokens for <Link to={ ROUTES.VERIFY }>biometric account access</Link>, <Link to={ ROUTES.DETECT }>synthetic identity detection</Link> and more</Fragment>,
    slug: 'do-more',
  },
];

export const BANNER_CONTENT = [
  {
    id: 1,
    title: 'More',
    slug: 'tick',
    list: [
      'Actionable insights',
      'System longevity',
      'Rapid deployment, scalability and adaptability',
      'Cost savings',
    ],
  },
  {
    id: 2,
    title: 'Less',
    slug: 'close',
    list: [
      'Risk to your organisation and users',
      'Time wasted for you and your customers',
      'Computational resources required',
      'Data stored',
    ],
  },
];

export const TITLE2 = 'Security, Efficiency & Privacy';

export const CONTENT = [
  {
    id: 1,
    title: 'Protect Your Systems',
    text: 'A data infrastructure that leverages Trust Stamp Protect throughout provides no potential value to cybercriminals, disincentivising attacks aimed at exposing the information in your systems',
    slug: 'protect-fraud',
  },
  {
    id: 2,
    title: 'Protect Your Users',
    text: <Fragment>No user information is at risk if an (IT)<sup>²</sup> is ever exposed, because it is impossible to recover source data through reverse-engineering. (IT)<sup>²</sup>s can be revoked if a database of tokens is breached, further proving superior to raw biometrics that can never be changed</Fragment>,
    slug: 'systems',
  },
  {
    id: 3,
    title: 'Streamline Compliance',
    text: 'As global privacy regulations grow alongside customer identification mandates, it is imperative to protect an increasing amount of user data. Trust Stamp Protect allows you to replace sensitive data with pseudonymised data under GDPR classification',
    slug: 'compliance',
  },
  {
    id: 4,
    title: 'Enhance Functionality Across Systems',
    text: <Fragment>Boost the efficiency of your <Link to={ ROUTES.VERIFY }>account access</Link> and <Link to={ ROUTES.DETECT }>fraud-prevention</Link> protocols by incorporating Trust Stamp Protect throughout</Fragment>,
    slug: 'integrate',
  },
  {
    id: 5,
    title: 'Build Robust Systems with Longevity',
    text: 'Seamlessly integrate new data types into the tokenisation process to stay on the cutting edge of identity technology without re-engineering for future advancements',
    slug: 'adjust-build',
  },
  {
    id: 6,
    title: 'Innovate Further',
    text: 'The transformation technology that protects data also makes it conducive for a variety of uses. Leverage benefits of a small token size by creating systems that work both online and offline and enable user control through storage on QR codes, smartcards, phones and more',
    slug: 'globe-longevity',
  },
];

export const VIDEO_CONTENT = {
  id: 1,
  title: 'The core technology',
  text: 'Trust Stamp Protect creates pseudonymised token identifiers for accurate, protected and low-footprint identification and deduplication',
  video: 'https://player.vimeo.com/video/499024505',
};
