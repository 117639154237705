/* global device */
import React from 'react';
import omit from 'lodash/omit';
import throttle from 'lodash/throttle';
import noop from 'no-op';
import PropTypes from 'prop-types';

import BaseComponent from 'components/BaseComponent';
import deviceModel from 'sf/models/device';

/**
 * This component opens given link in WebView or system browser.
 *
 * example:
 * <Link to="https://truststamp.net">truststamp.net</Link>
 *
 * read more:
 * https://wiki.apache.org/cordova/InAppBrowser
 */

export default class Link extends BaseComponent {
  className = 'ts-Link';

  static propTypes = {
    children: PropTypes.node,
    to: PropTypes.string,
    target: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    children: '',
    target: '_self',
    onClick: noop,
  };

  openExternally(e) {
    if (device.platform.toUpperCase() === 'ANDROID') {
      navigator.app.loadUrl(this.props.to, { openExternal: true });
      e.preventDefault();
    } else if (device.platform.toUpperCase() === 'IOS') {
      window.open(this.props.to, '_system');
      e.preventDefault();
    }
    // else: Leave standard behaviour
  }

  openInternally(e) {
    if (this.props.target !== '_blank') {
      this.navigate(this.props.to);
      e.preventDefault();
    }
  }

  handleClick = (e) => {
    if (deviceModel.isCordova() && this.props.target !== '_self') {
      this.openExternally(e);
    } else {
      this.openInternally(e);
    }
    this.props.onClick();
  }

  // Click cooldown is introduced (again) for amazing iOS devices.
  // Randomly click was not triggered, only focus. Now click is handled by two handlers:
  // onTouchEnd and onClick.
  handleClick = throttle(this.handleClick, 500, { trailing: false });

  render() {
    return (
      <a
        { ...omit(this.props, ['children', 'to']) }
        href={ this.props.to }
        onClick={ this.handleClick }
        className={ this.rootcn() }
        onTouchEnd={ this.handleClick }
      >
        { this.props.children }
      </a>
    );
  }
}
