/* eslint-disable max-len */
import React from 'react';
import BaseComponent from 'components/BaseComponent';
import { asset } from 'sf/helpers';

export default class DetectContent extends BaseComponent {
  className = 'ts-DetectContent';

  render() {
    return (
      <div className={ this.rootcn() }>
        <section>
          <div className="ts-container">
            <div className={ this.cn`__column __column--left` }>
              <h6>Duplicate Identity Detection</h6>
              <h2>Synthetic Identity Fraud Prevention in Banking</h2>
              <p>
                At Trust Stamp, we understand that community and small banks
                face unique challenges in fraud prevention. Our state-of-the-art
                biometric deduplication technology is tailored to meet these
                needs, providing an unparalleled solution for secure and
                efficient banking operations.
              </p>
              <p>
                Trust Stamp’s Greylist excels in identifying duplicate
                identities, a crucial feature in detecting synthetic identities
                used for illicit activities such as fraudulent account openings
                in financial sectors.
              </p>
            </div>
            <div className={ this.cn`__column __column--right` }>
              <img
                src={ asset`ai/angled-computer.png` }
                alt="High-performance deduplication"
                width="100%"
              />
            </div>
          </div>
        </section>
        <section>
          <div className="ts-container">
            <h2>Key Features</h2>
            <ol>
              <li>
                <b>Advanced 1:N Facial Matching:</b> Our technology excels in rapidly
                and securely matching facial biometrics across a broad dataset,
                ensuring accurate verification.
              </li>
              <li>
                <b>Unprecedented Speed and Security:</b> Experience the fastest
                processing in the industry without compromising on security -
                this is made possible by Trust Stamp’s biometric token
                technology, the Irreversibly Transformed Identity Token
              </li>
              <li>
                <b>Greylisting Functionality:</b> Our sophisticated system is adept at
                identifying and flagging potential fraudsters, even if they use
                synthetic or stolen IDs.
              </li>
            </ol>
          </div>
        </section>
      </div>
    );
  }
}
