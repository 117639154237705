/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router';
import { onFullRender } from 'sf/helpers/domHelper';
import device from 'models/device';
import { ROUTES } from 'constants';
import BasePage from 'pages/BasePage';
import Banner from 'components/Banner';
import Resources from 'components/Resources';
import { asset } from 'sf/helpers';
import Process from './components/Process';
import Work from './components/Work';
import ProductsSection from './components/ProductsSection';
import CertificatesSection from './components/CertificatesSection'

export default class IndexAi extends BasePage {
  className = 'ts-IndexAi';

  state = {};

  componentDidMount() {
    onFullRender().then(() => this.setState({ mainSectionStatus: true }));
    this.syncStateWithModel(device, ['mdUp']);
    this.subscribe('showFixedMenu', this.handleShowFixedMenu);
    this.subscribe('locationChange', () => {
      this.publish('mainLayout:hideFixedMenu');
    });
    this.publish('mainLayout:hideFixedMenu');
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        { /* banner */ }
        <Banner theme="phone">
          <h1>
            The Privacy First Identity Company<sup>(TM)</sup> <br />
            Secure, Private & Trusted Identity
          </h1>
          <p>
            Mitigate fraud, retain customers, and reach a broader base of users
            worldwide with AI-powered identity solutions at the intersection of
            biometrics, privacy, and cybersecurity.
          </p>
          { /* <Link to={ ROUTES.IDENTITY_TOOLS }>Get Started Today</Link> */ }
          <Link to={ ROUTES.CONTACT_US }>Get in Touch Now</Link>
        </Banner>

        { /* what we do section */ }
        <Work />

        { /* how we do it section */ }
        <Process />

        { /* powered by trust stamp section */ }
        <ProductsSection />

        { /* resources section */ }
        <Resources>
          <article>
            <h6>RESOURCES</h6>
            <h2>
              Privacy-Protecting Power of the Irreversibly Transformed Identity
              Token
            </h2>
            <p>
              Learn how Trust Stamp’s tokenisation tech maximises privacy and
              utility of personal data to enhance risk and data protection
              measures with a frictionless UX
            </p>
            <Link to={ ROUTES.WHITEPAPER } className="ts-button--brand">
              Get the Whitepaper now
            </Link>
          </article>
          <aside>
            <img
              src={ asset`/ai/privacy-paper-2.png` }
              alt="Privacy-Protecting Power of the Irreversibly Transformed Identity Token"
              width="400"
            />
          </aside>
        </Resources>

        { /* certificates section */ }
        <CertificatesSection />

      </div>
    );
  }
}
