import { STEPS } from 'components/ResetPassword/ResetPassword.constants';
import * as TYPES from 'components/ResetPassword/ResetPassword.types';

const initialState = {
  activeStep: STEPS.SELFIE,
  selfieDataURI: null,
  email: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TYPES.SAVE_SELFIE: {
      return {
        ...state,
        selfieDataURI: action.data,
        activeStep: STEPS.EMAIL,
      };
    }
    case TYPES.STORE_RESET_CODE: {
      return {
        ...state,
        code: action.data.code,
      };
    }
    case TYPES.STORE_EMAIL: {
      return {
        ...state,
        email: action.data.email,
      };
    }
    default:
      return state;
  }
}
