/* eslint-disable max-len */
import React from 'react';
import BaseComponent from 'components/BaseComponent';
import { asset } from 'sf/helpers';
import { CONTENT } from './constants';

export default class Work extends BaseComponent {
  className = 'ts-what-we-do';

  render() {
    return (
      <section className={ this.rootcn() }>
        <div className={ this.cn`__container ts-container` }>


          <h6>WHAT WE DO</h6>
          <h2>Improving customer experience and security for one of America’s largest credit card issuing banks</h2>
          <p>With a global customer base made up of tens of millions of active accounts, the company maintains an industry-leading stance in financial technology innovation. This enables them to reach their customers and end users with the best services possible, all while protecting the security of everyone involved. Trust Stamp worked alongside the bank to deploy a new approach to risk management and customer experience in an evolving world of digital finance.</p>
          <p>The solution needed to consider financial regulations including Know Your Customer (KYC) or Finra Rule 2090, optimize existing resources, and provide a tangible impact in addressing the following problems with fraud, seen throughout the online banking space: </p>


          { /* what we do list */ }
          <div className={ this.cn`__features` }>
            { CONTENT.map(({ id, title, content, slug }) => (
              <div className={ this.cn`__column` } key={ id }>
                <img src={ asset`ai/${slug}.png` } alt={ title } />
                <h3>{ title }</h3>
                <p>{ content }</p>
              </div>
            )) }

          </div>
        </div>
      </section>
    );
  }
}
