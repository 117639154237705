import React from 'react';
import Button from 'components/Button';
import Select from 'sf/components/Select';
import BasePage from 'pages/BasePage';
import onFullRender from 'sf/hoc/OnFullRender';
import { scrollTo } from 'sf/helpers/domHelper';
import { asset } from 'helpers';
import { downloadFileFromUrl } from 'sf/helpers';
import DOCUMENTS from './document_list.json';

const ITEMS_PER_PAGE = 30;

const Row = ({ item }) => {
  return (
    <tr>
      <td>{ item.continent }</td>
      { /* <td>{item.region}</td> */ }
      { /* <td>{item.sovereignty}</td> */ }
      { /* <td>{item.countryCode}</td> */ }
      <td>{ item.territory }</td>
      { /* <td>{item.stateCode}</td> */ }
      { /* <td>{item.stateName}</td> */ }
      <td>{ item.year }</td>
      <td>{ item.document }</td>
      { /* <td>{item.deprecated}</td> */ }
      <td>{ item.created }</td>
      <td>{ item.updated }</td>
    </tr>
  );
};

class SupportedDocuments extends BasePage {
  className = 'ts-SupportedDocuments';
  static title = 'Trust Stamp’s Identity Document Database';
  static description = 'Trust Stamp’s identity document database contains 14,000 templates of IDs from 251 countries. Find out all types of documents available.';

  state = {
    currentPage: 0,
    continentFilter: '',
    territoryFilter: '',
    documentFilter: ''
  };

  downloadPDF = () => {
    downloadFileFromUrl(asset`ai/ts_list_of_supported_documents.xls`);
  };

  handlePageChange = (newPage) => {
    this.setState({ currentPage: newPage }, () => {
      scrollTo(0);
    });
  };

  handleFilterChange = (filterType, value) => {
    this.setState({ [filterType]: value, currentPage: 0 });
  };

  getFilteredDocuments = () => {
    const { continentFilter, territoryFilter, documentFilter } = this.state;

    return DOCUMENTS.filter((item) => {
      return (
        (!continentFilter || item.continent === continentFilter) &&
        (!territoryFilter || item.territory === territoryFilter) &&
        (!documentFilter || item.document === documentFilter)
      );
    });
  };

  render() {
    const {
      currentPage,
      continentFilter,
      territoryFilter,
      documentFilter
    } = this.state;
    const filteredDocuments = this.getFilteredDocuments();
    const totalItems = filteredDocuments.length;
    const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
    const startIndex = currentPage * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const paginatedContent = filteredDocuments.slice(startIndex, endIndex);

    // Generate unique options for filters in the correct format
    const continents = [...new Set(DOCUMENTS.map((doc) => doc.continent))]
      .filter((continent) => continent)
      .map((continent) => ({ value: continent, label: continent }));

    const territories = [...new Set(DOCUMENTS.map((doc) => doc.territory))]
      .filter((territory) => territory)
      .map((territory) => ({ value: territory, label: territory }));

    const documents = [...new Set(DOCUMENTS.map((doc) => doc.document))]
      .filter((document) => document)
      .map((document) => ({ value: document, label: document }));

    return (
      <div className={ this.rootcn() }>
        <div className="ts-container">
          <h1 id="title">List of Supported Documents</h1>
          <p>
            Global Accessibility: Opening Doors to Users in Over{ ' ' }
            { totalItems || '200+' } Countries and Territories
          </p>

          { /* Filters */ }
          <div className={ this.cn`__filters` }>
            <Select
              value={ continentFilter }
              className={ this.cn`__select` }
              displayName="Country Continent "
              options={ continents }
              placeholder="Select Continent"
              placeholderAlwaysVisible={ true }
              onChange={ (e) =>
                this.handleFilterChange('continentFilter', e.value)
              }
            />

            <Select
              value={ territoryFilter }
              className={ this.cn`__select` }
              displayName="Country / Territory"
              options={ territories }
              placeholder="Select Territory"
              placeholderAlwaysVisible={ true }
              onChange={ (e) =>
                this.handleFilterChange('territoryFilter', e.value)
              }
            />
            <Select
              value={ documentFilter }
              className={ this.cn`__select` }
              displayName="Document Types"
              options={ documents }
              placeholder="Select Document"
              placeholderAlwaysVisible={ true }
              onChange={ (e) => this.handleFilterChange('documentFilter', e.value) }
            />
          </div>

          <Button className={ this.cn`__button` } onClick={ this.downloadPDF }>
            Download The List of Supported Documents
          </Button>

          <div className={ this.cn`__table-container` }>
            <table>
              <thead>
                <tr>
                  <th>Country Continent</th>
                  <th>Country / Territory</th>
                  <th>Year</th>
                  <th>Document</th>
                  <th>Created</th>
                  <th>Updated</th>
                </tr>
              </thead>
              <tbody>
                { paginatedContent.map((item, index) => (
                  <Row key={ startIndex + index } item={ item } />
                )) }
              </tbody>
            </table>
          </div>

          <div className={ this.cn`__pagination` }>
            <button
              onClick={ () => this.handlePageChange(currentPage - 1) }
              disabled={ currentPage === 0 }
              className={ this.cn`__pagination--button` }
            >
              Previous
            </button>
            <span>
              Page { currentPage + 1 } of { totalPages }
            </span>
            <button
              onClick={ () => this.handlePageChange(currentPage + 1) }
              disabled={ currentPage >= totalPages - 1 }
              className={ this.cn`__pagination--button` }
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default onFullRender(SupportedDocuments);
