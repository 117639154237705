var is = require('next-is');
var navigator = global.navigator || {};
var ua = navigator.userAgent || '';

var localStorage;
try {
  localStorage = global.localStorage;
} catch (e) {
	// volatile storage for paranoia mode
	localStorage = {
		getItem: (name) => localStorage[name],
		setItem: (name, value) => localStorage[name] = value,
	};
}

/**
 * debouncePromise takes function, that returns promise
 * and makes sure - there is just one concurrent call of promise.
 * NOTE: arguments might be ignored
 *
 * @param  {function} func input function
 * @return {Promise}
 */
const debouncePromise = (func) => {
	let isPromiseRunning = false;
	let lastPromise;

	return (...args) => {
		if (!isPromiseRunning) {
			lastPromise = new Promise((resolve, reject) => {
				isPromiseRunning = true;
				const promise = func(...args);
				const end = () => { isPromiseRunning = false; }
				const resolver = (...promiseArgs) => (end(), resolve(...promiseArgs));
				const rejector = (...promiseArgs) => (end(), reject(...promiseArgs));

				promise.then(resolver, rejector);
			});
		}
		return lastPromise;
	};
};

const whenDOMReady = (() => {
	// takes function, that can be called, when DOM is loaded.
	let isDOMLoaded = false;

	const domReadyPromise = new Promise((resolve) => {
		if (!is.browser() || ['interactive', 'complete'].includes(document.readyState)) {
			return resolve();
		}
		document.addEventListener('DOMContentLoaded', resolve, false);
	});
	domReadyPromise.then(() => { isDOMLoaded = true; });

	return (func) => {
		if (!is.browser()) {
			return () => {};
		}

		// on Chrome 52 @ android mediaDevices.getUserMedia is triggered with a delay.
		// functions to be triggered when camera is ready
		return (...args) => {
			if (isDOMLoaded) {
				// synchronous call
				return func(...args);
			}
			// asynchronous call
			return domReadyPromise.then(() => func(...args));
		};
	};
})();

function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

function setPrefixedStyle(element, cssProp, cssValue) {
	if (!element) return;
	const prefixes = ['o', 'ms', 'moz', 'webkit'];
	prefixes.forEach((prefix) => {
		element.style[prefix + capitalizeFirstLetter(cssProp)] = cssValue;
	});
	element.style[cssProp] = cssValue;
}

function createElement(elementName, props) {
	var element = document.createElement(elementName);
	var uniqAttrs = ['innerHTML', 'className', 'width', 'height'];
	if (props.style) {
		Object.assign(element.style, props.style);
	}
	Object.keys(props).forEach(function(prop) {
		if (prop === 'style') return;
		if (uniqAttrs.includes(prop) || /^on/.test(prop)) {
			element[prop] = props[prop];
		} else {
			element.setAttribute(prop, props[prop]);
		}
	});
	return element;
}

/**
 * InputDeviceInfo object can't be copied easly.
 * `Object.keys(obj)` returns `{}` regardless of it's content
 *
 * with following functions you're able to clone InputDeviceInfo properties to the simple object.
 * @param  {InputDeviceInfo} instance  InputDeviceInfo object to copy
 * @return {plain Object}
 */
function cloneInputDeviceInfo(instance) {
  const result = Object.keys(MediaDeviceInfo.prototype).reduce((acc, key) => {
    acc[key] = instance[key];

    return acc;
  }, {});
  delete result.toJSON;
  return result;
}

module.exports = {
	cloneInputDeviceInfo,
	createElement,
	debouncePromise,
	localStorage,
	setPrefixedStyle,
	whenDOMReady,
};
