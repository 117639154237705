import React from 'react';
import BasePage from 'pages/BasePage';
import En from 'components/Terms/En';
import Fr from 'components/Terms/Fr';
import Es from 'components/Terms/Es';

const languageComponents = {
  en: <En />,
  fr: <Fr />,
  es: <Es />
};

export default class TermsAndConditions extends BasePage {
  className = 'ts-TermsAndConditions';
  static title = 'Terms And Conditions';
  static description =
    'We research and leverage artificial intelligence and machine learning to deliver the most comprehensive facial biometric identity authentication solution.';

  render() {
    const { query } = this.props.location;
    const { lang } = query;

    const selectedComponent = languageComponents[lang] || <En />;

    return (
      <div className={ this.rootcn() }>
        <div className={ this.cn`__container` }>{ selectedComponent }</div>
      </div>
    );
  }
}
