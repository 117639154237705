/* eslint-disable max-len */
import React from 'react';
import BaseComponent from 'components/BaseComponent';
import Cards from 'components/Cards';
import { CONTENT } from '../../constants';

export default class BankingAndFintechContent extends BaseComponent {
  className = 'ts-BankingAndFintechContent';

  render() {
    return (
      <div className={ this.rootcn() }>
        <section>
          <div className="ts-container">
            <h2>
              Reinventing Security for Financial Institutions with Trust Stamp
            </h2>
            Financial institutions stand at the forefront of confidentiality and
            security, handling sensitive client information and vast financial
            assets daily. With customers expecting speed of service, the
            challenges of maintaining these standards are escalating, with
            sophisticated fraud methods and regulatory complexities. Trust Stamp
            is here to redefine how financial institutions protect their
            customers and meet compliance standards through advanced identity
            verification, customer due diligence (KYC/CDD), velocity and
            synthetic identity fraud detection, and biometric multifactor
            authentication. <br />
          </div>
        </section>
        <section>
          <div className="ts-container">
            <h2>Benefits</h2>
            Harnessing Trust Stamp's identity verification expertise streamlines
            regulatory compliance while seamlessly integrating into your
            existing systems and workflows.
          </div>
          <Cards content={ CONTENT } columnCount={ 3 } />
        </section>
      </div>
    );
  }
}
