import React from 'react';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import { browserHistory } from 'react-router';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import Render from 'components/Render';
import measured from 'hoc/Measured';
import device from 'models/device';
import help from 'models/help';
import Icon from 'sf/components/Icon';

export class HelpCenter extends BaseComponent {
  className = 'ts-HelpCenter';

  state = {
    leftMargin: 0,
    notifications: [],
    totalWidth: 0,
    firstVisibleCardIndex: 0,
  };

  isMeasured = false;

  windowWidth = 0;

  componentDidMount() {
    let currentLocation = {};
    this.syncStateWithModel(help, ['notifications', 'firstVisibleCardIndex', 'isHelpVisible']);

    this.__listeners.push(browserHistory.listen((location) => {
      if (currentLocation.pathname && currentLocation.pathname !== location.pathname) {
        help.close();
        help.clearNotifications();
        this.moveCardsLeft(this.state.firstVisibleCardIndex);
      }
      currentLocation = location;
    }));

    this.windowWidth = window.innerWidth;
    this.addEventListener(window, 'resize', this.handleWindowResize);
  }

  get visibleNotificationsCount() {
    return device.smUp() ? 3 : 1;
  }

  get canMoveLeft() {
    return this.state.firstVisibleCardIndex > 0;
  }

  get canMoveRight() {
    return this.state.firstVisibleCardIndex + this.visibleNotificationsCount <
      this.state.notifications.length;
  }

  get cardWidth() {
    const empiricalContainerPadding = 10; // Sigh, I'm not sure where it came from, but hey!
    return (this.state.totalWidth - empiricalContainerPadding) / this.visibleNotificationsCount;
  }

  calculateLeftOffset = () => {
    return this.state.leftMargin - this.state.firstVisibleCardIndex * this.cardWidth;
  }

  moveCardsLeft = (n = 1) => {
    if (!this.canMoveLeft) return;
    this.moveCards(n);
  }

  moveCardsRight = (n = 1) => {
    if (!this.canMoveRight) return;
    this.moveCards(-n);
  }

  moveCards = (n) => {
    help.moveTo(this.state.firstVisibleCardIndex - n);
  }

  handleWindowResize = () => {
    // Listen to changes in width only, recalculate and close help center when it happens
    if (this.windowWidth !== window.innerWidth) {
      help.close();
      this.setTimeout(() => {
        this.setState({
          firstVisibleCardIndex: 0,
        }, () => {
          this.windowWidth = window.innerWidth;
          this.isMeasured = false;
          this.refs.referenceContainer.measure();
        });
      }, 500);
    }
  }

  handleReferenceContainerMeasure = (dimensions) => {
    if (this.isMeasured || !this.state.isHelpVisible) return;
    this.setState({
      leftMargin: dimensions.left,
      totalWidth: dimensions.width,
    }, () => {
      this.isMeasured = true;
    });
  }

  renderNotificationCard = ({ title, type, value }, index) => {
    const classNames = {
      '__cards-item': true,
      '__cards-item--first-visible': this.state.firstVisibleCardIndex === index,
    };
    const notificationContent = _isArray(value)
      ? value.map((content) => <div key={ content }>{ content }</div>)
      : value;
    return (
      <div
        className={ this.cn(classNames) }
        key={ `${type}-${index}` }
        style={ {
          width: this.cardWidth,
        } }
      >
        <div className={ this.cn`__card __card--${type}` }>
          <Render when={ title }>
            <div className={ this.cn`__card-title` }>
              { title }
            </div>
          </Render>
          { notificationContent }
        </div>
      </div>
    );
  }

  renderNavigationDots() {
    if (this.state.notifications.length <= this.visibleNotificationsCount) return;
    const dots = Array.from({ length: this.state.notifications.length }, (item, index) => {
      const classNames = {
        '__dots-item': true,
        '__dots-item--first-visible': this.state.firstVisibleCardIndex === index,
      };
      return (
        <div className={ this.cn(classNames) } key={ index } />
      );
    });
    return <div className={ this.cn`__dots` }>{ dots }</div>;
  }

  renderArrowButtons() {
    const leftArrowClassNames = {
      '__left-button': true,
      '__left-button--active': this.canMoveLeft,
    };
    const rightArrowClassNames = {
      '__right-button': true,
      '__right-button--active': this.canMoveRight,
    };
    const iconSize = device.smUp() ? 20 : 40;
    return [
      <div
        className={ this.cn(leftArrowClassNames) }
        key="left"
      >
        <Button
          className={ this.cn`__button` }
          theme="no-theme"
          onClick={ () => this.moveCardsLeft() }
        >
          <Icon type="ios-arrow-left" set="io" size={ iconSize } />
        </Button>
      </div>,
      <div
        className={ this.cn(rightArrowClassNames) }
        key="right"
      >
        <Button
          className={ this.cn`__button` }
          theme="no-theme"
          onClick={ () => this.moveCardsRight() }
        >
          <Icon type="ios-arrow-right" set="io" size={ iconSize } />
        </Button>
      </div>,
    ];
  }

  renderCloseButton() {
    return (
      <div className={ this.cn`__close-button` }>
        <Button
          className={ this.cn`__button` }
          theme="no-theme"
          onClick={ help.close }
        >
          <Icon
            set="io"
            size={ 30 }
            type="ios-close-empty"
          />
        </Button>
      </div>
    );
  }

  render() {
    const firstVisibleCardType = _get(
      this.state.notifications[this.state.firstVisibleCardIndex],
      'type'
    );
    const classNames = {
      '--single': this.state.notifications.length === 1,
      [`--${firstVisibleCardType}-visible`]: true,
    };
    const MeasuredReferenceContainer = measured(() => (
      <div>
        <Render when={ this.state.notifications.length > 1 }>
          { this.renderArrowButtons() }
        </Render>
      </div>
    ));
    return (
      <div className={ this.rootcn(classNames) }>
        <div className={ this.cn`__cards` }>
          <div
            className={ this.cn`__cards-wrapper` }
            style={ {
              transform: `translate3d(${this.calculateLeftOffset()}px, 0, 0)`,
            } }
          >
            { this.state.notifications.map(this.renderNotificationCard) }
          </div>
        </div>
        { this.renderNavigationDots() }
        { this.renderCloseButton() }
        <MeasuredReferenceContainer
          className="ts-container"
          onMeasure={ this.handleReferenceContainerMeasure }
          ref="referenceContainer"
        />
      </div>
    );
  }
}

export default HelpCenter;
