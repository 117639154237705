import * as TYPES from './Profile.types';

const initialState = {
  mph_discount_code: null,
  user: null,
};

export default function (state = initialState, { data, type }) {
  switch (type) {
    // it's wrong place for this action, I know, but it's the only place related a user directly
    // need to be moved to a more general model/module object
    // TODO: create model-like place for this kind of actions
    case TYPES.SAVE_MPH_DISCOUNT_CODE:
      return {
        ...state,
        mph_discount_code: data.code,
      };
    case TYPES.CLEAR_MPH_DISCOUNT_CODE:
      return {
        ...state,
        mph_discount_code: null,
      };
    case TYPES.REQUEST_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        user: data.user,
      };
    case TYPES.CLEAR_PROFILE_DATA:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
}
