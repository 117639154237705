import React from 'react';
import atom from 'atom-js';
import noop from 'no-op';
import PropTypes from 'prop-types';
import { loadScriptPool } from 'sf/helpers';
import { getScript } from 'sf/helpers/domHelper';
import { UNEXPECTED_ERROR_TITLE } from 'sf/l10n';

import BaseComponent from 'components/BaseComponent';
import model from './model';

export class Captcha extends BaseComponent {
  className = 'ts-Captcha';

  static propTypes = {
    captchaUrl: PropTypes.string,
    delayedStartTime: PropTypes.number,
    language: PropTypes.string,
    onExpired: PropTypes.func,
    showLoading: PropTypes.bool,
    sitekey: PropTypes.string, // when sitekey provided then `captchaUrl` is not needed
    verificationCallback: PropTypes.func,
    invisible: PropTypes.bool,
    enabled: PropTypes.bool,
  };

  static defaultProps = {
    delayedStartTime: 0,
    language: 'en',
    onExpired: noop,
    showLoading: true,
    verificationCallback: noop,
    invisible: false,
    enabled: true,
  };

  componentDidMount() {
    if (!this.props.enabled) return null;

    if (this.props.delayedStartTime) {
      // Delayed start is useful to fix a problem on iOS when after rapid page refreshes,
      // user is redirected to google.com.
      // How it works:
      // - captcha is initialised after requested time, not right away
      // or
      // - captcha is initialised when any input changes (interaction with a user)
      this.addEventListener(window, 'change', () => {
        this.initCaptcha();
      });

      this.setTimeout(() => {
        this.initCaptcha();
      }, this.props.delayedStartTime);
    } else {
      this.initCaptcha();
    }
  }

  componentWillUnmount() {
    model.resetCaptcha();
    super.componentWillUnmount();
  }

  initCaptcha() {
    if (this.isDestroyed) return;

    const { sitekey, captchaUrl, language, showLoading } = this.props;
    const newModelData = {
      language,
      showLoading,
    };
    if (captchaUrl) newModelData.captchaUrl = captchaUrl;
    if (sitekey) newModelData.sitekey = sitekey;

    model.set(newModelData);

    if (sitekey) {
      this.createCaptcha(sitekey);
    } else {
      model.need('sitekey', this.createCaptcha);
    }
  }

  createCaptcha = async (sitekey) => {
    // callback might be called with a delay
    if (this.isDestroyed || !this.refs.captcha) return;

    model.set({ captchaLoaded: false });

    try {
      await loadScriptPool('grecaptcha.render', () => {
        // hl = language https://developers.google.com/recaptcha/docs/language
        getScript(`//www.google.com/recaptcha/api.js?render=explicit&hl=${model.get('language')}`);
      });
    } catch (e) {
      if (this.refs.captcha && !this.state.captchaLoaded) {
        this.refs.captcha.innerHTML = `ReCaptcha: ${UNEXPECTED_ERROR_TITLE}`;
      }
      return;
    }

    if (this.isDestroyed || !this.refs.captcha) return; // async code; re-check if still can render

    try {
      /* istanbul ignore next */
      global.grecaptcha.render(this.refs.captcha, {
        'callback': this.handleVerification,
        'expired-callback': this.handleExpired,
        'sitekey': sitekey,
        'size': this.props.invisible ? 'invisible' : '',
        'badge': this.props.invisible ? 'inline' : ''
      });
    } catch (e) {} // eslint-disable-line no-empty

    model.set({ captchaLoaded: true });
  };

  handleVerification = (captchaToken) => {
    model.set({ captchaToken });
    this.props.verificationCallback(captchaToken);
  }

  handleExpired = () => {
    model.onExpired();
    this.props.onExpired();
  }

  render() {
    if (!this.props.enabled) return null;
    return (
      <div className={ this.rootcn`` }>
        <div ref="captcha"></div>
      </div>
    );
  }
}

export default atom.reactConnect(model, [
  'sitekey'
])(Captcha);
