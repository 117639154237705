import atom from 'atom-js';

/* eslint-disable max-len */
const model = atom({
  nationalities: [
    { value: 'AFGHAN', label: 'AFGHAN' },
    { value: 'ÅLAND ISLAND', label: 'ÅLAND ISLAND' },
    { value: 'ALBANIAN', label: 'ALBANIAN' },
    { value: 'ALGERIAN', label: 'ALGERIAN' },
    { value: 'AMERICAN SAMOAN', label: 'AMERICAN SAMOAN' },
    { value: 'AMERICAN', label: 'AMERICAN' },
    { value: 'ANDORRAN', label: 'ANDORRAN' },
    { value: 'ANGOLAN', label: 'ANGOLAN' },
    { value: 'ANGUILLAN', label: 'ANGUILLAN' },
    { value: 'ANTARCTIC', label: 'ANTARCTIC' },
    { value: 'ANTIGUAN OR BARBUDAN', label: 'ANTIGUAN OR BARBUDAN' },
    { value: 'ARGENTINE', label: 'ARGENTINE' },
    { value: 'ARMENIAN', label: 'ARMENIAN' },
    { value: 'ARUBAN', label: 'ARUBAN' },
    { value: 'AUSTRALIAN', label: 'AUSTRALIAN' },
    { value: 'AUSTRIAN', label: 'AUSTRIAN' },
    { value: 'AZERBAIJANI, AZERI', label: 'AZERBAIJANI, AZERI' },
    { value: 'BAHAMIAN', label: 'BAHAMIAN' },
    { value: 'BAHRAINI', label: 'BAHRAINI' },
    { value: 'BANGLADESHI', label: 'BANGLADESHI' },
    { value: 'BARBADIAN', label: 'BARBADIAN' },
    { value: 'BARTHÉLEMOIS', label: 'BARTHÉLEMOIS' },
    { value: 'BASOTHO', label: 'BASOTHO' },
    { value: 'BELARUSIAN', label: 'BELARUSIAN' },
    { value: 'BELGIAN', label: 'BELGIAN' },
    { value: 'BELIZEAN', label: 'BELIZEAN' },
    { value: 'BENINESE, BENINOIS', label: 'BENINESE, BENINOIS' },
    { value: 'BERMUDIAN, BERMUDAN', label: 'BERMUDIAN, BERMUDAN' },
    { value: 'BHUTANESE', label: 'BHUTANESE' },
    { value: 'BIOT', label: 'BIOT' },
    { value: 'BISSAU-GUINEAN', label: 'BISSAU-GUINEAN' },
    { value: 'BOLIVIAN', label: 'BOLIVIAN' },
    { value: 'BONAIRE', label: 'BONAIRE' },
    { value: 'BOSNIAN OR HERZEGOVINIAN', label: 'BOSNIAN OR HERZEGOVINIAN' },
    { value: 'BOUVET ISLAND', label: 'BOUVET ISLAND' },
    { value: 'BRAZILIAN', label: 'BRAZILIAN' },
    { value: 'BRITISH VIRGIN ISLAND', label: 'BRITISH VIRGIN ISLAND' },
    { value: 'BRITISH, UK', label: 'BRITISH, UK' },
    { value: 'BRUNEIAN', label: 'BRUNEIAN' },
    { value: 'BULGARIAN', label: 'BULGARIAN' },
    { value: 'BURKINABÉ', label: 'BURKINABÉ' },
    { value: 'BURMESE', label: 'BURMESE' },
    { value: 'BURUNDIAN', label: 'BURUNDIAN' },
    { value: 'CABO VERDEAN', label: 'CABO VERDEAN' },
    { value: 'CAMBODIAN', label: 'CAMBODIAN' },
    { value: 'CAMEROONIAN', label: 'CAMEROONIAN' },
    { value: 'CANADIAN', label: 'CANADIAN' },
    { value: 'CAYMANIAN', label: 'CAYMANIAN' },
    { value: 'CENTRAL AFRICAN', label: 'CENTRAL AFRICAN' },
    { value: 'CHADIAN', label: 'CHADIAN' },
    { value: 'CHANNEL ISLAND', label: 'CHANNEL ISLAND' },
    { value: 'CHILEAN', label: 'CHILEAN' },
    { value: 'CHINESE, TAIWANESE', label: 'CHINESE, TAIWANESE' },
    { value: 'CHINESE', label: 'CHINESE' },
    { value: 'CHRISTMAS ISLAND', label: 'CHRISTMAS ISLAND' },
    { value: 'COCOS ISLAND', label: 'COCOS ISLAND' },
    { value: 'COLOMBIAN', label: 'COLOMBIAN' },
    { value: 'COMORAN, COMORIAN', label: 'COMORAN, COMORIAN' },
    { value: 'CONGOLESE', label: 'CONGOLESE' },
    { value: 'COOK ISLAND', label: 'COOK ISLAND' },
    { value: 'COSTA RICAN', label: 'COSTA RICAN' },
    { value: 'CROATIAN', label: 'CROATIAN' },
    { value: 'CUBAN', label: 'CUBAN' },
    { value: 'CURAÇAOAN', label: 'CURAÇAOAN' },
    { value: 'CYPRIOT', label: 'CYPRIOT' },
    { value: 'CZECH', label: 'CZECH' },
    { value: 'DANISH', label: 'DANISH' },
    { value: 'DJIBOUTIAN', label: 'DJIBOUTIAN' },
    { value: 'DOMINICAN', label: 'DOMINICAN' },
    { value: 'DUTCH, NETHERLANDIC', label: 'DUTCH, NETHERLANDIC' },
    { value: 'ECUADORIAN', label: 'ECUADORIAN' },
    { value: 'EGYPTIAN', label: 'EGYPTIAN' },
    { value: 'EMIRATI, EMIRIAN, EMIRI', label: 'EMIRATI, EMIRIAN, EMIRI' },
    { value: 'ENGLISH', label: 'ENGLISH' },
    { value: 'EQUATORIAL GUINEAN, EQUATOGUINEAN', label: 'EQUATORIAL GUINEAN, EQUATOGUINEAN' },
    { value: 'ERITREAN', label: 'ERITREAN' },
    { value: 'ESTONIAN', label: 'ESTONIAN' },
    { value: 'ETHIOPIAN', label: 'ETHIOPIAN' },
    { value: 'FALKLAND ISLAND', label: 'FALKLAND ISLAND' },
    { value: 'FAROESE', label: 'FAROESE' },
    { value: 'FIJIAN', label: 'FIJIAN' },
    { value: 'FINNISH', label: 'FINNISH' },
    { value: 'FRENCH GUIANESE', label: 'FRENCH GUIANESE' },
    { value: 'FRENCH POLYNESIAN', label: 'FRENCH POLYNESIAN' },
    { value: 'FRENCH SOUTHERN TERRITORIES', label: 'FRENCH SOUTHERN TERRITORIES' },
    { value: 'FRENCH', label: 'FRENCH' },
    { value: 'GABONESE', label: 'GABONESE' },
    { value: 'GAMBIAN', label: 'GAMBIAN' },
    { value: 'GEORGIAN', label: 'GEORGIAN' },
    { value: 'GERMAN', label: 'GERMAN' },
    { value: 'GHANAIAN', label: 'GHANAIAN' },
    { value: 'GIBRALTAR', label: 'GIBRALTAR' },
    { value: 'GREEK, HELLENIC', label: 'GREEK, HELLENIC' },
    { value: 'GREENLANDIC', label: 'GREENLANDIC' },
    { value: 'GRENADIAN', label: 'GRENADIAN' },
    { value: 'GUADELOUPE', label: 'GUADELOUPE' },
    { value: 'GUAMANIAN, GUAMBAT', label: 'GUAMANIAN, GUAMBAT' },
    { value: 'GUATEMALAN', label: 'GUATEMALAN' },
    { value: 'GUINEAN', label: 'GUINEAN' },
    { value: 'GUYANESE', label: 'GUYANESE' },
    { value: 'HAITIAN', label: 'HAITIAN' },
    { value: 'HEARD ISLAND OR MCDONALD ISLANDS', label: 'HEARD ISLAND OR MCDONALD ISLANDS' },
    { value: 'HONDURAN', label: 'HONDURAN' },
    { value: 'HONG KONG, HONG KONGESE', label: 'HONG KONG, HONG KONGESE' },
    { value: 'HUNGARIAN, MAGYAR', label: 'HUNGARIAN, MAGYAR' },
    { value: 'I-KIRIBATI', label: 'I-KIRIBATI' },
    { value: 'ICELANDIC', label: 'ICELANDIC' },
    { value: 'INDIAN', label: 'INDIAN' },
    { value: 'INDONESIAN', label: 'INDONESIAN' },
    { value: 'IRANIAN, PERSIAN', label: 'IRANIAN, PERSIAN' },
    { value: 'IRAQI', label: 'IRAQI' },
    { value: 'IRISH', label: 'IRISH' },
    { value: 'ISRAELI', label: 'ISRAELI' },
    { value: 'ITALIAN', label: 'ITALIAN' },
    { value: 'IVORIAN', label: 'IVORIAN' },
    { value: 'JAMAICAN', label: 'JAMAICAN' },
    { value: 'JAPANESE', label: 'JAPANESE' },
    { value: 'JORDANIAN', label: 'JORDANIAN' },
    { value: 'KAZAKHSTANI, KAZAKH', label: 'KAZAKHSTANI, KAZAKH' },
    { value: 'KENYAN', label: 'KENYAN' },
    { value: 'KITTITIAN OR NEVISIAN', label: 'KITTITIAN OR NEVISIAN' },
    { value: 'KUWAITI', label: 'KUWAITI' },
    { value: 'KYRGYZSTANI, KYRGYZ, KIRGIZ, KIRGHIZ', label: 'KYRGYZSTANI, KYRGYZ, KIRGIZ, KIRGHIZ' },
    { value: 'LAO, LAOTIAN', label: 'LAO, LAOTIAN' },
    { value: 'LATVIAN', label: 'LATVIAN' },
    { value: 'LEBANESE', label: 'LEBANESE' },
    { value: 'LIBERIAN', label: 'LIBERIAN' },
    { value: 'LIBYAN', label: 'LIBYAN' },
    { value: 'LIECHTENSTEIN', label: 'LIECHTENSTEIN' },
    { value: 'LITHUANIAN', label: 'LITHUANIAN' },
    { value: 'LUXEMBOURG, LUXEMBOURGISH', label: 'LUXEMBOURG, LUXEMBOURGISH' },
    { value: 'MACANESE, CHINESE', label: 'MACANESE, CHINESE' },
    { value: 'MACEDONIAN', label: 'MACEDONIAN' },
    { value: 'MAHORAN', label: 'MAHORAN' },
    { value: 'MALAGASY', label: 'MALAGASY' },
    { value: 'MALAWIAN', label: 'MALAWIAN' },
    { value: 'MALAYSIAN', label: 'MALAYSIAN' },
    { value: 'MALDIVIAN', label: 'MALDIVIAN' },
    { value: 'MALIAN, MALINESE', label: 'MALIAN, MALINESE' },
    { value: 'MALTESE', label: 'MALTESE' },
    { value: 'MANX', label: 'MANX' },
    { value: 'MARSHALLESE', label: 'MARSHALLESE' },
    { value: 'MARTINIQUAIS, MARTINICAN', label: 'MARTINIQUAIS, MARTINICAN' },
    { value: 'MAURITANIAN', label: 'MAURITANIAN' },
    { value: 'MAURITIAN', label: 'MAURITIAN' },
    { value: 'MEXICAN', label: 'MEXICAN' },
    { value: 'MICRONESIAN', label: 'MICRONESIAN' },
    { value: 'MOLDOVAN', label: 'MOLDOVAN' },
    { value: 'MONÉGASQUE, MONACAN', label: 'MONÉGASQUE, MONACAN' },
    { value: 'MONGOLIAN', label: 'MONGOLIAN' },
    { value: 'MONTENEGRIN', label: 'MONTENEGRIN' },
    { value: 'MONTSERRATIAN', label: 'MONTSERRATIAN' },
    { value: 'MOROCCAN', label: 'MOROCCAN' },
    { value: 'MOTSWANA, BOTSWANAN', label: 'MOTSWANA, BOTSWANAN' },
    { value: 'MOZAMBICAN', label: 'MOZAMBICAN' },
    { value: 'NAMIBIAN', label: 'NAMIBIAN' },
    { value: 'NAURUAN', label: 'NAURUAN' },
    { value: 'NEPALI, NEPALESE', label: 'NEPALI, NEPALESE' },
    { value: 'NEW CALEDONIAN', label: 'NEW CALEDONIAN' },
    { value: 'NEW ZEALAND, NZ', label: 'NEW ZEALAND, NZ' },
    { value: 'NI-VANUATU, VANUATUAN', label: 'NI-VANUATU, VANUATUAN' },
    { value: 'NICARAGUAN', label: 'NICARAGUAN' },
    { value: 'NIGERIAN', label: 'NIGERIAN' },
    { value: 'NIGERIEN', label: 'NIGERIEN' },
    { value: 'NIUEAN', label: 'NIUEAN' },
    { value: 'NORFOLK ISLAND', label: 'NORFOLK ISLAND' },
    { value: 'NORTH KOREAN', label: 'NORTH KOREAN' },
    { value: 'NORTHERN MARIANAN', label: 'NORTHERN MARIANAN' },
    { value: 'NORWEGIAN', label: 'NORWEGIAN' },
    { value: 'OMANI', label: 'OMANI' },
    { value: 'PAKISTANI', label: 'PAKISTANI' },
    { value: 'PALAUAN', label: 'PALAUAN' },
    { value: 'PALESTINIAN', label: 'PALESTINIAN' },
    { value: 'PANAMANIAN', label: 'PANAMANIAN' },
    { value: 'PAPUA NEW GUINEAN, PAPUAN', label: 'PAPUA NEW GUINEAN, PAPUAN' },
    { value: 'PARAGUAYAN', label: 'PARAGUAYAN' },
    { value: 'PERUVIAN', label: 'PERUVIAN' },
    { value: 'PHILIPPINE, FILIPINO', label: 'PHILIPPINE, FILIPINO' },
    { value: 'PITCAIRN ISLAND', label: 'PITCAIRN ISLAND' },
    { value: 'POLISH', label: 'POLISH' },
    { value: 'PORTUGUESE', label: 'PORTUGUESE' },
    { value: 'PUERTO RICAN', label: 'PUERTO RICAN' },
    { value: 'QATARI', label: 'QATARI' },
    { value: 'RÉUNIONESE, RÉUNIONNAIS', label: 'RÉUNIONESE, RÉUNIONNAIS' },
    { value: 'ROMANIAN', label: 'ROMANIAN' },
    { value: 'RUSSIAN', label: 'RUSSIAN' },
    { value: 'RWANDAN', label: 'RWANDAN' },
    { value: 'SAHRAWI, SAHRAWIAN, SAHRAOUIAN', label: 'SAHRAWI, SAHRAWIAN, SAHRAOUIAN' },
    { value: 'SAINT HELENIAN', label: 'SAINT HELENIAN' },
    { value: 'SAINT LUCIAN', label: 'SAINT LUCIAN' },
    { value: 'SAINT VINCENTIAN, VINCENTIAN', label: 'SAINT VINCENTIAN, VINCENTIAN' },
    { value: 'SAINT-MARTINOISE', label: 'SAINT-MARTINOISE' },
    { value: 'SAINT-PIERRAIS OR MIQUELONNAIS', label: 'SAINT-PIERRAIS OR MIQUELONNAIS' },
    { value: 'SALVADORAN', label: 'SALVADORAN' },
    { value: 'SAMMARINESE', label: 'SAMMARINESE' },
    { value: 'SAMOAN', label: 'SAMOAN' },
    { value: 'SÃO TOMÉAN', label: 'SÃO TOMÉAN' },
    { value: 'SAUDI, SAUDI ARABIAN', label: 'SAUDI, SAUDI ARABIAN' },
    { value: 'SCOTTISH', label: 'SCOTTISH' },
    { value: 'SENEGALESE', label: 'SENEGALESE' },
    { value: 'SERBIAN', label: 'SERBIAN' },
    { value: 'SEYCHELLOIS', label: 'SEYCHELLOIS' },
    { value: 'SIERRA LEONEAN', label: 'SIERRA LEONEAN' },
    { value: 'SINGAPOREAN', label: 'SINGAPOREAN' },
    { value: 'SINT MAARTEN', label: 'SINT MAARTEN' },
    { value: 'SLOVAK', label: 'SLOVAK' },
    { value: 'SLOVENIAN, SLOVENE', label: 'SLOVENIAN, SLOVENE' },
    { value: 'SOLOMON ISLAND', label: 'SOLOMON ISLAND' },
    { value: 'SOMALI, SOMALIAN', label: 'SOMALI, SOMALIAN' },
    { value: 'SOUTH AFRICAN', label: 'SOUTH AFRICAN' },
    { value: 'SOUTH GEORGIA OR SOUTH SANDWICH ISLANDS', label: 'SOUTH GEORGIA OR SOUTH SANDWICH ISLANDS' },
    { value: 'SOUTH KOREAN', label: 'SOUTH KOREAN' },
    { value: 'SOUTH SUDANESE', label: 'SOUTH SUDANESE' },
    { value: 'SPANISH', label: 'SPANISH' },
    { value: 'SRI LANKAN', label: 'SRI LANKAN' },
    { value: 'SUDANESE', label: 'SUDANESE' },
    { value: 'SURINAMESE', label: 'SURINAMESE' },
    { value: 'SVALBARD', label: 'SVALBARD' },
    { value: 'SWAZI', label: 'SWAZI' },
    { value: 'SWEDISH', label: 'SWEDISH' },
    { value: 'SWISS', label: 'SWISS' },
    { value: 'SYRIAN', label: 'SYRIAN' },
    { value: 'TAJIKISTANI', label: 'TAJIKISTANI' },
    { value: 'TANZANIAN', label: 'TANZANIAN' },
    { value: 'THAI', label: 'THAI' },
    { value: 'TIMORESE', label: 'TIMORESE' },
    { value: 'TOGOLESE', label: 'TOGOLESE' },
    { value: 'TOKELAUAN', label: 'TOKELAUAN' },
    { value: 'TONGAN', label: 'TONGAN' },
    { value: 'TRINIDADIAN OR TOBAGONIAN', label: 'TRINIDADIAN OR TOBAGONIAN' },
    { value: 'TUNISIAN', label: 'TUNISIAN' },
    { value: 'TURKISH', label: 'TURKISH' },
    { value: 'TURKMEN', label: 'TURKMEN' },
    { value: 'TURKS AND CAICOS ISLAND', label: 'TURKS AND CAICOS ISLAND' },
    { value: 'TUVALUAN', label: 'TUVALUAN' },
    { value: 'U.S. VIRGIN ISLAND', label: 'U.S. VIRGIN ISLAND' },
    { value: 'UGANDAN', label: 'UGANDAN' },
    { value: 'UKRAINIAN', label: 'UKRAINIAN' },
    { value: 'URUGUAYAN', label: 'URUGUAYAN' },
    { value: 'UZBEKISTANI, UZBEK', label: 'UZBEKISTANI, UZBEK' },
    { value: 'VATICAN', label: 'VATICAN' },
    { value: 'VENEZUELAN', label: 'VENEZUELAN' },
    { value: 'VIETNAMESE', label: 'VIETNAMESE' },
    { value: 'WALLIS AND FUTUNA, WALLISIAN OR FUTUNAN', label: 'WALLIS AND FUTUNA, WALLISIAN OR FUTUNAN' },
    { value: 'WELSH', label: 'WELSH' },
    { value: 'YEMENI', label: 'YEMENI' },
    { value: 'ZAMBIAN', label: 'ZAMBIAN' },
    { value: 'ZIMBABWEAN', label: 'ZIMBABWEAN' },
  ],
  countries: [
    { value: 'AF', label: 'AFGHANISTAN', nationality: 'AFGHAN' },
    { value: 'AX', label: 'ÅLAND ISLANDS', nationality: 'ÅLAND ISLAND' },
    { value: 'AL', label: 'ALBANIA', nationality: 'ALBANIAN' },
    { value: 'DZ', label: 'ALGERIA', nationality: 'ALGERIAN' },
    { value: 'AS', label: 'AMERICAN SAMOA', nationality: 'AMERICAN SAMOAN' },
    { value: 'AD', label: 'ANDORRA', nationality: 'ANDORRAN' },
    { value: 'AO', label: 'ANGOLA', nationality: 'ANGOLAN' },
    { value: 'AI', label: 'ANGUILLA', nationality: 'ANGUILLAN' },
    { value: 'AQ', label: 'ANTARCTICA', nationality: 'ANTARCTIC' },
    { value: 'AG', label: 'ANTIGUA AND BARBUDA', nationality: 'ANTIGUAN OR BARBUDAN' },
    { value: 'AR', label: 'ARGENTINA', nationality: 'ARGENTINE' },
    { value: 'AM', label: 'ARMENIA', nationality: 'ARMENIAN' },
    { value: 'AW', label: 'ARUBA', nationality: 'ARUBAN' },
    { value: 'AU', label: 'AUSTRALIA', nationality: 'AUSTRALIAN' },
    { value: 'AT', label: 'AUSTRIA', nationality: 'AUSTRIAN' },
    { value: 'AZ', label: 'AZERBAIJAN', nationality: 'AZERBAIJANI, AZERI' },
    { value: 'BS', label: 'BAHAMAS', nationality: 'BAHAMIAN' },
    { value: 'BH', label: 'BAHRAIN', nationality: 'BAHRAINI' },
    { value: 'BD', label: 'BANGLADESH', nationality: 'BANGLADESHI' },
    { value: 'BB', label: 'BARBADOS', nationality: 'BARBADIAN' },
    { value: 'BY', label: 'BELARUS', nationality: 'BELARUSIAN' },
    { value: 'BE', label: 'BELGIUM', nationality: 'BELGIAN' },
    { value: 'BZ', label: 'BELIZE', nationality: 'BELIZEAN' },
    { value: 'BJ', label: 'BENIN', nationality: 'BENINESE, BENINOIS' },
    { value: 'BM', label: 'BERMUDA', nationality: 'BERMUDIAN, BERMUDAN' },
    { value: 'BT', label: 'BHUTAN', nationality: 'BHUTANESE' },
    { value: 'BO', label: 'BOLIVIA (PLURINATIONAL STATE OF)', nationality: 'BOLIVIAN' },
    { value: 'BQ', label: 'BONAIRE, SINT EUSTATIUS AND SABA', nationality: 'BONAIRE' },
    { value: 'BA', label: 'BOSNIA AND HERZEGOVINA', nationality: 'BOSNIAN OR HERZEGOVINIAN' },
    { value: 'BW', label: 'BOTSWANA', nationality: 'MOTSWANA, BOTSWANAN' },
    { value: 'BV', label: 'BOUVET ISLAND', nationality: 'BOUVET ISLAND' },
    { value: 'BR', label: 'BRAZIL', nationality: 'BRAZILIAN' },
    { value: 'IO', label: 'BRITISH INDIAN OCEAN TERRITORY', nationality: 'BIOT' },
    { value: 'BN', label: 'BRUNEI DARUSSALAM', nationality: 'BRUNEIAN' },
    { value: 'BG', label: 'BULGARIA', nationality: 'BULGARIAN' },
    { value: 'BF', label: 'BURKINA FASO', nationality: 'BURKINABÉ' },
    { value: 'BI', label: 'BURUNDI', nationality: 'BURUNDIAN' },
    { value: 'CV', label: 'CABO VERDE', nationality: 'CABO VERDEAN' },
    { value: 'KH', label: 'CAMBODIA', nationality: 'CAMBODIAN' },
    { value: 'CM', label: 'CAMEROON', nationality: 'CAMEROONIAN' },
    { value: 'CA', label: 'CANADA', nationality: 'CANADIAN' },
    { value: 'KY', label: 'CAYMAN ISLANDS', nationality: 'CAYMANIAN' },
    { value: 'CF', label: 'CENTRAL AFRICAN REPUBLIC', nationality: 'CENTRAL AFRICAN' },
    { value: 'TD', label: 'CHAD', nationality: 'CHADIAN' },
    { value: 'CL', label: 'CHILE', nationality: 'CHILEAN' },
    { value: 'CN', label: 'CHINA', nationality: 'CHINESE' },
    { value: 'CX', label: 'CHRISTMAS ISLAND', nationality: 'CHRISTMAS ISLAND' },
    { value: 'CC', label: 'COCOS (KEELING) ISLANDS', nationality: 'COCOS ISLAND' },
    { value: 'CO', label: 'COLOMBIA', nationality: 'COLOMBIAN' },
    { value: 'KM', label: 'COMOROS', nationality: 'COMORAN, COMORIAN' },
    { value: 'CG', label: 'CONGO (REPUBLIC OF THE)', nationality: 'CONGOLESE' },
    { value: 'CD', label: 'CONGO (DEMOCRATIC REPUBLIC OF THE)', nationality: 'CONGOLESE' },
    { value: 'CK', label: 'COOK ISLANDS', nationality: 'COOK ISLAND' },
    { value: 'CR', label: 'COSTA RICA', nationality: 'COSTA RICAN' },
    { value: 'CI', label: 'CÔTE D’IVOIRE', nationality: 'IVORIAN' },
    { value: 'HR', label: 'CROATIA', nationality: 'CROATIAN' },
    { value: 'CU', label: 'CUBA', nationality: 'CUBAN' },
    { value: 'CW', label: 'CURAÇAO', nationality: 'CURAÇAOAN' },
    { value: 'CY', label: 'CYPRUS', nationality: 'CYPRIOT' },
    { value: 'CZ', label: 'CZECH REPUBLIC', nationality: 'CZECH' },
    { value: 'DK', label: 'DENMARK', nationality: 'DANISH' },
    { value: 'DJ', label: 'DJIBOUTI', nationality: 'DJIBOUTIAN' },
    { value: 'DM', label: 'DOMINICA', nationality: 'DOMINICAN' },
    { value: 'DO', label: 'DOMINICAN REPUBLIC', nationality: 'DOMINICAN' },
    { value: 'EC', label: 'ECUADOR', nationality: 'ECUADORIAN' },
    { value: 'EG', label: 'EGYPT', nationality: 'EGYPTIAN' },
    { value: 'SV', label: 'EL SALVADOR', nationality: 'SALVADORAN' },
    { value: 'GQ', label: 'EQUATORIAL GUINEA', nationality: 'EQUATORIAL GUINEAN, EQUATOGUINEAN' },
    { value: 'ER', label: 'ERITREA', nationality: 'ERITREAN' },
    { value: 'EE', label: 'ESTONIA', nationality: 'ESTONIAN' },
    { value: 'ET', label: 'ETHIOPIA', nationality: 'ETHIOPIAN' },
    { value: 'FK', label: 'FALKLAND ISLANDS (MALVINAS)', nationality: 'FALKLAND ISLAND' },
    { value: 'FO', label: 'FAROE ISLANDS', nationality: 'FAROESE' },
    { value: 'FJ', label: 'FIJI', nationality: 'FIJIAN' },
    { value: 'FI', label: 'FINLAND', nationality: 'FINNISH' },
    { value: 'FR', label: 'FRANCE', nationality: 'FRENCH' },
    { value: 'GF', label: 'FRENCH GUIANA', nationality: 'FRENCH GUIANESE' },
    { value: 'PF', label: 'FRENCH POLYNESIA', nationality: 'FRENCH POLYNESIAN' },
    { value: 'TF', label: 'FRENCH SOUTHERN TERRITORIES', nationality: 'FRENCH SOUTHERN TERRITORIES' },
    { value: 'GA', label: 'GABON', nationality: 'GABONESE' },
    { value: 'GM', label: 'GAMBIA', nationality: 'GAMBIAN' },
    { value: 'GE', label: 'GEORGIA', nationality: 'GEORGIAN' },
    { value: 'DE', label: 'GERMANY', nationality: 'GERMAN' },
    { value: 'GH', label: 'GHANA', nationality: 'GHANAIAN' },
    { value: 'GI', label: 'GIBRALTAR', nationality: 'GIBRALTAR' },
    { value: 'GR', label: 'GREECE', nationality: 'GREEK, HELLENIC' },
    { value: 'GL', label: 'GREENLAND', nationality: 'GREENLANDIC' },
    { value: 'GD', label: 'GRENADA', nationality: 'GRENADIAN' },
    { value: 'GP', label: 'GUADELOUPE', nationality: 'GUADELOUPE' },
    { value: 'GU', label: 'GUAM', nationality: 'GUAMANIAN, GUAMBAT' },
    { value: 'GT', label: 'GUATEMALA', nationality: 'GUATEMALAN' },
    { value: 'GG', label: 'GUERNSEY', nationality: 'CHANNEL ISLAND' },
    { value: 'GN', label: 'GUINEA', nationality: 'GUINEAN' },
    { value: 'GW', label: 'GUINEA-BISSAU', nationality: 'BISSAU-GUINEAN' },
    { value: 'GY', label: 'GUYANA', nationality: 'GUYANESE' },
    { value: 'HT', label: 'HAITI', nationality: 'HAITIAN' },
    { value: 'HM', label: 'HEARD ISLAND AND MCDONALD ISLANDS', nationality: 'HEARD ISLAND OR MCDONALD ISLANDS' },
    { value: 'VA', label: 'VATICAN CITY STATE', nationality: 'VATICAN' },
    { value: 'HN', label: 'HONDURAS', nationality: 'HONDURAN' },
    { value: 'HK', label: 'HONG KONG', nationality: 'HONG KONG, HONG KONGESE' },
    { value: 'HU', label: 'HUNGARY', nationality: 'HUNGARIAN, MAGYAR' },
    { value: 'IS', label: 'ICELAND', nationality: 'ICELANDIC' },
    { value: 'IN', label: 'INDIA', nationality: 'INDIAN' },
    { value: 'ID', label: 'INDONESIA', nationality: 'INDONESIAN' },
    { value: 'IR', label: 'IRAN', nationality: 'IRANIAN, PERSIAN' },
    { value: 'IQ', label: 'IRAQ', nationality: 'IRAQI' },
    { value: 'IE', label: 'IRELAND', nationality: 'IRISH' },
    { value: 'IM', label: 'ISLE OF MAN', nationality: 'MANX' },
    { value: 'IL', label: 'ISRAEL', nationality: 'ISRAELI' },
    { value: 'IT', label: 'ITALY', nationality: 'ITALIAN' },
    { value: 'JM', label: 'JAMAICA', nationality: 'JAMAICAN' },
    { value: 'JP', label: 'JAPAN', nationality: 'JAPANESE' },
    { value: 'JE', label: 'JERSEY', nationality: 'CHANNEL ISLAND' },
    { value: 'JO', label: 'JORDAN', nationality: 'JORDANIAN' },
    { value: 'KZ', label: 'KAZAKHSTAN', nationality: 'KAZAKHSTANI, KAZAKH' },
    { value: 'KE', label: 'KENYA', nationality: 'KENYAN' },
    { value: 'KI', label: 'KIRIBATI', nationality: 'I-KIRIBATI' },
    { value: 'KP', label: 'KOREA (DEMOCRATIC PEOPLE’S REPUBLIC OF)', nationality: 'NORTH KOREAN' },
    { value: 'KR', label: 'KOREA (REPUBLIC OF)', nationality: 'SOUTH KOREAN' },
    { value: 'KW', label: 'KUWAIT', nationality: 'KUWAITI' },
    { value: 'KG', label: 'KYRGYZSTAN', nationality: 'KYRGYZSTANI, KYRGYZ, KIRGIZ, KIRGHIZ' },
    { value: 'LA', label: 'LAO PEOPLE’S DEMOCRATIC REPUBLIC', nationality: 'LAO, LAOTIAN' },
    { value: 'LV', label: 'LATVIA', nationality: 'LATVIAN' },
    { value: 'LB', label: 'LEBANON', nationality: 'LEBANESE' },
    { value: 'LS', label: 'LESOTHO', nationality: 'BASOTHO' },
    { value: 'LR', label: 'LIBERIA', nationality: 'LIBERIAN' },
    { value: 'LY', label: 'LIBYA', nationality: 'LIBYAN' },
    { value: 'LI', label: 'LIECHTENSTEIN', nationality: 'LIECHTENSTEIN' },
    { value: 'LT', label: 'LITHUANIA', nationality: 'LITHUANIAN' },
    { value: 'LU', label: 'LUXEMBOURG', nationality: 'LUXEMBOURG, LUXEMBOURGISH' },
    { value: 'MO', label: 'MACAO', nationality: 'MACANESE, CHINESE' },
    { value: 'MK', label: 'MACEDONIA (THE FORMER YUGOSLAV REPUBLIC OF)', nationality: 'MACEDONIAN' },
    { value: 'MG', label: 'MADAGASCAR', nationality: 'MALAGASY' },
    { value: 'MW', label: 'MALAWI', nationality: 'MALAWIAN' },
    { value: 'MY', label: 'MALAYSIA', nationality: 'MALAYSIAN' },
    { value: 'MV', label: 'MALDIVES', nationality: 'MALDIVIAN' },
    { value: 'ML', label: 'MALI', nationality: 'MALIAN, MALINESE' },
    { value: 'MT', label: 'MALTA', nationality: 'MALTESE' },
    { value: 'MH', label: 'MARSHALL ISLANDS', nationality: 'MARSHALLESE' },
    { value: 'MQ', label: 'MARTINIQUE', nationality: 'MARTINIQUAIS, MARTINICAN' },
    { value: 'MR', label: 'MAURITANIA', nationality: 'MAURITANIAN' },
    { value: 'MU', label: 'MAURITIUS', nationality: 'MAURITIAN' },
    { value: 'YT', label: 'MAYOTTE', nationality: 'MAHORAN' },
    { value: 'MX', label: 'MEXICO', nationality: 'MEXICAN' },
    { value: 'FM', label: 'MICRONESIA (FEDERATED STATES OF)', nationality: 'MICRONESIAN' },
    { value: 'MD', label: 'MOLDOVA (REPUBLIC OF)', nationality: 'MOLDOVAN' },
    { value: 'MC', label: 'MONACO', nationality: 'MONÉGASQUE, MONACAN' },
    { value: 'MN', label: 'MONGOLIA', nationality: 'MONGOLIAN' },
    { value: 'ME', label: 'MONTENEGRO', nationality: 'MONTENEGRIN' },
    { value: 'MS', label: 'MONTSERRAT', nationality: 'MONTSERRATIAN' },
    { value: 'MA', label: 'MOROCCO', nationality: 'MOROCCAN' },
    { value: 'MZ', label: 'MOZAMBIQUE', nationality: 'MOZAMBICAN' },
    { value: 'MM', label: 'MYANMAR', nationality: 'BURMESE' },
    { value: 'NA', label: 'NAMIBIA', nationality: 'NAMIBIAN' },
    { value: 'NR', label: 'NAURU', nationality: 'NAURUAN' },
    { value: 'NP', label: 'NEPAL', nationality: 'NEPALI, NEPALESE' },
    { value: 'NL', label: 'NETHERLANDS', nationality: 'DUTCH, NETHERLANDIC' },
    { value: 'NC', label: 'NEW CALEDONIA', nationality: 'NEW CALEDONIAN' },
    { value: 'NZ', label: 'NEW ZEALAND', nationality: 'NEW ZEALAND, NZ' },
    { value: 'NI', label: 'NICARAGUA', nationality: 'NICARAGUAN' },
    { value: 'NE', label: 'NIGER', nationality: 'NIGERIEN' },
    { value: 'NG', label: 'NIGERIA', nationality: 'NIGERIAN' },
    { value: 'NU', label: 'NIUE', nationality: 'NIUEAN' },
    { value: 'NF', label: 'NORFOLK ISLAND', nationality: 'NORFOLK ISLAND' },
    { value: 'MP', label: 'NORTHERN MARIANA ISLANDS', nationality: 'NORTHERN MARIANAN' },
    { value: 'NO', label: 'NORWAY', nationality: 'NORWEGIAN' },
    { value: 'OM', label: 'OMAN', nationality: 'OMANI' },
    { value: 'PK', label: 'PAKISTAN', nationality: 'PAKISTANI' },
    { value: 'PW', label: 'PALAU', nationality: 'PALAUAN' },
    { value: 'PS', label: 'PALESTINE, STATE OF', nationality: 'PALESTINIAN' },
    { value: 'PA', label: 'PANAMA', nationality: 'PANAMANIAN' },
    { value: 'PG', label: 'PAPUA NEW GUINEA', nationality: 'PAPUA NEW GUINEAN, PAPUAN' },
    { value: 'PY', label: 'PARAGUAY', nationality: 'PARAGUAYAN' },
    { value: 'PE', label: 'PERU', nationality: 'PERUVIAN' },
    { value: 'PH', label: 'PHILIPPINES', nationality: 'PHILIPPINE, FILIPINO' },
    { value: 'PN', label: 'PITCAIRN', nationality: 'PITCAIRN ISLAND' },
    { value: 'PL', label: 'POLAND', nationality: 'POLISH' },
    { value: 'PT', label: 'PORTUGAL', nationality: 'PORTUGUESE' },
    { value: 'PR', label: 'PUERTO RICO', nationality: 'PUERTO RICAN' },
    { value: 'QA', label: 'QATAR', nationality: 'QATARI' },
    { value: 'RE', label: 'RÉUNION', nationality: 'RÉUNIONESE, RÉUNIONNAIS' },
    { value: 'RO', label: 'ROMANIA', nationality: 'ROMANIAN' },
    { value: 'RU', label: 'RUSSIAN FEDERATION', nationality: 'RUSSIAN' },
    { value: 'RW', label: 'RWANDA', nationality: 'RWANDAN' },
    { value: 'BL', label: 'SAINT BARTHÉLEMY', nationality: 'BARTHÉLEMOIS' },
    { value: 'SH', label: 'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA', nationality: 'SAINT HELENIAN' },
    { value: 'KN', label: 'SAINT KITTS AND NEVIS', nationality: 'KITTITIAN OR NEVISIAN' },
    { value: 'LC', label: 'SAINT LUCIA', nationality: 'SAINT LUCIAN' },
    { value: 'MF', label: 'SAINT MARTIN (FRENCH PART)', nationality: 'SAINT-MARTINOISE' },
    { value: 'PM', label: 'SAINT PIERRE AND MIQUELON', nationality: 'SAINT-PIERRAIS OR MIQUELONNAIS' },
    { value: 'VC', label: 'SAINT VINCENT AND THE GRENADINES', nationality: 'SAINT VINCENTIAN, VINCENTIAN' },
    { value: 'WS', label: 'SAMOA', nationality: 'SAMOAN' },
    { value: 'SM', label: 'SAN MARINO', nationality: 'SAMMARINESE' },
    { value: 'ST', label: 'SAO TOME AND PRINCIPE', nationality: 'SÃO TOMÉAN' },
    { value: 'SA', label: 'SAUDI ARABIA', nationality: 'SAUDI, SAUDI ARABIAN' },
    { value: 'SN', label: 'SENEGAL', nationality: 'SENEGALESE' },
    { value: 'RS', label: 'SERBIA', nationality: 'SERBIAN' },
    { value: 'SC', label: 'SEYCHELLES', nationality: 'SEYCHELLOIS' },
    { value: 'SL', label: 'SIERRA LEONE', nationality: 'SIERRA LEONEAN' },
    { value: 'SG', label: 'SINGAPORE', nationality: 'SINGAPOREAN' },
    { value: 'SX', label: 'SINT MAARTEN (DUTCH PART)', nationality: 'SINT MAARTEN' },
    { value: 'SK', label: 'SLOVAKIA', nationality: 'SLOVAK' },
    { value: 'SI', label: 'SLOVENIA', nationality: 'SLOVENIAN, SLOVENE' },
    { value: 'SB', label: 'SOLOMON ISLANDS', nationality: 'SOLOMON ISLAND' },
    { value: 'SO', label: 'SOMALIA', nationality: 'SOMALI, SOMALIAN' },
    { value: 'ZA', label: 'SOUTH AFRICA', nationality: 'SOUTH AFRICAN' },
    { value: 'GS', label: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS', nationality: 'SOUTH GEORGIA OR SOUTH SANDWICH ISLANDS' },
    { value: 'SS', label: 'SOUTH SUDAN', nationality: 'SOUTH SUDANESE' },
    { value: 'ES', label: 'SPAIN', nationality: 'SPANISH' },
    { value: 'LK', label: 'SRI LANKA', nationality: 'SRI LANKAN' },
    { value: 'SD', label: 'SUDAN', nationality: 'SUDANESE' },
    { value: 'SR', label: 'SURINAME', nationality: 'SURINAMESE' },
    { value: 'SJ', label: 'SVALBARD AND JAN MAYEN', nationality: 'SVALBARD' },
    { value: 'SZ', label: 'SWAZILAND', nationality: 'SWAZI' },
    { value: 'SE', label: 'SWEDEN', nationality: 'SWEDISH' },
    { value: 'CH', label: 'SWITZERLAND', nationality: 'SWISS' },
    { value: 'SY', label: 'SYRIAN ARAB REPUBLIC', nationality: 'SYRIAN' },
    { value: 'TW', label: 'TAIWAN, PROVINCE OF CHINA', nationality: 'CHINESE, TAIWANESE' },
    { value: 'TJ', label: 'TAJIKISTAN', nationality: 'TAJIKISTANI' },
    { value: 'TZ', label: 'TANZANIA, UNITED REPUBLIC OF', nationality: 'TANZANIAN' },
    { value: 'TH', label: 'THAILAND', nationality: 'THAI' },
    { value: 'TL', label: 'TIMOR-LESTE', nationality: 'TIMORESE' },
    { value: 'TG', label: 'TOGO', nationality: 'TOGOLESE' },
    { value: 'TK', label: 'TOKELAU', nationality: 'TOKELAUAN' },
    { value: 'TO', label: 'TONGA', nationality: 'TONGAN' },
    { value: 'TT', label: 'TRINIDAD AND TOBAGO', nationality: 'TRINIDADIAN OR TOBAGONIAN' },
    { value: 'TN', label: 'TUNISIA', nationality: 'TUNISIAN' },
    { value: 'TR', label: 'TURKEY', nationality: 'TURKISH' },
    { value: 'TM', label: 'TURKMENISTAN', nationality: 'TURKMEN' },
    { value: 'TC', label: 'TURKS AND CAICOS ISLANDS', nationality: 'TURKS AND CAICOS ISLAND' },
    { value: 'TV', label: 'TUVALU', nationality: 'TUVALUAN' },
    { value: 'UG', label: 'UGANDA', nationality: 'UGANDAN' },
    { value: 'UA', label: 'UKRAINE', nationality: 'UKRAINIAN' },
    { value: 'AE', label: 'UNITED ARAB EMIRATES', nationality: 'EMIRATI, EMIRIAN, EMIRI' },
    { value: 'GB', label: 'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND', nationality: 'BRITISH, UK' },
    { value: 'UM', label: 'UNITED STATES MINOR OUTLYING ISLANDS', nationality: 'AMERICAN' },
    { value: 'US', label: 'UNITED STATES OF AMERICA', nationality: 'AMERICAN' },
    { value: 'UY', label: 'URUGUAY', nationality: 'URUGUAYAN' },
    { value: 'UZ', label: 'UZBEKISTAN', nationality: 'UZBEKISTANI, UZBEK' },
    { value: 'VU', label: 'VANUATU', nationality: 'NI-VANUATU, VANUATUAN' },
    { value: 'VE', label: 'VENEZUELA (BOLIVARIAN REPUBLIC OF)', nationality: 'VENEZUELAN' },
    { value: 'VN', label: 'VIETNAM', nationality: 'VIETNAMESE' },
    { value: 'VG', label: 'VIRGIN ISLANDS (BRITISH)', nationality: 'BRITISH VIRGIN ISLAND' },
    { value: 'VI', label: 'VIRGIN ISLANDS (U.S.)', nationality: 'U.S. VIRGIN ISLAND' },
    { value: 'WF', label: 'WALLIS AND FUTUNA', nationality: 'WALLIS AND FUTUNA, WALLISIAN OR FUTUNAN' },
    { value: 'EH', label: 'WESTERN SAHARA', nationality: 'SAHRAWI, SAHRAWIAN, SAHRAOUIAN' },
    { value: 'YE', label: 'YEMEN', nationality: 'YEMENI' },
    { value: 'ZM', label: 'ZAMBIA', nationality: 'ZAMBIAN' },
    { value: 'ZW', label: 'ZIMBABWE', nationality: 'ZIMBABWEAN' },
  ],
  states: [
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    { label: 'American Samoa', value: 'AS' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'District Of Columbia', value: 'DC' },
    { label: 'Federated States Of Micronesia', value: 'FM' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Guam', value: 'GU' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Marshall Islands', value: 'MH' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Northern Mariana Islands', value: 'MP' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Palau', value: 'PW' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virgin Islands', value: 'VI' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' }
  ]
});
/* eslint-enable */

model.getCountryByCode = (code) => model
  .get('countries')
  .find(({ value }) => value === code);

model.getStateByShortName = (stateAbbr) => model
  .get('states')
  .find((stateObj) => stateObj.value === stateAbbr);

export default model;
