/* eslint-disable max-len */
import React, { Fragment } from 'react';
import { Link } from 'react-router';
import BasePage from 'pages/BasePage';
import { asset } from 'sf/helpers';
import { ROUTES } from 'constants';
import Banner from 'components/Banner';
import Card from 'sf/components/Card';
import VideoAndContent from 'components/VideoAndContent';
import HowItWorks from 'components/HowItWorks';
import {
  BIOMFA,
  BIOMFA_TITLE,
  HOWITWORKS_TITLE,
  HOWITWORKS,
  VIDEO_CONTENT,
  BANNER_CONTENT
} from './constant';
import help from 'models/help';

export default class StableIT2 extends BasePage {
  className = 'ts-BiometricMFA';
  static title = 'Biometric Multi-Factor Authentication';
  static description = 'By adding an extra layer of biometric binding, Stable IT2 sets a new standard for identity authentication, aligning with and surpassing FIDO2 standards.';

  componentDidMount() {
    help.set({ showDemoButton: true });
  }

  componentWillUnmount() {
    help.set({ showDemoButton: false });
    super.componentWillUnmount();
  }

  renderBenefitsSection = () => (
    <section className={ this.cn`__banner` }>
      <div className={ this.cn`__container __banner-container` }>
        <div className={ this.cn`__banner-card-container` }>
          <Card
            className={ this.cn`__banner-card` }
            key="benefits"
          >
            <h2>Streamlined Security</h2>
          </Card>

          { BANNER_CONTENT.map(({ id, title, slug, list }) => (
            <Card
              className={ this.cn`__banner-card` }
              key={ id }
            >
              <h4><img src={ asset`ai/${slug}.svg` } alt={ title } width="15px" /> { title } </h4>
              <ul>
                { list.map((item) => (
                  <li key={ item.substr(1, 3) }>{ item }</li>
                )) }
              </ul>
            </Card>
          )) }
        </div>
      </div>
    </section>
  );

  render() {
    return (
      <div className={ this.rootcn() }>
        { /* banner */ }
        <Banner theme="mfa" size="small">
          <h1>Biometric Multi-Factor <br /> Authentication</h1>
        </Banner>

        <VideoAndContent content={ VIDEO_CONTENT } />

        <HowItWorks
          content={ BIOMFA }
          sectionTitle={ BIOMFA_TITLE }
          columnCount={ 3 }
          subTitle={ (<Fragment>WHY TRUST STAMP BIOMETRIC MFA <sup>TM</sup></Fragment>) }
        />

        { this.renderBenefitsSection() }

        <HowItWorks
          content={ HOWITWORKS }
          sectionTitle={ HOWITWORKS_TITLE }
          columnCount={ 3 }
          subTitle={ (<Fragment>WHY TRUST STAMP BIOMETRIC MFA <sup>TM</sup></Fragment>) }
        />

        <section>
          <div className="ts-container">
            <h6>GET IN TOUCH</h6>
            <h2>Get started with Trust Stamp's Biometric MFA<sup>TM</sup></h2>
            Our sales and solutions team is here to help. Whether you have
            questions, need a demo, or want to discuss customized solutions for
            your organization, we’re just a click away.
            <br />
            <br />
            <Link to={ ROUTES.CONTACT_US } className="ts-button--primary">
              CONTACT US
            </Link>
          </div>
        </section>
      </div>
    );
  }
}
