import { combineReducers } from 'redux';
import marketplaceHeroesIndex
  from 'pages/MarketplaceHeroesIndex/MarketplaceHeroesIndex.reducer';
import profile from 'pages/Profile/Profile.reducer';
import userSettings from 'pages/UserSettings/UserSettings.reducer';
import userPanel from 'components/UserPanel/UserPanel.reducer';
import resetPassword from 'components/ResetPassword/ResetPassword.reducer';

const reducersMap = {
  marketplaceHeroesIndex,
  profile,
  resetPassword,
  userPanel,
  userSettings,
};

const rootReducer = combineReducers(reducersMap);

export { reducersMap };
export default rootReducer;
