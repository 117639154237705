export const RESULTS_CARDS = [
  /* eslint-disable max-len */
  {
    title: 'Detect Identity Fraud',
    slug: 'icon-detect',
    description: 'Our AI identified three organized fraud rings for one bank client in 2019! In another sample of 7,915 applicants, we identified 63 unique fraudsters and referred 33 more for investigation',
  },
  {
    title: 'Recover Previously Lost Customers',
    slug: 'icon-83',
    description: 'Existing customers whose accounts were closed for failing legacy authentication methods - overall 83% of accounts reopened',
  },
  {
    title: 'Improve Registration Pass Rate',
    slug: 'icon-81',
    description: 'New account applicants previously declined for identity authentication - overall 81% of accounts reopened',
  },
  /* eslint-enable */
];

export const BANNER_CONTENT = [
  {
    id: 1,
    title: 'for the bank',
    slug: 'tick-green',
    list: [
      'Reduced losses to fraud',
      'Improved sales with faster onboarding',
      'Increased customer retention',
      'Increased efficiency / reduced need for manual verification',
      'Greater card spend',
    ],
  },
  {
    id: 2,
    title: 'for their customers',
    slug: 'tick-green',
    list: [
      'A frictionless, user-friendly verification experience',
      'Authentication completed in minutes',
      'Improved account security and personal data privac­y',
    ],
  },
];
