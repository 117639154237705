import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Render from 'components/Render';
import help from 'models/help';
import Icon from 'sf/components/Icon';

export default class HelpCenterNotification extends BaseComponent {
  className = 'ts-HelpCenterNotification';

  static propsTypes = {
    iconSize: PropTypes.number,
    label: PropTypes.string,
  };

  static defaultProps = {
    iconSize: 17,
    label: 'Help Center',
  };

  state = {
    notifications: [],
  };

  componentDidMount() {
    this.syncStateWithModel(help, ['notifications']);
  }

  checkNotificationTypes = () => ({
    hasSuccess: this.state.notifications.filter(({ type }) => type === 'success').length > 0,
    hasErrors: this.state.notifications.filter(({ type }) => type === 'error').length > 0,
    hasInfos: this.state.notifications.filter(({ type }) => type === 'info').length > 0,
    hasWarnings: this.state.notifications.filter(({ type }) => type === 'warning').length > 0,
  });

  render() {
    const { hasSuccess, hasErrors, hasWarnings, hasInfos } = this.checkNotificationTypes();
    const classNames = {
      '--has-notifications':
        hasErrors ||
        hasInfos ||
        hasWarnings ||
        hasSuccess,
    };
    return (
      <span className={ this.rootcn(classNames) }>
        { this.props.label }
        <span className={ this.cn`__icon` }>
          <Icon set="io" size={ this.props.iconSize } type="ios-bell" />
        </span>
        <Render when={ hasSuccess }>
          <span className={ this.cn`__dot __dot--success` } />
        </Render>
        <Render when={ hasErrors }>
          <span className={ this.cn`__dot __dot--error` } />
        </Render>
        <Render when={ hasWarnings }>
          <span className={ this.cn`__dot __dot--warning` } />
        </Render>
        <Render when={ hasInfos }>
          <span className={ this.cn`__dot __dot--info` } />
        </Render>
      </span>
    );
  }
}
