/* global device */
import React from 'react';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';

/**
 * This component opens given link in WebView or system browser.
 *
 * example:
 * <Link to="https://truststamp.net">truststamp.net</Link>
 *
 * read more:
 * https://wiki.apache.org/cordova/InAppBrowser
 */

export default class Link extends BaseComponent {
  className = 'ts-Link';

  static propTypes = {
    children: PropTypes.node,
    to: PropTypes.string,
    target: PropTypes.string,
  };

  static defaultProps = {
    children: '',
    target: '_self',
  };

  openExternally(e) {
    if (device.platform.toUpperCase() === 'ANDROID') {
      navigator.app.loadUrl(this.props.to, { openExternal: true });
      e.preventDefault();
    } else if (device.platform.toUpperCase() === 'IOS') {
      window.open(this.props.to, '_system');
      e.preventDefault();
    }
    // else: Leave standard behaviour
  }

  openInternally(e) {
    this.navigate(this.props.to);
    e.preventDefault();
  }

  handleClick = (e) => {
    if (this.props.target === '_self') {
      this.openInternally(e);
    } else {
      this.openExternally(e);
    }
  }

  render() {
    return (
      <a
        { ...omit(this.props, 'to') }
        href={ this.props.to }
        onClick={ this.handleClick }
        className={ this.rootcn() }
      >
        { this.props.children }
      </a>
    );
  }
}
