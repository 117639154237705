import React from 'react';
import { COPY_SECURED_EMAIL } from 'apps/ai/pages/CorporateIndustries/copy';
import BasePage from 'pages/BasePage';
import HeroComponent from '../CorporateComponents/CorporateIndustriesHero';
import CorporateSectionComponent from '../CorporateComponents/CorporateSectionComponent';

export default class CorporateSecuredEmail extends BasePage {
  className = 'ts-CorporateIndustries';

  render() {
    return (
      <div className={ this.rootcn() }>
        <HeroComponent title="Biometric Secured Email" extraClass="email" />
        <CorporateSectionComponent sectionClass="no-padding-top" data={ COPY_SECURED_EMAIL } />
      </div>
    );
  }
}
