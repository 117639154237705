import React from 'react';
import { findDOMNode, hydrate } from 'react-dom';
import PropTypes from 'prop-types';
import template from 'lodash/template';
import mapValues from 'lodash/mapValues';
import { sentryLog } from 'sf/helpers';

export const isValidElement = (value) => {
  return React.isValidElement(value) || Array.isArray(value);
};

/**
 * renderTemplate is the same as lodash/template but returns react element
 * instead of string (if needed). Additionally parameters passed to renderTemplate
 * also can be React Element.
 *
 * NOTE: All HTML tags are changed into html!
 * This is potential security problem, and you should never pass unsafe
 * strings here.
 *
 * @param  {String} templateString
 * @return {ReactElement}
 */
class ReactTemplateResult extends React.PureComponent {
  static propTypes = {
    reactElementParams: PropTypes.object,
    templateResult: PropTypes.string,
  };

  componentDidMount() {
    const reactElementParamsArr = Object.entries(this.props.reactElementParams);
    if (reactElementParamsArr.length) {
      const container = findDOMNode(this);
      reactElementParamsArr.forEach(([param, value]) => {
        hydrate(value, container.querySelector(`[template-id="${param}"]`));
      });
    }
  }

  render() {
    // eslint-disable-next-line react/no-danger
    return <span dangerouslySetInnerHTML={ { __html: this.props.templateResult } } />;
  }
}

/**
 * Sanitize HTML elements in the template string
 *
 * @param   {[String]}  html  Text that contains html elements
 *
 * @return  {[String]}        Sanitized text with html elements
 */
export const sanitizeHTMLElement = (html) => {
  if (!html) return '';
  if (typeof html !== 'string') throw Error('Invalid template string value');

  const div = document.createElement('div');
  div.innerHTML = html;
  return div.innerHTML?.replaceAll(/&lt;/g, '<').replaceAll(/&gt;/g, '>').replaceAll(/&amp;/g, '&');
}

/**
 * Removes <script>, <style> and html elements with event handler - OL-2342
 *
 * @param   {[string]}  str  Text to sanitize
 *
 * @return  {[string]}       Sanitized text string
 */
export const sanitizeTemplateString = (str) => {
  if (!str) return '';
  if (typeof str !== 'string') throw Error('Invalid template string value');

  return sanitizeHTMLElement(str)
    .replaceAll(/( on[^<>="]+=["`']+[^<>]*["`'])|<(script|style)>[^<]*<\/\2*>/gi, '')
    .trim();
}

export const renderTemplate = (templateString, isTranslationsEditable = false) => {
  const lodashTemplate = template(templateString);

  return (params = {}) => {
    // we need the html attribute to be able to edit translations
    if (isTranslationsEditable) {
      return templateString;
    }
    const reactElementParams = {};
    let templateResult = '';
    const paramsPlaceholders = mapValues(params, (value, param) => {
      if (isValidElement(value)) {
        reactElementParams[param] = value;
        return `<span template-id="${param}"></span>`;
      }
      return value;
    });

    try {
      templateResult = lodashTemplate(paramsPlaceholders);
      templateResult = sanitizeTemplateString(templateResult);
    } catch (e) {
      sentryLog(`Invalid template string "${templateString}"`);
    }

    return (
      <ReactTemplateResult
        key={ `${templateString}${Math.random()}` }
        templateResult={ templateResult }
        reactElementParams={ reactElementParams }
      />
    );
  };
};

export const isReactComponent = (component) => {
  return !!(
    typeof component === 'function'
    && (
      // functional component. Might not be 100% accurate
      /return(.*)\.createElement\(/.test(component.toString())
      || component.prototype.isReactComponent // class Component
    )
  );
};

export const joinArray = (array, joinWith = ', ') => {
  if (!array || array.length < 2) return array;

  return array.reduce((prev, curr) => [prev, joinWith, curr]);
};

/* eslint-disable no-nested-ternary */
/**
 * Returns value that can be rendered by react.
 * It's an easy fix for a white page issue in case of invalid data pushed
 * to React's render function.
 *
 * @param  {any} child
 * @return {ReactElement}      Element that can be rendered by react.
 */
export const ensureChildValidity = (child) => {
  if (child === undefined || child === null) {
    return '';
  }

  try {
    return React.isValidElement(child) ?
      child :
      child && child.toString ?
        child.toString() :
        String(child);
  } catch (e) {
    return child
      ? JSON.stringify(child)
      : '';
  }
};

/**
 * check if two objects are the same
 */
export const isEqualShallow = (a, b) => {
  if (!a && !b) { return true; }
  if (!a && b || a && !b) { return false; }
  const keysA = Object.keys(a).sort();
  const keysB = Object.keys(b).sort();

  // all keys must be the same
  if (keysA.toString() !== keysB.toString()) { return false; }

  // all values must be the same
  return keysA.every((key) => {
    return a[key] === b[key];
  });
};
