import React, { Fragment } from 'react';

export const PROCESS_1 = [
  /* eslint-disable max-len */
  {
    title: '1. Contactless Biometric Enrollment',
    description: 'Upon first visit to a participating community facility, the user enrolls through biometric capture on a tablet within the mesh network',
    slug: 'money_card_1.svg',
  },
  {
    title: '2. Transform and Store',
    description: <Fragment>The collected data is transformed into the IT<sup>2</sup> which is stored on a smartcard, issued per individual for offline authentication</Fragment>,
    slug: 'money_card_2.svg',
  },
  {
    title: '3. Prove Identity Offline',
    description: <Fragment>For future verification without network connectivity, the user’s new IT<sup>2</sup> is compared to their NFC-enabled smartcard on a one-to-one basis</Fragment>,
    slug: 'money_card_3.svg',
  },
  /* eslint-enable */
];

export const PROCESS_2 = [
  /* eslint-disable max-len */
  {
    title: 'Upload Images',
    description: 'Participating Attorneys General batch upload images of possible victims of human trafficking',
    slug: 'icon-upload.png',
  },
  {
    title: <Fragment>Create the IT<sup>2</sup>s</Fragment>,
    description: <Fragment>Images are hashed and discarded, and all IT<sup>2</sup> are stored in the Consortium Lake across jurisdictions</Fragment>,
    slug: 'icon-batch.png',
  },
  {
    title: 'Identify through Matching',
    description: <Fragment>IT<sup>2</sup>s are continually matched on a one- to-many basis to identify images originating from the same individual, regardless of time between uploads or locations</Fragment>,
    slug: 'icon-lake-match.png',
  },
  /* eslint-enable */
];
