/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router';
import BasePage from 'pages/BasePage';
import { ROUTES } from 'constants';
import Banner from 'components/Banner';
import Benefits from 'components/Benefits';
import VideoAndContent from 'components/VideoAndContent';
import HowItWorks from 'components/HowItWorks';
import {
  TITLE,
  CONTENT,
  HOWITWORKS_TITLE,
  HOWITWORKS,
  USE_CASES_TITLE,
  USE_CASES,
  VIDEO_CONTENT
} from './constant';
import help from 'models/help';

export default class StableIT2 extends BasePage {
  className = 'ts-StableIT2';
  static title = 'Stable IT2 - The Future of Secure Biometric Authentication';
  static description = 'By adding an extra layer of biometric binding, Stable IT2 sets a new standard for identity authentication, aligning with and surpassing FIDO2 standards.';

  componentDidMount() {
    help.set({ showDemoButton: true });
  }

  componentWillUnmount() {
    help.set({ showDemoButton: false });
    super.componentWillUnmount();
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        { /* banner */ }
        <Banner theme="globe" size="small">
          <h1>Stable IT2</h1>
        </Banner>

        <VideoAndContent content={ VIDEO_CONTENT } />

        <section>
          <div className="ts-container">
            <h6>addressing key challenges</h6>
            <h2>Limitations with Traditional Biometric Systems</h2>
            <ol>
              <li>
                Traditional biometric systems store biometric templates, making
                them vulnerable to replay attacks if these templates are
                stolen.
              </li>
              <li>
                Conventional authentication methods, which typically rely on
                passwords and possession of a device like a cell phone, are
                susceptible to various cyber attacks, including phishing and
                man-in-the-middle attacks.
              </li>
              <li>
                Traditional authentication systems require centralized storage
                and control of biometric information or cryptographic keys,
                which poses risks to customer privacy and enterprise security.
              </li>
            </ol>
            <br />
            <br />
          </div>
        </section>

        <HowItWorks
          content={ HOWITWORKS }
          sectionTitle={ HOWITWORKS_TITLE }
          columnCount={ 4 }
          subTitle="THE SOLUTION"
        />

        <Benefits
          subTitle="THE TECHNOLOGY"
          sectionTitle={ TITLE }
          content={ CONTENT }
          colored={ true }
        />

        <HowItWorks
          content={ USE_CASES }
          sectionTitle={ USE_CASES_TITLE }
          columnCount={ 3 }
          subTitle="USE CASES"
        />

        <section>
          <div className="ts-container">
            <h6>GET IN TOUCH</h6>
            <h2>Ready to Elevate Your Security with Stable IT2?</h2>
            Our sales and solutions team is here to help. Whether you have
            questions, need a demo, or want to discuss customized solutions for
            your organization, we’re just a click away.
            <br />
            <br />
            <Link to={ ROUTES.CONTACT_US } className="ts-button--primary">
              CONTACT US
            </Link>
          </div>
        </section>
      </div>
    );
  }
}
