import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import { asset } from 'sf/helpers';

export default class AppStoreBadges extends BaseComponent {
  className = 'ts-AppStoreBadges';

  static propTypes = {
    badges: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div
        className={ this.rootcn() }
      >
        { Object.entries(this.props.badges).map(
          ([storeName, link]) => {
            const img = asset(`img/${storeName}-badge.svg`);

            return (
              <a
                className={ this.cn`__badge` }
                href={ link }
                key={ storeName }
                rel="noreferrer"
                target="_blank"
              >
                <img
                  className={ this.cn`__badge-img` }
                  src={ img }
                  alt=""
                  crossOrigin="anonymous"
                />
              </a>
            );
          }
        ) }
      </div>
    );
  }
}
