import React from 'react';
import { COPY_TOKENIZED_IDENTITY } from 'apps/ai/pages/CorporateIndustries/copy';
import BasePage from 'pages/BasePage';
import HeroComponent from '../CorporateComponents/CorporateIndustriesHero';
import CorporateSectionComponent from '../CorporateComponents/CorporateSectionComponent';

export default class CorporateTokenizedIdentity extends BasePage {
  className = 'ts-CorporateIndustries';

  render() {
    return (
      <div className={ this.rootcn() }>
        <HeroComponent
          title="Tokenized Identity and Zero Knowledge Proofs"
          extraClass="p2p"
        />
        <CorporateSectionComponent data={ COPY_TOKENIZED_IDENTITY } />
      </div>
    );
  }
}
