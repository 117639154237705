/* eslint-disable max-len */

export const CONTENT = [
  {
    id: '01',
    title: 'Analyze biometrics',
    content: 'The customer uses their mobile phone to capture a selfie video; presentation-attack-detection including proof-of-liveness tests analyze authenticity',
    slug: 'pol',
  },
  {
    id: '02',
    title: 'Validate the ID document',
    content: 'The customer captures a photo of their ID; multiple anti-spoofing tests check for print or device display attacks, along with other indicators of falsified documents',
    slug: 'IDbiometrics',
  },
  {
    id: '03',
    title: 'Protect sensitive data',
    content: "Trust Stamp's deep neural networks turn any sensitive data into secure tokens that are safe to use, store, and share as privacy-preserving identifiers",
    slug: 'multiplechecks_3',
  },
  {
    id: '04',
    title: 'Block fraudsters',
    content: 'New customer enrollments are compared to the entire user base to detect synthetic or stolen identities, for the next level of fraud prevention',
    slug: 'blackfraudsters',
  },
];
