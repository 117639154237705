import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';

/*
  NOTE: Remember Render's children will be rendered
  in the first reconciliation cycle
  which may lead to required props warnings
*/
export default class Render extends BaseComponent {
  static propTypes = {
    children: PropTypes.node,
    // when:
    // anything that could be casted to bool, that mean anything.
    // isRequired is not here as value can be undefined
    when: PropTypes.any,
    then: PropTypes.any,
    otherwise: PropTypes.any,
  };

  static defaultProps = {
    children: null,
    otherwise: null,
  };

  constructor(props) {
    super(props);
    this.rawProps = props;
  }

  componentWillReceiveProps(nextProps) {
    this.rawProps = nextProps;
  }

  render() {
    const { children, then } = this.props;
    const shouldRender = this.rawProps.when;
    if (!shouldRender) return this.props.otherwise;

    const toRender = children || then;
    return toRender.length > 1 ? <div>{ toRender }</div> : toRender;
  }
}
