import atom from 'atom-js';
import { post } from 'sf/helpers/request';
import { mediator } from 'sf/helpers';

let sessionTimeoutInterval;
let tokenExpirationTime;

const clearSessionInterval = () => {
  clearInterval(sessionTimeoutInterval);
};

const SESSION_WARNING_TIMEOUT = 10 * 60 * 1000; // 10 minutes

const model = atom.setup({
  methods: {
    extendSession(resolve, reject) {
      post('backend/extend_session/', 'UNIVERSAL')
        .type('form')
        .end((err, res) => {
          if (err) {
            reject();
          } else {
            model.setSessionInterval(); // Restart session check interval
            resolve(res.body.token_expiration_time);
          }
        });
    },
  },
})();

/* helper methods, no need to return a Promise */
Object.assign(model, {
  setTokenExpiration(tokenTime) {
    // Store tokenTime in Date object, no need to parse it later
    tokenExpirationTime = new Date(tokenTime);
    // Handle case when user has wrong date set on his device
    const isExpiredAlready = Date.now() - tokenExpirationTime > 0;
    if (isNaN(1 * tokenExpirationTime) || isExpiredAlready) {
      mediator.publish('warning', `Time set in your operating system is wrong.
Please set it correctly and refresh the page.`);
      clearSessionInterval();
      return;
    }
    model.setSessionInterval();
  },

  setSessionInterval() {
    clearSessionInterval();
    sessionTimeoutInterval = setInterval(() => {
      // To test it just set switch lines:
      if (tokenExpirationTime - Date.now() < SESSION_WARNING_TIMEOUT) {
        clearSessionInterval();
        // To test it just set switch lines:
        // mediator.publish('sessionTimeout', new Date(Date.now() + 3000*1000));
        mediator.publish('sessionTimeout', tokenExpirationTime);
      }
    }, 1000);
  },

  clearSessionInterval: clearSessionInterval,
});

export default model;
