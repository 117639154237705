import Dialog, { DialogText, DialogFooter } from 'sf/components/Dialog';

export default Object.assign(
  Dialog,
  {
    defaultProps: {
      ...Dialog.defaultProps,
      closeIconSet: 'io',
      closeIconType: 'close-round',
      fullScreenCloseIconSize: 12,
      windowedCloseIconSize: 18,
    },
  }
);

export { DialogText, DialogFooter };
