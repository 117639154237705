import React from 'react';
import BaseComponent from 'components/BaseComponent';
import { asset } from 'sf/helpers';
import { Link } from 'react-router';
import { PRODUCTS } from './constant';

export default class CertificatesSection extends BaseComponent {
  className = 'ts-CertificatesSection';

  render() {
    return (
      <section className={ this.rootcn() }>
        <div className="ts-container">
          <h6>TRUST STAMP CERTIFICATIONS</h6>
          <h2>Setting Standards, Ensuring Trust</h2>
          <div className={ this.cn`__container` }>
            { PRODUCTS.map(({ id, title, text, button, href, slug, className }) => (
              <div className={ this.cn`__product __${className}` } key={ id }>
                { slug ?
                  <img src={ asset`/ai/${slug}.png` } alt={ title } />
                  :
                  <h3>{ title }</h3>
                }
                <p>{ text }</p>
                { button && (
                <Link
                  href={ href }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ts-button--brand"
                >
                  { button }
                </Link>
                ) }
              </div>
            )) }
          </div>
        </div>
      </section>
    );
  }
}
