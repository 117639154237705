import atom from 'atom-js';
import pick from 'lodash/pick';
import is from 'next-is';
import { post, requestEnd } from 'sf/helpers/request';
import { getStringValidation } from 'helpers/validation';

const model = atom.setup({
  validation: {
    email: {
      ...getStringValidation('email address'),
      'Please provide a valid emaill address': (input) => is.string.isEmail(input),
    },
    name: getStringValidation('name'),
    message: getStringValidation('message'),
  },
  methods: {
    submitProblem: (resolve, reject) => {
      const data = pick(
        model.get(),
        [
          'email',
          'message',
          'name',
        ],
      );
      post('backend/contactus/', 'UNIVERSAL')
        .type('form')
        .send({
          ...data,
          app: BRAND_NAME,
          recipient: 'bugs',
        })
        .end(requestEnd(resolve, reject));
    },
  },
})();

export default {
  ...model,
  showReportProblemDialog: () => model.set({
    isDialogVisible: true,
  }),
  hideReportProblemDialog: () => model.set({
    isDialogVisible: false,
  }),
};
