/* eslint-disable max-len */
import React from 'react';
import BasePage from 'pages/BasePage';
import { Link } from 'react-router';
import { ROUTES } from 'constants';
import Banner from 'components/Banner';
import Benefits from 'components/Benefits';
import VideoAndContent from 'components/VideoAndContent';
import Resources from 'components/Resources';
import DetectContent from './components/DetectContent';
import { TITLE, CONTENT, VIDEO_CONTENT } from './constants';
import help from 'models/help';

export default class Detect extends BasePage {
  className = 'ts-Detect';
  static title = 'Duplicate Detection';
  static description = 'Lead the charge in digital transformation with technology that streamlines onboarding, re-authentication, and account recovery, and enables fraud-focused database deduplication that pushes the limits of what is computationally possible today.';

  componentDidMount() {
    help.set({ showDemoButton: true });
  }

  componentWillUnmount() {
    help.set({ showDemoButton: false });
    super.componentWillUnmount();
  }

  render() {
    return (
      <div className={ this.rootcn() }>

        { /* banner */ }
        <Banner theme="avatars" size="small">
          <h1>Duplicate Identity Detection</h1>
        </Banner>

        <DetectContent />

        <Benefits sectionTitle={ TITLE } subTitle="CUSTOMER BENEFITS" content={ CONTENT } />

        <VideoAndContent content={ VIDEO_CONTENT } colored={ true } />

        { /* resources section */ }
        <Resources>
          <article>
            <h6>contact us</h6>
            <h2>Get Started with Trust Stamp</h2>
            <p>Elevate your bank's fraud prevention strategies with Trust Stamp's Biometric Deduplication Technology. Contact us today for a demo and join the forefront of secure and efficient banking.</p>
            <Link to={ ROUTES.SCHEDULE_DEMO } className="ts-button--primary">Request a Demo</Link>
          </article>
        </Resources>

      </div>
    );
  }
}
