import atom from 'atom-js';
import _get from 'lodash/get';
import pick from 'lodash/pick';
import throttle from 'lodash/throttle';
import is from 'next-is';
import webcam from 'mighty-webcamjs';
import { ROLES, AGE_OF_ELIGIBILITY, OLDEST_PERSON_BIRTHDATE } from 'constants';
import { post, get, patch, errorHandler, requestEnd, postMultipart } from 'sf/helpers/request';
import store from 'helpers/store';
import {
  getStringValidation,
  passwordValidation,
} from 'helpers/validation';
import { YEAR, SEC } from 'models/date';
import router from 'models/router';
import stateModel from 'models/state';
import * as ProfileActions from 'pages/Profile/Profile.actions';
import reduxStore from 'reduxStore';
import { mediator, navigate, generateToken } from 'sf/helpers';
import { dataURItoImage } from 'sf/helpers/canvas';
import sessionModel from './session';

function waitForAllPromises(values) {
  return new Promise((resolve, reject) => {
    let failed = false;
    // Ensure that catched promises are always resolved not rejected
    const resolvedPromises = values.map((v) => Promise
      .resolve(v)
      .catch(() => { failed = true; }));
    Promise.all(resolvedPromises).then(() => {
      if (failed) {
        reject();
      } else {
        resolve();
      }
    });
  });
}

function handleLogOutRedirection(options) {
  if (options.redirect) {
    let redirectURL;
    const reason = options.reason || 'click';

    if (reason === 'click') {
      // click:
      // user intentionally clicked on "Log Out" button
      redirectURL = BRAND_NAME === 'default'
        ? '/LogIn.html' // 'default' has a redirection to the LP on root
        : '/';
    } else if (reason === 'auto') {
      // auto:
      // auto logout:
      // - user signed out in another tab (`shouldLogOut` localStorage was set)
      // - session exipred and by some reason request with old token was sent
      // - user is registering and his token is expired
      if (router.get('isPageRestricted')) {
        redirectURL = '/LogIn.html'; // TODO: add ?next=SomePage.html
      }
      if (location.pathname.includes('SignUp')) {
        redirectURL = '/SignUp.html';
      }
    } else if (reason === 'session') {
      redirectURL = options.isBusiness ?
        '/business/SessionExpired.html' : '/SessionExpired.html';
    }
    if (redirectURL) {
      // Perform full page reload on redirection.
      // TODO: Switch to proper approach with react-router
      // Use timeout to allow cleanup operations before (e.g. Redux)
      setTimeout(() => {
        window.location = redirectURL;
      }, 0);
    }
  }
}

const pinValidation = {
  'Please provide a 4-digit number': (input) => input && /^[0-9]{4}$/.test(input) ? true : false,
};

const model = atom.setup({
  modelName: 'userModel',
  persistenceLib: store,
  validation: {
    token: {
      'Token not provided': is.isString,
    },
    connectedServices: {
      'Connected services are invalid': is.isArray,
    },
    phone: {
      'Please provide valid phone number': (input) => {
        return is.string(input) && is.string.isPhone(input, 'us');
      },
    },
    legal_name: {
      'Provided full name is not valid': is.string,
      'Please provide at least two characters': (input) => is.string.minLen(input, 2, true),
      'Please provide your name and surname': (input) => {
        // name and surname are required
        const [, surname] = input.trim().split(' ');
        return surname ? true : false;
      },
    },
    address: {
      'Please provide address': is.string,
      'Your address is too short': (input) => is.string.minLen(input, 2, true),
    },
    email: {
      'Please provide your email address': is.string,
      'Your email address is not valid': is.string.isEmail,
    },
    zip_code: {
      'Please provide ZIP code': (input) => input && input.length > 0 ? true : false,
      'ZIP code is not valid': (input) => is.string.isZip(input, 'us'),
    },
    state: {
      'Please provide your state': is.string,
      'Selected state is not valid': (input) => {
        return !!stateModel.get('states').find((obj) => obj.value === input);
      },
    },
    city: {
      'Please provide your city': is.string,
      'City name too short': (input) => is.string.minLen(input, 2, true),
    },
    date_birth: {
      'Provided birth date is not valid': (input) => is.date.isValidPattern(input),
      'You are not so old': (input) => is.date.isSameOrBefore(OLDEST_PERSON_BIRTHDATE, input),
      'Sorry, you’re too young': (input) => {
        const currentEpoch = new Date().getTime();
        const maxEpoch = currentEpoch - AGE_OF_ELIGIBILITY * YEAR;
        const maxDate = new Date(maxEpoch);
        const maxBirthdate = [
          maxDate.getUTCFullYear(),
          `0${maxDate.getUTCMonth() + 1}`.substr(-2),
          `0${maxDate.getUTCDate()}`.substr(-2),
        ].join('-');
        return is.date.isSameOrBefore(input, maxBirthdate);
      },
    },
    isSignedIn: {
      bool: is.isBoolean,
    },
    published: {
      bool: is.isBoolean,
    },
    roles: {
      array: is.isArray,
    },
    pinCode: pinValidation,
    new_pin: pinValidation,
    realtor_number: {
      'Please provide your Realtor number': (input) => !!input,
    },
    firm_name: {
      ...getStringValidation('firm name'),
    },
    individuals_name: {
      ...getStringValidation('name'),
    },
    individuals_email: {
      ...getStringValidation('email address'),
      'Your email address is not valid': is.string.isEmail,
    },
    new_password: passwordValidation,
    new_password_confirm: {
      ...passwordValidation,
      'The passwords don\'t match': (input, { new_password }) =>
        input === new_password,
    },
    old_password: passwordValidation,
    reset_password_email: {
      ...getStringValidation('email address'),
      'Your email address is not valid': is.string.isEmail,
    },
  },
  methods: {
    getProfileData(resolve, reject) {
      const spinnerTimeout = setTimeout(() => {
        if (
          global.REALTOR
          || (model.get('roles') || []).includes('business')
          || model.get('is_realtor')
        ) return;

        mediator.publish(
          'GlobalLoader--setCustomMessage',
          'We are calculating the TrustScore... Please wait.'
        );
      }, 2000);

      model.set({
        isLoading: true,
      });
      get('backend/profile/data/', 'UNIVERSAL')
        .end((err, res) => {
          if (err) {
            if (res && res.statusCode === 401) {
              model.logOut();
            } else {
              errorHandler(err, res);
            }
            reject(err);
          } else {
            const user = res.body.data;
            model.set({
              isLoading: false,
              ...user,
            });
            resolve(user);
          }
          clearTimeout(spinnerTimeout);
        });
    },

    getPolStatus(resolve, reject) {
      get('backend/profile/pol_verification_status/', 'UNIVERSAL')
        .end((err, res) => {
          if (err) {
            mediator.publish('showFloatingText', {
              text: _get(res, 'body.data.message'),
              isValid: false,
            });
            reject(err);
          } else {
            resolve({
              status: res.statusCode,
            });
          }
        }, {
          disableMediator: true,
        });
    },

    getSharingSettings: (resolve, reject) => {
      get('backend/profile/share_settings/', 'UNIVERSAL')
        .end((err, res) => {
          if (err) {
            errorHandler(err, res);
            reject();
          } else {
            const { data } = res.body;
            model.set({
              sharing_settings: data,
            });
          }
        });
    },

    saveSharingSettings: (resolve, reject, sharingSettings) => {
      patch('backend/profile/share_settings/', 'UNIVERSAL')
        .send(sharingSettings)
        .end(requestEnd(resolve, reject));
      model.set({
        sharing_settings: {
          ...model.get('sharing_settings'),
          ...sharingSettings,
        },
      });
    },

    loginWithSelfie: async (resolve, reject, code, nextUrl) => {
      const { reset_password_selfie } = model.get();
      const image = await dataURItoImage(reset_password_selfie);

      postMultipart('backend/login/business/photo/', 'LOGIN')
        .field('code', code)
        .attach('photo', image.data, `photo.${image.extension}`)
        .end(requestEnd(
          (response) => {
            resolve(response);
            navigate(nextUrl);
          },
          reject,
        ));
    },

    logInWithToken(resolve, reject, token) {
      model.logOut().then(() => {
        model.set({
          'isSignedIn': true,
          'token': token,
        }).then(() => {
          resolve();
          setTimeout(() => {
            // TODO: Remove timeout when backend allows to use backend/profile/data
            // for business users.
            model.getProfileData();
          });
        });
      });
    },

    updatePassword(resolve, reject, data) {
      post('backend/profile/business/password_update/', 'UNIVERSAL')
        .type('form')
        .send(data)
        .end(requestEnd(resolve, reject));
    },

    async updateUserPhoto(resolve, reject) {
      const image = await dataURItoImage(model.get('photo'));

      postMultipart('backend/profile/change_image/', 'UNIVERSAL')
        .field('image_type', 'selfie')
        .attach('image', image.data, `photo.${image.extension}`)
        .end(requestEnd(resolve, reject));
    },

    async updateDrivingLicensePhoto(resolve, reject, options = {}) {
      const image = await dataURItoImage(model.get('driverLicensePhoto'));
      postMultipart('backend/profile/change_image/', 'UNIVERSAL')
        .field('image_type', 'driver_license')
        .field('is_back', options.side === 'back' ? 'True' : '')
        .attach('image', image.data, `photo.${image.extension}`)
        .end(requestEnd(resolve, reject));
    },

    updateUserPolVideo(resolve, reject, videoData) {
      const endpointURL = 'backend/profile/replace_pol/';
      if (typeof videoData === 'string') { // URL
        return post(endpointURL, 'UNIVERSAL')
          .type('json')
          .send({ video_url: videoData })
          .end(requestEnd(resolve, reject));
      }

      const fileName = `video.${webcam.helpers.videoRecorder.videoFormatToExt(videoData.type)}`;

      if (videoData.size < 1024) { // Video smaller than 1KB - super weird.
        throw new Error('blob passed to sendVideoSelfie is suspiciously small.');
      }

      postMultipart(endpointURL, 'UNIVERSAL')
        .attach('video_file', videoData, fileName)
        .end(requestEnd(resolve, reject));
    },

    refreshProfile(resolve, reject) {
      get('backend/profile/refresh/', 'UNIVERSAL')
        .end(requestEnd(resolve, reject));
    },

    resendToken(resolve, reject, resendUrl) {
      post(resendUrl, 'UNIVERSAL')
        .type('form')
        .end(requestEnd(resolve, reject));
    },

    setNewPassword: async (resolve, reject, nextUrl) => {
      const {
        new_password,
        new_password_confirm,
        reset_password_code,
        reset_password_selfie,
      } = model.get();

      const image = await dataURItoImage(reset_password_selfie);

      postMultipart('/backend/login/business/new_password/', 'LOGIN')
        .field('code', reset_password_code)
        .field('new_password', new_password)
        .field('new_password_confirm', new_password_confirm)
        .attach('photo', image.data, `photo.${image.extension}`)
        .end(requestEnd(
          (response) => {
            resolve(response);
            navigate(nextUrl);
          },
          reject,
        ));
    },

    sendTokenEmail(resolve, reject, email, ownerPays) {
      post('backend/profile/send_profile/email', 'UNIVERSAL')
        .type('form')
        .send({
          email: email,
          owner_pays: ownerPays,
        })
        .end(requestEnd(resolve, reject, false));
    },

    sendTokenSms(resolve, reject, phone, ownerPays) {
      post('backend/profile/send_profile/phone_number', 'UNIVERSAL')
        .type('form')
        .send({
          phone_number: phone,
          owner_pays: ownerPays,
        })
        .end(requestEnd(resolve, reject, false));
    },

    sendTokenFacebook(resolve, reject, ownerPays) {
      post('backend/profile/send_profile/facebook/', 'UNIVERSAL')
        .type('form')
        .send({
          owner_pays: ownerPays,
        })
        .end(requestEnd(resolve, reject, false));
    },

    // TODO: This metod should be implemented on server side
    sendTokensBatch(resolve, reject, contacts, ownerPays) {
      const unsendedInvites = [];
      const errors = [];
      const sendPromises = contacts.map((contact) => {
        const sendMethod = is.string.isEmail(contact) ? 'sendTokenEmail' : 'sendTokenSms';
        return model[sendMethod](contact, ownerPays).catch((err) => {
          unsendedInvites.push(contact);
          errors.push(err);
        });
      });
      return new Promise(() => {
        // resolve only, as waitForAllPromises should always resolve
        waitForAllPromises(sendPromises).then(() => {
          if (unsendedInvites.length > 0) {
            reject({ contacts: unsendedInvites, errors: errors });
          } else {
            resolve();
          }
        });
      });
    },

    resendVerificationEmail(resolve, reject) {
      get('backend/profile/resend_verification_email/', 'UNIVERSAL')
        .end(requestEnd(resolve, reject, false));
      resolve();
    },

    publishProfile(resolve, reject, publish) {
      post('backend/profile/publish/', 'UNIVERSAL')
        .type('form')
        .send({ published: publish })
        .end(requestEnd(() => {
          model.set('published', publish);
          resolve();
        }, reject));
    },

    disableProfile(resolve, reject) {
      post('backend/profile/disable/', 'UNIVERSAL')
        .type('form')
        .end(requestEnd(resolve, reject));
    },

    logOut(resolve, reject, options = {}) {
      const isBusiness = require('models/login').get('businessLogIn') ||
        model.hasRole(ROLES.BUSINESS_USER);
      const isRealtor = model.get('is_realtor');

      model.clear().then(() => {
        require('models/registration').clear();

        // set a localstorage key to logout all app opened tabs with a user logged in
        // see more in src/app.js file
        // TODO: This logic should be verified and tested. It happens that `shouldLogOut` stays true
        // in the localStorage even after logging out. Race conditions between tabs can happen.
        store.set('shouldLogOut', JSON.stringify(!store.get('shouldLogOut')));

        // generate new token to prevent page reload
        model.set('token', generateToken());

        reduxStore.dispatch(ProfileActions.clearProfileData());
        handleLogOutRedirection(Object.assign({}, options, {
          isRealtor,
          isBusiness,
        }));

        resolve();
      });
    },

    resetPINPostPIN: (resolve, reject) => {
      const { pinResetCode: code, new_pin } = model.get();

      post('backend/login/new_pin/', 'LOGIN')
        .type('form')
        .send({ new_pin, code })
        .end(requestEnd(resolve, reject));
    },

    resetPINPostSelfie: async (resolve, reject, dataURI) => {
      const { data, extension } = await dataURItoImage(dataURI);

      postMultipart('/backend/login/photo/', 'LOGIN')
        .field('code', model.get('pinResetCode'))
        .attach('photo', data, `photo.${extension}`)
        .end(requestEnd(resolve, reject));
    },

    postEmailChange: (resolve, reject) => {
      post('backend/profile/change_email/', 'UNIVERSAL')
        .type('form')
        .send({
          email: model.get('email'),
          url: `${location.origin}/EmailChangeConfirmation.html`,
        })
        .end(requestEnd(resolve, reject));
    },

    postPhoneNumberChange: (resolve, reject) => {
      post('backend/profile/change_phone/', 'UNIVERSAL')
        .type('form')
        .send({
          phone_number: model.get('phone'),
        })
        .end(requestEnd(resolve, reject));
    },

    postConsecutiveVerificationRequest: (resolve, reject, url) => {
      post(url, 'UNIVERSAL')
        .type('form')
        .end(requestEnd(resolve, reject));
    },

    async uploadDocument(
      resolve,
      reject,
      dataURI,
      documentType = 'driving_license',
      countryState = 'GA',
      isFront = true
    ) {
      const image = await dataURItoImage(dataURI);
      postMultipart('backend/profile/replace_document/', 'UNIVERSAL')
        .field('is_back', isFront ? '' : 'True')
        .field('state', countryState || '')
        .field('document_type', documentType || '')
        .attach('document', image.data, `photo.${image.extension}`)
        .end((err, res) => {
          if (err) {
            errorHandler(err, res);
            reject();
          } else {
            resolve(res.body.data);
          }
        });
    },
    acceptWalletInsightTerms: (resolve, reject, data) => {
      post('backend/profile/accept_wallet_insight_terms/', 'UNIVERSAL')
        .type('form')
        .send(pick(data, [
          'firm_name',
          'individuals_name',
          'individuals_email',
          'promocode',
        ]))
        .end(requestEnd(resolve, reject));
    },
    sendEmailAndSelfieForPasswordReset: async (resolve, reject, nextUrl) => {
      const { reset_password_email, reset_password_selfie } = model.get();

      const { data, extension } = await dataURItoImage(reset_password_selfie);
      postMultipart('/backend/login/business/reset_password/', 'LOGIN')
        .field('email', reset_password_email)
        .attach('photo', data, `photo.${extension}`)
        .end(requestEnd(
          (response) => {
            resolve(response);
            navigate(nextUrl);
          },
          reject,
        ));
    },
  },
})(store.get('userModel') || {
  roles: [],
});

Object.assign(model, {
  saveSelfieForPasswordReset(reset_password_selfie) {
    model.set({
      reset_password_selfie,
    });
  },

  async saveSelfieForPasswordResetAndProceed(reset_password_selfie, nextUrl) {
    await model.saveSelfieForPasswordReset(reset_password_selfie);
    navigate(nextUrl);
  },

  getBirthDate() {
    return model.get('date_birth') || '1900-01-01';
  },

  getCreditsBalance() {
    const credits = model.get('number_of_credits') || 0;
    return credits.toFixed(credits % 1 ? 2 : 0);
  },

  getAge(...args) {
    const birthDate = new Date(args.length ? (args[0] || NaN) : model.get('date_birth'));

    if (isNaN(birthDate.getTime())) {
      return '';
    }

    const today = new Date();
    let age = today.getUTCFullYear() - birthDate.getUTCFullYear();
    const m = today.getUTCMonth() - birthDate.getUTCMonth();
    if (m < 0 || m === 0 && today.getUTCDate() < birthDate.getUTCDate()) {
      --age;
    }

    return age || '';
  },

  getPublicURL() {
    const origin = global.location ? global.location.origin : '';
    return `${origin}/${model.get('relative_short_url')}-profile.html`;
  },

  hasRole(role) {
    const modelRoles = model.get('roles') || [];
    if (Array.isArray(role)) {
      // check if any of role param item exists in model`s role
      return Boolean(role.filter((roleItem) => modelRoles.includes(roleItem)).length);
    }
    return modelRoles.includes(role);
  },
  saveBrowserSessionAsActive() {
    store.set('_activeBrowserSessionId', window._activeBrowserSessionId);
  },
  isBrowserSessionActive() {
    return window._activeBrowserSessionId === store.get('_activeBrowserSessionId');
  },
  setPINResetStep(resetPINStep) {
    model.set({ resetPINStep });
  },
  startup() {
    if (!model.get('token')) {
      // model needs to trigger onChange to update localStorage.
      model.set('token', generateToken());
    }

    // set current browser session as active one
    window._activeBrowserSessionId = generateToken();
    store.set('_activeBrowserSessionId', window._activeBrowserSessionId);

    if (model.get('isSignedIn')) {
      // timeout is here to allow user model load entirely before getProfileData.
      setTimeout(() => {
        model.getProfileData().then(sessionModel.setSessionInterval);
      });
    }

    model.on('isSignedIn', (isSigned) => {
      if (isSigned) {
        sessionModel.setSessionInterval();
      } else {
        sessionModel.clearSessionInterval();
      }
    });

    setInterval(() => {
      // When user writes localStorage.clear(), we don't want to have unexpected
      // behaviour. That's why localStorage is checked every 5 seconds.
      const { token } = store.get('userModel') || {};
      if (!token) {
        return location.reload();
      }
      if (token !== model.get('token')) {
        // eslint-disable-next-line no-console
        console.error('Token mishmatch!', model.get('token'), '!==', token);
      }
    }, 5 * SEC);

    // activity checker
    const updateActivity = throttle(() => {
      model.set('lastActivity', Date.now());
    }, 5 * SEC);

    'click keydown resize scroll touchstart mousemove'.split(' ').forEach((eventName) => {
      document.body.addEventListener(eventName, updateActivity, false);
    });
  },
});

export default model;
