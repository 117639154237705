/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import reactTimeout from 'react-timeout';
import BaseComponent from 'components/BaseComponent';
import HelpCenter from 'components/HelpCenter';
import PageFooter from 'components/PageFooter';
import PageHeader from 'components/PageHeader';
import PageHeaderFloating from 'components/PageHeaderFloating';
import ReportProblem from 'components/ReportProblem';
import { BRANDINGS } from 'constants';
import device from 'models/device';
import help from 'models/help';
import layout from 'models/layout';
import CookiesConsent from 'sf/components/CookiesConsent';

const FIXED_HEADER_VISIBILITY_THRESHOLD = 50;

class MainLayoutComponent extends BaseComponent {
  className = 'ts-MainLayout';

  static propTypes = {
    children: PropTypes.node,
    contentTheme: PropTypes.string,
    FooterComponent: PropTypes.func,
    footerTheme: PropTypes.string,
    HeaderComponent: PropTypes.func,
    headerSize: PropTypes.string,
    headerTheme: PropTypes.string,
    headerVersion: PropTypes.string,
    manualHeaderFloat: PropTypes.bool,
    reportProblem: PropTypes.bool,
    showFooterLogo: PropTypes.bool,
    title: PropTypes.string,
  };

  static defaultProps = {
    FooterComponent: PageFooter,
    HeaderComponent: PageHeader,
    footerTheme: null,
    headerSize: null,
    headerTheme: null,
    headerVersion: null,
    reportProblem: true,
    showFooterLogo: false,
  };

  state = {
    isHelpVisible: false,
    topScrollOffset: 0,
  }

  componentDidMount() {
    this.syncStateWithModel(device, ['topScrollOffset']);
    this.syncStateWithModel(help, ['isHelpVisible']);
    this.syncStateWithModel(layout, ['isCustomStyleLoading']);
    this.subscribe('mainLayout:showFixedMenu', this.handleShowFixedMenu);
    this.subscribe('mainLayout:hideFixedMenu', this.handleHideFixedMenu);
  }

  handleShowFixedMenu = () => {
    this.setState({
      isFixedHeaderVisible: true,
    });
  }

  handleHideFixedMenu = () => {
    this.setState({
      isFixedHeaderVisible: false,
    });
  }

  renderPageHeader() {
    const {
      brandingName,
      HeaderComponent,
      headerProps,
      headerSize,
      headerTheme,
    } = this.props;
    const pageHeaderProps = {
      brandingName,
      size: headerSize,
      theme: headerTheme,
      toTheRight: brandingName === BRANDINGS.REALTOR,
      ...headerProps,
    };
    const isFixedHeaderVisible = (
      !this.props.manualHeaderFloat &&
      this.state.topScrollOffset > FIXED_HEADER_VISIBILITY_THRESHOLD
    ) || this.state.isFixedHeaderVisible;
    if (this.props.isHeaderFloating) {
      return (
        <PageHeaderFloating { ...pageHeaderProps } />
      );
    }
    return [
      <HeaderComponent
        active={ !isFixedHeaderVisible }
        key="0"
        visible={ true }
        { ...pageHeaderProps }
      />,
      <HeaderComponent
        active={ !!isFixedHeaderVisible }
        key="1"
        fixed={ true }
        visible={ isFixedHeaderVisible }
        { ...pageHeaderProps }
      />,
    ];
  }

  render() {
    const {
      children,
      contentTheme,
      FooterComponent,
      footerTheme,
      reportProblem,
      showFooterLogo,
    } = this.props;
    const layoutClassName = {};
    if (ENV === 'local') {
      layoutClassName['--debug-css-breakpoints'] = true;
    }
    const contentClassName = {
      '__content': true,
      '__content--dark': contentTheme === 'dark',
    };
    const helpCenterClassNames = {
      '__help-center': true,
      '__help-center--opened': this.state.isHelpVisible,
    };
    return (
      <div className={ this.rootcn(layoutClassName) }>
        { this.renderPageHeader() }
        <div className={ this.cn(helpCenterClassNames) }>
          <HelpCenter />
        </div>
        <div className={ this.cn(contentClassName) }>
          { children }
        </div>
        <FooterComponent
          theme={ footerTheme }
          showFooterLogo={ showFooterLogo }
        />
        <div className={ this.cn`__report-problem` }>
          { reportProblem && <ReportProblem /> }
        </div>

        { /* cookie consent section */ }
        { ENV !== 'local' && GOOGLE_ANALYTICS_KEYS[BRAND_NAME] ?
          <CookiesConsent

            // script to be added if consent is granted
            onConsentGranted={ () => {
              const gtagURL = document.createElement('script');
              gtagURL.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_KEYS[BRAND_NAME]}`;
              document.head.append(gtagURL);

              // inline google tag script
              const scriptdata = `window.dataLayer = window.dataLayer || [];

              function gtag() {
                dataLayer.push(arguments);
              };

              gtag('consent', 'default', {
              'ad_storage': 'granted',
              'analytics_storage': 'granted'
              });

              gtag('js', new Date());

              gtag('config', '${GOOGLE_ANALYTICS_KEYS[BRAND_NAME]}');`;

              // append inline google tag script to head
              const gtagScript = document.createElement('script');
              gtagScript.innerHTML = scriptdata;
              document.head.append(gtagScript);
            }
            }

            // cookies ti be deleted if consent rejected
            onConsentRejected={ () => {
              ['_gid',
                '_ga',
                `_gat_gtag_${GOOGLE_ANALYTICS_KEYS[BRAND_NAME].replace(/-/g, '_')}`,
              ].forEach((key) => {
                document.cookie = `${key}=; Path=/; Domain=.${window.location.hostname}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
              });
            }
            }
          />
          :
          null
        }
      </div>
    );
  }
}

export default reactTimeout(MainLayoutComponent);
