import isPlainObject from 'lodash/isPlainObject';
import omit from 'lodash/omit';
import help from 'models/help';
import ShareBaseComponent from 'sf/components/BaseComponent';

export default class BaseComponent extends ShareBaseComponent {
  /**
   * Shorthand for publishing a notification in HelpCenter
   * If `value` isn't of type Object it'll be converted to { value }
   */
  notify(type, value) {
    help.addNotification(
      Object.assign(
        { type, value },
        isPlainObject(value) ? omit(value, 'type') : {}
      ),
    );
  }
}
