import React from 'react';
import BaseComponent from 'components/BaseComponent';
import { asset } from 'sf/helpers';
import { BANNER_CONTENT } from '../../constants';

export default class ProtectBanner extends BaseComponent {
  className = 'ts-ProtectBanner';

  render() {
    return (
      <section className={ this.rootcn() }>
        <div className="ts-container">
          <h2>Do more with less</h2>

          { /* less & more listing */ }
          { BANNER_CONTENT.map(({ id, title, slug, list }) => (
            <div className={ this.cn`__column` } key={ id }>
              <h4><img src={ asset`ai/${slug}.svg` } alt={ title } width="15px" /> { title } </h4>
              <ul>
                { list.map((item) => (
                  <li key={ item.substr(1, 3) }>{ item }</li>
                )) }
              </ul>
            </div>
          )) }
        </div>
      </section>
    );
  }
}
