import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import Dialog, { DialogText } from 'components/Dialog';
import NotificationPill from 'components/NotificationPill';
import Render from 'components/Render';
import { getDate } from 'sf/helpers';
import invitationModel from 'models/invitation';
import user from 'models/user';

let isDialogDisplayedFirstTime = false;

class PendingInvitationsDialogContents extends BaseComponent {
  className = 'ts-PendingInvitationsDialogLink';

  state = {
    acceptedInvitations: [],
    pendingInvitations: [],
  };

  static propTypes = {
    active: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    onClick: noop,
  };

  getInitialInvitations() {
    Promise.all([
      invitationModel.getAcceptedInvitations(),
      invitationModel.getPendingInvitations(),
    ]).then(() => {
      if (this.state.pendingInvitations.length) {
        this.setState({ displayDialogOnInit: true });
      }
    });
  }

  componentDidMount() {
    this.syncStateWithModel(invitationModel, [
      'acceptedInvitations',
      'pendingInvitations',
    ]);
    this.subscribe('locationChange', () => {
      if (this.props.active) this.getInitialInvitations();
    });
    if (!isDialogDisplayedFirstTime) {
      this.getInitialInvitations();
      isDialogDisplayedFirstTime = true;
    }
  }

  handleClick = (e) => {
    e.preventDefault();
    this.props.onClick();
    if (this.refs.dialog) {
      this.refs.dialog.toggle(true);
    }
  }

  refreshInvitations = () => {
    Promise.all([
      invitationModel.getAcceptedInvitations(),
      invitationModel.getPendingInvitations(),
    ]).then(() => {
      if (!invitationModel.get('pendingInvitations').length && this.refs.dialog) {
        this.refs.dialog.showFloatingText({
          text: 'All done.',
          isValid: true,
          autoHide: true,
        });
      }
    });
  }

  acceptInvitation = (invitation) => {
    invitationModel
      .acceptInvitation(invitation)
      .then(this.refreshInvitations);
  }

  rejectInvitation = (invitation) => {
    invitationModel
      .rejectInvitation(invitation)
      .then(this.refreshInvitations);
  }

  renderInvitations(pendingInvitations) {
    const cn = this.cn();
    return pendingInvitations.map((pendingInvitation) => {
      const handleAcceptClick = this.acceptInvitation.bind(this, pendingInvitation);
      const handleRejectClick = this.rejectInvitation.bind(this, pendingInvitation);

      return (
        <div
          key={ pendingInvitation.reject_url }
          className={ cn`__invitation` }
        >
          <div className={ cn`__invitation-name` }>
            { pendingInvitation.inviter_name }
          </div>
          <span className={ cn`__invitation-date` }>
            { getDate(pendingInvitation.modified) }
          </span>
          <br className={ cn`__separator` } />
          <Render when={ pendingInvitation.accept_url }>
            <Button
              className={ cn`__invitation-accept` }
              onClick={ handleAcceptClick }
            >
              Accept
            </Button>
          </Render>
          <Button
            className={ cn`__invitation-reject` }
            onClick={ handleRejectClick }
            theme="ternary"
          >
            Reject
          </Button>
        </div>
      );
    });
  }

  render() {
    const {
      acceptedInvitations = [],
      pendingInvitations = [],
    } = this.state;
    if (!pendingInvitations.length && !acceptedInvitations.length) return null;

    return (
      <div>
        <a
          className={ this.rootcn() }
          href="#"
          onClick={ this.handleClick }
        >
          { this.props.children } <NotificationPill>{ pendingInvitations.length }</NotificationPill>
        </a>

        <Dialog
          ref="dialog"
          title="Pending Invitations"
          visible={ this.state.displayDialogOnInit }
        >
          <Render when={ pendingInvitations.length }>
            <DialogText>
              <p>
                Following { pendingInvitations.length > 1 ? 'people want ' : 'person wants ' }
                to access your profile:
              </p>
            </DialogText>
          </Render>

          { this.renderInvitations(pendingInvitations) }

          <Render when={ acceptedInvitations.length }>
            <DialogText>
              <p>
                You have granted { acceptedInvitations.length > 1 ? '' : 'the ' }
                following { acceptedInvitations.length > 1 ? 'people ' : 'person ' }
                access to your profile:
              </p>
            </DialogText>
          </Render>

          { this.renderInvitations(acceptedInvitations) }
        </Dialog>
      </div>
    );
  }
}


export default class PendingInvitationsDialogLink extends BaseComponent {
  state = {};

  componentDidMount() {
    this.syncStateWithModel(user, ['email_confirmed']);
  }

  render() {
    return this.state.email_confirmed ?
      <PendingInvitationsDialogContents { ...this.props } /> : null;
  }
}
