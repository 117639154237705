import React from 'react';
import { Link } from 'react-router';
import BaseComponent from 'components/BaseComponent';
import { asset } from 'sf/helpers';
import { BRAND_URL } from '../../constants';

export default class Header extends BaseComponent {
  className = 'ts-Header';


  render() {
    return (
      <header className={ this.rootcn() }>
        <div className="ts-container">

          { /* logo */ }
          <Link href={ BRAND_URL } rel="noopener noreferrer">
            <img src={ asset`ai/logo-pixelpin.png` } alt="PixelPin" width="205" />
          </Link>

          { /* menu */ }
          <div className={ this.cn`__content--right` }>
            <Link
              href={ `${BRAND_URL}/consumers/` }
              rel="noopener noreferrer"
              className={ this.cn`__button __button--secondary` }
            >
              Consumer
            </Link>
            <Link
              href={ `${BRAND_URL}/business/` }
              rel="noopener noreferrer"
              className={ this.cn`__button __button--tertiary` }
            >
              Business
            </Link>
          </div>

        </div>
      </header>
    );
  }
}
