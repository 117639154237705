/* eslint-disable max-len */
import React from 'react';
import BasePage from 'pages/BasePage';
import Banner from 'components/Banner';
import { Link } from 'react-router';

export default class BlogCrypto extends BasePage {
  className = 'ts-BlogCrypto';
  static title = 'Blog Crypto';

  renderContent() {
    return (
      <section>
        <div className="ts-container">
          <h2>Streamlined Compliance for the Cryptocurrency Space </h2>
          <div className={ this.cn`__info` }>
            June 17, 2021 <br />
            John Bridge
          </div>
          <p>Demand for cryptocurrencies has grown exponentially recently.  With the explosive growth in cryptocurrency valuation over the past year, crypto platforms have seen a massive surge in customer onboarding in just the first two quarters of 2021. Our Executive VP and Blockchain Expert, John Bridge, shares insights on the rapidly changing regulatory landscape in the cryptocurrency space and how Trust Stamp is helping companies stay ahead.</p>
          <h3>A new frontier in financial regulations</h3>
          <p>When financial institutions onboard new customers, they assume the risk of fraud and are required to conduct comprehensive due diligence processes to comply with regulations and to protect their own financial and reputational interests. With persistent technical advancement in cybercrime, preventing fraud that involves digital assets can pose unique challenges from crime perpetrated through fiat transactions and traditional bank accounts.</p>
          <p>Where does the liability lie with digital currencies? According to the EU’s <Link href="https://ec.europa.eu/info/business-economy-euro/banking-and-finance/financial-supervision-and-risk-management/anti-money-laundering-and-counter-terrorist-financing_en" target="_blank" rel="noopener noreferrer">Fifth Anti-Money Laundering Directive</Link> (AMLD5), service providers that sell, issue, transfer, trade, or exchange cryptocurrencies, including exchanges, wallets, and peer-to-peer platforms, are obliged entities that “shall apply customer due diligence requirements when entering into a business relationship…’’, including verifying the identity of any senders and beneficiaries.</p>
          <p>In the United States, the Financial Crimes Enforcement Network (FinCEN), the <Link href="https://www.cftc.gov/" target="_blank" rel="noopener noreferrer">Commodity Futures Trading Commission</Link> (CFTC), and the <Link href="https://www.sec.gov/" target="_blank" rel="noopener noreferrer">Securities and Exchange Commission</Link> (SEC) published guidelines stating that cryptocurrency exchanges are subject to existing AML and Know Your Customer (KYC) standards under the Bank Secrecy Act (BSA) in 2019.</p>
          <p><i><b>For cryptocurrency and digital asset companies, whether DeFi platforms and investors, issuers, exchanges, payments services and/or wallet providers, meeting KYC/AML requirements is not just a legal imperative, it is also critical to building trust with regulators and users, and to deterring fraudulent transactions.</b></i></p>
          <p>With increasing scrutiny over the last few years, the regulatory landscape is expected to continue evolving alongside the rapid transformation of digital asset use. Digital fiat currencies, non-fungible tokens, and the promulgation of cryptocurrencies as accepted forms of payment are just a few of the moving pieces in a complex comprehensive legal framework.</p>
          <p><i><b>Long-term success with regulatory compliance can be achieved by deploying a robust identity and risk framework that meets the highest current compliance standards, while also featuring rapid deployment, flexibility with adjustable risk-acceptance criteria, and an interoperability that allows layered integration of additional security measures to meet evolving requirements.</b></i></p>
          <h3>Crypto ATMs</h3>
          <p>Cryptocurrency ATMs bring the digital currency experience to a physical space, allowing you to deposit cash into a crypto wallet, all without the need to provide banking information or log into a crypto account. This poses a potential grey area for customer due diligence.</p>
          <p>In November 2019, the US Internal Revenue Service (IRS) <Link href="https://cointelegraph.com/news/irs-criminal-investigators-looking-into-bitcoin-atms-and-kiosks" target="_blank" rel="noopener noreferrer">launched a criminal investigation into Bitcoin ATMS and kiosks</Link> in response to their potential to facilitate illicit activities like money laundering and tax evasion. In fact, a <Link href="https://ciphertrace.com/spring-2020-cryptocurrency-anti-money-laundering-and-crime-report-research-finds-criminals-netted-1-4-billion-from-cryptocurrency-crimes/" target="_blank" rel="noopener noreferrer">2020 report by CipherTrace</Link> found that 88% of funds sent from US Bitcoin ATMs to exchanges in 2019 were sent offshore.</p>
          <p>This is where Point-of-Sale (PoS) verification comes in. Today Trust Stamp provides real-time KYC/AML-compliant facial biometric and ID document verification for leading Bitcoin ATMs around the world, including <Link href="https://www.instacoinatm.com/index-en.html" target="_blank" rel="noopener noreferrer">Instacoin ATM</Link>. This allows users to seamlessly prove their identity at the kiosk, with the added benefit of Trust Stamp’s privacy-positive data protection technology. <i><b>It’s the best of both worlds – the flexibility, privacy, and convenience that consumers expect of crypto with the compliance, fraud-prevention, and security required of crypto platforms and regulators.</b></i></p>
          <h3>Trust Stamp is your partner for crypto compliance</h3>
          <p>Trust Stamp’s multi-layer, automated identity proofing process provides on-demand authentication with the low-friction user experience expected by participants in the digital economy. Our biometric identity verification technology layers security and privacy into customer due diligence processes, and when combined with Trust Stamp's real time document analysis solution, comprehensive identity verification is as simple as capturing a photo.</p>
          <h5>Reduce exposure to legal liability</h5>
          <p>Our risk-based process provides audit trails, compliance reports, and fuzzy logic to identify risky transactions, helping your organization avoid the financial and reputational risks of non-compliance.</p>
          <h5>Combat fraud</h5>
          <p>Prevent funds from illicit activities from passing through your crypto platform, while also protecting sensitive user information from would-be criminals.</p>
          <h5>Increase conversion rates </h5>
          <p>Empower your users to quickly prove their identity at a kiosk or on their mobile phone, reducing customer onboarding time and simplifying user experience so they can access your services the way you intended.</p>
          <h5>Get up and running in less than a day</h5>
          <p>Trust Stamp’s seamless API integrations enable low-code implementation so you can easily add advanced biometric authentication and ID validation capabilities to your systems.</p>
          <h6>Talk to the author</h6>
          <small>Trust Stamp EVP John Bridge is a career expert in financial technology and cryptocurrency, with a focus on fraud detection and cyber investigations. He has spent 34 years in law enforcement, which included serving as Senior Inspector/Chief Inspector of Financial Surveillance with United States Marshals Service, as a charter member of the DC Blockchain Alliance, and he now serves as an active member of the North Carolina Blockchain Initiative.</small>
        </div>
      </section>
    );
  }

  render() {
    return (
      <div className={ this.rootcn() }>

        { /* banner */ }
        <Banner theme="crypto" size="small">
          <h1>FinTech Blog</h1>
        </Banner>

        { this.renderContent() }

      </div>
    );
  }
}
