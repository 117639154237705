import React from 'react';
import BaseComponent from 'components/BaseComponent';
import Spinner from 'components/Spinner';
import device from 'models/device';

// when request is finished in less than TIMEOUT_THROTTLE, loader will not be shown
const TIMEOUT_THROTTLE = 250;

export default class GlobalLoader extends BaseComponent {
  className = 'ts-GlobalLoader';

  state = {
    isLoading: true,
  };

  timeout = null;

  toggleLoader = (xhrCount) => {
    if (xhrCount === 0) {
      clearTimeout(this.timeout);
      this.timeout = null;
      this.setState({
        isLoading: false,
        customMessage: null,
      });
    } else if (this.timeout === null) {
      this.timeout = setTimeout(() => {
        this.timeout = null;
        this.setState({
          isLoading: true,
        });
      }, TIMEOUT_THROTTLE);
    }
  }

  setCustomMessage = (message) => {
    this.setState({
      customMessage: message,
    });
  }

  toggle = (isOn) => {
    this.setState({
      isLoading: isOn,
    });
  }

  componentDidMount() {
    // HTML generated by backend needs to have GlobalLoader spinning until JS loads
    // and executes.
    this.toggle(false);
    this.subscribe('XHR', this.toggleLoader);
    this.subscribe('GlobalLoader--toggle', this.toggle);
    this.subscribe('GlobalLoader--setCustomMessage', this.setCustomMessage);
    this.syncStateWithModel(device, ['xsmDown']);
  }

  renderCustomMessage() {
    if (this.state.customMessage) {
      return (
        <div className={ this.cn`__custom-message` }>
          { this.state.customMessage }
        </div>
      );
    }

    return null;
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className={ this.rootcn() }>
          { this.renderCustomMessage() }
          <Spinner
            className={ this.cn`__spinner` }
            size={ global.REALTOR || this.state.xsmDown ? 'medium' : 'big' }
          />
        </div>
      );
    }
    return null;
  }
}
