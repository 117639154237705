/* eslint-disable max-len */
import React from 'react';
import BaseComponent from 'components/BaseComponent';
import { asset } from 'sf/helpers';
import HowItWorks from 'components/HowItWorks';
import { TITLE3, HOWITWORKS2 } from '../../constants';


export default class ProtectContent extends BaseComponent {
  className = 'ts-ProtectContent';

  render() {
    return (
      <div className={ this.rootcn() }>
        <section>
          <div className="ts-container">
            <div className={ this.cn`__column __column--left` }>
              <h6>TRUST STAMP PROTECT</h6>
              <h2>Privtech - Privacy-First Solutions</h2>
              <p>
                Trust Stamp empowers government agencies to provide
                authenticated users with secure digital access with Privtech
                Certified ™ technology.{ ' ' }
              </p>
            </div>
            <div className={ this.cn`__column __column--right` }>
              <img
                src={ asset`ai/it2.png` }
                alt="Privacy and data protection"
                width="217px"
              />
            </div>
          </div>
        </section>
        <HowItWorks
          content={ HOWITWORKS2 }
          sectionTitle={ TITLE3 }
          columnCount={ 4 }
          colored={ true }
          subTitle="Products"
        />
      </div>
    );
  }
}
