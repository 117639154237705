import React from 'react';

function PrivacyPolicy() {
  return (
    <div>
      <h1><b>Privacy Policy</b></h1>
      <p>
        T Stamp Inc. d/b/a Trust Stamp (“Trust Stamp”) is an international
        provider of secure identity authentication and verification services to
        the financial industry, governments, and other enterprise clients (each
        a “Verifying Entity”).. You are utilizing Trust Stamp and its family of
        websites and applications because you have been referred to us by a
        Verifying Entity or directly accessing Trust Stamp whether by typing a
        URL, snapping a QR Code or clicking on a link.
      </p>
      <p>
        Our general contact details can be found at:{ ' ' }
        <a href="https://truststamp.ai/contact-us.html">
          https://truststamp.ai/contact-us.html
        </a>
        .
      </p>
      <p>
        We will only process and use the personal and sensitive information you
        provide for the original purpose(s) specified in this Policy and any
        Terms and Conditions to which you have agreed, in accordance with any
        applicable legal requirements, including the European Union (“EU”)
        General Data Protection Regulation (“GDPR”), the California Consumer
        Privacy Act (“CCPA”) and the California Privacy Rights Act (“CPRA”),
        subject to any legal exemptions and requirements.
      </p>
      <p>
        Personal Information refers to any data that can identify an individual
        directly or indirectly.
      </p>
      <p>
        <b>Collection of Information</b>
      </p>
      { /* eslint-disable-next-line react/no-unescaped-entities */ }
      <p>
        When you are completing biometric identity verification through websites
        and/or mobile applications operated by a Verifying Entity, Trust Stamp
        acts as a “Processor” of personal and sensitive information you provide.
        As a Processor, we will process your voluntarily provided personal and
        sensitive information pursuant to our agreements with a Verifying
        Entity. In some instances, we also access third-party data sources to
        augment or validate the information you provide.
      </p>
      <p>
        Trust Stamp collects Personally Identifiable Information, on behalf of
        the Verifying Entity, from you when you interact with our products and
        services and communicate with a member of our team, including but not
        limited to when you sign up for an account, you are, in some instances,
        voluntarily providing us with a self-portrait style photographic image
        (we call it a secure selfie) to allow us to prove your identity, help
        the Verifying Entity know and trust your identity, for Biometric
        Multi-factor Authentication (MFA) or Age Estimation. You have consented
        to that process in accordance with the Terms and Conditions of Use and
        Privacy Policy applicable to our client’s services.
      </p>
      <p>
        You are, in some cases, also providing us with an image of an identity
        document. We use that image to compare the photograph on the document to
        your secure selfie and estimate whether the identity document photograph
        matches the secure selfie. Sometimes there is not a match due to the age
        of the image, damage to the document or the quality of the photograph.
        We also compare the text on the identity document to the information
        that you have given in account opening. You have consented to that
        process in accordance with the Terms and Conditions of Use and Privacy
        Policy applicable to the Verifying Entities’ services.
      </p>
      <p>
        We may collect non-personally identifiable information from you
        regarding your usage of our products and services. Non-personally
        identifiable information refers to data that does not, by itself
        identify persons and may include, search criteria used and results,
        date, time, connection speed, internet protocol ("IP") address, which
        may also be contained on server logs, and/or other information which
        does not specifically identify you. The source of the usage data is our
        analytics tracking system. This usage data may be processed for the
        purposes of analyzing the use of the website and services.
      </p>
      <p>
        Where specified in the Terms and Conditions to which you have agreed, in
        limited instances, we may act as a “Controller” with respect to your
        personal and sensitive information. In those instances, we may retain
        for limited periods and use your personal and sensitive information for
        limited purposes including:
        <ol start="1">
          <li>
            <p>
              developing and training software to carry out functions, including
              the identity authentication and verification process. To train
              software, we use a process called “machine learning” through which
              the software learns to recognize patterns in data it receives and to
              make predictions based upon that data. In such instance, your data
              will be part of the continuous process of training and contribute to
              the learning of the software; and
            </p>
          </li>
          <li>
            <p>
              preventing, detecting, and seeking legal redress for attempted or
              actual fraudulent activity committed against us or a Verifying
              Entity.
            </p>
          </li>
        </ol>
      </p>
      <p>
        <b>Data Storage </b>
      </p>
      <p>
        If you are in the United States, any data that we store will be stored
        on a secure server in the United States. If your registered address is
        in Turkey, any data that we store will be stored on a secure server in
        Turkey. If you are in the EU or the European Economic Area (“EEA”), any
        data that we store will be stored on a secure server in the EU. If you
        are resident in the United Kingdom (“UK”), given that the UK is no
        longer part of the EU, we will continue to ensure that appropriate
        technical and organizational measures are implemented to safeguard your
        personal data.
      </p>
      <p>
        Unless compelled by law, we do not and will not share your identity or
        other data with anyone other than a Verifying Entity, and the applicable
        sub-processors required to fulfill the purpose. We do not use your data
        to market to you, nor do we sell or share your data to allow anyone else
        to do so.
      </p>
      <p>
        Verifying Entities have varying legal obligations as to the length of
        time that data must be stored. With respect to any data that we store,
        we comply with requirements as directed by the Verifying Entity and
        other requirements as agreed to by you.
      </p>
      <p>
        <b>ADDITIONAL RIGHTS IF YOU ARE RESIDENT IN THE EU, EEA, OR UK </b>
      </p>
      <p>
        If you are resident in the EU or EEA, you have the right to request a
        copy of any personal information we hold about you. This is known as a
        Subject Access Request, for which there is no charge, providing the
        requests are not manifestly unfounded or excessive. You may submit your
        request by sending an email to <a href="mailto:privacy@truststamp.net">privacy@truststamp.net</a>. We may ask you to
        provide certain information before processing the request to verify your
        identity. Once in receipt of this, we will process the request without
        undue delay and within one month. If the matter is complex, an extension
        of up to two months can be given.
      </p>
      <p>
        You also have the right to withdraw consent at any time, acknowledging
        that withdrawing consent does not affect the lawfulness of processing
        that has already been completed when consent has been used as legal
        basis for data collection. To request to withdraw consent, please send
        an email to{ ' ' }
        <a href="mailto:privacy@truststamp.net">privacy@truststamp.net</a> using
        the email or at least stating the email you entered when the data was
        collected.
      </p>
      <p>
        You also have rights in relation to rectification, erasure, restriction
        of processing, data portability, objection, and automated
        decision-making. If you would like more information on these rights or
        would like to submit a direct request within the context of your
        relationship with Trust Stamp, please contact us by using the contact
        details provided below.{ ' ' }
      </p>
      <p>
        We can only delete the data that we have stored and then only if we are
        not legally obligated to keep it for a set or indeterminate length of
        time. If you have a broader request than the data we store, we recommend
        that you contact the referring client who can provide us with a request
        to delete the data.
      </p>
      <p>
        If you require further assistance, would like to make a complaint, or
        wish to access your personal information, please contact us using the
        information below:
      </p>
      <p>
        Contact us by emailing{ ' ' }
        <a href="mailto:privacy@truststamp.net">privacy@truststamp.net</a>.
      </p>
      <p>
        Mailing Address:
        <br />
        Trust Stamp
        <br />
        Level 1,
        <br />
        Tagliaferro Business Centre,
        <br />
        Gaiety Lane c/w High Street, Sliema
        <br />
        SLM 1551, Malta
      </p>

      <p>
        <b>ADDITIONAL RIGHTS IF YOU ARE RESIDENT OF CALIFORNIA</b>
      </p>
      <p>
        Residents of California have additional rights under the California
        Consumer Privacy Act (“CCPA”) and the California Privacy Rights Act
        (“CPRA”). You have the right to know: what categories and specific
        pieces of CCPA personal information have been collected about you; the
        categories of sources from which CCPA personal information are
        collected; our business or commercial purpose for collecting, using, or
        disclosing CCPA personal information; the categories of third parties
        with whom we share CCPA personal information; the categories of CCPA
        personal information we have disclosed about you for a business purpose.
        We do not sell personal information.
      </p>
      <p>
        You have a right to receive a copy of the specific CCPA personal
        information we have collected about you.
      </p>

      <p>
        You have a right to deletion of your CCPA personal information, subject
        to exceptions under the CCPA. We will not discriminate against you based
        on your exercise of any rights under the CCPA or CPRA. However, these
        rights can only be asserted through a verified request from you. To make
        a request, or if you have a question regarding our privacy practices,
        please contact us at:{ ' ' }
        <a href="mailto:privacy@truststamp.net">privacy@truststamp.net</a>
      </p>
    </div>
  );
}

export default PrivacyPolicy;
