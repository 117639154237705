/* eslint-disable max-len */
export const TITLE = 'Capture high quality data, efficiently and reliably';

export const CONTENT = [
  {
    id: 1,
    title: 'Enhance User Experience',
    text: 'Empower your users to quickly enrol or authenticate anytime and anywhere, using Trust Stamp’s biometric capture solution that turns any camera-enabled smartphone, tablet, or computer into a biometric capture device',
    slug: 'biometrics-experience',
  },
  {
    id: 2,
    title: 'Protect Against Fraud',
    text: 'Reduce losses to fraud with robust biometric presentation attack detection, including proof-of-life and anti-spoofing technology. Trust Stamp’s biometric technology provides a low-friction foundation to expand your organisation’s fraud-prevention capabilities',
    slug: 'fraud',
  },
  {
    id: 3,
    title: 'Seamlessly Integrate',
    text: 'Easily implement Trust Stamp Capture to add advanced biometric capabilities to your user flows for customer onboarding, account recovery, second chance approval, and more. The solution works across devices and platforms for rapid deployment and a consistent user experience',
    slug: 'integrate-pc',
  },
  {
    id: 4,
    title: 'Boost Reputation and Reach',
    text: 'Leverage the positive friction and reputational boost that biometric technology can add to businesses of all sizes. By enabling users to establish trust virtually, you can reach more people than ever before',
    slug: 'boost-reach',
  },
  {
    id: 5,
    title: 'Address Contextual Needs',
    text: 'With multiple contactless modalities to choose from, seamlessly adapt your systems to meet evolving needs and user preferences from a functional, cultural, or public health perspective',
    slug: 'context',
  },
];

export const TITLE2 = 'Advanced AI-powered tools and machine learning techniques';

export const HOWITWORKS = [
  {
    id: 1,
    title: 'Proof-of-liveness techniques',
    text: '3D face detection examines expected movement and tracked measurements',
    slug: 'pol',
  },
  {
    id: 2,
    title: 'Anti-spoofing algorithms',
    text: 'AI-powered technology analyses the capture to seek evidence of print and device display attacks',
    slug: 'display-attack',
  },
  {
    id: 3,
    title: 'Robust system of checks',
    text: 'Over 20 individual checks block fraudsters from spoofing biometric capture',
    slug: 'multiple-checks',
  },
  {
    id: 4,
    title: 'Constant innovation',
    text: 'With biometrics at the core of our work, Trust Stamp continues to raise the standard for accurate, accessible, and fraud-resistant biometric capture and analysis tools',
    slug: 'continuous-innovation',
  },
];

export const VIDEO_CONTENT = {
  id: 1,
  title: 'See it in action',
  text: 'Empower your users to quickly, easily, and securely prove identity to any time and anywhere, without putting biometric data at risk.',
  video: 'https://player.vimeo.com/video/499025036',
};
