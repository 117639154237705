import React from 'react';
import BasePage from 'pages/BasePage';
import { asset } from 'sf/helpers';
import Card from 'sf/components/Card';

import HeroComponent from '../CorporateIndustries/CorporateComponents/CorporateIndustriesHero';

const CURRENT_ISSUES_CARDS = [
  /* eslint-disable max-len */
  {
    title: 'Synthetic Identity Fraud',
    slug: 'icon-fraud',
    description: 'Fraudsters have access to fraudulent identification documents which allow them to pass siloed biometric systems. Annually, over $40 Billion lost to synthetic and stolen identity fraud and over $20 Billion lost to legitimate transactions failing legacy authentication',
  },
  {
    title: 'Security ',
    slug: 'icon-legacy-id',
    description: 'Legacy identity authentication takes place in a “silo” with matched data required for authentication. Data points used are virtually always PII and data leakage at one institution can invalidate authentication at multiple institutions.',
  },
  {
    title: 'Financial Inclusion',
    slug: 'icon-financial-inclusion',
    description: 'Over 2 Billion people globally are undocumented & financially excluded, and 4 Billion people are financially under-served. This represents a potential $3.7 Trillion annual growth in global GDPR.',
  },
];

const VISION_CARDS = [
  /* eslint-disable max-len */
  {
    title: 'Secure & Trusted Identity for all People',
    slug: 'icon-trusted-id',
    description: 'Every human being, regardless of location or connectivity, has an established existence and a means of proving their identity without the need to share personal records',
  },
  {
    title: 'Opportunity & Access',
    slug: 'icon-access',
    description: 'Access to services is no longer limited by a person’s ability to prove identity, making the opportunity for economic mobility and elevated quality of life universal',
  },
  {
    title: 'Barrier-Free Global Value Exchange',
    slug: 'icon-value',
    description: 'The ability to confidently make secure, convenient transactions is ubiquitous',
  },
  /* eslint-enable */
];

export default class OurMission extends BasePage {
  className = 'ts-OurMission';
  static title = 'Our Mission';
  static description = 'Our mission is to create transformational technology that accelerates secure societal and financial inclusion.';

  renderTopSection = () => (
    <section className={ this.cn`__top` }>
      <div className={ this.cn`__container __top-container` }>
        <div className={ this.cn`__top-intro` }>
          <h1 className={ this.cn`__title` }>
            { /* eslint-disable max-len */ }
            Our mission is to create transformational technology that accelerates secure societal and financial inclusion.
          </h1>
        </div>
        <img
          className={ this.cn`__top-logos` }
          src={ asset`/ai/mission-1.png` }
          alt="logos"
        />
      </div>
    </section>
  );

  renderCurrentIssuesCard = (issues) => {
    const { title, slug, description } = issues;
    return (
      <Card
        className={ this.cn`__card` }
        key={ title }
      >
        <img
          alt=""
          className={ this.cn`__card-image` }
          src={ `/ai/${slug}.png` }
        />
        <h4 className={ this.cn`__card-title` }>{ title }</h4>
        <p className={ this.cn`__card-description` }>{ description }</p>
      </Card>
    );
  };

  renderBannerCard = (vision) => {
    const { title, slug, description } = vision;
    return (
      <Card
        className={ this.cn`__banner-card` }
        key={ title }
      >
        <img
          alt=""
          className={ this.cn`__banner-card-image` }
          src={ `/ai/${slug}.png` }
        />
        <h4 className={ this.cn`__banner-card-title` }>{ title }</h4>
        <p className={ this.cn`__banner-card-copy` }>{ description }</p>
      </Card>
    );
  };

  renderCardSection = () => (
    <section className={ this.cn`__container` }>
      <h1 className={ this.cn`__title` }> Current Issues </h1>
      <div className={ this.cn`__responsive-container` }>
        { CURRENT_ISSUES_CARDS.map(this.renderCurrentIssuesCard) }
      </div>
    </section>
  );

  renderBannerSection = () => (
    <section className={ this.cn`__banner` }>
      <div className={ this.cn`__container __banner-container` }>
        <h1 className={ this.cn`__title __banner-title` }> Our Vision </h1>
        { /* eslint-disable max-len */ }
        <p className={ this.cn`__banner-description` }>A world where <span className={ this.cn`__banner-description-colored-green` }> secure, trusted identity </span> is a universal human right, empowering <span className={ this.cn`__banner-description-colored-green` }>opportunity and access</span> &ndash; and technical interoperability facilitates an open ecosystem, enabling <span className={ this.cn`__banner-description-colored-green` }> barrier-free global value exchange</span></p>
        <div className={ this.cn`__banner-card-container` }>
          { VISION_CARDS.map(this.renderBannerCard) }
        </div>
      </div>
    </section>
  );

  renderPrinciplesSection = () => (
    <section className={ this.cn`__principles` }>
      <div className={ this.cn`__container` }>
        <h1>Our Principles</h1>
        <p>Trust Stamp's core biometric principles provide the framework to navigate the complex, evolving world of digital identity to ensure that our technologies are used ethically, are implemented equitably, and power solutions in line with Trust Stamp’s mission and vision.</p>
        <a className={ this.cn`ts-Button` } href={ asset`/ai/BiometricPrinciples.pdf` } target="_blank" rel="noopener noreferrer">View Principles</a>
      </div>
    </section>
  )

  render() {
    return (
      <div className={ this.rootcn() }>
        <HeroComponent title="Our Mission" extraClass="our-mission" />
        { this.renderTopSection() }
        { this.renderCardSection() }
        { this.renderBannerSection() }
        { this.renderPrinciplesSection() }
      </div>
    );
  }
}
