/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router';
import BasePage from 'pages/BasePage';
import { asset } from 'sf/helpers';
import { ROUTES } from 'constants';
import Banner from 'components/Banner';
import Benefits from 'components/Benefits';
import Cards from 'components/Cards';
import { TITLE, CONTENT, CONTENT2 } from './constant';
import help from 'models/help';

export default class AgeEstimation extends BasePage {
  className = 'ts-AgeEstimation';
  static title = 'AI-powered Secure Selfie Age Estimation';
  static description =
    "Elevate your business with Trust Stamp's Age Estimation. Ensure regulatory compliance, enhance user experience, and unlock new market opportunities.";

  componentDidMount() {
    help.set({ showDemoButton: true });
  }

  componentWillUnmount() {
    help.set({ showDemoButton: false });
    super.componentWillUnmount();
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        { /* banner */ }
        <Banner theme="age-estimation" size="small">
          <h1>Age Estimation</h1>
        </Banner>

        <section className={ this.cn`__documents` }>
          <div className={ this.cn`__documents-content ts-container` }>
            <div className={ this.cn`__documents-left-content` }>
              <h6>Age Estimation</h6>
              <h2>
                Unlock new market opportunities with secure selfie age checks
              </h2>
              <p className="ts-p">
                Navigating the digital world safely and responsibly is more
                crucial than ever, especially when it comes to protecting our
                younger users. At Trust Stamp, we're pioneering the forefront of
                age assurance technology with our innovative Age Estimation
                solution, designed to foster safer online environments without
                compromising privacy.
              </p>
              <br />
              <Link to={ ROUTES.CONTACT_US } className="ts-button--filled">
                GET IN TOUCH
              </Link>
            </div>
            <div className={ this.cn`__documents-right-content` }>
              <img
                src={ asset`ai/Age_Estimation.png` }
                alt="Trust Stamp - Age Estimation"
              />
            </div>
          </div>
        </section>

        <section>
          <div className="ts-container">
            <h2>
              Streamline age verification without requiring identity documents
            </h2>
            Our Age Estimation technology utilizes state-of-the-art facial
            analysis to approximate the age range of users, providing a seamless
            way for digital platforms to ensure content suitability and comply
            with age-related regulations. By analyzing visual cues, our solution
            places users within specific age bands, ensuring a high level of
            accuracy without the need for personal identification documents.
            <br />
            <br />
          </div>
          <Cards content={ CONTENT2 } columnCount={ 4 } />
        </section>

        <Benefits
          subTitle="3 SIMPLE STEPS"
          sectionTitle={ TITLE }
          content={ CONTENT }
          colored={ true }
        />

        <section>
          <div className="ts-container">
            <h6>GET IN TOUCH</h6>
            <h2>Ready to Revolutionize Your Age Verification Process?</h2>
            Discover how Trust Stamp’s Age Estimation can transform your
            business, making age verification a seamless part of your digital
            interaction. Let’s create a future where every online experience
            begins with trust and confidence.
            <br />
            <br />
            <Link to={ ROUTES.CONTACT_US } className="ts-button--primary">
              CONTACT US
            </Link>
          </div>
        </section>
      </div>
    );
  }
}
