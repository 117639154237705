import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import Dialog, { DialogFooter, DialogText } from 'components/Dialog';
import user from 'models/user';

export default class SignOutModal extends BaseComponent {
  className = 'ts-SignOutModal';

  static propTypes = {
    children: PropTypes.node.isRequired,
    linkClickCallback: PropTypes.func,
  };

  static defaultProps = {
    modalTitle: 'Log out',
    linkClickCallback: noop,
  };

  handleClick = (e) => {
    e.preventDefault();
    this.refs.dialog.toggle(true);
    this.props.linkClickCallback();
  }

  handleCancelButtonClick = () => {
    this.refs.dialog.toggle(false);
  }

  handleLogOutButtonClick = () => {
    this.refs.dialog.toggle(false);
    user.logOut({ redirect: true, reason: 'click' });
  }

  render() {
    return (
      <div>
        <a
          className={ this.rootcn() }
          href="#"
          onClick={ this.handleClick }
        >
          { this.props.children }
        </a>
        <Dialog
          ref="dialog"
          size="small"
          title="Log out"
        >
          <DialogText>
            <p>Are you sure want to log out from Trust Stamp?</p>
          </DialogText>
          <DialogFooter>
            <Button
              theme=""
              onClick={ this.handleCancelButtonClick }
            >
              Cancel
            </Button>
            <Button
              theme="success"
              onClick={ this.handleLogOutButtonClick }
            >
              Continue
            </Button>
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}
