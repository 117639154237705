import React, { Fragment } from 'react';
import { asset } from 'helpers';

/* eslint-disable max-len */
export const COPY_BANKING = {
  sectionTop: [{
    slug: 'customizable-solutions',
    text: [
      <span
        key="title"
        className="ts-CorporateIndustries__section__paragraph"
      >
        Complete customizable solutions available on-premises, hosted or via API include:
      </span>,
      <ul
        key="list"
        className="ts-CorporateIndustries__section__paragraph"
      >
        <li>Apply-By-Smile™ customer onboarding</li>
        <li>Beneficial ownership registration</li>
        <li>Call Center access optimization</li>
        <li>Device-based fraud detection</li>
        <li>Encrypted e-mail and messaging</li>
        <li>Facial and Behavioral Biometrics</li>
        <li>Graylisting™ allowing identity fraud detection intra or inter institution using non-PII data</li>
        <li>KYC / AML for new and existing accounts (including fast-track remediation projects)</li>
        <li>Multi-factor and step-up authentication</li>
        <li>Online account access for customers, vendors and staff</li>
        <li>Password recovery and OTP/SMS replacement</li>
        <li>Synthetic Identity elimination</li>
        <li>Wire-transfer authorizations </li>
      </ul>,
    ],
    image: asset`ai/corporate-industries/cart-paying.jpg`,
    pictureClass: 'small',
    textClass: 'wide-lg',
  }],
  sectionWithVideo: {
    title: 'Step Up Authentication',
    imgTitle: 'Apply by Selfie',
    text: [
      {
        slug: 'paragraph-first',
        paragraph: <Fragment>When a suspected account take-over occurs, Trust Stamp’s step-up authentication
          allows enterprises to send the customer a secure message with a link prompting them to
          confirm their identity by taking a “secure selfie”. By comparing the secure selfie to
          the biometric IT<sup>2</sup> collected at registration (for pre-registered customers) or to photo-ID
          and online images (for non-registered customers), you can quickly authenticate legitimate
          customers and provide instant account access
                   </Fragment>,
      }, {
        slug: 'paragraph-second',
        paragraph: <Fragment>Along with our step-up authentication,
          our proprietary biometric IT<sup>2</sup> can be used to search pictures of other account holders,
          fraudulently created with stolen or synthetic identities, who might be using the same
          face in a process we call “Greylisting”. This process continues to catch fraudsters using
          the same face for multiple accounts, allowing our clients to give their customers a piece
          of mind that their accounts and identities are safe
                   </Fragment>,
      }],
    image: asset`ai/phone-transparent.png`,
  },
};

export const FEATURES_BANKING = [
  {
    icon: asset`ai/corporate-industries/icon-email.png`,
    title: 'Identity Fraud',
    text: 'Send a link to your customer before you commence a business relationship',
  },
  {
    icon: asset`ai/corporate-industries/icon-laptop.png`,
    title: 'Wire Fraud',
    text: 'The customer provides a selfie, photos of their Identity and address ' +
    'documents and then completes a short online form',
  },
  {
    icon: asset`ai/corporate-industries/icon-monitor.png`,
    title: 'Money Laundering',
    text: 'You verify the data provided and a report is created and stored for your Organisation',
  },
];

export const COPY_KYC = [
  {
    slug: 'know-your-customers',
    title: 'Know Your Customer and Anti-Money Laundering',
    text: `
To address the exponential growth in KYC/AML requirements,
we empower businesses with a completely automated transaction including
our facial biometric tools and proprietary document OCR. Our multi-layer,
automated, risk-based process speeds the process for both institutions
and customers and provides audit trails, compliance reports and fuzzy logic
to identify risky transactions and potential non-compliance.
    `,
    image: asset`ai/home_4.jpg`,
    pictureClass: 'visible-md-up',
    isContactButtonShowed: true,
  },
];

export const COPY_P2P = [
  {
    slug: 'custom-branded',
    title: 'Custom-Branded ID Verification & Onboarding Tool',
    text: `
Every enterprise needs to establish trust, verify identity, and manage
the relationships with the people whom your company interacts with. Trust Stamp’s pay-per-use
utility is a turn-key platform that enables you as an administrator to immediately verify your
customers, employees and contractors. Through the platform, you will be able to send SMS /
email invitations which will prompt your users through the white-labeled Trust Stamp
verification process.
    `,
    image: asset`ai/p2p-1.jpg`,
    pictureClass: 'p2p-1',
    textClass: 'wide-lg',
  }, {
    slug: 'biometric-login',
    title: 'Biometric Login',
    text: `
With the increase in internet-mediated transactions, providing speed and
convenience as a part of your service, product, or platform is becoming more critical.
Biometric Login, powered by Trust Stamp’s core identity solution, separates your company from
the competition by providing a fast and secure biometric integration for your customers
to access their accounts.
    `,
    image: asset`ai/p2p-2.jpg`,
    pictureClass: 'p2p-2',
    textClass: 'wide-lg',
  }, {
    slug: 'replacement-of-otp-via-sms',
    title: 'Replacement of OTP-via-SMS',
    text: `
SMS-OTP’s can be easily intercepted by criminals, compromising existing two-factor
authentication processes. Trust Stamp has created a replacement for SMS-OTP’s with web channel
delivery of encrypted passwords that are only decrypted by the biometrics of the intended
recipient.
    `,
    image: asset`ai/p2p-3.jpg`,
    pictureClass: 'p2p-1',
    textClass: 'wide-lg',
    isContactButtonShowed: true,
  },
];

export const COPY_BIOMETRIC_CHECKIN = [
  {
    slug: 'streamlined-event-registration',
    title: 'Streamlined Event Registration and Check-In with Enhanced Security',
    text: <Fragment>
      To speed up event registration and check-in, Trust Stamp has developed a biometric application that allows attendees to verify their identity using only facial biometrics. The technology functions as an added security measure and utilizes Trust Stamp’s facial recognition, IT<sup>2</sup> and Identity Lake\u2122 technologies through the medium of a browser-accessed biometric check-in application.
      Attendees register by taking a secure-selfie and entering basic information. Biometric data is uploaded to a secure server via an endpoint. All data is irreversibly transformed into a hash using a proprietary IT<sup>2</sup> Translator and stored only in hashed form. The attendee is then able to check-in using only a secure-selfie which is matched against all known hashes for authentication.
    </Fragment>,
    linkToFollowText: 'Link to register for IAFCI Biometric Check-In coming soon',
    image: asset`ai/iafci.jpg`,
    pictureClass: 'p2p-1',
    textClass: 'wide-lg',
  }, {
    slug: 'biometric-login',
    title: 'Benefits include:',
    text: [
      <ul
        key="list"
        className="ts-CorporateIndustries__section__paragraph"
      >
        <li>Speed: streamline the process of registration and check-in at the event by automating authentication and enabling self check-in</li>
        <li>Security: Know that your attendees are who they say they are and can be trusted</li>
        <li>Insight: Add custom pivot points to PII information that is relevant to your event and determine who is attending individual sessions</li>
        <li>Technology: establish your organization an early innovator while creating a memorable experience for your attendees</li>
        <li>Convenience: unlike with other biometric check-in services, we don’t require additional hardware. The entire process is carried out on the attendee’s camera-enabled cell phone</li>
      </ul>,
    ],
    image: asset`ai/conference2.jpg`,
    pictureClass: 'p2p-2',
    textClass: 'wide-lg',
  },
];

export const COPY_TOKENIZED_IDENTITY = [
  {
    slug: 'tokenized-identity',
    title: 'Tokenized Identity and Zero Knowledge Proofs',
    text: (
      <Fragment>
        <p>Our patented technology creates tokenized identities (using a proprietary IT<sup>2</sup> architecture) from any modality or source allowing comparison of like-modality templates from different sources, and breaking vendor lock-in.</p>
        <p>The Irreversibly Transformed Identity Token (IT<sup>2</sup>) is created using a one-way transformation and is classified as "pseudonymized data” under legislation such as GDPR. Using our hashing to create digital identities protects against databreaches and allows controlled data sharing as only our AI can create a probabilistic zero knowledge proof as to whether two IT<sup>2</sup>s originated from the same person.</p>
        <p>Our IT<sup>2</sup> can also act as a pivot to unlimited externally stored data and using pivot tables, the linked data and access permissions can be changed to reflect changes in data needs and render data access “self-sovereign”.</p>
      </Fragment>
    ),
    image: asset`ai/tokenized-identity first-3-paragraphs@2x.jpg`,
    pictureClass: 'p2p-1',
    textClass: 'wide-lg',
  }, {
    slug: 'biometric-login',
    title: 'Biometric Login',
    text: (
      <Fragment>
        <p>Combined with proof of life and other presentation attack detection AI’s, our hashing process identifies duplicate and synthetic identities that pass siloed KYC/AML/onboarding processes.</p>
        <p>The IT<sup>2</sup> can be stored on-premises and/or in the cloud and/or in a blockchain or other distributed ledger, or even on the originating device. To counter fraud, the IT<sup>2</sup> can be stored or copied to a shared Identity Lake™  and/or used to service zero-knowledge-proofs.</p>
        <p>Whether you need to onboard new users, provide access to existing accounts, authenticate for higher risk transactions, identify persons-of-interest, or create identities for undocumentedor thin-file subjects, our hashing technology will work with your existing and future authentication modalities to provide an unparalleled level of efficiency and security for your identity programs.</p>
      </Fragment>
    ),
    image: asset`ai/tokenized-identity-last-3-paragraphs@2x.jpg`,
    pictureClass: 'p2p-2',
    textClass: 'wide-lg',
  },
];

export const COPY_PUBLIC_SERVICES = [
  {
    slug: 'our-solution',
    title: 'Our Solution',
    text: `
Our solutions are trusted by state public service and law enforcement
entities across North America to combat human trafficking, facilitate cross-border
information sharing, and identify criminals. In addition, we are blockchain enabled.
Our proprietary biometric hash can be stored on a private or public blockchain and
used as a reference or pivot point for authentication or data transactions with
minimal storage cost and latency.
    `,
    image: asset`ai/home_5.jpg`,
    pictureClass: 'visible-md-up',
    isContactButtonShowed: true,
  },
];

export const COPY_REAL_ESTATE = {
  sectionTop: [{
    slug: 'using-truststamp-for-your-business',
    title: 'Using Trust Stamp for your business',
    text: `
We use sophisticated algorithms and artificial intelligence to prove your
customer's identity then our service reads the data from your customer's
documents to create the customer report. Agents must confirm the data provided
by their customers and that they have met them. Our service assists
Estate Agents in meeting the requirements of the Regulations but is not a
substitute for the Risk Based Assessment demanded by law.
    `,
    image: asset`ai/ipad.png`,
    pictureClass: 'ipad',
  }],
  sectionBottom: [{
    slug: 'how-it-works',
    title: 'How it works',
    text: <p>The Trust Stamp App for Realtors<sup>&reg;</sup> is a quick and simple tool to establish the identity and trustworthiness of a stranger before meeting them and/or providing access to your client’s home or property. This App is available only to members of the National Association of Realtors<sup>&reg;</sup> and is accessed using your NRDS number. Register now, or contact us to learn more.</p>,
    image: asset`ai/real-estate.jpg`,
    pictureClass: 'img-small',
  }],
};

export const FEATURES_REAL_ESTATE = [
  {
    icon: asset`ai/corporate-industries/icon-send.svg`,
    text: 'Send a link to your customer before you commence a business relationship',
  },
  {
    icon: asset`ai/corporate-industries/icon-photo.svg`,
    text: 'The customer provides a selfie, photos of their Identity and address ' +
    'documents and then completes a short online form',
  },
  {
    icon: asset`ai/corporate-industries/icon-newspaper.svg`,
    text: 'You verify the data provided and a report is created and stored for your Organisation',
  },
];

export const COPY_SECURED_EMAIL = [
  {
    slug: 'your-solution-to-email-fraud',
    title: 'Your Solution to Email Fraud',
    text: `
When we share confidential information with a confidante we ideally do
so “face to face” but how can that work using the speed and convenience of an e-mail?
With Trusted Mail the recipient is confident that the communication was generated by
a specific sender and has not been tampered with en-route. To protect e-mail in transit,
Trusted Mail layers proprietary encryption techniques utilizing hashed facial biometrics.
    `,
    image: asset`ai/phone_email.png`,
    pictureClass: 'email-phone',
    textClass: 'email-phone',
    appstoreBadges: {
      'app-store': 'https://itunes.apple.com/app/trusted-mail/id1299701700',
      'google-play': 'https://play.google.com/store/apps/details?id=com.tenclouds.trustedmail',
    },
    additionalButton: {
      caption: 'Visit Trusted Mail',
      href: 'https://trustedmail.pro',
    },
  }, {
    slug: 'how-truststamp-mail-works',
    title: 'How Trusted Mail Works',
    text: `
Trusted Mail requires a facial-biometric sign-in to create and send a secure e-mail.
The authorized recipient likewise uses their facial-biometric sign-in to open and decrypt
the e-mail. Mail can be designated as one-to-one or one-to-many potentially permitting
multiple authorized recipients to decrypt the email.
    `,
    image: asset`ai/email-2.png`,
    pictureClass: 'email-2',
    textClass: 'email-2',
    isContactButtonShowed: true,
  },
];

export const COPY_TECHNOLOGY = [
  {
    slug: 'proof-of-liveness',
    title: 'Proof of Liveness',
    text: <Fragment>We authenticate users by applying multiple proof-of-liveness /
      attack detection techniques to a still image or video (“secure selfie”).
      Pattern matching AI compares the secure selfie to a government-issued photo
      ID and/or images mined from social media or proprietary sources to attach a
      verified identity. The secure selfie is then converted into a 3-D mask and a
      biometric IT<sup>2</sup> which is stored on a server and/or identity Blockchain.
      The IT<sup>2</sup> can be used for subsequent authentications or to attach and access relevant data.
    </Fragment>,
    image: asset`ai/corporate-industries/technology-1.jpg`,
    pictureClass: 'p2p-1',
    textClass: 'wide-lg',
  }, {
    slug: 'evergreen-hash',
    title: <Fragment>IT<sup>2</sup></Fragment>,
    text: [
      <p
        className="ts-CorporateIndustries__section__paragraph"
        key="first"
      >
        The future of authentication is multi-factor with active authentication supplemented “passive” techniques. Unfortunately, each technique creates its own “identity” which is then tied back to the forms of PII that are causing the synthetic identity crisis, and each new “identity” needs to be integrated into enterprise data flows causing expensive implantation challenges.
      </p>,
      <p
        className="ts-CorporateIndustries__section__paragraph"
        key="second"
      >
        Our patented IT<sup>2</sup> uses AI facial biometrics to store an encrypted IT<sup>2</sup> unique to the user’s face, however, the IT<sup>2</sup> architecture can use (or add) any alternate authentication and every IT<sup>2</sup> is comparable regardless of the method. The IT<sup>2</sup> protects against system and data redundancy providing a lifelong “digital-DNA” that can store (or pivot to) any type of KYC or relationship data with fields individually hashed or (salted and) encrypted, facilitating selective data sharing.
      </p>,
    ],
    image: asset`ai/corporate-industries/technology-evergreen-hash.jpg`,
    pictureClass: 'p2p-3',
    textClass: 'wide-lg',
  }, {
    slug: 'flexible-delivery',
    title: 'Flexible delivery',
    text: `
Our solutions can be delivered on premises and integrated within an enterprise code base or as
a containerized solution, via API, as a blockchain solution and / or built, hosted and 100% managed by us. We can achieve
this partly because of our investment in MicroService architecture and partly because our
own expert AI researchers and engineers are housed within a diverse 100+ engineer
partner-enterprise that gives us the flexibility to rapidly scale up and down as needed.
This also enables us to deliver fully effective proofs of concept with minimal integration
requirements allowing ideas to be quickly tested and iterated.
    `,
    image: asset`ai/corporate-industries/technology-2.jpg`,
    pictureClass: 'align-right',
    textClass: 'wide-lg',
  },
];
/* eslint-enable */
