import React, { Fragment } from 'react';
import Button from 'components/Button';
import BasePage from 'pages/BasePage';
import { asset } from 'sf/helpers';
import Card from 'sf/components/Card';
import { ROUTES } from 'constants';
import Dialog from 'components/Dialog';
import { Col, Row } from 'sf/components/Grid';
import HeroComponent from '../CorporateIndustries/CorporateComponents/CorporateIndustriesHero';

import {
  HASH_CARDS,
  LAKE_CARDS,
  USE_CASES2,
  PROCESS_CARDS1,
  PROCESS_CARDS2,
} from './constants';

export default class Technology extends BasePage {
  className = 'ts-Technology';
  static title = 'Technology';
  // eslint-disable-next-line max-len
  static description = 'We research and leverage artificial intelligence and machine learning to deliver the most comprehensive facial biometric identity authentication solution.';

  state = {
    activeProcessId: null,
    activeProcess: null,
    inTransition: false,
    isDialogVisible: false,
  };

  renderTopSection = () => (
    <section className={ this.cn`__top` }>
      <div className={ this.cn`__container __top-container` }>
        <div className={ this.cn`__top-intro` }>
          <h2 className={ this.cn`__title` }>
            { /* eslint-disable max-len */ }
            Technology that creates unique, irreversible hashed-tokenized identities and facilitates secure, interoperable identity proofing – anywhere in the world, online and offline
          </h2>
        </div>
        <img
          className={ this.cn`__top-logos` }
          src={ asset`/ai/technology-new-1.png` }
          alt="logos"
        />
      </div>
    </section>
  );

  renderBannerCard = (hash) => {
    const { title, slug, description } = hash;
    return (
      <Card
        className={ this.cn`__banner-card` }
        key={ title }
      >
        <img
          alt=""
          className={ this.cn`__banner-card-image` }
          src={ `/ai/${slug}.png` }
        />
        <h4 className={ this.cn`__banner-card-title` }>{ title }</h4>
        <p className={ this.cn`__banner-card-copy` }>{ description }</p>
      </Card>
    );
  };

  renderBannerSection = (arrOfCards, title, modifier) => {
    const classNameModifier = {
      '__banner': true,
      '__banner--background-navy': modifier === 'navy',
      '__banner--background-green': modifier === 'green',
    };

    return (
      <section className={ this.rootcn(classNameModifier) }>
        <div className={ this.cn`__container __banner-container` }>
          <h2 className={ this.cn`__title __banner-title` }> { title } </h2>
          <div className={ this.cn`__banner-card-container` }>
            { arrOfCards.map(this.renderBannerCard) }
          </div>
        </div>
      </section>
    );
  };

  renderUsecaseSection = (arrOfUsecases, imgSlug, url) => (
    <section className={ this.cn`__useCase` }>
      <div className={ this.cn`__container __useCase-container` }>
        <img
          className={ this.cn`__useCase-image` }
          src={ asset`/ai/${imgSlug}.png` }
          alt={ imgSlug }
        />

        <div className={ this.cn`__useCase-content` }>
          { arrOfUsecases.map((useCase) => (
            <div key={ useCase.title }>
              <h2 className={ this.cn`__title __useCase-content-title` }>{ useCase.title }</h2>
              <ul className={ this.cn`__useCase-content-subItems` }>
                { useCase.subItems.map((item) => (
                  <li key={ item }>{ item }</li>
                )) }
              </ul>
            </div>
          )) }
          <Button
            onClick={ () => this.navigate(url) }
            className={ this.cn`__useCase__button` }
          >
            Use Cases
          </Button>
        </div>
      </div>
    </section>
  );

  handleProcessCardClick(event, { slug: processSlug }, processes) {
    event.preventDefault();
    const activeProcessId = processes.findIndex(({ slug }) => slug === processSlug);
    if (!processes[activeProcessId].description) return;

    this.setState({
      activeProcess: processes,
      activeProcessId,
    });
  }

  mapProcessCard = (processes) => {
    return processes.map((process) => {
      return (
        <Card
          className={ this.cn`__card` }
          key={ process.title }
          onClick={ (event) => this.handleProcessCardClick(event, process, processes) }
        >
          { process.description && <span className={ this.cn`__card-expand-span` }>+</span> }
          <img
            alt=""
            className={ this.cn`__card-image` }
            src={ `/ai/${process.slug}.png` }
          />
          <h4 className={ this.cn`__card-title` }>{ process.title }</h4>
          <p className={ this.cn`__card-copy` }>{ process.excerpt }</p>
        </Card>
      );
    });
  };

  renderCardSection = (src) => (
    <section className={ this.cn`__cards` }>
      <div className={ this.cn`__container` }>
        <h2 className={ this.cn`__title __cards-title` }> Process </h2>
        <div className={ this.cn`__responsive-container` }>
          { this.mapProcessCard(src) }
        </div>
      </div>
    </section>
  );

  renderProcess = ({ process, itemModifier, textModifier, pictureModifier } = {}) => {
    const className = (prefix, modifier) => {
      return {
        [`${prefix}`]: true,
        [`${prefix}--${modifier}`]: modifier,
      };
    };

    return (
      <div className={ this.cn(className('__process', itemModifier)) }>
        <img
          alt={ `${process.name}` }
          className={ this.cn(className('__process-image', pictureModifier)) }
          // src={ cacheParam(asset`/ai/${benefit.slug}.svg`) }
          src={ `/ai/${process.slug}.png` }
        />
        <p className={ this.cn(className('__process-title', textModifier)) }>
          { process.title }
        </p>
      </div>
    );
  };

  renderProcessesDetails = (processes) => {
    const process = processes[this.state.activeProcessId];
    return (
      <div
        className={ this.cn`__dialog-process` }
        style={ {
          height: this.state.processHeight,
        } }
      >
        <div
          className={ this.cn`__dialog-process-wrapper` }
          ref={ (wrapper) => { this.processWrapper = wrapper; } }
        >
          { this.renderProcess({
            process,
            itemModifier: 'center',
            textModifier: 'big',
            pictureModifier: 'small',
          }) }

          <div
            className={ this.cn({
              '__dialog-description': true,
              '__dialog-description--in-transition': this.state.inTransition,
            }) }
          >
            { process.description }
          </div>
        </div>
      </div>
    );
  };

  renderBenefitsDialog = () => {
    const { activeProcessId, activeProcess } = this.state;
    if (activeProcessId === null) return;

    return (
      <Dialog
        className={ this.cn`__dialog` }
        size="xlarge"
        ref="dialog"
        visible={ true }
        onDismiss={ () => this.setState({ activeProcessId: null }) }
      >
        <Row>
          <Col initial={ 12 }>
            <Row>
              { this.renderProcessesDetails(activeProcess) }
            </Row>
          </Col>
        </Row>
      </Dialog>
    );
  };

  render() {
    return (
      <div className={ this.rootcn() }>
        <HeroComponent title="Technology" extraClass="technology" />
        { this.renderTopSection() }
        { this.renderBannerSection(HASH_CARDS, <Fragment>IT<sup>2</sup></Fragment>, 'navy') }
        { /* { this.renderUsecaseSection(USE_CASES1, 'technology-new-2', ROUTES.BANKING_AND_FINTECH) } */ }
        { this.renderBenefitsDialog() }
        { this.renderCardSection(PROCESS_CARDS1) }
        { this.renderBannerSection(LAKE_CARDS, 'The Identity Lake™', 'green') }
        { this.renderUsecaseSection(USE_CASES2, 'technology-new-3', ROUTES.HUMANITARIAN) }
        { this.renderCardSection(PROCESS_CARDS2) }
      </div>
    );
  }
}
