/* eslint-disable max-len */
import React from 'react';

import BaseComponent from 'components/BaseComponent';
import Benefits from 'components/Benefits';
import HowItWorks from 'components/HowItWorks';

import { asset } from 'sf/helpers';

import { TITLE, HOWITWORKS, TITLE2, CONTENT } from '../../constants';

export default class CorporateKycContent extends BaseComponent {
  className = 'ts-CorporateKycContent';

  render() {
    return (
      <div className={ this.rootcn() }>
        <section>
          <div className="ts-container">
            <div className={ this.cn`__column __column--left` }>
              <h6>Unmasking Identity Fraud</h6>
              <h2>Advanced Detection for today’s identity fraud</h2>
              <p>
                Velocity attacks and Synthetic identity fraud poses unique
                challenges, as it often evades traditional detection systems.
                Trust Stamp's Velocity Graylist technology identifies velocity
                attacks and synthetic identity fraud by examining whether the
                fraudsters are repeatedly opening accounts. By securing your
                system against these emerging threats, we safeguard your
                institution's integrity and your customers' trust.
              </p>
            </div>
            <div className={ this.cn`__column __column--right` }>
              <img
                src={ asset`ai/technology-new-1.png` }
                alt="Privacy and data protection"
                width="217px"
              />
            </div>
          </div>
        </section>

        <HowItWorks content={ HOWITWORKS } sectionTitle={ TITLE } columnCount={ 4 } />

        <Benefits subTitle="Protection" sectionTitle={ TITLE2 } content={ CONTENT } />
      </div>
    );
  }
}
