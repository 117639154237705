import React from 'react';
import baseFunctionalComponent from 'components/BaseFunctionalComponent';
import PrivacyPolicy from 'components/PrivacyPolicy';
import Button from 'sf/components/Button';
import Dialog from 'sf/components/Dialog';

function PrivacyPolicyDialog() {
  return (
    <Button
      className={ this.rootcn() }
      theme="link-unstyled"
      type="button"
      onClick={ () => this.paymentDialogInstance.toggle(true) }
    >
      Privacy Policy
      <Dialog
        className={ this.cn`__dialog` }
        ref={ (dialog) => {
          this.paymentDialogInstance = dialog;
        } }
        onDismiss={ this.handlePaymentDialogDismiss }
        size="xlarge"
      >
        <PrivacyPolicy />
      </Dialog>
    </Button>
  );
}

export default baseFunctionalComponent(
  PrivacyPolicyDialog,
  'PrivacyPolicyDialog'
);
