import * as TYPES from 'pages/UserSettings/UserSettings.types';

const initialState = {};

export default function (state = initialState, { type }) {
  switch (type) {
    case TYPES.INITIALIZE_SELFIE_UPDATE:
      return {
        ...state,
        isRetakeSelfieDialogVisible: true,
      };
    case TYPES.HIDE_RETAKE_SELFIE_DIALOG:
    case TYPES.SELFIE_UPDATE_FAILURE:
    case TYPES.SELFIE_UPDATE_SUCCESS:
      return {
        ...state,
        isRetakeSelfieDialogVisible: false,
      };
    default:
      return state;
  }
}
