import React from 'react';
import PropTypes from 'prop-types';

import BaseComponent from 'components/BaseComponent';

class AdminPanelLayout extends BaseComponent {
  className = 'ts-AdminPanelLayout';

  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <div className={ this.rootcn() } >
        { this.props.children }
      </div>
    );
  }
}

export default AdminPanelLayout;
