import webcam from 'mighty-webcamjs';
import throttle from 'lodash/throttle';
import is from 'next-is';

import { CAPTURE_MODE_AUTO } from 'sf/components/Webcam/constants';
import { asset, FPS } from 'sf/helpers';
import { getCanvas } from 'sf/helpers/canvas';
import webcamModel from 'sf/models/webcam';
import { getCrossOriginWorker } from 'cross-origin-worker';

let workerMessageId = Number.MIN_SAFE_INTEGER;
const debug = process.env.NODE_ENV !== 'production';

function createWorker() {
  return getCrossOriginWorker(asset('lib/barcode-detector.worker.js'));
}

/**
 * MAX_FRAME_RATE is there to save CPU/GPU power.
 *
 * @type {Number}
 */
const MAX_FRAME_RATE = 20;

export default {
  readPhoto: async (canvasOrDataURI) => {
    const worker = await createWorker();

    return new Promise((resolve, reject) => {
      getCanvas(canvasOrDataURI)
        .then((canvas) => {
          worker.onmessage = (e) => {
            resolve(e.data);
            worker.terminate();
            canvas.remove();
          };

          worker.postMessage({
            id: ++workerMessageId,
            imageData: canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height),
          });
        })
        .catch(reject);
    });
  },

  getCaptureTrigger: (_options = {}) => {
    const options = {
      barcodeType: 'pdf417',
      ..._options,
    };

    return ({ video, trigger }) => {
      const startTimeStampMs = Date.now();
      let canvas = document.createElement('canvas');
      let canvasContext = canvas.getContext('2d', { willReadFrequently: true });
      let fps;
      let startTime;
      let worker;

      const doScan = throttle(() => {
        const { overlaySize } = webcamModel.get();
        startTime = Date.now();

        if (!canvas) {
          return;
        }
        // NOTE: videoWidth and videoHeight can change over time (resolution scanner)
        canvas.width = overlaySize.width || video.videoWidth;
        canvas.height = overlaySize.height || video.videoHeight;

        if (!video || !video.videoWidth || !video.videoHeight) {
          // This might happen while device rotation
          return setTimeout(doScan, 100);
        }

        const sx = overlaySize.x || 0;
        const sy = overlaySize.y || 0;
        const sw = canvas.width;
        const sh = canvas.height;

        /* eslint-disable function-paren-newline */
        canvasContext.drawImage(
          video, // source frame
          sx, sy, sw, sh, // source x, y, width, height
          0, 0, sw, sh, // destination x, y, width, height
        );
        /* eslint-enable function-paren-newline */
        const croppedImageData = canvasContext.getImageData(0, 0, canvas.width, canvas.height);

        worker.postMessage({
          barcodeType: options.barcodeType,
          id: ++workerMessageId,
          imageData: croppedImageData,
        });
      }, 1000 / MAX_FRAME_RATE, { leading: true });

      return {
        run: async () => {
          if (debug) {
            console.info('barcode worker start'); // eslint-disable-line no-console
          }
          fps = new FPS('barcodeDetector FPS:');

          worker = await createWorker();
          worker.onmessage = ({ data }) => {
            const { imageData, result } = data;
            fps.count();

            if (debug) {
              const initText = result ? 'Barcode read succesfully.' : 'Barcode scan done.';
              // eslint-disable-next-line no-console
              console.info(
                `[Detector] ${initText} Processing time:`,
                `${((Date.now() - startTime) / 1000)}s`,
                'Data from worker:',
                data,
              );
            }

            if (result) {
              if (webcam.params.get('captureInProgress')) {
                // Button manually pressed. Barcode trigger will be terminated soon.
                return;
              }

              // NOTE: `trigger` called with `canvas` arg is possible as long, as we don't
              //       run multiple scans simultaneously
              return trigger(imageData, {
                barcode_data: is.isArray(data.result) ? data.result[0] : data.result,
                pic_time: Date.now() - startTimeStampMs,
                pic_mode: CAPTURE_MODE_AUTO,
                source: 'captureTrigger',
                original_image_size: `${video.videoWidth}x${video.videoHeight}`,
                compressed_image_size: `${imageData.width}x${imageData.height}`,
                detector_used: 'BarcodeDetector',
              });
            }

            doScan(); // continue scanning until barcode is there
            if (debug && data.err) {
              console.error(data.err); // eslint-disable-line no-console
            }
          };

          if (canvas) {
            doScan();
          } else {
            // captureTrigger.remove() called while worker creation
            worker.terminate();
            worker = null;
          }
        },
        remove: () => {
          if (debug) {
            console.info('barcode worker termination'); // eslint-disable-line no-console
          }
          if (worker) {
            worker.terminate();
            worker = null;
          }
          fps.remove();
          canvas.remove();
          canvas = null;
          canvasContext = null;
        },
      };
    };
  }
};
