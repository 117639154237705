import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';


/**
 * This doesn't use TS spinner svg
 * @param {any} [PropTypes.oneOf(['small'
 * @param {any} 'medium'
 * @param {any} 'large'
 * @param {any} 'xlarge']
 * @returns {any}
 */
export default class Loader extends BaseComponent {
  className = 'ts-Loader';

  static propTypes = {
    size: PropTypes.oneOfType([
      PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
      PropTypes.number,
    ]),
  };

  static defaultProps = {
    size: 'medium',
  };

  sizes = {
    small: 24,
    medium: 48,
    large: 80,
    xlarge: 120,
  };

  render() {
    const classNames = {
      [`--${this.props.size}`]: true,
    };

    // Calculate the width based on the size prop
    const size = `${this.sizes[this.props.size] || this.props.size}px`;

    return (
      <div
        className={ this.rootcn(classNames) }
        style={ {
          width: size,
          height: size, // Set height to match width
        } }
      >
        <span className={ this.cn`__circle` }></span>
      </div>
    );
  }
}
