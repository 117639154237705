/* eslint-disable max-len */
import { ROUTES } from 'constants';

export const TITLE = 'Low-friction, simplified verification';

export const CONTENT = [
  {
    id: 1,
    title: 'Easy Integration',
    text: 'Easily implement Trust Stamp\'s ID verification solution into your user flows for customer onboarding, account recovery, second chance approval, and more. The solution works across devices and platforms, within your mobile, desktop, or kiosk systems for rapid deployment and consistent UX',
    slug: 'ID-benefits-laptop',
  },
  {
    id: 2,
    title: 'Increased Conversion',
    text: 'Pass genuine users through your risk and compliance in seconds to onboard more good users and keep bad actors out. Our real time approach to ID verification is fast and frictionless, reducing drop-out rates and increasing customer satisfaction',
    slug: 'benefits-people',
  },
  {
    id: 3,
    title: 'Simplified Experience',
    text: 'To simplify your user flow even further, our computer vision algorithms can use information extracted from identity documents and utility bills to pre-fill forms or validate previously entered information',
    slug: 'experience',
  },
  {
    id: 4,
    title: 'Enhanced Efficiency',
    text: 'By integrating an automated verification layer with advanced anti-spoofing and algorithmic document-proofing functionality, you can reduce the time and cost spent on manual review to only those instances where it is really needed',
    slug: 'efficiency',
  },
  {
    id: 5,
    title: 'Streamlined Compliance',
    text: 'Streamline the ID verification component of your global compliance strategy. With over 800 supported documents, our solutions help clients achieve compliance with growing customer identification mandates around the world',
    slug: 'compliance',
  },
];

export const TITLE2 = 'Advanced AI-powered tools and machine learning techniques';

export const HOWITWORKS = [
  {
    id: 1,
    title: 'Detect attempted attacks',
    text: 'Multiple anti-spoofing algorithms detect print and device display attacks',
    slug: 'tamper',
  },
  {
    id: 2,
    title: 'Validate document attributes',
    text: 'AI-powered technology matches proprietary templates and analyses features for authenticity',
    slug: 'features',
  },
  {
    id: 3,
    title: 'Check for tampering',
    text: 'Examination of the document using patented techniques to seek evidence of tampering',
    slug: 'compare',
  },
  {
    id: 4,
    title: 'Go further with facial biometrics and presentation attack detection',
    text: 'When combined with facial biometrics, the document photo is matched against a live facial capture to establish your user\'s true identity',
    slug: 'biometrics',
    button: ROUTES.CAPTURE,
  },
];

export const VIDEO_CONTENT = {
  id: 1,
  title: 'Watch a demo',
  text: 'In this example, Trust Stamp Capture, Identify, and Protect work together in a biometric enrolment user flow',
  video: 'https://player.vimeo.com/video/361351107',
};
