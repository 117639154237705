import React from 'react';
import { asset } from 'helpers';
import { COPY_REAL_ESTATE, FEATURES_REAL_ESTATE } from 'apps/ai/pages/CorporateIndustries/copy';
import BasePage from 'pages/BasePage';
import Button from 'components/Button';
import { ROUTES } from 'constants';
import HeroComponent from '../CorporateComponents/CorporateIndustriesHero';
import CorporateSectionComponent from '../CorporateComponents/CorporateSectionComponent';

const registerUrl = 'https://truststamp.re/';

export default class CorporateRealEstate extends BasePage {
  className = 'ts-CorporateIndustries';
  static title = 'Corporate Real Estate';
  // eslint-disable-next-line max-len
  static description = 'We research and leverage artificial intelligence and machine learning to deliver the most comprehensive facial biometric identity authentication solution.';

  renderFeaturesItem = (feature, index) => {
    return (
      <div key={ index } className={ this.cn`__feature` }>
        <img
          alt={ `icon ${feature.title}` }
          className={ this.cn`__feature-icon` }
          src={ feature.icon }
        />
        <div className={ this.cn`__feature-content` }><p>{ feature.text }</p></div>
      </div>
    );
  };

  renderFeatures() {
    return (
      <section className={ this.rootcn`ts-container` }>
        <div className={ this.rootcn`__features` }>
          <div className={ this.rootcn`__features-container` }>
            { FEATURES_REAL_ESTATE.map((item, index) => (
              this.renderFeaturesItem(item, index)
            )) }
          </div>
          <div className={ this.rootcn`__features-btns` }>
            <Button
              onClick={ () => location.href = registerUrl }
              className={ this.cn`__section__button` }
            >
              Register
            </Button>
            <Button
              onClick={ () => this.navigate(ROUTES.CONTACT_US) }
              className={ this.cn`__section__button` }
            >
              Contact us
            </Button>
          </div>
        </div>
      </section>
    );
  }

  renderGoogleCustomerSurvey() {
    return (
      <section className={ this.rootcn`ts-container` }>
        <div className={ this.rootcn`__gcs` }>
          <img
            src={ asset`ai/corporate-industries/google-customer-survey.png` }
            alt="Google Custome Survey Logo"
            className={ this.rootcn`__gcs-img` }
          />
          <p className={ this.rootcn`__gcs-content` }>
            In a Google Consumer Survey, over 80% of consumers
            believe that Realtors<sup>&reg;</sup> have an obligation to verify a
            stranger&apos;s identity.
          </p>
        </div>
      </section>
    );
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        <HeroComponent title="Real Estate" extraClass="real-estate" />
        <CorporateSectionComponent
          sectionClass="no-padding-bottom"
          data={ COPY_REAL_ESTATE.sectionTop }
        />
        { this.renderFeatures() }
        <CorporateSectionComponent
          sectionClass="padding-small"
          data={ COPY_REAL_ESTATE.sectionBottom }
        />
        { this.renderGoogleCustomerSurvey() }
      </div>
    );
  }
}
