import BaseComponent from 'components/BaseComponent';

/**
 * This is a higher order function responsible for binding utils
 * from BaseComponent to the functional components (which lack context).
 * Make it as slim as possible, remember about lifting the state up when only possible.
 */
export default function BaseFunctionalComponent(component, name) {
  const innerContext = {
    props: {},
    className: `ts-${name}`,
  };

  innerContext.cn = BaseComponent.prototype.cn.bind(innerContext);
  innerContext.rootcn = BaseComponent.prototype.rootcn.bind(innerContext);

  const boundComponent = component.bind(innerContext);
  boundComponent.propTypes = component.propTypes;
  boundComponent.defaultProps = component.defaultProps;

  return boundComponent;
}
